import { cloneDeep } from 'lodash';
import axios from 'axios';

import { IMultiStepperCMSData } from './types';
import { getCurrentDomain } from '../../../constants/cbEnv';

export const transformMultistepCmsContent = (cmsContent: IMultiStepperCMSData, proctorScriptGroup: string | null) => {
  if (!proctorScriptGroup) {
    return cmsContent;
  }
  const MULTIPLIER = 1000;
  const parsedGroupNum = parseInt(proctorScriptGroup, 10);
  if (isNaN(parsedGroupNum) || parsedGroupNum < 1) {
    return cmsContent;
  }
  const convertSteps = (steps: any[]) => {
    const result: any[] = [];

    (steps || []).forEach((step) => {
      const stepNum = step.step_number;
      if (stepNum > 0 && stepNum < MULTIPLIER) {
        const targetStepNum = stepNum * MULTIPLIER + parsedGroupNum;
        const replacementStep = steps.find((s) => s.step_number === targetStepNum);

        if (replacementStep) {
          result.push({ ...replacementStep, step_number: step.step_number });
        } else {
          result.push(step);
        }
      } else if (stepNum < 1) {
        result.push(step);
      }
    });
    return result;
  };

  try {
    const newContent: IMultiStepperCMSData = cloneDeep(cmsContent);
    newContent.templates = newContent?.templates?.map((section) => ({
      ...section,
      steps: section.steps ? convertSteps(section.steps) : [],
    }));
    return newContent;
  } catch (error) {
    return cmsContent;
  }
};

export const populateMultiStepData = async (tdtkAsmtType: string, asmtId: string) => {
  const currentDomain = getCurrentDomain();
  let multiStepData;
  const url = `${currentDomain}/cmsDirectory/DIGITAL_${tdtkAsmtType}/MultiStepTemplate.json`;
  try {
    const cmsResponse = await axios(url);
    multiStepData = cmsResponse?.data;
    const proctorScriptGroupMappingResponse = await axios(
      `${currentDomain}/cmsDirectory/ProctorScriptGroupMapping.json`
    );
    const proctorScriptGroup = (proctorScriptGroupMappingResponse.data || []).find(
      (groupItem: { asmtId: string }) => groupItem.asmtId === asmtId
    );
    multiStepData = transformMultistepCmsContent(cmsResponse?.data, proctorScriptGroup?.group);
  } catch (error) {}
  return multiStepData;
};
