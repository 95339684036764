import {
  RoomCheckinWebsocketDataBody,
  StudentTelemetryWebsocketDataBody,
  WEBSOCKET_MESSAGE_TYPE,
  type WebsocketMessage,
} from './types';
import { getLogTimestamp } from '../../../utils/common';
import cloneDeep from 'lodash/cloneDeep';
import keyBy from 'lodash/keyBy';
import groupBy from 'lodash/groupBy';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import type { IGetRoomData, IGetStudentData, ITDTKData, TDTKQuery } from '../../../apollo/types';
import moment from 'moment';

/*
 * checks if target (existing data) and source (incoming websocket message data) are
 * in the correct format, determines which updates to apply, then checks data validity
 * and merges data if source timestamps are newer
 */

const { REACT_APP_TDTK_ENV } = import.meta.env;

const debug = REACT_APP_TDTK_ENV !== 'prod';

export function mergeMessageIntoData<T extends ITDTKData>(
  query: TDTKQuery<T>,
  incomingWebsocketMessages: WebsocketMessage[]
): { hasUpdated: boolean; updatedQuery: TDTKQuery<T> } {
  let finalHasUpdated = false;
  if (query.resolvedData && Array.isArray(incomingWebsocketMessages) && !isEmpty(incomingWebsocketMessages)) {
    let resolved;

    switch (query.kind) {
      case 'CheckUserName':
      case 'GetAllStudentsForEditRoom': // New one
      case 'GetAvailableAdmins':
      case 'GetAvailableEventsQuery':
      case 'GetAvailableSitesQuery':
      case 'GetClosureReasons':
      case 'GetCMSContent':
      case 'GetCMSMultiStepper':
      case 'GetDigitalStudentsPaginatedRoster':
      case 'GetGroupsInfo':
      case 'GetLoginUser':
      case 'GetNonDigitalPrintable':
      case 'GetNonDigitalStudentsRoster':
      case 'GetNotifications':
      case 'GetPastAndActiveEvents':
      case 'GetPrompts':
      case 'GetRoomDetailsForEditRoom': // New one
      case 'GetRooms':
      case 'GetRoomsAndStudents':
      case 'GetSignInTicketActivity':
      case 'GetSir':
      case 'GetSirs':
      case 'GetSiteStats':
      case 'GetStaff':
      case 'GetStaffAndRooms':
      case 'GetStaffDetail':
      case 'GetStaffInventory':
      case 'GetTestCenter':
      case 'GetVoucher':
      case 'StaffForm':
        resolved = { hasUpdated: false, updatedData: query.resolvedData };
        break;
      case 'GetRoom':
        resolved = mergeGetRoomData(cloneDeep(query.resolvedData), incomingWebsocketMessages);
        break;
      case 'GetStudent':
        resolved = mergeGetStudentData(cloneDeep(query.resolvedData), incomingWebsocketMessages);
        break;
      default:
        // NOTE: Placed here so that the compiler will show an error if a query is not handled
        // or throw an error at runtime
        ((x: never): never => {
          throw new Error(`Invalid query kind: ${x}`);
        })(query);
    }
    if (resolved.hasUpdated && resolved.updatedData) {
      query.resolvedData = resolved.updatedData;
      finalHasUpdated = resolved.hasUpdated;
    }
  }
  return { hasUpdated: finalHasUpdated, updatedQuery: query };
}

function mergeStudentCheckinData(
  student: {
    id: string;
    checkedInRoom?: boolean;
    checkedInRoomTimestamp?: string;
    joinCode?: string | null;
    joinCodeTimestamp?: string;
    startPin?: string | null;
    startPinTimestamp?: string;
    room?: { id?: string } | null;
    roomIdTimestamp?: string;
    updated: number;
  },
  checkinDataForStudent: RoomCheckinWebsocketDataBody
) {
  let hasUpdated = false;
  if (checkinDataForStudent.id === student.id) {
    if (
      checkinDataForStudent.checkedInRoomTimestamp &&
      student.checkedInRoomTimestamp &&
      student.checkedInRoomTimestamp < checkinDataForStudent.checkedInRoomTimestamp
    ) {
      student.checkedInRoom = checkinDataForStudent.checkedInRoom || false;
      student.checkedInRoomTimestamp = checkinDataForStudent.checkedInRoomTimestamp;
      student.updated = new Date(checkinDataForStudent.checkedInRoomTimestamp).getTime();
      hasUpdated = true;
    }
    if (
      checkinDataForStudent.joinCodeTimestamp &&
      student.joinCodeTimestamp &&
      student.joinCodeTimestamp < checkinDataForStudent.joinCodeTimestamp
    ) {
      student.joinCode = checkinDataForStudent?.joinCode || null;
      student.joinCodeTimestamp = checkinDataForStudent.joinCodeTimestamp;
      student.updated = new Date(checkinDataForStudent.joinCodeTimestamp).getTime();
      hasUpdated = true;
    }
    if (
      checkinDataForStudent.startPinTimestamp &&
      student.startPinTimestamp &&
      student.startPinTimestamp < checkinDataForStudent.startPinTimestamp
    ) {
      student.startPin = checkinDataForStudent?.startPin || null;
      student.startPinTimestamp = checkinDataForStudent.startPinTimestamp;
      student.updated = new Date(checkinDataForStudent.startPinTimestamp).getTime();
      hasUpdated = true;
    }
    if (
      checkinDataForStudent.roomIdTimestamp &&
      student.roomIdTimestamp &&
      student.roomIdTimestamp < checkinDataForStudent.roomIdTimestamp
    ) {
      if (student?.room?.id && checkinDataForStudent.room?.id) {
        student.roomIdTimestamp = checkinDataForStudent.roomIdTimestamp;
        student.updated = new Date(checkinDataForStudent.roomIdTimestamp).getTime();
        hasUpdated = true;
      } else if (student?.room?.id && !checkinDataForStudent.room?.id) {
        student.roomIdTimestamp = checkinDataForStudent.roomIdTimestamp;
        student.updated = new Date(checkinDataForStudent.roomIdTimestamp).getTime();
        hasUpdated = true;
      } else if (!student?.room?.id && checkinDataForStudent.room?.id) {
        student.roomIdTimestamp = checkinDataForStudent.roomIdTimestamp;
        student.updated = new Date(checkinDataForStudent.roomIdTimestamp).getTime();
        hasUpdated = true;
      }
    }
  }
  return { hasUpdated };
}

function mergeStudentTelemetryData(
  student: {
    id: string;
    dapExamStatus?: { name: string; timestamp: string };
    dapTestStatus?: { name: string; timestamp: string; payload?: any };
    updated: number;
  },
  telemetryDataForStudent: StudentTelemetryWebsocketDataBody
) {
  let hasUpdated = false;
  if (telemetryDataForStudent.id === student.id) {
    if (
      has(telemetryDataForStudent, 'des') &&
      telemetryDataForStudent.des &&
      has(telemetryDataForStudent.des, 'n') &&
      has(telemetryDataForStudent.des, 'ts') &&
      student?.dapExamStatus?.timestamp &&
      student.dapExamStatus.timestamp < telemetryDataForStudent.des.ts
    ) {
      student.dapExamStatus = {
        name: telemetryDataForStudent.des.n,
        timestamp: telemetryDataForStudent.des.ts,
      };
      student.updated = new Date(telemetryDataForStudent.des.ts).getTime();
      hasUpdated = true;
    }
    if (
      has(telemetryDataForStudent, 'dts') &&
      telemetryDataForStudent.dts &&
      has(telemetryDataForStudent.dts, 'n') &&
      has(telemetryDataForStudent.dts, 'ts') &&
      student?.dapTestStatus?.timestamp &&
      student.dapTestStatus.timestamp < telemetryDataForStudent.dts.ts
    ) {
      student.dapTestStatus = {
        name: telemetryDataForStudent.dts.n,
        timestamp: telemetryDataForStudent.dts.ts,
        payload: telemetryDataForStudent.dts?.p,
      };
      if (student.dapTestStatus?.payload?.sectionName) {
        student.dapTestStatus.payload.__typename = 'DAPSectionStartTime';
      }
      student.updated = new Date(telemetryDataForStudent.dts.ts).getTime();
      hasUpdated = true;
    }
  }
  return { hasUpdated };
}

const mergeDataForNewStudents = (unprocessedRoomCheckins: RoomCheckinWebsocketDataBody[], updatedStudents: any[]) => {
  const newStudents = unprocessedRoomCheckins.map((roomCheckin) => {
    delete roomCheckin.room;
    const newStudent = {
      // Defaults
      // NOTE: Since the query is generic for getRoom, different students
      // across events may have different fields. This is a default set of fields
      // so apollo does not make a network call when missing fields are found
      absent: false,
      adultTestTakerFlag: false,
      candDOB: '',
      candFirstName: '',
      candGender: '',
      candLastName: '',
      candMidInit: '',
      candRegNo: '',
      checkedInCenter: false,
      checkedInRoom: false,
      checkedInRoomTimestamp: '',
      dapExamStatus: null,
      dapTestStatus: null,
      deniedEntry: false,
      displayedRegNo: '',
      grade8OrLowerFlag: false,
      groupType: '',
      joinCode: '',
      joinCodeTimestamp: '',
      multiDayInd: false,
      photoRequiredFlag: false,
      room: null,
      roomIdTimestamp: '',
      accommodationsArray: [],
      startPin: '',
      startPinTimestamp: '',
      testBookNumber: '',
      testPackageCount: 0,
      testPackageSeq: 0,
      updated: 0,
      waitListFlag: false,

      // Updates
      // NOTE: Should be the full payload for the student
      ...roomCheckin,
      __typename: 'StudentType',
    };
    return newStudent;
  });
  return updatedStudents.concat(newStudents);
};

function mergeGetRoomData(
  resolvedData: IGetRoomData,
  incomingWebsocketMessages: WebsocketMessage[]
): { hasUpdated: boolean; updatedData: IGetRoomData } {
  debug && console.debug(`${getLogTimestamp()} (wsmerge) mergeGetRoomData`, JSON.stringify(incomingWebsocketMessages));
  try {
    let finalHasUpdated = false;
    incomingWebsocketMessages.forEach((message) => {
      if (message.t === WEBSOCKET_MESSAGE_TYPE.ROOM_CHECKIN) {
        debug && console.debug(`${getLogTimestamp()} (wsmerge) ROOM_CHECKIN`, JSON.stringify(message));

        const incomingStudentUpdatesKeyedByStudentId = keyBy(message.d, 'id');
        const processed = new Set();

        const updatedStudents = resolvedData.readRoom.students.reduce((accum: any[], student) => {
          const studentUpdate = incomingStudentUpdatesKeyedByStudentId[student.id];
          if (studentUpdate) {
            if (studentUpdate.room && studentUpdate.room.id !== resolvedData.readRoom.id) {
              // Student moved out of the room.
              // Do not add to list
              finalHasUpdated = true;
            } else {
              const { hasUpdated } = mergeStudentCheckinData(student, studentUpdate);
              finalHasUpdated = hasUpdated || finalHasUpdated;
              accum.push(student);
            }
          } else {
            accum.push(student);
          }
          processed.add(student.id);
          return accum;
        }, []);

        const unprocessedRoomCheckins = message.d.filter(
          (studentUpdate) => resolvedData.readRoom.id === studentUpdate.room?.id && !processed.has(studentUpdate.id)
        );

        let finalStudents = updatedStudents;
        if (unprocessedRoomCheckins.length > 0) {
          finalStudents = mergeDataForNewStudents(unprocessedRoomCheckins, finalStudents);
          finalHasUpdated = true;
        }
        resolvedData.readRoom.students = finalStudents;
      }
      if (message.t === WEBSOCKET_MESSAGE_TYPE.STUDENT_TELEMETRY) {
        const telemetryKeyedByStudentId = groupBy(message.d, 'id');
        resolvedData.readRoom.students.forEach((student) => {
          const telemetryArr = telemetryKeyedByStudentId[student.id];
          const latestTelemetryForStudent = getLatestTelemetryValue(telemetryArr);
          if (latestTelemetryForStudent) {
            const { hasUpdated } = mergeStudentTelemetryData(student, latestTelemetryForStudent);
            finalHasUpdated = hasUpdated || finalHasUpdated;
          }
          return student;
        });
      }
    });
    return { hasUpdated: finalHasUpdated, updatedData: resolvedData };
  } catch (e) {
    debug && console.error(`${getLogTimestamp()} (wsmerge) mergeGetRoomData error`, e);
  }
  return { hasUpdated: false, updatedData: resolvedData };
}

function getLatestTelemetryValue(telemetries: StudentTelemetryWebsocketDataBody[] | undefined) {
  if (!telemetries || telemetries.length <= 0) {
    return undefined;
  }
  const latestTelemetry = telemetries.reduce((latest, telemetry) => {
    if (telemetry.dts && moment(telemetry.dts.ts).isAfter(moment(latest?.dts?.ts))) {
      return telemetry;
    } else if (telemetry.des && moment(telemetry.des.ts).isAfter(moment(latest?.des?.ts))) {
      return telemetry;
    }

    return latest;
  }, telemetries[0]);
  return latestTelemetry;
}

function mergeGetStudentData(
  resolvedData: IGetStudentData,
  incomingWebsocketMessages: WebsocketMessage[]
): { hasUpdated: boolean; updatedData: IGetStudentData } {
  debug &&
    console.debug(`${getLogTimestamp()} (wsmerge) mergeGetStudentData`, JSON.stringify(incomingWebsocketMessages));
  try {
    let finalHasUpdated = false;
    incomingWebsocketMessages.forEach((message) => {
      if (message.t === WEBSOCKET_MESSAGE_TYPE.ROOM_CHECKIN) {
        const roomCheckin: RoomCheckinWebsocketDataBody | undefined = message.d.find(
          (checkinData) => checkinData.id === resolvedData.readStudent.id
        );
        if (roomCheckin) {
          const { hasUpdated } = mergeStudentCheckinData(resolvedData.readStudent, roomCheckin);
          finalHasUpdated = hasUpdated || finalHasUpdated;
        }
      }
      if (message.t === WEBSOCKET_MESSAGE_TYPE.STUDENT_TELEMETRY) {
        const telemetryArr: StudentTelemetryWebsocketDataBody[] | undefined =
          message.d.filter((telemetryData) => telemetryData.id === resolvedData.readStudent.id) || [];
        // find the telmetry with the latest timestamp
        if (telemetryArr?.length > 0) {
          const latestTelemetryForStudent = getLatestTelemetryValue(telemetryArr);
          if (latestTelemetryForStudent) {
            const { hasUpdated } = mergeStudentTelemetryData(resolvedData.readStudent, latestTelemetryForStudent);
            finalHasUpdated = hasUpdated || finalHasUpdated;
          }
        }
      }
    });
    return { hasUpdated: finalHasUpdated, updatedData: resolvedData };
  } catch (e) {
    debug && console.error(`${getLogTimestamp()} (wsmerge) mergeGetRoomData error`, e);
  }
  return { hasUpdated: false, updatedData: resolvedData };
}
