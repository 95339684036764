import React, { useCallback, useContext, useEffect, useReducer } from 'react';
import { localStateReducer } from '../../../../utils/common';
import { ROLES_MAP } from '../../../../constants/roles';
import { ModalDispatchContext } from '../../../ui/modal/ModalContext';
import keyBy from 'lodash/keyBy';
import { useMutation } from '@apollo/client';
import { BULK_IMPORT_USERS, RESEND_EMAIL } from '../../../../apollo/mutations';
import { GET_STAFF } from '../../../../apollo/queries';
import OneButtonModal from '../../../ui/modal/standard/OneButtonModal';
import { YellowButton } from '@cb/apricot-react';
import { AddStaff, DrawerTableComponent } from './inventory-staff/AddStaff';
import './StaffInventoryRoster.scss';
import '../../../digital/common/common.scss';

import { canCreateAdmin, isRoleAdmin } from '../../../../utils/user';
import { useStateValue } from '../../../../context/AppContext';

type StaffInventoryRosterProps = {
  onClose: () => void;
  handleDrawerDirty: (isDirty: boolean) => void;
  inventoryStaff: { email: string; firstName: string; id: string; lastName: string; phone: string; role: string }[];
};

function StaffInventoryRoster({ onClose, handleDrawerDirty, inventoryStaff }: StaffInventoryRosterProps) {
  const dispatchModal = useContext(ModalDispatchContext);
  const { orgEvent, user } = useStateValue();

  const [bulkImportUsers] = useMutation(BULK_IMPORT_USERS);
  const [resendEmail] = useMutation(RESEND_EMAIL);

  const [localState, setLocalState] = useReducer(localStateReducer, {
    importStaffSelected: [],
    importStaffSubmitDisabled: true,
    inventoryStaff: inventoryStaff.map((staff) => ({ ...staff, role: staff.role ? staff.role : 'proctor' })),
  });

  const allowAdminRole = canCreateAdmin(orgEvent, user);
  const determineStaffRole = (role: string): string => {
    if (!role || (isRoleAdmin(role) && !allowAdminRole)) {
      return ROLES_MAP.PROCTOR;
    }
    return role;
  };

  useEffect(() => {
    setLocalState({
      inventoryStaff: inventoryStaff.map((staff) => ({ ...staff, role: determineStaffRole(staff.role) })),
    });
  }, [inventoryStaff]);

  const inventoryStaffById = keyBy(localState.inventoryStaff, 'id');
  //decorate staff by adding default role

  const staffChangeHandler = (staff) => {
    const copyInventoryStaff = [...localState.inventoryStaff];
    const index = copyInventoryStaff.findIndex((s) => s.id === staff.id);
    copyInventoryStaff[index] = staff;
    setLocalState({ inventoryStaff: copyInventoryStaff });
    handleDrawerDirty(true);
  };

  let sendBulkEmailCheckboxValue = true;

  function handleImportSubmit(selectedStaff = [], inventoryStaffById = {}) {
    return function (e) {
      e && e.preventDefault && e.preventDefault();

      const users: { id: string; role: string }[] = [];
      const collectedNames = selectedStaff.map((staffId) => {
        const user = inventoryStaffById[staffId];
        if (user) {
          users.push({ id: user.id, role: user.role });
        }
        return `${user?.lastName || ''}, ${user?.firstName || ''}`;
      });

      if (selectedStaff.length > 0) {
        const importStaffSubmitDisabled = true;
        handleDrawerDirty(!importStaffSubmitDisabled);
        setLocalState({
          importStaffSubmitDisabled,
        });

        // onClose() shifts drawer component out of view, but is still rendered, so queries
        // and then/catch blocks still execute.
        onClose();

        bulkImportUsers({
          variables: { input: { users } },
          refetchQueries: ['getStaff', 'getSiteStats'],
          update: (cache, { data: { bulkImportUsers } }) => {
            // bug: bulkImportUsers returns a boolean instead of a list of records
            try {
              // Read the existing data from the local cache using the GET_STAFF query
              const { viewer } = cache.readQuery({ query: GET_STAFF }) || { viewer: { site: { staff: [] } } };
              const importedStaff = Array.isArray(bulkImportUsers) ? bulkImportUsers : [];

              // Create a new object newData by spreading the existing viewer data and updating the staff array
              const newData = {
                viewer: {
                  site: {
                    staff: [...viewer.site.staff, ...importedStaff],
                  },
                },
              };

              // Write the updated data back to the local cach  e using the GET_STAFF query
              cache.writeQuery({
                query: GET_STAFF,
                data: newData,
              });
            } catch (error) {
              // Log an error message if there's an issue updating the cache
              console.error('Error updating cache:', error);
            }
          },
        })
          .then(() => {
            const afterAction = () => {
              setLocalState({
                importStaffSelected: [],
              });
            };

            function handleContinueFromModal() {
              if (sendBulkEmailCheckboxValue) {
                const input = {
                  ids: users?.map((user) => user?.id) || [],
                };
                resendEmail({
                  variables: { input },
                });
              }
              afterAction();
            }

            dispatchModal(
              <OneButtonModal
                body={
                  <>
                    <ul key='imported-staff'>
                      {collectedNames.map((selectedStaff) => (
                        <li key={selectedStaff}>{selectedStaff}</li>
                      ))}
                    </ul>
                    <label key='email-imported-staff' htmlFor='email-imported-staff'>
                      <input
                        id='email-imported-staff'
                        onChange={(e) => {
                          sendBulkEmailCheckboxValue = e.target.checked;
                        }}
                        defaultChecked={true}
                        type='checkbox'
                      />
                      &nbsp; Send notification email to added staff?
                    </label>
                  </>
                }
                modalId='bulkImportConfirm'
                buttonLabel='Close'
                onClose={handleContinueFromModal}
                title={'You’ve added the following staff to this test administration'}
                variant='success'
              />
            );
          })
          .catch((e) => {
            console.error('bulkImportUsers failed', e);
            dispatchModal(
              <OneButtonModal
                body='Please try again later.'
                modalId='bulkStaffError'
                title='We were unable to add these users.'
                variant='error'
              />
            );
          });
      }
    };
  }

  const addUserHandler = useCallback((importStaffSelected) => {
    const importStaffSubmitDisabled = !importStaffSelected || importStaffSelected.length === 0;
    setLocalState({
      importStaffSelected,
      importStaffSubmitDisabled,
    });
    handleDrawerDirty(!importStaffSubmitDisabled);
  }, []);

  return (
    <form
      id='bulkImportStaffForm'
      className='cb-form'
      onSubmit={handleImportSubmit(localState.importStaffSelected, inventoryStaffById)}
    >
      <div className={'sticky-wrapper p-4 form-content form-content-staff-inventory w-100'}>
        <AddStaff
          drawerTableComponent={DrawerTableComponent.inventoryStaff}
          items={localState.inventoryStaff}
          staffChangeHandler={staffChangeHandler}
          {...{ addUserHandler }}
        />
      </div>
      <div className='form-footer flex-row-reverse pr-4'>
        <YellowButton
          small
          data-automation={'button-import-staff'}
          disabled={localState.importStaffSubmitDisabled || !localState.importStaffSelected.length}
          type='submit'
        >
          Add Selected Staff
        </YellowButton>
      </div>
    </form>
  );
}

export default StaffInventoryRoster;
