import React from 'react';
import ProgressBar from './ProgressBar/ProgressBar';
import './common.scss';
import ErrorPage from '../../nonDigital/error/ErrorPage';

interface StepContentTabProps {
  content?: {
    main_panel_header?: string;
    detail_panel_header?: string;
  };
  darkMode?: boolean;
  desc?: React.ReactNode;
  heading?: string;
  invertStyle?: boolean;
  panelHeading?: string;
  progressStepperMax?: number;
  rightPanel?: React.ReactNode;
  showProgressBar?: boolean;
  stepNum?: number;
  subHeading?: React.ReactNode;
}

function StepContentTab({
  content,
  darkMode,
  desc,
  heading,
  invertStyle,
  panelHeading,
  progressStepperMax,
  rightPanel,
  showProgressBar,
  stepNum,
  subHeading,
}: StepContentTabProps) {
  if (!content) {
    return (
      <div className='container'>
        <ErrorPage errorCode='cms_missing' />
      </div>
    );
  }

  return (
    <div className={`wizard-content-tab ${darkMode ? 'wizard-dark-mode' : ''}`}>
      <div className='row mb-4' style={{ justifyContent: 'right', marginLeft: '0', marginRight: '0' }}>
        {rightPanel}
      </div>
      {showProgressBar ? <ProgressBar currentStep={stepNum} totalSteps={progressStepperMax}></ProgressBar> : null}
      <div className='wizard-heading-container mt-3 row d-flex align-items-center'>
        <div
          aria-level={1}
          className='wizard-heading col-xs-12 col-md-7'
          data-automation='wizard-main-heading'
          id='wizard-main-heading'
          role='heading'
          tabIndex={-1}
        >
          {content?.main_panel_header ? content?.main_panel_header : heading}
        </div>
      </div>
      <div>{subHeading}</div>
      <div className='wizard-panel-illustration-board'>
        <div role='heading' aria-level={2} data-automation='wizard-panel-heading' className='wizard-panel-heading'>
          {content?.detail_panel_header ? content?.detail_panel_header : panelHeading}
        </div>
        <div
          data-automation='wizard-panel-desc'
          className={`wizard-panel-desc mt-3 ${invertStyle ? 'wizard-invert-style cb-blue5-shade-1 content-tab' : ''}`}
        >
          <div>{desc}</div>
        </div>
      </div>
    </div>
  );
}

export default StepContentTab;
