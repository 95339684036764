import { cb_table, digital_cb_table } from '../../../constants/accommodations/cb';

/**
 * Look up accommodation information based on id and which table to use
 * @param  {array}   accommodations An array of accommodation id strings, or an accommodationsArray array of objects
 * @param  {string}  table          The accommodations table to use (varies by test type (for now))
 * @return {array}                  An array of accommodations found in the table with supplementalInfo if provided in the input array
 */
export const AccommodationLookup = (accommodations, table) => {
  let accommodationsTable = {};
  if (table === 'cb') {
    accommodationsTable = cb_table;
  } else if (table === 'digital_cb') {
    accommodationsTable = digital_cb_table;
  }
  const aArray = [];
  if (Array.isArray(accommodations) && accommodations.length > 0) {
    // if the first element in the array is a string, we take this path and just look up each string in the table
    if (typeof accommodations[0] === 'string') {
      accommodations.forEach((accommodation) => {
        if (accommodationsTable[accommodation] !== undefined) {
          aArray.push({
            title: accommodation,
            description: accommodationsTable[accommodation],
          });
        } else {
          if (accommodation !== 'null') {
            aArray.push({
              title: accommodation,
              description: 'Contact Services for Students with Disabilities at 844-255-7728',
            });
          }
        }
      });
    } else {
      // if the first array item isn't a string, we assume it's an object and use that object's properties to do lookups
      accommodations.forEach((accommodation) => {
        if (accommodationsTable[accommodation?.code] !== undefined) {
          aArray.push({
            title: accommodation?.code,
            description: accommodationsTable[accommodation?.code],
            supplementalInfo: accommodation?.supplementalInfo,
          });
        } else {
          if (accommodation?.code !== 'null') {
            aArray.push({
              title: accommodation?.code,
              description: 'Contact Services for Students with Disabilities at 844-255-7728',
              supplementalInfo: accommodation?.supplementalInfo,
            });
          }
        }
      });
    }
  }

  return aArray;
};
