import React, { useContext } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';
import keyBy from 'lodash/keyBy';
import unionBy from 'lodash/unionBy';
import { rule } from 'indicative';
import { useMutation } from '@apollo/client';
import { CREATE_SIR, DELETE_SIR, UPDATE_SIR } from '../../../../apollo/mutations';
import { hasAdminAccess } from '../../../../utils/user';
import { localStateReducer, scrollTo, sortItems } from '../../../../utils/common';
import { validate, validateAll } from './utility';
import { useStateValue } from '../../../../context/AppContext';
import { ModalDispatchContext } from '../../../ui/modal/ModalContext';
import Checkbox from '../../../ui/form/Checkbox';
import CheckboxGroup from '../../../ui/form/CheckboxGroup';
import ErrorMessages from '../../../ui/message/ErrorMessage';
import Input from '../../../ui/form/Input';
import OneButtonModal from '../../../ui/modal/standard/OneButtonModal';
import RadioButton from '../../../ui/form/RadioButton';
import RadioButtonGroup from '../../../ui/form/RadioButtonGroup';
import Select from '../../../ui/form/Select';
import Selection from '../../../ui/Selection';
import SelectionContainer from './SelectionContainer';
import TextArea from '../../../ui/form/TextArea';
import TwoButtonModal from '../../../ui/modal/standard/TwoButtonModal';
import { useNavigate } from 'react-router-dom';
import { BlackButton, YellowButton } from '@cb/apricot-react';

type Props = {
  entireTestCenter?: string;
  eventRooms?: any[];
  eventStudents?: any[];
  id?: string;
  irregularity: any;
  irregularityCategory: any;
  readOnly?: boolean;
  rooms?: any[];
  students?: any[];
  updating?: boolean;
  userResponse?: string;
};

function IrregularityForm({
  entireTestCenter = '',
  eventRooms = [],
  eventStudents = [],
  id = '',
  irregularity = {},
  irregularityCategory = {},
  readOnly = false,
  rooms = [],
  students = [],
  updating = false,
  userResponse = '',
}: Props) {
  const navigate = useNavigate();

  function buildValidation(prompt) {
    const validations = [];
    if (prompt.required) {
      validations.push(rule('required'));
    }
    if (prompt.responseType === 'checkbox') {
      const minLength = prompt.required ? (prompt.validation_minlength > 0 ? prompt.validation_minlength : 1) : 0;

      validations.push(rule('checkbox', minLength));
    }

    if (prompt.responseType === 'textarea' || prompt.responseType === 'textbox') {
      const minLength = get(prompt, 'validation_minlength') || 0;
      const maxLength = get(prompt, 'validation_maxlength') || 9999;
      const maxValue = maxLength > 9999 || maxLength <= 0 ? 9999 : maxLength;

      const regex = get(prompt, 'validation_regex')
        ? new RegExp(get(prompt, 'validation_regex'))
        : new RegExp(/^[ a-zA-Z0-9()#&:.,;?!$'\n\r"]*$/);

      // Pushing Validations
      validations.push(rule('string'));

      validations.push(rule('regex', regex));

      // Min validation doesn't register 0 as an argument
      minLength > 0 && validations.push(rule('min', minLength));
      validations.push(rule('max', maxValue));
    } else if (prompt.responseType.startsWith('radio')) {
      // Pushing Validations
      validations.push(rule('array'));
      // Custom choice function
      validations.push(rule('choice', prompt.responseList));
    } else if (prompt.responseType === 'multiselect') {
      // Pushing Validations
      validations.push(rule('array'));

      // Custom selection function
      validations.push(rule('selection', prompt.responseList));
    }

    return validations;
  }

  function buildFormObject(isDraft = false) {
    const { entireTestCenter, id, irregularity, irregularityCategory, rooms, students, userResponse } = localState;

    const roomRecords: { id: string; title: string }[] = [];
    const studentRecords: any[] = [];

    let singleRoomRecord: Record<string, any> = {};
    let singleStudentRecord: any = {};

    const roomsByRoomId = keyBy(eventRooms, 'id');

    // Get the full room record from the event's list of rooms.
    rooms.forEach((room) => {
      singleRoomRecord = roomsByRoomId[room.id];

      if (singleRoomRecord && singleRoomRecord.id) {
        roomRecords.push({
          id: singleRoomRecord.id,
          title: singleRoomRecord.title,
        });
      }
    });

    // Get the full room record from the event's list of rooms.
    students.forEach((student) => {
      singleStudentRecord = eventStudents.find((s) => s.id === student.id);
      if (singleStudentRecord && singleStudentRecord.id) {
        studentRecords.push({
          candDOB: singleStudentRecord.candDOB,
          candFirstName: singleStudentRecord.candFirstName,
          candLastName: singleStudentRecord.candLastName,
          candMidInit: singleStudentRecord.candMidInit || '',
          displayedRegNo: singleStudentRecord.displayedRegNo || '',
          groupType: singleStudentRecord.groupType,
          id: singleStudentRecord.id,
          multiDayInd: singleStudentRecord.multiDayInd,
          regId: singleStudentRecord.candRegNo,
          roomId: singleStudentRecord.room ? singleStudentRecord.room.id : '',
          roomName: singleStudentRecord.room ? singleStudentRecord.room.title : 'Unassigned',
          ssdAccommodations: singleStudentRecord.accommodationsArray?.map((item) => item.code).join(', '),
          testPackageCount: singleStudentRecord.testPackageCount,
          testPackageSeq: singleStudentRecord.testPackageSeq,
        });
      }
    });

    // Only pass the appropriate IDs if creating an IR.
    const passIds = id
      ? {
          id,
        }
      : {
          irregularityCategoryId: irregularityCategory.id,
          irregularityId: irregularity.id,
        };

    // Format the object for GraphQL.
    const inputObj = {
      ...passIds,
      entireTestCenter: hasStudentsAffected ? 'students' : entireTestCenter,
      rooms: roomRecords,
      statusText: isDraft ? 'drafted' : getSubmitStatus(user.role),
      students: studentRecords,
      userResponse: JSON.stringify({ ...userResponse }),
    };

    return inputObj;
  }

  function handleCancel() {
    navigate(-1);
  }

  async function handleChange(val, e) {
    // handle variable inputs to this function for the Apricot 4 transition
    if (!e) {
      e = val;
    }

    // Get the type of input to put it in the right spot.
    const inputType = e?.target?.type;
    const inputChecked = e?.target?.checked;
    const selectedResponse = e?.target?.value;
    let name = e?.target?.name;

    // Create a new copy to avoid mutating original state.
    const clientErrors = {};
    let selectedResponseText = '';
    let statePrompts: any = [];
    let userResponseArr: any = [];

    if (inputType === 'checkbox') {
      const uuidRegex = /\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/;
      name = uuidRegex.exec(e?.target?.id)?.[0] || '';
    }
    // Determine if the element was unchecked.
    if (inputType === 'checkbox' || inputType === 'radio') {
      if (!inputChecked) {
        // They are un-checking this element.
        if (localState.userResponse[name] !== undefined && localState.userResponse[name].selectedResponse.length) {
          // Filter out all instances of this text match.
          statePrompts = localState.userResponse[name].selectedResponse.filter((i) => {
            return i !== selectedResponse;
          });
        }
      } else {
        // If this is a checkbox, we're adding an additional element.
        if (
          inputType === 'checkbox' &&
          localState.userResponse[name] !== undefined &&
          localState.userResponse[name].selectedResponse.length
        ) {
          // They checked it, so add it to the array removing duplicates.
          statePrompts = [...new Set([...localState.userResponse[name].selectedResponse, selectedResponse])];
        } else {
          // Only use this value if it's checked.
          statePrompts = [selectedResponse];
        }
      }
    } else {
      // It's something else, so just use text.
      selectedResponseText = selectedResponse;
    }

    // Validate the current input.
    await validateAll(
      {
        ...localState.userResponse,
        [name]: {
          selectedResponse: statePrompts,
          selectedResponseText: selectedResponseText,
        },
      },
      localState.promptRules
    ).catch((e) => {
      userResponseArr = pullOutErrors(e, 0);

      userResponseArr.forEach((error) => {
        const field = error.key;

        // Pull out the error.
        clientErrors[field] = error.errorMessage;
      });
    });

    setLocalState({
      clientErrors: {
        ...localState.clientErrors,
        [name]: clientErrors[name] || '',
      },
      submitDisabled: false,
      userResponse: {
        ...localState.userResponse,
        [name]: {
          selectedResponse: statePrompts,
          selectedResponseText: selectedResponseText,
        },
      },
    });
  }

  async function validateSelectionFields(newState) {
    const { entireTestCenter = '', rooms = [], students = [] } = newState;

    const clientErrors = {};

    await validate(entireTestCenter, ['required'])
      .then(async () => {
        if (entireTestCenter === 'rooms') {
          await validate(rooms, ['required']).catch(() => {
            clientErrors['rooms'] = errorText('required', 'Choose one or more testing rooms');
          });
        } else if (entireTestCenter === 'students') {
          await validate(students, ['required']).catch(() => {
            clientErrors['students'] = errorText('required', 'Choose one or more students');
          });
        }
      })
      .catch(() => {
        clientErrors['entireTestCenter'] = errorText('required', 'Which students were involved in this irregularity?');
      });

    return clientErrors;
  }

  async function validateForm(newState, isDraft, hasStudentsAffected = false) {
    let clientErrors = {};
    const promptRules = { ...newState.promptRules };
    let userResponseArr: any[] = [];

    // Don't validate the top selection if this is a draft.
    if (!isDraft && !hasStudentsAffected) {
      clientErrors = await validateSelectionFields(newState);
    }

    // Only validate the Regex stuff if they're saving a draft.
    if (isDraft) {
      Object.keys(promptRules).forEach((key) => {
        promptRules[key].forEach((validation, index) => {
          if (validation.name === 'required') {
            delete promptRules[key][index];
          } else if (validation.name === 'checkbox') {
            delete promptRules[key];
          }
        });
      });
    }

    await validateAll(newState.userResponse, promptRules).catch((e) => {
      userResponseArr = pullOutErrors(e, 0);

      userResponseArr.forEach((error) => {
        const field = error.key;

        // Pull out the error.
        clientErrors[field] = error.errorMessage;
      });
    });

    return clientErrors;
  }

  function handlerFunction() {
    /**
     * Send data to GraphQL.
     */
    deleteIrregularity({
      variables: {
        input: {
          id: localState.id,
        },
      },
    })
      .then(() =>
        dispatchModal(
          <OneButtonModal
            modalId='IrrDeleteSuccess'
            onClose={() => navigate(-1)}
            title='You&rsquo;ve deleted this report.'
            buttonLabel='Ok'
            variant='success'
          />
        )
      )
      .catch((e) => {
        // We've failed! Scroll to top so they can see the new errors on the form.
        if (e.graphQLErrors && e.graphQLErrors.length > 0 && e.graphQLErrors[0].payload) {
          setLocalState({
            errors: e.graphQLErrors[0].payload,
          });
        } else {
          setLocalState({
            errors: {
              code: 'error',
              error: e,
            },
          });
        }
        scrollTo('generalInfoFormErrorBlock');
      });
  }

  function handleDelete(e) {
    e && e.preventDefault && e.preventDefault();

    if (localState.id) {
      // Trigger warning modal first.
      return dispatchModal(
        <TwoButtonModal
          title={'Are you sure?'}
          modalId='deleteIrrForm'
          variant='error'
          body={
            <p className='mb-4' key='deleteModal'>
              You&rsquo;re about to delete this report.
            </p>
          }
          primaryButtonLabel='Delete'
          primaryButtonHandler={handlerFunction}
        />
      );
    }
  }

  function handleSubmit(isDraft = false, hasStudentsAffected = true) {
    return async function (e) {
      e && e.preventDefault && e.preventDefault();

      let firstError = '';

      // Disable the submit buttons while we do stuff.
      setLocalState({
        submitDisabled: true,
      });

      const clientErrors = await validateForm(localState, isDraft, hasStudentsAffected);

      // If we have any client errors, find the first one and scroll to it.
      if (!isEmpty(clientErrors)) {
        firstError = Object.keys(clientErrors)[0];

        if (firstError) {
          // We've failed! Scroll to top so they can see the new errors on the form.
          scrollTo(firstError);
        }
      }

      const submitFunc = localState.updating ? updateIrregularity : createIrregularity;
      let inputObj = {};

      // Build the form object.
      inputObj = buildFormObject(isDraft);

      if (isEmpty(clientErrors)) {
        // Form is OK to submit, no client errors found.
        /**
         * Send data to GraphQL.
         */
        submitFunc({
          variables: {
            input: inputObj,
          },
        })
          .then(() => {
            navigate('/irregularities');
          })
          .catch((e) => {
            if (e.graphQLErrors && e.graphQLErrors.length > 0 && e.graphQLErrors[0].payload) {
              setLocalState({
                errors: e.graphQLErrors[0].payload,
              });
            } else {
              setLocalState({
                errors: {
                  code: 'error',
                  error: e,
                },
              });
            }
            // Re-enable the buttons when ready.
            setLocalState({
              submitDisabled: false,
            });
          });
      } else {
        // We have client errors, update the state.
        setLocalState({
          clientErrors,
          submitDisabled: false,
        });
      }
    };
  }

  function errorText(
    errorType,
    field,
    regexMessage = 'can include only letters, numbers, spaces, and these symbols: ( ) # & : ; . , \' " ? ! $.',
    error
  ) {
    // Remove ending punctuation.
    const formattedField = field.replace(/[^A-Za-z()\-0-9\s]/i, '');

    let prefixMessage = `"${formattedField}" `;
    let msg = '';

    // Print out a pretty error message.
    switch (errorType) {
      case 'required':
        msg = 'is required.';
        break;

      case 'max':
        msg = 'has too many characters.';
        break;

      case 'min':
        msg = 'doesn&lsquo;t have enough characters.';
        break;

      case 'regex':
        msg = regexMessage;
        break;

      case 'checkbox':
        prefixMessage = '';
        msg = error.message;
        break;

      default:
        msg = 'contains an error.';
    }
    return `${prefixMessage}${msg}`;
  }

  function pullOutErrors(errors: any[] = [], index) {
    return errors.map((error) => {
      let ir = '';
      let key = error.field?.split('.')[index];
      let regexMessage;

      // entireTestCenter is a custom validation not on the same line as all other prompts.
      if (error.field === 'entireTestCenter') {
        ir = 'Which students were involved in this irregularity?';
        key = 'entireTestCenter';
      } else if (error.field === 'rooms') {
        ir = 'Choose one or more testing rooms';
        key = 'rooms';
      } else if (error.field === 'students') {
        ir = 'Choose one or more students';
        key = 'students';
      } else {
        // Find the specific field that is throwing the error and return a proper error message if applicable.
        localState.irregularity.promptCategoryList.forEach((category) => {
          category.promptList.forEach((prompt) => {
            if (prompt.id === key) {
              ir = prompt.label;
              if (prompt.validation_regex_message) {
                regexMessage = prompt.validation_regex_message;
              }
            }
          });
        });
      }

      const errorMessage = errorText(error.validation, ir, regexMessage, error);

      return {
        errorMessage,
        key,
        uiName: key,
      };
    });
  }

  async function handleSelectChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    const clientErrors = {};
    const newState: any = {
      [name]: value,
    };

    // Clear certain values based on if they are changing the top selection.
    if (name === 'entireTestCenter') {
      if (value === 'rooms') {
        newState.students = [];
      } else if (value === 'students') {
        newState.rooms = [];
      } else {
        newState.rooms = [];
        newState.students = [];
      }
    }

    // Validate this field.
    await validate(value, {
      entireTestCenter: [rule('required'), rule('in', ['entireTestCenter', 'rooms', 'students'])],
    })
      .then(() => {
        // No error.
        clientErrors['entireTestCenter'] = '';
      })
      .catch(() => {
        clientErrors['entireTestCenter'] = errorText('required', 'Which students were involved in this irregularity?');
      });

    // Set the value of the selectbox they changed.
    setLocalState({
      ...newState,
      clientErrors: {
        ...localState.clientErrors,
        ...clientErrors,
      },
      submitDisabled: false,
    });
  }

  function handleMultiSelectChange(id) {
    return async function (selectedValues) {
      let clientErrors = {};
      // Deep copy to ensure we don't lose anything.
      let newState = cloneDeep(localState);

      if (id === 'rooms' || id === 'students') {
        // Add these up a level.
        newState[id] = selectedValues;
      } else {
        newState = {
          userResponse: {
            ...localState.userResponse,
            [id]: {
              selectedResponse: selectedValues || [],
              selectedResponseText: '',
            },
          },
        };
      }

      // Validate the form with the new values.
      clientErrors = await validateForm(newState, false);

      setLocalState({
        ...newState,
        clientErrors: {
          ...localState.clientErrors,
          [id]: clientErrors[id] || '',
        },
      });
    };
  }

  function getSubmitStatus(role) {
    switch (role) {
      // If they are an admin or cb admin, submit to College Board.
      case 'admin':
      case 'CBAdmin':
        return 'cb_submit';

      // Else, submit to coordinator.
      default:
        return 'coordinator_submit';
    }
  }

  function renderPrompt(
    error = '',
    prompt: Record<string, any> = {},
    readOnly = false,
    selectedResponse: any[] = [],
    selectedResponseText = ''
  ) {
    const radioOptions = {};

    if (prompt.responseList) {
      // Create the options.
      prompt.responseList.forEach((response) => {
        radioOptions[response] = response;
      });
    }

    let selectOptions: { label: string; title: string; value: string }[] = [];

    if (prompt.responseList) {
      // Create the options.
      selectOptions = prompt.responseList.map((response) => {
        return {
          label: response,
          title: response,
          value: response,
        };
      });
    }

    const isCheckbox = () => {
      return (
        <CheckboxGroup
          additionalInstructions={prompt.description}
          errorMessage={error}
          fieldsetId={`checkbox-${prompt.id}-label`}
          id={prompt.id}
          key={prompt.id}
          legend={prompt.label}
          name={prompt.id}
          required={prompt.required}
          value={selectedResponse ? selectedResponse || '-' : '-'}
          vertical
        >
          {Object.keys(radioOptions).map((key: string) => {
            return (
              <Checkbox
                checked={selectedResponse.includes(key)}
                disabled={readOnly}
                id={`${prompt.id}-${key}`}
                key={`${prompt.id}-${key}`}
                label={radioOptions[key]}
                onChange={handleChange}
                value={key}
              />
            );
          })}
        </CheckboxGroup>
      );
    };

    const isMultiselect = () => {
      return (
        <Selection
          additionalInstructions={prompt.description}
          ariaDescribedBy={error !== '' ? `promptError-${prompt.id}` : ''}
          elements={selectOptions}
          errorMessage={error}
          handleSelectChange={handleMultiSelectChange}
          id={prompt.id}
          key={prompt.id}
          label={prompt.label}
          labelKeys={['title']}
          name={prompt.id}
          readOnly={readOnly}
          required={prompt.required}
          selectedElements={selectedResponse ? selectedResponse : []}
          valueKey='value'
        />
      );
    };

    const isRadio = () => {
      return (
        <div className='tdtk-form-group' key={prompt.id}>
          <RadioButtonGroup
            additionalInstructions={prompt.description}
            disabled={readOnly}
            errorMessage={error}
            fieldsetId={`radio-${prompt.id}-label`}
            id={prompt.id}
            legend={prompt.label}
            name={prompt.id}
            onChange={handleChange}
            required={prompt.required}
            useAdditionalInstructionsAsLegend={!!prompt.description}
            value={selectedResponse ? selectedResponse[0] || '-' : '-'}
            vertical
          >
            {Object.keys(radioOptions).map((key) => {
              return (
                <RadioButton
                  disabled={readOnly}
                  id={`${prompt.id}-${key}`}
                  key={`${prompt.id}-${key}`}
                  label={radioOptions[key]}
                  value={key}
                />
              );
            })}
          </RadioButtonGroup>
        </div>
      );
    };

    const isRadioOther = () => {
      return (
        <div className='tdtk-form-group' key={prompt.id}>
          <RadioButtonGroup
            additionalInstructions={prompt.description}
            disabled={readOnly}
            errorMessage={error}
            fieldsetId={`radio-${prompt.id}-label`}
            id={prompt.id}
            legend={prompt.label}
            name={prompt.id}
            onChange={handleChange}
            required={prompt.required}
            value={selectedResponse ? selectedResponse[0] || '-' : '-'}
            vertical
          >
            {Object.keys(radioOptions).map((key) => {
              return (
                <RadioButton
                  disabled={readOnly}
                  id={`${prompt.id}-${key}`}
                  key={`${prompt.id}-${key}`}
                  label={radioOptions[key]}
                  value={key}
                />
              );
            })}
          </RadioButtonGroup>
        </div>
      );
    };

    const isSelect = () => {
      return (
        <div className='my-4' key={`${prompt.id}-ctn`}>
          <Select
            additionalInstructions={prompt.description}
            disabled={readOnly}
            errorMessage={error}
            id={prompt.id}
            key={prompt.id}
            label={prompt.label}
            labelClassName='cb-roboto-bold'
            name={prompt.id}
            onChange={(val) => {
              val = {
                target: {
                  name: prompt.id,
                  type: 'select',
                  value: val,
                },
              };
              handleChange(val);
            }}
            required={prompt.required}
            value={selectedResponseText || '-'}
            values={[
              {
                label: 'Select...',
                value: '',
              },
              ...selectOptions,
            ]}
          />
        </div>
      );
    };

    const isTextarea = () => {
      return (
        <div className='mt-4' key={`${prompt.id}-ctn`}>
          <TextArea
            additionalInstructions={prompt.description}
            ariaDescribedby={error !== '' ? `promptError-${prompt.id}` : ''}
            disabled={readOnly}
            errorMessage={error}
            floating={false}
            id={prompt.id}
            key={prompt.id}
            label={prompt.label}
            labelClassName='cb-roboto-bold'
            maxLength={prompt.validation_maxlength}
            name={prompt.id}
            onChange={handleChange}
            required={prompt.required}
            value={selectedResponseText ? selectedResponseText : ''}
          />
        </div>
      );
    };

    const isTextBox = () => {
      return (
        <Input
          additionalInstructions={prompt.description}
          defaultValue={selectedResponseText ? selectedResponseText : ''}
          disabled={readOnly}
          errorMessage={error}
          floating={false}
          id={prompt.id}
          key={prompt.id}
          label={prompt.label}
          labelClassName='cb-roboto-bold'
          maxLength={prompt.validation_maxlength}
          name={prompt.id}
          onChange={handleChange}
          required={prompt.required}
        />
      );
    };

    const formElements = {
      checkbox: isCheckbox,
      multiselect: isMultiselect,
      radio: isRadio,
      radio_other: isRadioOther,
      select: isSelect,
      textarea: isTextarea,
      textbox: isTextBox,
    };

    return formElements[prompt.responseType]();
  }

  function studentsAffectedComponent() {
    /** Merge saved data into existing query in case a reference breaks */
    let mergedRooms = unionBy(rooms, eventRooms, 'id');

    mergedRooms.sort((a, b) => {
      if (a.title < b.title) {
        return -1;
      } else if (a.title > b.title) {
        return 1;
      } else {
        return 0;
      }
    });

    mergedRooms = sortItems(
      mergedRooms,
      [
        {
          name: 'title',
          order: 'asc',
        },
      ],
      'natural'
    );

    const mergedStudents = unionBy(students, eventStudents, 'id');
    mergedStudents.sort((a, b) => {
      if (a.candLastName < b.candLastName) {
        return -1;
      } else if (a.candLastName > b.candLastName) {
        return 1;
      } else {
        return 0;
      }
    });

    return (
      <SelectionContainer
        entireTestCenter={localState.entireTestCenter}
        errorMessages={localState.clientErrors}
        handleSelectChange={handleSelectChange}
        handleMultiSelectChange={handleMultiSelectChange}
        mergedRooms={mergedRooms}
        mergedStudents={mergedStudents}
        readOnly={readOnly}
        selectedRooms={localState.rooms}
        selectedStudents={localState.students}
      />
    );
  }

  const promptRules = {};
  let field = 'selectedResponseText';

  irregularity.promptCategoryList.forEach((promptGroup) => {
    promptGroup.promptList.forEach((prompt) => {
      if (
        prompt.responseType === 'radio' ||
        prompt.responseType === 'radio_other' ||
        prompt.responseType === 'multiselect' ||
        prompt.responseType === 'checkbox'
      ) {
        field = 'selectedResponse';
        promptRules[`${prompt.id}.${field}`] = buildValidation(prompt);
      } else {
        field = 'selectedResponseText';
        promptRules[`${prompt.id}.${field}`] = buildValidation(prompt);
      }
    });
  });

  // Global App state.
  const { user } = useStateValue();
  const dispatchModal = useContext(ModalDispatchContext);

  // Local state.
  const [localState, setLocalState] = React.useReducer(localStateReducer, {
    clientErrors: {},
    entireTestCenter,
    id: id || null,
    irregularity: irregularity || {},
    irregularityCategory: irregularityCategory || {},
    promptRules,
    rooms: rooms || [],
    statusText: 'drafted',
    students: students || [],
    submitDisabled: false,
    updating: updating || false,
    userResponse: userResponse ? JSON.parse(userResponse) : {},
  });

  // Apollo.
  const [createIrregularity] = useMutation(CREATE_SIR);
  const [deleteIrregularity] = useMutation(DELETE_SIR);
  const [updateIrregularity] = useMutation(UPDATE_SIR);

  // Refs.
  const refButtonDelete = React.useRef();

  const { promptCategoryList } = irregularity;

  let fieldError = '';
  let generatedErrors = [];
  const promptLabels = {};
  let renderedQuestions = [];
  let selectedResponse = [];
  let selectedResponseText = '';

  if (promptCategoryList) {
    renderedQuestions = promptCategoryList.map((promptGroup) => {
      return (
        <fieldset key={promptGroup.order}>
          <legend className='ir-legend'>{promptGroup.label}</legend>
          {promptGroup.promptList.map((prompt) => {
            const promptFieldName = prompt.id;

            // Set defaults to blank. We'll check if these have values below.
            fieldError = localState.clientErrors[promptFieldName] || '';
            selectedResponse = localState.userResponse[promptFieldName]
              ? localState.userResponse[promptFieldName].selectedResponse
              : [];
            selectedResponseText = localState.userResponse[promptFieldName]
              ? localState.userResponse[promptFieldName].selectedResponseText
              : '';

            // Set the key: value pair for the Server-side error to reference.
            promptLabels[prompt.id] = prompt.label;

            return renderPrompt(fieldError, prompt, readOnly, selectedResponse, selectedResponseText);
          })}
        </fieldset>
      );
    });
  }

  if (localState.errors) {
    if (isPlainObject(localState.errors) && !!localState.errors.code) {
      generatedErrors = [
        {
          errorMessage: 'Could not Add Irregularity',
          key: '',
          uiName: '',
        },
      ];
    } else {
      generatedErrors = pullOutErrors(localState.errors, 1);
    }
    scrollTo('error-content');
  }

  // Determine if we are getting a "Students Affected" section from the CMS.
  const hasStudentsAffected =
    promptCategoryList.find((irSection) => irSection.label === 'Students Affected') !== undefined;

  // Added 'noValidate' to disable built-in browser validation since we'll be handling it.
  return (
    <form id='top-of-ir-form' noValidate onSubmit={handleSubmit(false, hasStudentsAffected)} tabIndex='-1'>
      <p className='mb-4'>* = Required</p>
      <div id='error-content'>
        <ErrorMessages errorMessages={generatedErrors} />
      </div>
      {
        // If the IR CMS has "Students Affected" don't show our custom component.
        !hasStudentsAffected && <React.Fragment>{studentsAffectedComponent()}</React.Fragment>
      }

      {renderedQuestions}

      <div className='tdtk-form-group tdtk-primary-secondary display-flex--column-mobile'>
        <YellowButton
          small
          className='text--white-space-normal'
          disabled={readOnly || localState.submitDisabled}
          type='submit'
        >
          {hasAdminAccess(user.rid, user.role)
            ? readOnly || localState.submitDisabled
              ? 'Submitted to College Board'
              : 'Submit to College Board'
            : 'Submit to Coordinator'}
        </YellowButton>
        {
          /** Only show "Save Draft" if they can still edit the form. */
          !readOnly ? (
            <BlackButton small className='text--white-space-normal' onClick={handleSubmit(true)}>
              Save Draft
            </BlackButton>
          ) : null
        }

        <BlackButton small className='text--white-space-normal border--none' onClick={handleCancel}>
          {!readOnly ? 'Cancel Changes' : 'Close'}
        </BlackButton>

        {
          /** Only show "Delete" if:
           * They are still allowed to edit this form
           * They are a Coordinator or CBAdmin
           * This is already saved at least once in the db.
           * This form has an ID */
          !readOnly && hasAdminAccess(user.rid, user.role) && localState.updating && localState.id ? (
            <BlackButton
              small
              className='text--white-space-normal'
              disabled={readOnly || localState.submitDisabled}
              onClick={handleDelete}
              id='button-deleteIRDraft'
              ref={refButtonDelete}
            >
              Delete
            </BlackButton>
          ) : null
        }
      </div>
    </form>
  );
}

export default IrregularityForm;
