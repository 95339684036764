const staffRoles = {
  proctor: 'Proctor',
  hallMonitor: 'Hall Monitor',
  roomMonitor: 'Room Monitor',
  technologyCoordinator: 'Technology Coordinator',
};

const cbAdminRoleProps = {
  CB_ADMIN_USER_RID: '803',
  CB_ADMIN_USER_ROLE: 'CBAdmin',
};

const roles = {
  admin: 'Coordinator',
  ...staffRoles,
};

const ROLES_MAP = {
  ADMIN: 'admin',
  PROCTOR: 'proctor',
  HALL_MONITOR: 'hallMonitor',
  ROOM_MONITOR: 'roomMonitor',
  TECHNOLOGY_COORDINATOR: 'technologyCoordinator',
};

const digitalRoles = {
  admin: 'Coordinator',
  ...staffRoles,
};

const rolesDisplay = [
  {
    title: 'Coordinator',
    value: 'admin',
    requireAdmin: true,
  },
  { title: 'Proctor', value: 'proctor' },
  { title: 'Hall Monitor', value: 'hallMonitor' },
  { title: 'Room Monitor', value: 'roomMonitor' },
];

const digitalrolesDisplay = [...rolesDisplay, { title: 'Technology Coordinator', value: 'technologyCoordinator' }];

const allRoles = Object.keys(roles);

// @todo Remove CBAdmin when it's not needed.
allRoles.push('CBAdmin');

const getTitle = (roleName) => {
  const matchingRole = digitalrolesDisplay.find((item) => {
    return item.value === roleName;
  });

  return matchingRole ? matchingRole.title : '';
};

export {
  roles,
  allRoles,
  getTitle,
  staffRoles,
  rolesDisplay,
  digitalrolesDisplay,
  digitalRoles,
  cbAdminRoleProps,
  ROLES_MAP,
};
