import React from 'react';
import Accordion from '../../../ui/accordion/AccordionWrapper';
import AccordionItem from '../../../ui/accordion/AccordionItemWrapper';
import IrregularityFormContainer from '../form/Container';
import QueryComponent from '../../network/QueryComponent';
import get from 'lodash/get';
import { GET_PROMPTS } from '../../../../apollo/queries';
import ListTable from '../../../ui/list/ListTable';
import { useStateValue } from '../../../../context/AppContext';
import { useTitle } from '../../../../constants/useTitle';
import { isInSchoolPSATSATEvent, isWeekendEvent } from '../../../../utils/event';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { doNotDisplayRetestCategoryEventList, displayIrCategoryFilter } from './util';
import sanitizeHtml from 'sanitize-html';

type IrCategory = {
  __typename: 'IrregularityCategoryType';
  id: string;
  label: string;
  description: string;
  order: number;
  irregularityList: Record<string, any>[];
};

function Container() {
  useTitle('Add Irregularity ');
  const navigate = useNavigate();
  const params = useParams();

  // Global App state.
  const { orgEvent } = useStateValue();

  function handleClick(e, url) {
    e && e.preventDefault && e.preventDefault();

    // Push the URL to this form.
    navigate(`${url}`);
  }

  const irCategoryFilter = (category: IrCategory) =>
    displayIrCategoryFilter(category, orgEvent.asmtEventId, doNotDisplayRetestCategoryEventList);

  function prepareAccordion(irregularityCategoryList: IrCategory[]) {
    const accordionItems = [];
    let i = 0;
    let listItems = [];
    // We need to render an accordion.
    irregularityCategoryList.filter(irCategoryFilter).forEach((category) => {
      // Loop through this category list and build a simple list array.
      category.irregularityList.forEach((simpleListItem) => {
        listItems.push({
          id: simpleListItem.id,
          label: simpleListItem.label,
          description: simpleListItem.description,
          url: `/irregularities/add/${category.id}/${simpleListItem.id}`,
        });
      });

      // Create the parent accordion and populate it with the simple list array.
      accordionItems.push({
        key: category.order,
        title: category.label ? <span>{category.label}</span> : <span>-</span>,
        description: category.description,
        body: (
          <ListTable
            callerName='irreg-categories'
            clickHandler={handleClick}
            key={`irregularity-category-${i++}`}
            list={listItems}
          />
        ),
      });

      // Empty the array for the next run.
      listItems = [];
    });

    return accordionItems;
  }

  const { irregularityCategoryId = '', irregularityId = '' } = params;

  return (
    <div className={orgEvent?.dapInd ? 'container' : ''}>
      <div className='col-md-12'>
        <h1 className='tdtk-h2 py-4' data-automation='heading-h1'>
          Add Irregularity
        </h1>
        {irregularityId === '' ? (
          isWeekendEvent(orgEvent) ? (
            <p data-automation='create-report-message' className='mb-4'>
              Submit a report for each individual incident that happens during the test administration.
            </p>
          ) : isInSchoolPSATSATEvent(orgEvent) ? (
            <div>
              {' '}
              <p data-automation='create-report-message-1' className='mb-4'>
                {' '}
                <strong>When not to submit an irregularity:</strong> if students didn’t enter a room code,{' '}
                <Link to='/help#rescheduling'> follow the instructions for rescheduling tests.</Link>{' '}
              </p>{' '}
              <p data-automation='create-report-message-2' className='mb-4'>
                {' '}
                <strong>Submit an irregularity</strong> to set up a retest for students who entered the room code, to
                report a security issue or other irregularity, or to cancel a score on behalf of a student.{' '}
              </p>{' '}
              <p data-automation='create-report-message-3' className='mb-4'>
                {' '}
                <strong>If you need to correct roster data,</strong> update it in the system before trying to test the
                student again.{' '}
                <Link to='/help#correcting'> Learn how to correct registration and accommodations data.</Link>{' '}
              </p>{' '}
            </div>
          ) : (
            <p data-automation='create-report-message' className='mb-4'>
              Create a report for each incident that happens during the test administration.
            </p>
          )
        ) : (
          ''
        )}
        <QueryComponent query={{ kind: 'GetPrompts', specification: GET_PROMPTS }}>
          {(data) => {
            const irregularityCategoryList = get(data, 'irregularityCategoryList') || [];
            let chosenCategory = {};
            let chosenIR = {};
            if (irregularityCategoryList.length) {
              // Show the form if they picked an irregularity.
              if (irregularityCategoryId !== '' && irregularityId !== '') {
                // Find the selected Category in the query object.
                chosenCategory = irregularityCategoryList.filter((el) => {
                  return el.id === irregularityCategoryId;
                })[0];

                // Find the selected Irregularity in the query object.
                if (chosenCategory.irregularityList) {
                  chosenIR = chosenCategory.irregularityList.filter((el) => {
                    return el.id === irregularityId;
                  })[0];
                }

                if (chosenIR && chosenIR.id) {
                  return <IrregularityFormContainer irregularity={chosenIR} irregularityCategory={chosenCategory} />;
                } else {
                  return <p className='mb-4'>Irregularity not found.</p>;
                }
              } else {
                return (
                  <div className='shadow cb-white-bg pt-3 mb-4'>
                    <Accordion controls={true} className='accord-add-ir-categories'>
                      {prepareAccordion(irregularityCategoryList).map((panel, i) => (
                        <AccordionItem
                          key={panel.key || panel.title}
                          title={
                            <div id={`ir-accord-category-${i}`}>
                              <div>
                                <span className='cb-margin-left-8 cb-font-weight-medium'>{panel.title}</span>
                              </div>
                              {panel.description && (
                                <div
                                  className='cb-accordion-description'
                                  data-automation={`accordion-description-${i}`}
                                >
                                  <span className='cb-margin-left-8 category-description'>
                                    {sanitizePanelDescription(panel.description)}
                                  </span>
                                </div>
                              )}
                            </div>
                          }
                          data-automation={`accordion-item-${i}`}
                        >
                          {panel.body}
                        </AccordionItem>
                      ))}
                    </Accordion>
                  </div>
                );
              }
            } else {
              // Nothing returned from GraphQL.
              return <p className='mb-4'>Irregularity reports coming soon.</p>;
            }
          }}
        </QueryComponent>
      </div>
    </div>
  );
}

function sanitizePanelDescription(input: string) {
  return sanitizeHtml(input, {
    allowedTags: [],
    allowedAttributes: {},
  });
}

Container.displayName = 'IrregularitiesCategoriesContainer';

export default Container;
