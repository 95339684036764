import Expandable from '../../ui/expandable/ExpandableWrapper';
import NotificationContainer from './NotificationContainer';
import QueryComponent from '../../common/network/QueryComponent';
import React from 'react';
import Statistics from './Statistics';
import get from 'lodash/get';
import moment from 'moment';
import { GET_SITE_STATS } from '../../../apollo/queries';
import { ResizeContext } from '../../../context/ResizeContext';
import { hasAdminAccess } from '../../../utils/user';
import { useStateValue } from '../../../context/AppContext';
import { useLocation } from 'react-router-dom';

function StatusPanel() {
  const { pathname } = useLocation();
  function showCTAs() {
    const { eventStartDt, eventEndDt, dapInd } = orgEvent;

    // Set the date we're counting down to, 8am on the event day.
    const eventDt = !dapInd ? eventStartDt : eventEndDt;

    // Allow CTA through the event end date, otherwise use start date.
    const secondsPast = moment().diff(moment(`${eventDt} 08:00:00`, 'YYYY-MM-DD HH:mm:ss', true), 'seconds', true);

    // Will be less than 0 if the date hasn't happened yet.
    return secondsPast < 0;
  }

  function showStatusPanel() {
    const { orgId, siteId } = orgEvent;

    /**
     * @todo: Refactor this hard coded stuff.
     */
    return (
      hasAdminAccess(user.rid, user.role) &&
      orgId &&
      siteId &&
      pathname !== '/event' &&
      pathname.indexOf('test-center-closure') === -1 &&
      pathname.indexOf('students/get/') === -1 &&
      pathname.indexOf('rooms/get/') === -1 &&
      pathname.indexOf('rooms/edit/') === -1 &&
      pathname.indexOf('staff/get/') === -1 &&
      pathname.indexOf('staff/edit/') === -1 &&
      pathname.indexOf('printable-rosters') === -1 &&
      pathname.indexOf('irregularities') === -1 &&
      pathname.indexOf('vouchers') === -1 &&
      pathname.indexOf('help') === -1 &&
      pathname.indexOf('rooms/seating-chart/edit') === -1
    );
  }

  // Global App state.
  const { orgEvent, user } = useStateValue();
  const showCTA = showCTAs();
  const windowSize = React.useContext(ResizeContext);

  function statusPanel(cta, stat) {
    return (
      <div key='status-panel'>
        {showCTA ? (
          <div className='row border__gray--bottom background--light-gray shadow mx-0'>
            <NotificationContainer cta={cta} />
          </div>
        ) : null}
        <div className='row background--corporate-gradient shadow mx-0'>
          <Statistics stat={stat} />
        </div>
      </div>
    );
  }

  return (
    <QueryComponent query={{ kind: 'GetSiteStats', specification: GET_SITE_STATS }} key='site-stats' showError={false}>
      {(data) => {
        let cta = {};
        let stat = {};

        try {
          cta = JSON.parse(get(data, 'viewer.site.stat.cta') || '{}');
          stat = JSON.parse(get(data, 'viewer.site.stat.stat') || '{}');
        } catch (e) {
          console.error('CTA or Stat was not parsable.', e);
        }

        return (
          showStatusPanel() && (
            <div className='hide-from-print progress-stats-container cb-white-bg border__gray--bottom'>
              {windowSize.prefix === 'xs' || windowSize.prefix === 'sm' ? (
                <Expandable title='Progress &amp; Statistics' linkPosition='top' triggerId='progress-stats-mobile'>
                  {statusPanel(cta, stat)}
                </Expandable>
              ) : (
                statusPanel(cta, stat)
              )}
            </div>
          )
        );
      }}
    </QueryComponent>
  );
}

export default StatusPanel;
