import React, { useRef, useState, useEffect } from 'react';
import TooltipWrapper from '../../../../ui/tooltip/TooltipWrapper';
import Input from '../../../../ui/form/Input';

import './TooltipInput.scss';

/**
 * TooltipInput component - Conditionally renders the tooltipwrapper if Input clips text
 *
 * Notes:
 * Already attempted to use useRef to get mainRef of input, but doesn't work as accurately since it retrieves the div ref instead...
 * Odd behavior, providing className provides the right calculations for tooltip wrapper to accurately appear.
 *
 * TODO: Ask Apricot to include cbRef for Input, instead of relying uniqueness of inputId
 */

interface TooltipInputProps {
  inputId: string;
  inputValue: string;
  inputLabel?: string;
  inputAriaLabel?: string;
  inputClassName?: string;
  inputMaxLength: number;
  tooltipId: string;
  tooltipMsg: string;
  onChange: (val: any) => void;
  onBlur?: () => void;
  errorMessage: string;
}

export function TooltipInput({
  errorMessage = '',
  inputId,
  inputValue,
  inputLabel = '',
  inputClassName = '',
  inputMaxLength,
  inputAriaLabel = '',
  tooltipId,
  tooltipMsg,
  onChange,
  onBlur = () => {},
}: TooltipInputProps) {
  const [isOverflowed, setIsOverflow] = useState(false);
  useEffect(() => {
    const inputEl = document.getElementById(inputId);
    if (!inputValue) {
      setIsOverflow(false);
      return;
    }
    if (inputEl) {
      setIsOverflow(inputEl.scrollWidth > inputEl.clientWidth);
    }
  }, [inputValue]);
  return (
    <>
      <Input
        id={inputId}
        label={inputLabel}
        maxLength={inputMaxLength}
        required={true}
        className={inputClassName}
        value={inputValue}
        onChange={onChange}
        onBlur={onBlur}
        ariaLabel={inputAriaLabel}
        errorMessage={errorMessage}
        condensed={true}
      />
      {isOverflowed && (
        <div aria-hidden={true} tabIndex={-1}>
          <TooltipWrapper trigger={inputId} tooltipId={tooltipId} className='tooltip-box-shadow'>
            {tooltipMsg}
          </TooltipWrapper>
        </div>
      )}
    </>
  );
}
