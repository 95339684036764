import { rule } from 'indicative';

export const userValidationRules = {
  firstName: [
    rule('max', 100),
    rule('regex', new RegExp(/^[ a-zA-Z0-9()#&:.,;?\-_!$'"]*$/)),
    rule('required'),
    rule('string'),
  ],
  lastName: [
    rule('max', 100),
    rule('regex', new RegExp(/^[ a-zA-Z0-9()#&:.,;?\-_!$'"]*$/)),
    rule('required'),
    rule('string'),
  ],
  username: [rule('min', 6), rule('max', 15), rule('regex', new RegExp(/^\w{6,15}$/)), rule('string')],
  role: [], // Can be conditionally required based on room.
  email: [rule('email'), rule('max', 254), rule('required')],
  phoneMobile: [rule('required_without_all', ['phoneHome', 'phoneWork'])],
  phoneHome: [rule('required_without_all', ['phoneMobile', 'phoneWork'])],
  phoneWork: [rule('required_without_all', ['phoneHome', 'phoneMobile']), rule('required_with_any', ['phoneWorkExt'])],
  phoneWorkExt: [rule('regex', new RegExp(/^[0-9]*$/))],
  active: [rule('required')],
};

export const staffValidationRules = {
  firstName: [
    rule('max', 100),
    rule('regex', new RegExp(/^[ a-zA-Z0-9()#&:.,;?\-_!$'"]*$/)),
    rule('required'),
    rule('string'),
  ],
  lastName: [
    rule('max', 100),
    rule('regex', new RegExp(/^[ a-zA-Z0-9()#&:.,;?\-_!$'"]*$/)),
    rule('required'),
    rule('string'),
  ],
  email: [rule('email'), rule('max', 254), rule('required')],
  phone: [rule('required'), rule('max', 15)],
};

const usernameDisplay =
  'College Board username must be 6–15 characters and include only letters, numbers, and underscores.';

export const atLeastOneCoordinatorMessage = 'You must have at least one coordinator for this administration.';
export const atLeastOnePhoneNumberRequiredMessage = 'At least one phone number is required.';
export const userValidationMessages = {
  'active.required': 'Access status is required.',
  'email.email': 'Please enter a valid email address.',
  'email.max': 'Email must be 254 characters or less.',
  'email.min': 'Email is required.',
  'email.required': 'Email is required.',
  'email.unique':
    'Error: Someone with this email was already added to the toolkit. Select them from your staff list to assign them to this administration.',
  'email.emailDomain': 'Error: Please enter a valid email address. The email domain %%emailDomain%% is not valid',
  'firstName.max': 'First name must be 100 characters or less.',
  'firstName.min': 'First name is required.',
  'firstName.regex':
    'First name can include only letters, numbers, spaces, and these symbols: ( ) # & : ; . , \' " ? ! $',
  'firstName.required': 'First Name is required.',
  'lastName.max': 'Last name must be 100 characters or less.',
  'lastName.min': 'Last name is required.',
  'lastName.regex':
    'Last name can include only letters, numbers, spaces, and these symbols: ( ) # & : ; . , \' " ? ! $',
  'lastName.required': 'Last Name is required.',
  'phone.invalid': 'Please enter a valid phone number.',
  'phone.parse': atLeastOnePhoneNumberRequiredMessage,
  'phone.schema': atLeastOnePhoneNumberRequiredMessage,
  'phone.required': 'Phone number is required.',
  'phoneHome.required_without_all': atLeastOnePhoneNumberRequiredMessage,
  'phoneMobile.required_without_all': atLeastOnePhoneNumberRequiredMessage,
  'phoneWork.required_with_any': 'Work number is required if an extension is given.',
  'phoneWork.required_without_all': atLeastOnePhoneNumberRequiredMessage,
  'phoneWorkExt.regex': 'Work extension can include only numbers.',
  'role.required': 'Role is required.',
  'role.coordinator_required': atLeastOneCoordinatorMessage,
  'role.technology_coordinator_assigned_to_room': 'Technology Coordinator can not be assigned to a room',
  'username.role_addition_failed': 'Unable to attach roles to this user.',
  'username.role_not_allowed': 'You are not allowed to edit this user’s username.',
  'username.max': usernameDisplay,
  'username.min': usernameDisplay,
  'username.regex': usernameDisplay,
  'username.required': 'Username is required.',
  'username.unique': 'This username is already in use.',
  'username.valid_person': 'This username is not linked to a valid user.',
};
export const userBackendValidationMessages = {
  'bulk-create:email_invalid_domain': userValidationMessages['email.emailDomain'],
};
