import React, { useRef, useMemo } from 'react';
import { roles, digitalrolesDisplay, rolesDisplay } from '../../../../../constants/roles';
import { canCreateAdmin } from '../../../../../utils/user';

import { IStaff } from '../type';
import { parsePhoneNumbers } from '../../../../../utils/common';
import Select from '../../../../ui/form/Select';

import { ResizeContext } from '../../../../../context/ResizeContext';
import { useStateValue } from '../../../../../context/AppContext';

import './AddStaffRow.scss';

interface AddStaffRowProps {
  staff: IStaff;
  options: {
    checked: boolean;
  };
  handleCheckItems: (ids: string[]) => void;
  staffChangeHandler: (staff: IStaff) => void;
}

export const AddStaffRow = ({
  handleCheckItems = () => {},
  staffChangeHandler = () => {},
  options = { checked: false },
  staff,
}: AddStaffRowProps) => {
  const windowSize = React.useContext(ResizeContext);
  const checkId = `add-bulk-check-${staff.id}`;
  const { orgEvent, user } = useStateValue();

  const rolesDisplayLocal = orgEvent?.dapInd ? digitalrolesDisplay : rolesDisplay;

  const staffTitle = `${staff.lastName ? staff.lastName + ', ' : ''}${staff.firstName}`;

  const roleRef = useRef(staff.role);

  function checkStaff(staffId: string) {
    return function () {
      handleCheckItems([staffId]);
    };
  }

  function generateRoleOptions() {
    let displayItem;
    const displayRoles: { label: string; value: string }[] = [];

    rolesDisplayLocal.forEach((item) => {
      displayItem = {
        label: item.title,
        value: item.value,
      };

      if (!item.requireAdmin) {
        displayRoles.push(displayItem);
      } else if (canCreateAdmin(orgEvent, user)) {
        displayRoles.push(displayItem);
      }
    });

    return displayRoles;
  }

  function renderMobileComponent() {
    const staffTitle = `${staff.lastName ? staff.lastName + ', ' : ''}${staff.firstName}`;
    const staffTitleWRole = staffTitle + (staff.role ? ` (${roles[staff.role]})` : '');

    return (
      <tr>
        <td className='staff-checkbox'>
          <label htmlFor={checkId}>
            <input
              checked={options.checked ? 'checked' : ''}
              id={checkId}
              name={`add-bulk-check[${staff.id}]`}
              onChange={checkStaff(staff.id)}
              type='checkbox'
              value={staff.id}
            />
            <span className='cb-sr-only'>Select {staffTitle} for a bulk operation</span>
          </label>
        </td>
        <td>
          <strong>{staffTitleWRole}</strong>
          <br />
          <strong>{staff.email}</strong>
        </td>
      </tr>
    );
  }

  const roleOptions = useMemo(() => generateRoleOptions(), [rolesDisplayLocal]);

  function renderDesktopComponent() {
    return (
      <tr>
        <td className='staff-checkbox'>
          <label htmlFor={checkId}>
            <input
              checked={options.checked ? 'checked' : ''}
              id={checkId}
              name={`add-bulk-check[${staff.id}]`}
              onChange={checkStaff(staff.id)}
              type='checkbox'
              value={staff.id}
            />
            <span className='cb-sr-only'>Select {staffTitle} for a bulk operation</span>
          </label>
        </td>
        <td>{staff.firstName}</td>
        <td>{staff.lastName}</td>
        <td id={'email'}>{staff.email}</td>
        <td>
          {parsePhoneNumbers(staff.phone).map((number) => {
            // return number.phoneNumber;
            const type = number.type.charAt(0).toUpperCase() + number.type.slice(1);
            const value = number.phoneNumber;
            return value ? (
              <li key={type + value}>
                <strong>{type}</strong>: <br />
                {value}
              </li>
            ) : (
              ''
            );
          })}
        </td>
        <td id={`role-select-wrapper-${staff.id}`}>
          <Select
            floating={false}
            id={`role-${staff.id}`}
            name={`role-${staff.id}`}
            onChange={(val) => {
              val && val.preventDefault && val.preventDefault();
              roleRef.current = val;
              const staffCopy = { ...staff, role: val };
              staffChangeHandler(staffCopy);
            }}
            value={staff.role}
            values={roleOptions}
          />
        </td>
      </tr>
    );
  }

  return windowSize.mobile ? renderMobileComponent() : renderDesktopComponent();
};
