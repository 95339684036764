import React from 'react';
import { hasAdminAccess } from '../../../utils/user';
import { useStateValue } from '../../../context/AppContext';

export interface TableProps {
  checkboxAllRef?: any;
  handleCheckAll?: (items: any[]) => void;
  headers?: { title: string }[];
  id: string;
  items?: any[];
  options?: any;
  renderHeader: (header: any, index: number) => JSX.Element;
  renderItem: (item: any, options: any, index: number) => JSX.Element;
  stickyHeaders?: boolean;
  addStaff?: boolean;
  shadow?: boolean;
  striped?: boolean;
  tableStyleBorderless?: boolean;
  tableStyleLight?: boolean;
  tableStyleReverseStripes?: boolean;
  tableClassName?: string;
  tableWrapperClassName?: string;
}
function Table({
  checkboxAllRef = {},
  handleCheckAll = (items: any[]) => {},
  headers = [],
  id = '',
  items = [],
  options = {},
  renderHeader,
  renderItem,
  stickyHeaders = false,
  addStaff = false,
  shadow = true,
  striped = true,
  tableStyleBorderless = false,
  tableStyleLight = false,
  tableStyleReverseStripes = false,
  tableWrapperClassName = 'p-0 mb-4',
  tableClassName = '',
}: TableProps) {
  // Global App state.
  const { user } = useStateValue();

  const striped_class = !striped ? '' : tableStyleReverseStripes ? 'cb-table-striped-reverse' : 'cb-table-striped';

  function renderFirstHeader() {
    if (headers?.[0]?.title === 'checkAll' && hasAdminAccess(user.rid, user.role)) {
      return (
        <th scope='col' className={stickyHeaders ? 'sticky' : ''}>
          <label htmlFor='check-all-items'>
            <input id='check-all-items' onClick={handleCheckAll(items)} ref={checkboxAllRef} type='checkbox' />
            <span className='cb-sr-only'> Select all items in this table</span>
          </label>
        </th>
      );
    } else if (headers?.[0]?.title === '') {
      return (
        <th scope='col' className={stickyHeaders ? 'sticky' : ''}>
          <span className='cb-sr-only'>Action</span>
        </th>
      );
    } else {
      return null;
    }
  }

  return (
    <div className={`box-card border__rounded-full ${tableWrapperClassName} ${shadow ? 'shadow' : ''} `}>
      <table
        id={id}
        className={`cb-table mb-0 ${tableClassName} ${tableStyleLight ? 'cb-table-light' : ''} ${
          tableStyleBorderless ? 'cb-no-border-left-right' : ''
        } ${striped_class}  ${addStaff ? 'cb-table-add-staff' : ''}`}
      >
        {options.caption ? <caption className='cb-sr-only'>{options.caption}</caption> : null}
        <thead>
          <tr>
            {renderFirstHeader()}
            {headers
              .filter((h) => h.title !== 'checkAll' && h.title !== '')
              .map((header, index) => renderHeader(header, index))}
          </tr>
        </thead>
        <tbody>{items.map((item, i) => renderItem(item, options, i))}</tbody>
      </table>
    </div>
  );
}

export default Table;
