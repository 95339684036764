import Papa from 'papaparse';
import { isEqual } from 'lodash';
import { removeBomFromArrayBuffer } from './base64';

export enum FileErrorType {
  fileName = 'fileName',
  fileType = 'fileType',
  fileSize = 'fileSize',
  fileContent = 'fileContent',
  fileHeaders = 'fileHeaders',
  fileRecord = 'fileRecord',
}

export enum FileStateType {
  success = 'SUCCESS',
  fail = 'FAIL',
}

export type IFileFormatOutput = {
  type: FileErrorType;
  status: FileStateType;
  message: string;
  detail: string;
};

// This constant also lives in backend/api/operations/file/common.js, so keep it updated
const STAFF_FILE_UPLOAD_MAX_SIZE = 2 * 1024 * 1024; // 2MB
const safeFilenameRegex = /^[ a-zA-Z0-9.()_-]+$/;
const validCharRegex = /^[\x20-\x7A\r\n]+$/;

const validateName = (fileName: string) => {
  const validation: IFileFormatOutput = {
    type: FileErrorType.fileName,
    status: FileStateType.success,
    message: 'File name is valid.',
    detail: '',
  };
  if (!safeFilenameRegex.test(fileName)) {
    validation.status = FileStateType.fail;
    validation.detail =
      'File name includes invalid characters. Only letters, numbers, spaces, parentheses, hyphens, and underscores are valid.';
  }

  return validation;
};

const validateType = (fileName: string) => {
  const validation: IFileFormatOutput = {
    type: FileErrorType.fileName,
    status: FileStateType.success,
    message: 'File type is CSV.',
    detail: '',
  };
  const fileNameArray = fileName.split('.');
  const fileType = fileNameArray.length > 1 ? fileNameArray[fileNameArray.length - 1]?.toUpperCase() : '';

  if (fileNameArray.length < 2 || fileType !== 'CSV') {
    validation.status = FileStateType.fail;

    let invalidFileTypeMessage;
    if (!fileType) {
      invalidFileTypeMessage = 'Invalid file type';
    } else {
      invalidFileTypeMessage = fileType;
    }

    validation.detail = `Your file: ${invalidFileTypeMessage}`;
  }

  return validation;
};

const validateContent = async (file: File) => {
  const validations: IFileFormatOutput[] = [];
  const fileContent = await readFileContents(file);
  if (file.size > STAFF_FILE_UPLOAD_MAX_SIZE) {
    const fileSizeInMB = (file.size / 1024 / 1024).toFixed(1);

    validations.push({
      type: FileErrorType.fileSize,
      status: FileStateType.fail,
      message: 'File size is less than 2 MB.',
      detail: `Your file: ${fileSizeInMB} MB`,
    });
  } else {
    validations.push({
      type: FileErrorType.fileSize,
      status: FileStateType.success,
      message: 'File size is less than 2 MB.',
      detail: '',
    });
  }

  if (
    fileContent &&
    typeof fileContent == 'string' &&
    file.size <= STAFF_FILE_UPLOAD_MAX_SIZE &&
    !validCharRegex.test(fileContent)
  ) {
    validations.push({
      type: FileErrorType.fileContent,
      status: FileStateType.fail,
      message: 'All characters are valid.',
      detail:
        '1 or more records include invalid characters. Only letters, numbers, spaces, and some basic punctuation are valid.',
    });
  } else {
    validations.push({
      type: FileErrorType.fileContent,
      status: FileStateType.success,
      message: 'All characters are valid.',
      detail: '',
    });
  }

  if (fileContent) {
    const parsedData = Papa.parse<string[]>(fileContent.toString(), {
      skipEmptyLines: 'greedy',
      header: true,
    });

    const headers = ['First Name', 'Last Name', 'Email', 'Phone'];

    const { data, meta } = parsedData;

    const { fields } = meta;

    if (!isEqual(headers, fields)) {
      validations.push({
        type: FileErrorType.fileHeaders,
        status: FileStateType.fail,
        message: 'All column headers are valid.',
        detail: '1 or more column headers are invalid. Use only the headers provided in the staff upload template.',
      });
    } else {
      validations.push({
        type: FileErrorType.fileHeaders,
        status: FileStateType.success,
        message: 'All column headers are valid.',
        detail: '',
      });
    }

    if (data.length === 0) {
      validations.push({
        type: FileErrorType.fileRecord,
        status: FileStateType.fail,
        message: 'File includes at least 1 record.',
        detail: 'Your file: 0 records',
      });
    } else {
      validations.push({
        type: FileErrorType.fileRecord,
        status: FileStateType.success,
        message: 'File includes at least 1 record.',
        detail: '',
      });
    }
  } else {
    validations.push({
      type: FileErrorType.fileHeaders,
      status: FileStateType.fail,
      message: 'File headers validation.',
      detail: 'CSV file header must exactly match the header in the CSV file template.',
    });
    validations.push({
      type: FileErrorType.fileContent,
      status: FileStateType.fail,
      message: 'File includes at least 1 record.',
      detail: 'Your file: 0 records',
    });
  }

  return validations;
};

export const validateFile = async (file: File) => {
  const validations = [];
  validations.push(validateName(file.name));
  validations.push(validateType(file.name));
  validations.push(...(await validateContent(file)));
  return validations;
};

const readFileContents = async (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        let binary = '';
        const processedFile: Uint8Array = removeBomFromArrayBuffer(new Uint8Array(reader.result as ArrayBuffer));
        const len = processedFile.byteLength;
        for (let i = 0; i < len; i++) {
          binary += String.fromCharCode(processedFile[i]);
        }
        const btoa_str = btoa(binary);
        const fileContentStr = atob(btoa_str);
        resolve(fileContentStr);
      },
      'failure'
    );
    reader.onerror = reject;

    if (file) {
      reader.readAsArrayBuffer(file);
    }
  });
