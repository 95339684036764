import React, { useRef } from 'react';
import CreateRooms from '../createRoom/CreateRoom';
import { Room } from '../types';

interface CreateRoomRosterProps {
  onClose?: () => void;
  handleDrawerDirty?: (isDirty: boolean) => void;
  footerButtonLabel: string;
}

/**
 * Needed: Since DropDownDrawerButton's Drawer uses React.cloneElement, causing components to be rerender triggering
 * useEffects whose dependencies depend on props.
 */
export function CreateRoomRoster({ onClose, handleDrawerDirty, footerButtonLabel }: CreateRoomRosterProps) {
  const rooms = useRef<Room[]>([]);
  return (
    <CreateRooms
      onClose={onClose}
      handleDrawerDirty={handleDrawerDirty}
      footerButtonLabel={footerButtonLabel}
      rooms={rooms.current}
    />
  );
}
