import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import Roster from '../../../common/Roster';
import get from 'lodash/get';
import RoomRosterRow from './RoomRosterRow';
import HallStaffList from '../staff/HallStaffList';
import BulkAddRooms from '../form/BulkAddRooms';
import { GET_STAFF } from '../../../../apollo/queries';
import { useQuery, useMutation } from '@apollo/client';
import { hasAdminAccess } from '../../../../utils/user';
import { localStateReducer, sortItems } from '../../../../utils/common';
import { useStateValue } from '../../../../context/AppContext';
import { ResizeProvider } from '../../../../context/ResizeContext';
import { GENERATE_PDF } from '../../../../apollo/mutations';
import b64toBlob from 'b64-to-blob';
import { saveAs } from 'file-saver';
import Spinner from '../../../ui/loading/SpinnerWrapper';
import { TourContent, TourContainer, useTour } from '../../../common/productTour';
import chunk from 'lodash/chunk';
import keyBy from 'lodash/keyBy';
import flatten from 'lodash/flatten';
import { YellowButton } from '@cb/apricot-react';

function RoomRoster({ rooms = [] }) {
  const location = useLocation();

  function handlePrint() {
    const roomIds = rooms.map((room) => room.id);

    setLocalState({
      printBtnDisabled: true,
    });

    generatePDF({
      variables: {
        input: {
          eventId: orgEvent.asmtEventId,
          roomIds,
        },
      },
    }).catch((e) => {
      console.error('Failed to generate pdf: ', e);
      setLocalState({
        printBtnDisabled: false,
      });
    });
  }

  function toggleForm(toggle) {
    // If they had saved the originating button, focus on it.
    if (localState.saveFocusElement) {
      document.getElementById(localState.saveFocusElement).focus();
    }

    // Toggle the Add Rooms form.
    // Set saveFocusState to null if we're closing the form.
    setLocalState({
      showAddForm: toggle,
      saveFocusElement: toggle ? localState.saveFocusElement : null,
    });
  }

  function generateForm() {
    // Show the Add Rooms form if necessary.
    return localState.showAddForm ? (
      <div className='shadow border__gray'>
        <h2 className='tdtk-h2 cb-sr-only' data-automation='heading-h2'>
          Add Rooms
        </h2>
        <BulkAddRooms toggleForm={toggleForm} initialRows={1} />
      </div>
    ) : null;
  }

  function updateSearch(e) {
    setLocalState({
      search: e.target.value,
    });
  }

  function buildTableControlElements() {
    const tableControlElements = [
      {
        defaultValue: localState.search,
        glyphIcon: 'search',
        label: 'Enter part of a room name here to find the room',
        name: 'room-search',
        onChange: updateSearch,
        placeholder: 'Search Rooms',
        specialClasses: 'cb-input-search data-tour-room-search',
        srOnlyLabel: true,
        suppressFormGroupClass: true,
        type: 'input',
      },
    ];

    if (hasAdminAccess(user.rid, user.role)) {
      // Build a button.
      const buildAddRoomButton = {
        isExpanded: localState.showAddForm,
        icon: !localState.showAddForm ? 'plus' : 'minus',
        parentClassName: 'add-room-button',
        label: 'Add Rooms',
        name: 'add-room',
        onClick: () => {
          // If they are opening the accordion, focus on it.
          if (!localState.showAddForm) {
            document.getElementById('tableControlForm').focus();
          }
          setLocalState({
            saveFocusElement: 'add-room',
            showAddForm: !localState.showAddForm,
            showEditDataForm: false,
            showFilterTableForm: false,
          });
        },
        type: 'button',
      };

      // If they have no rooms, we should show a helpful popup.
      if (!rooms || rooms.length === 0) {
        // Add the popover.
        buildAddRoomButton.popoverTitle = 'Start Here';
        buildAddRoomButton.popoverText = 'Import rooms you used in the past or add new ones.';
        buildAddRoomButton.showPopover = true;
        buildAddRoomButton.type = 'buttonWithPopover';
      }
      tableControlElements.unshift(buildAddRoomButton);
    }

    return tableControlElements;
  }

  // Global App state.
  const { orgEvent, user } = useStateValue();

  const isAdmin = hasAdminAccess(user.rid, user.role);

  // Local state.
  const [localState, setLocalState] = React.useReducer(localStateReducer, {
    printBtnDisabled: false,
    saveFocusElement: null,
    search: '',
    showAddForm: location.hash === '#add' || get(location, 'state.showAddForm', false),
    showEditDataForm: false,
    showFilterTableForm: false,
  });

  // Apollo. Sort by Role, Last Name, First Name.
  const eventStaff = sortItems(
    get(useQuery(GET_STAFF, { skip: !hasAdminAccess(user.rid, user.role) }).data, 'viewer.site.staff', []),
    [
      {
        name: 'lastName',
        order: 'asc',
      },
      {
        name: 'firstName',
        order: 'asc',
      },
    ]
  );

  const [generatePDF, { data: pdfData }] = useMutation(GENERATE_PDF);

  React.useEffect(() => {
    if (pdfData && pdfData.generatePDF && pdfData.generatePDF.length === 2) {
      const [fileName, base64] = pdfData.generatePDF;
      const blob = b64toBlob(base64, 'application/pdf');
      saveAs(blob, fileName);

      setLocalState({
        printBtnDisabled: false,
      });
    }
  }, [pdfData]);

  // Create a clone of the array so we can mutate it.
  let filteredRooms = [...rooms];
  let searchTerm = '';

  if (localState.search && localState.search.trim()) {
    searchTerm = localState.search.toUpperCase().trim();

    filteredRooms = rooms.filter((item) => {
      if (searchTerm && item['title']) {
        return item['title'].toUpperCase().indexOf(searchTerm) > -1;
      } else {
        return true;
      }
    });
  }

  // Table headers.
  const headers = [
    { title: 'Room Name', sortField: ['title'] },
    { title: 'Testing Groups', sortField: ['groupTypes'] },
    { title: 'Staff', sortField: ['staffingPriority'] },
    { title: 'Seats Taken', sortField: ['availableSeats'] },
    { title: 'Seating Chart', sortField: ['seatingStatus.sortOrder'] },
    { title: 'Room Status', sortField: ['status.sortOrder'] },
  ];

  const tableControlForm = generateForm();
  const tableControlElements = buildTableControlElements();

  const emptyMessage = !rooms.length ? 'You haven’t added any rooms for this test date yet.' : '';

  const { shouldShowTour, tourOpen, onRequestClose } = useTour('roomOverview');

  const tourSteps = [
    {
      selector: '.data-tour-room-search',
      content: function tourContent1(props) {
        return (
          <TourContent
            totalSteps={tourSteps.length}
            title='Room Search'
            content='If you know which room you’re proctoring, search for it here.'
            {...props}
          />
        );
      },
    },
  ];

  if (rooms.length) {
    tourSteps.push({
      selector: '[data-tour="room-list"]',
      content: function tourContent2(props) {
        return (
          <TourContent
            totalSteps={tourSteps.length}
            title='Room List'
            content='Look for your name and click it to access the student roster for your room.'
            {...props}
          />
        );
      },
    });
  }

  let tourTargetAssigned = false;

  // Is the user assigned to a room?
  const userRoom = user?.room?.id || null;

  const goToPage = React.useCallback(
    (items, pageSize) => {
      return chunk(
        items.map(({ staff }) => staff),
        pageSize
      )
        .map((page) => keyBy(flatten(page), 'id'))
        .reduce((acc, val, index) => {
          if (val[user.id]) {
            return index;
          } else {
            return acc;
          }
        }, null);
    },
    [user.id]
  );

  return (
    <React.Fragment>
      {shouldShowTour && <TourContainer steps={tourSteps} isOpen={tourOpen} onRequestClose={onRequestClose} />}
      <ResizeProvider>
        <Roster
          caption='Test Center Rooms'
          emptyMessage={emptyMessage}
          groupName='rooms'
          headers={headers}
          items={filteredRooms}
          renderItem={(room) => {
            let isTourTarget = false;

            // Pick a room to target for the tour.
            if (!userRoom && !tourTargetAssigned) {
              // The user is not in a room, just assign the first room in the list if it exists.
              isTourTarget = true;
              tourTargetAssigned = true;
            } else if (room.id === userRoom && !tourTargetAssigned) {
              // The user has a room, and we haven't already assigned a target.
              isTourTarget = true;
              tourTargetAssigned = true;
            }

            return (
              <RoomRosterRow
                key={`room_${room.id}`}
                eventStaff={eventStaff}
                isTourTarget={isTourTarget}
                room={room}
                rooms={rooms}
              />
            );
          }}
          sortFields={[
            {
              name: 'status.sortOrder',
              order: 'asc',
            },
            {
              name: 'title',
              order: 'asc',
            },
          ]}
          sortStrategy='natural'
          stickyHeaders={true}
          tableControlElements={tableControlElements}
          tableControlForm={tableControlForm}
          goToPage={shouldShowTour ? goToPage : null}
        />
      </ResizeProvider>

      {rooms.length && isAdmin ? (
        localState.printBtnDisabled ? (
          <div className='row'>
            <div className='col-xs-3'>
              <Spinner />
            </div>
          </div>
        ) : (
          <YellowButton
            small
            className='mb-4'
            disabled={localState.printBtnDisabled}
            onClick={handlePrint}
            icon='download'
            iconDecorative
          >
            Download All Seating Charts
          </YellowButton>
        )
      ) : null}

      {
        // Hall monitor component if the user is a coordinator and they
        hasAdminAccess(user.rid, user.role) && rooms.length ? (
          <React.Fragment>
            <h1 className='tdtk-h1 py-4' id='hall-monitors-header'>
              Hall Monitors
            </h1>
            <div className='cb-card'>
              <div className='cb-card-content'>
                <HallStaffList staff={eventStaff} rooms={rooms} />
              </div>
            </div>
          </React.Fragment>
        ) : null
      }
    </React.Fragment>
  );
}

RoomRoster.propTypes = {
  rooms: PropTypes.array,
};

export default RoomRoster;
