import React from 'react';
import ProgressBar from './ProgressBar/ProgressBar';
import './common.scss';
import ErrorPage from '../../nonDigital/error/ErrorPage';
import RosterLink from './RosterLink';

interface StepContentPanelProps {
  children?: React.ReactNode;
  content?: {
    main_panel_header?: string;
    detail_panel_header?: string;
    detail_panel_content?: React.ReactNode | string;
  };
  darkMode?: boolean;
  desc?: React.ReactNode;
  heading?: string;
  illustrationContentVerticalJustify?: boolean;
  img?: React.ReactNode;
  imgDesc?: React.ReactNode | string;
  invertStyle?: boolean;
  nextUpStyle?: boolean;
  panelHeading?: string;
  progressStepperMax?: number;
  room?: { id?: string };
  rosterLinkName?: string;
  showPanelHeader?: boolean;
  showProgressBar?: boolean;
  showRosterLink?: boolean;
  showStepCompletePanel?: boolean;
  stepNum?: number;
}

function StepContentPanel({
  children,
  content,
  darkMode,
  desc,
  heading,
  illustrationContentVerticalJustify,
  img,
  imgDesc,
  invertStyle,
  nextUpStyle,
  panelHeading,
  progressStepperMax,
  room,
  rosterLinkName,
  showPanelHeader,
  showProgressBar,
  showRosterLink,
  showStepCompletePanel = false,
  stepNum,
}: StepContentPanelProps) {
  if (!content) {
    return (
      <div className='container'>
        <ErrorPage errorCode='cms_missing' />
      </div>
    );
  }

  return (
    <div>
      {showRosterLink ? <RosterLink roomId={room?.id} linkName={rosterLinkName} /> : null}
      <div className='row step-content-row mx-0'>
        <div className='col-xs-12 col-md-6 step-content-col-px'>
          {showProgressBar ? <ProgressBar currentStep={stepNum} totalSteps={progressStepperMax}></ProgressBar> : null}
          {showStepCompletePanel ? <div className='stepComplete-bar' /> : null}
          <div
            aria-level={1}
            className={`${showStepCompletePanel ? 'stepComplete-header' : 'wizard-heading mt-3'}`}
            data-automation='wizard-main-heading'
            id='wizard-main-heading'
            role='heading'
            tabIndex={-1}
          >
            {content?.main_panel_header ? content?.main_panel_header : heading}
          </div>
          <div
            data-automation='wizard-panel-desc'
            className={`wizard-panel-desc my-3 ${invertStyle ? 'wizard-invert-style' : ''}`}
          >
            <div>{desc}</div>
          </div>
        </div>
        <div className='col-xs-12 col-md-6 step-content-col-px'>
          <div
            className={`wizard-panel-illustration-board ${
              illustrationContentVerticalJustify ? 'wizard-panel-illustration-board-vertically-justified' : ''
            }`}
          >
            <div className='wizard-panel-illustration-content p-4'>
              {img && (
                <div role='presentation' className='wizard-panel-illustration-img' aria-hidden='true'>
                  {img}
                </div>
              )}
              {showPanelHeader ? (
                <div
                  role='heading'
                  aria-level={2}
                  data-automation='wizard-panel-heading'
                  className='wizard-panel-heading'
                >
                  {content?.detail_panel_header ? content?.detail_panel_header : panelHeading}
                </div>
              ) : null}
              <div
                data-automation='wizardPanelImgDesc'
                className={`wizard-panel-illustration-img-desc mx-auto ${nextUpStyle ? 'wizard-next-up-screen' : ''} ${
                  darkMode ? 'wizard-dark-mode mt-4' : ''
                }`}
              >
                {content?.detail_panel_content ? content?.detail_panel_content : imgDesc}
              </div>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StepContentPanel;
