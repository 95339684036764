import React, { useEffect, useRef } from 'react';
import { Room } from '../types';
import { localStateReducer, sortItems } from '../../../../../utils/common';

import QueryComponent from '../../../../common/network/QueryComponent';

import { GET_PAST_ACTIVE_EVENTS } from '../../../../../apollo/queries';

import { Icon } from '@cb/apricot-react';
import ReactSelect, { components } from 'react-select';
import { IGetPastAndActiveEventsData } from '../../../../../apollo/types';
import { isEqual } from 'apollo-utilities';

import { useLazyQuery } from '@apollo/client';
import './ImportRoomRoster.scss';

import { useStateValue } from '../../../../../context/AppContext';
import CreateRooms from '../createRoom/CreateRoom';

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon name='down' decorative style={{ fontSize: '.6875rem', fontWeight: 'bold', padding: '0 .3rem' }} />
    </components.DropdownIndicator>
  );
};

// TODO: It's good to move this to the css file to make it re-useable
const customStyles = {
  control: (controlStyles) => {
    return {
      ...controlStyles,
      borderColor: '#d9d9d9',
      borderRadius: '8px',
      height: '40px',
    };
  },
  dropdownIndicator: (styles) => {
    return {
      ...styles,
      color: 'hsl(0, 0%, 0%)',
    };
  },
  menu: (styles) => {
    return {
      ...styles,
      zIndex: 902,
    };
  },
  indicatorSeparator: (indicatorStyles) => {
    return {
      ...indicatorStyles,
      backgroundColor: '#d9d9d9',
      margin: 0,
    };
  },
};

interface ImportRoomRosterProps {
  onClose: () => void;
  handleDrawerDirty: (isDirty: boolean) => void;
}

export function ImportRoomRoster(props: ImportRoomRosterProps) {
  const { onClose, handleDrawerDirty } = props;

  const isSelectOpen = useRef(false);
  // Global App state.
  const { orgEvent } = useStateValue();

  // Local state.
  const [localState, setLocalState] = React.useReducer(localStateReducer, {
    initialRooms: [],
    pastEventRooms: [],
    enableResetBtn: false,
    selectedPastEvent: '',
  });

  const pERoomsTitleToIndexMap: { [key: string]: number } = {};
  localState.pastEventRooms.forEach((room: any, index: number) => {
    pERoomsTitleToIndexMap[room.title] = index;
  });

  const [fetchPastAndActiveEvent, { data, error, loading }] = useLazyQuery(GET_PAST_ACTIVE_EVENTS, {
    variables: { input: { asmtEventId: localState.selectedPastEvent } },
  });

  useEffect(() => {
    if (
      data &&
      data.pastAndActiveEvents &&
      data.pastAndActiveEvents[0] &&
      data.pastAndActiveEvents[0].rooms &&
      data.pastAndActiveEvents[0].rooms.length > 0
    ) {
      const rooms: Room[] = sortItems(
        data.pastAndActiveEvents[0].rooms,
        [
          {
            name: 'title',
            order: 'asc',
          },
        ],
        'natural'
      );

      setLocalState({ pastEventRooms: rooms, initialRooms: rooms, enableResetBtn: false });
    }
  }, [data]);

  function fetchPreviousEvent(eventId: string) {
    if (eventId) {
      // Fetch our old event rooms.
      fetchPastAndActiveEvent();
    }
  }

  const handleEventSelection = (eventId: string) => {
    setLocalState({
      selectedPastEvent: eventId,
    });
    fetchPreviousEvent(eventId);
  };

  const handleUndoButton = () => {
    setLocalState({
      pastEventRooms: [...localState.initialRooms],
      enableResetBtn: false,
    });
  };

  const defaultEventVal = {
    label: '-- Choose an administration --',
    value: '',
  };

  const transformSelectOptions = (pastAndActiveEvents: IGetPastAndActiveEventsData) => {
    const options = pastAndActiveEvents.pastAndActiveEvents.map((event) => {
      return {
        label: `${event.eventStartDt?.split('-')[0]} - ${event.eventTitle}`,
        value: event.asmtEventId,
        isDisabled: orgEvent?.asmtEventId === event?.asmtEventId,
      };
    });
    options.unshift(defaultEventVal);
    return options;
  };

  const handleRoomChangesHandler = (currentRooms: Room[]) => {
    //compare currentRooms with initialRooms, if they are different, enable undo button

    if (!isEqual(currentRooms, localState.initialRooms)) {
      setLocalState({
        enableResetBtn: true,
      });
    }
  };

  return (
    <React.Fragment>
      <QueryComponent
        query={{
          kind: 'GetPastAndActiveEvents',
          specification: GET_PAST_ACTIVE_EVENTS,
          variables: { input: { since: 13 } },
        }}
      >
        {(pastAndActiveEventsData) => {
          return (
            <div className='tdtk-form-group px-4' data-automation='select-test-center'>
              <label id='selectSite-label' className='control-label cb-roboto-bold' htmlFor='selectSite'>
                Save time by using rooms you already created:
              </label>
              <ReactSelect
                components={{ DropdownIndicator }}
                id='import-room-select'
                name='selectEventRoom'
                openMenuOnClick={true}
                openMenuOnFocus={false}
                options={transformSelectOptions(pastAndActiveEventsData || { pastAndActiveEvents: [] })}
                defaultValue={defaultEventVal}
                onChange={(option) => handleEventSelection(option.value)}
                styles={customStyles}
                onKeyDown={(event) => {
                  //isSelectOpen will still be true when escape is pressed, since onKeyDown is invoked before
                  //onMenuOpen and onMenuClose toggle functions are invoked.
                  if (event.key === 'Escape' && isSelectOpen.current) {
                    event.stopPropagation();
                  }
                }}
                onMenuClose={() => {
                  isSelectOpen.current = false;
                }}
                onMenuOpen={() => {
                  isSelectOpen.current = true;
                }}
              />
            </div>
          );
        }}
      </QueryComponent>
      <CreateRooms
        footerButtonLabel='Save'
        rooms={localState.pastEventRooms}
        handleRoomChanges={handleRoomChangesHandler}
        handleUndoButton={handleUndoButton}
        enableResetBtn={localState.enableResetBtn}
        onClose={onClose}
        handleDrawerDirty={handleDrawerDirty}
        type={'import'}
      ></CreateRooms>
    </React.Fragment>
  );
}
