import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { getStudentStatuses } from '../../../../common/utility';
import { localStateReducer } from '../../../../../../utils/common';
import ReloadButton from '../../../../common/ReloadButton';
import Timestamps from '../../../../common/Timestamps';
import StudentRosterColumn from './StudentRosterColumn';
import StudentRosterDirectionsAP from './StudentRosterDirections/StudentRosterDirectionsAP';
import './Attendance.scss';
import { useStateValue } from '../../../../../../context/AppContext';
import { isInSchoolAPEvents, isInSchoolEvent } from '../../../../../../utils/event';
import StudentRosterDirectionsSAT from './StudentRosterDirections/StudentRosterDirectionsSAT';
export default function StudentRoster({
  refetch = () => {},
  room = {},
  rosterHeading = '',
  showDirectionInfo = true,
  showInpage = false,
  lastUpdated = new Date().toISOString(),
}) {
  const { orgEvent } = useStateValue();
  const studentStatuses = getStudentStatuses(room?.students);
  // start - recall user's accordion visibility preferences from sessionStorage
  const getSavedAccordionState = () => {
    let accordions = {};

    try {
      accordions = JSON.parse(sessionStorage.getItem('rosterAccordions'));
      accordions.preRostered = accordions?.preRostered || false;
      accordions.ready = accordions?.ready || false;
      accordions.joined = accordions?.joined || false;
    } catch (e) {
      accordions = {
        preRostered: false,
        joined: false,
        ready: false,
      };
    }

    return accordions;
  };

  // accordion state
  const [localState, setLocalState] = useReducer(localStateReducer, {
    accordion: getSavedAccordionState(),
    lastUpdated,
  });

  // swap the state of the accordion that was clicked; set all others to false
  function toggleAccordions(accordionName = '') {
    const newAccordions = Object.keys(localState.accordion).reduce((prev, cur) => {
      return {
        ...prev,
        [cur]: cur === accordionName ? !localState.accordion[cur] : false,
      };
    }, {});

    sessionStorage.setItem('rosterAccordions', JSON.stringify(newAccordions));

    setLocalState({
      accordion: newAccordions,
    });
  }
  // end accordion code

  function refreshData() {
    setLocalState({
      lastUpdated: new Date().toISOString(),
    });
    refetch();
  }

  return (
    <>
      <div className='mb-4 display-flex justify-content-between align-items-center flex-sm-column-only flex-xs-column-only'>
        <div
          role='heading'
          aria-level='1'
          data-automation='wizard-student-roster-heading'
          className='wizard-heading mb-0 flex-grow-1 align-self-sm-start-only align-self-xs-start-only'
          tabIndex='-1'
          name='student-roster'
          id={showInpage ? 'student-roster' : 'wizard-main-heading'}
        >
          {rosterHeading}
        </div>
        <div className='cb-align-center cb-width-xs-100-up cb-width-md-auto-up cb-margin-sm-down-top-16' role='status'>
          {!!lastUpdated && (
            <Timestamps
              className='cb-font-size-xsmall cb-margin-sm-down-8'
              id='StdRosterReloadTimeStamp'
              updatedAt={localState.lastUpdated}
            />
          )}
          <ReloadButton
            refetch={refreshData}
            buttonStyle='secondary'
            text='Refresh Data'
            reloadButtonID='StdRosterReloadData'
            reloadTimeStampID='StdRosterReloadTimeStamp'
          />
        </div>
      </div>
      <div className='cb-white-bg cb-box-shadow-light'>
        {isInSchoolAPEvents(orgEvent) ? (
          <StudentRosterDirectionsAP showDirectionInfo={showDirectionInfo} />
        ) : (
          <StudentRosterDirectionsSAT showDirectionInfo={showDirectionInfo} />
        )}
        <div className='student-roster-swimLane-wrapper row-eq-height row px-0 mx-0'>
          <StudentRosterColumn
            columnType={'preRostered'}
            emptyStateText={
              'Students listed here were assigned to your room in Test Day Toolkit before test day but haven‘t entered the room code yet.'
            }
            headerText='ASSIGNED'
            studentStatusArray={studentStatuses?.preRosteredStudents}
            toggleVisible={toggleAccordions}
            visible={localState.accordion.preRostered}
          />
          <StudentRosterColumn
            columnType={'joined'}
            emptyStateText={
              isInSchoolEvent(orgEvent)
                ? 'Students are listed here after they enter the room code and before they complete check-in.'
                : 'Students are listed here after they enter the room code and before they finish checking in to the app.'
            }
            headerText='ENTERED ROOM CODE'
            studentStatusArray={studentStatuses?.joinedStudents}
            toggleVisible={toggleAccordions}
            visible={localState.accordion.joined}
          />
          <StudentRosterColumn
            columnType={'ready'}
            emptyStateText={'Students are listed here when they complete check-in.'}
            headerText='READY TO TEST'
            studentStatusArray={studentStatuses?.readyStudents}
            toggleVisible={toggleAccordions}
            visible={localState.accordion.ready}
          />
        </div>
      </div>
    </>
  );
}

StudentRoster.propTypes = {
  lastUpdated: PropTypes.string,
  refetch: PropTypes.func,
  room: PropTypes.object,
  showDirectionInfo: PropTypes.bool,
  showInpage: PropTypes.bool,
  rosterHeading: PropTypes.string,
};
