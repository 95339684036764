import React from 'react';
import { Link } from 'react-router-dom';
import ToggleDirections from '../../../../../common/ToggleDirections';

function MonitorStudentDirectionsSAT() {
  return (
    <ToggleDirections>
      <div className='cb-white-bg'>
        <h3 className='tdtk-h3 how-to-Monitor-Testing'>How to Monitor Testing</h3>
        <div className='row px-0 mx-0 py-3 cb-border-top' role='region' aria-label='directions'>
          <div className='col-md-4 cb-margin-sm-down-bottom-16'>
            <h4 className='cb-paragraph6-st'>Reminders</h4>
            <ul className='pl-4 ml-2 mt-2'>
              <li>Walk around every 15 minutes.</li>
              <li>Make sure laptops are open and tablets uncovered.</li>
              <li>Tell students to take off brimmed hats and lower hoods.</li>
              <li>Watch for suspicious activity—online and off.</li>
              <li>Minimize distractions.</li>
            </ul>
          </div>
          <div className='col-md-4 cb-margin-sm-down-bottom-16'>
            <h4 className='cb-paragraph6-st'>Statuses</h4>
            <ul className='pl-4 ml-2 mt-2'>
              <li>A student’s status could be inaccurate if they’re testing offline and can’t send updates.</li>
              <li>No action is required if students are testing smoothly.</li>
            </ul>
          </div>
          <div className='col-md-4'>
            <h4 className='cb-paragraph6-st'>Handling Problems</h4>
            <ul className='pl-4 ml-2 mt-2'>
              <li>Alert your coordinator about security violations immediately.</li>
              <li>Send students to the help room if they have technical issues.</li>
              <li>
                <Link to='/irregularities'>Go to Irregularities to report all issues.</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </ToggleDirections>
  );
}

export default MonitorStudentDirectionsSAT;
