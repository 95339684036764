import React, { useEffect } from 'react';
import { CardWithTitle } from '../../../../ui/card/CardWithTitle';
import { Icon } from '@cb/apricot-react';
import { formattedPhone } from '../../../../../utils/common';
import { IStaffEditable } from '../type';
import { isEqual, uniqueId } from 'lodash';
import { AddStaff, DrawerTableComponent } from '../inventory-staff/AddStaff';
import { ROLES_MAP } from '../../../../../constants/roles';
import Papa from 'papaparse';
import { validateAll } from 'indicative';
import { staffValidationRules, userValidationMessages } from '../../../../../validations/user/validator';
import Spinner from '../../../../../components/ui/loading/SpinnerWrapper';

import { isValidPhoneNumber } from 'react-phone-number-input';

export interface FileDataCheckStateType {
  staffRows: IStaffEditable[];
  staffErrorRows: IStaffEditable[];
  dirtyForm: boolean;
  isProcessing: boolean;
  startingPageOpts: { pageNum: number };
  formErrorsByStaffId: { [key: string]: IStaffEditable };
  submitErrors: any[];
  submitEnabled: boolean;
  hasInitialErrors: boolean;
}

type FileDataCheckProps = {
  file?: File;
  fileDataCheckState: FileDataCheckStateType;
  setFileDataCheckState: React.Dispatch<any>;
  loading: boolean;
};

const emptyStaff: IStaffEditable = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  role: ROLES_MAP.PROCTOR,
  id: '',
};

const DEFAULT_ADD_STAFF_ROWS: IStaffEditable[] = [];
function areSomeRowsFilled(rows: IStaffEditable[]) {
  const filledRows = rows.filter((row) => {
    const rowDup = { ...row };
    rowDup.id = '';
    return !isEqual(rowDup, emptyStaff);
  });

  return filledRows.length > 0;
}

const ErrorCardTitle = () => {
  return (
    <div>
      <Icon name='exclamation-fill' color='red1' className='p-2' />
      <b>Correct your errors below or replace your file.</b>
    </div>
  );
};

const ResolvedErrorCardTitle = () => {
  return (
    <div>
      <Icon name='exclamation-fill' color='black1' className='p-2' />
      <b>Ensure all your corrections are the way you want.</b>
    </div>
  );
};

const SuccessCardTitle = () => {
  return (
    <div>
      <Icon name='check-fill' color='green3' className='p-2' />
      <b>Nice work! The data for each record is complete and formatted correctly and all emails are unique. </b>
    </div>
  );
};

const AdjustmentCardTitle = () => {
  return (
    <div>
      <Icon name='note' className='p-2' />
      <b>
        Optional: Use the table below to correct data errors we can&apos;t detect (e.g. first and last name might be
        swapped).
      </b>
    </div>
  );
};

type csvMeta = {
  'First Name': string;
  'Last Name': string;
  'Email': string;
  'Phone': string;
};

export function FileDataCheck({ file, fileDataCheckState, setFileDataCheckState, loading }: FileDataCheckProps) {
  const parseCSV = (file?: File): Promise<IStaffEditable[]> => {
    if (!file) {
      return Promise.resolve([]);
    }

    return new Promise((resolve, reject) => {
      Papa.parse<csvMeta[]>(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          const csvMapper: Record<keyof csvMeta, string> = {
            'First Name': 'firstName',
            'Last Name': 'lastName',
            'Email': 'email',
            'Phone': 'phone',
          };
          const parsedData = results.data.map((row: any) => {
            const newRow: any = {};
            Object.keys(row).forEach((key) => {
              newRow[csvMapper[key as keyof csvMeta]] = (typeof row[key] === 'string' && row[key].trim()) || '';
            });

            newRow.phone = formattedPhone(newRow.phone || '', 'International').replace(/[ ]/g, '') || '';
            newRow.role = ROLES_MAP.PROCTOR;
            newRow.id = uniqueId();
            return newRow;
          });
          resolve(parsedData as IStaffEditable[]);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  };

  /**  @param formErrorsByStaffIdRow - a formErrorsByStaffId[staffId] object   */

  const catchCallback =
    (staffId: string, formErrorsByStaffId: { [key: string]: IStaffEditable }) => (errors: any[]) => {
      errors.forEach((err: any) => {
        if (formErrorsByStaffId[staffId]) {
          formErrorsByStaffId[staffId][err.field as keyof IStaffEditable] = err.message;
        }
      });
    };
  const validateCSVrows = async (rows: any[]) => {
    const formErrorsByStaffId: { [key: string]: IStaffEditable } = { ...fileDataCheckState.formErrorsByStaffId };
    let submitEnabled = true;

    //if there is a count of 2 or more for an email, then it is a duplicate email
    //if email is blank, it is not a duplicate email
    const dupEmailCount: { [key: string]: number } = {};
    (rows as IStaffEditable[])?.forEach((staff) => {
      const email = staff.email?.toLowerCase()?.trim() || '';

      if (email && dupEmailCount[email]) {
        dupEmailCount[email] += 1;
      } else {
        dupEmailCount[email] = 1;
      }
    });
    for (let index = 0; index < rows.length; index++) {
      formErrorsByStaffId[rows[index].id] = { ...emptyStaff, role: '' };
      let errorFoundInRow = false;
      await validateAll(rows[index], staffValidationRules, userValidationMessages).catch((errors) => {
        errorFoundInRow = true;
        submitEnabled = false;
        catchCallback(rows[index].id, formErrorsByStaffId)(errors);
      });
      // validate phone number again with E164 check
      if (rows[index].phone && !isValidPhoneNumber(rows[index].phone)) {
        submitEnabled = false;
        errorFoundInRow = true;
        formErrorsByStaffId[rows[index].id].phone = 'Please enter a valid phone number.';
      }

      // validate if the email is unique
      const email = rows[index].email?.toLowerCase()?.trim() || '';
      if (dupEmailCount?.[email] && dupEmailCount[email] > 1) {
        const dupEmailRow = rows.find((i) => i.email.toLowerCase() === rows[index].email?.toLowerCase());
        submitEnabled = false;
        errorFoundInRow = true;
        formErrorsByStaffId[rows[index].id].email = 'Email has already been used.';
      }
      if (!errorFoundInRow) {
        delete formErrorsByStaffId[rows[index].id];
      }
    }

    return {
      rows,
      formErrorsByStaffId: submitEnabled ? {} : formErrorsByStaffId,
      submitEnabled: !fileDataCheckState.isProcessing && submitEnabled,
    };
  };

  useEffect(() => {
    parseCSV(file).then(async (csvRows) => {
      //parse phone number in such way that it gets validated properly
      const finalObject = await validateCSVrows(csvRows);
      if (Object.keys(finalObject?.formErrorsByStaffId).length) {
        const filteredErrorRows: any = [],
          filteredSuccessRows: any = [];
        csvRows.map((row) => {
          row.id in finalObject.formErrorsByStaffId ? filteredErrorRows.push(row) : filteredSuccessRows.push(row);
        });
        setFileDataCheckState({
          staffErrorRows: filteredErrorRows,
          staffRows: filteredSuccessRows,
          hasInitialErrors: true,
          formErrorsByStaffId: finalObject?.formErrorsByStaffId,
        });
      } else {
        setFileDataCheckState({ staffRows: csvRows });
      }
    });
  }, [file]);

  const deleteStaffHandler = (staff: any) => {
    const dupStaffRows = fileDataCheckState.hasInitialErrors
      ? [...fileDataCheckState.staffErrorRows]
      : [...fileDataCheckState.staffRows];
    const index = dupStaffRows.findIndex((s) => s.id === staff.id);

    if (index === -1) {
      console.error('Unable to delete row.');
      return;
    }
    dupStaffRows.splice(index, 1);
    const dirtyForm = areSomeRowsFilled(dupStaffRows);

    const formErrorsByStaffId = { ...fileDataCheckState.formErrorsByStaffId };
    if (staff.id in fileDataCheckState.formErrorsByStaffId) {
      delete formErrorsByStaffId[staff.id];
      //If we are in error table mode, we have to consider the valid staffRows emails as well before clearing error messages
      const previousEmails = fileDataCheckState.hasInitialErrors
        ? fileDataCheckState.staffRows.map((staff) => staff.email?.toLowerCase())
        : [];
      retestForEmailUniqueness(dupStaffRows, formErrorsByStaffId, previousEmails);
    }

    fileDataCheckState.hasInitialErrors
      ? setFileDataCheckState({
          staffErrorRows: dupStaffRows,
          dirtyForm,
          submitEnabled: dirtyForm,
          formErrorsByStaffId,
        })
      : setFileDataCheckState({ staffRows: dupStaffRows, dirtyForm, submitEnabled: dirtyForm, formErrorsByStaffId });
  };

  const editStaffChangeHandler = (staff: IStaffEditable) => {
    let updatedStaffRows;
    if (fileDataCheckState.hasInitialErrors) {
      updatedStaffRows = fileDataCheckState.staffErrorRows.map((row) => {
        if (row.id === staff.id) {
          return staff;
        }
        return row;
      });
      const dirtyForm = areSomeRowsFilled(updatedStaffRows);
      setFileDataCheckState({ staffErrorRows: updatedStaffRows, dirtyForm, submitEnabled: dirtyForm });
    } else {
      updatedStaffRows = fileDataCheckState.staffRows.map((row) => {
        if (row.id === staff.id) {
          return staff;
        }
        return row;
      });
      const dirtyForm = areSomeRowsFilled(updatedStaffRows);
      setFileDataCheckState({ staffRows: updatedStaffRows, dirtyForm, submitEnabled: dirtyForm });
    }
  };

  const retestForEmailUniqueness = (
    staffRowsOfInterest: IStaffEditable[],
    formErrorsByStaffId: { [key: string]: IStaffEditable },
    emails_checked: string[]
  ) => {
    //creating our email duplicate counter
    const dupEmailCount: { [key: string]: number } = {};

    emails_checked?.forEach((email) => {
      const parsed_email = email?.toLowerCase()?.trim() || '';
      if (parsed_email && dupEmailCount[parsed_email]) {
        dupEmailCount[parsed_email] += 1;
      } else {
        dupEmailCount[parsed_email] = 1;
      }
    });
    staffRowsOfInterest?.forEach((staff) => {
      const email = staff.email?.toLowerCase()?.trim() || '';

      if (email && dupEmailCount[email]) {
        dupEmailCount[email] += 1;
      } else {
        dupEmailCount[email] = 1;
      }
    });
    //if email has been edited, causing no more duplicates make sure to update other formErrorsByStaffId
    staffRowsOfInterest.map((staff) => {
      const email = staff.email?.toLowerCase()?.trim() || '';
      if (dupEmailCount?.[email] && dupEmailCount[email] === 1) {
        if (
          formErrorsByStaffId?.[staff.id]?.email &&
          formErrorsByStaffId[staff.id]?.email === 'Email has already been used.'
        ) {
          formErrorsByStaffId[staff.id].email = '';
          const allFieldsEmpty =
            formErrorsByStaffId[staff.id]?.firstName === '' &&
            formErrorsByStaffId[staff.id]?.lastName === '' &&
            formErrorsByStaffId[staff.id]?.phone === '';

          if (allFieldsEmpty) {
            delete formErrorsByStaffId[staff.id];
          }
        }
      } else {
        if (!formErrorsByStaffId?.[staff.id]) {
          formErrorsByStaffId[staff.id] = { ...emptyStaff, role: '' };
        }
        formErrorsByStaffId[staff.id].email = 'Email has already been used.';
      }
    });
  };

  const onBlur = async (staff: IStaffEditable, fieldName: keyof IStaffEditable) => {
    //rerun validation for this row
    const formErrorsByStaffId: { [key: string]: IStaffEditable } = { ...fileDataCheckState.formErrorsByStaffId };

    formErrorsByStaffId[staff.id] = {
      ...emptyStaff,
      email:
        formErrorsByStaffId?.[staff.id]?.email === 'Email has already been used.' ? 'Email has already been used.' : '',
      role: '',
    };

    let errorFoundInRow = false;

    staff[fieldName] = staff[fieldName]?.trim() || '';

    let staffErrorRows, staffRows;

    if (fileDataCheckState.hasInitialErrors) {
      staffErrorRows = [...fileDataCheckState.staffErrorRows];
      const staffIndex = staffErrorRows.findIndex((s) => s.id === staff.id);
      staffErrorRows[staffIndex] = staff;
    } else {
      staffRows = [...fileDataCheckState.staffRows];
      const staffIndex = staffRows.findIndex((s) => s.id === staff.id);
      staffRows[staffIndex] = staff;
    }

    await validateAll(staff, staffValidationRules, userValidationMessages).catch((errors: any) => {
      errorFoundInRow = true;
      catchCallback(staff.id, formErrorsByStaffId)(errors);
    });

    // validate phone number again with E164 check
    if (staff.phone && !isValidPhoneNumber(staff.phone)) {
      errorFoundInRow = true;
      formErrorsByStaffId[staff.id].phone = 'Please enter a valid phone number.';
    }
    // validate if the email is unique

    if (fieldName === 'email') {
      const staffRowsOfInterest: IStaffEditable[] = fileDataCheckState.hasInitialErrors ? staffErrorRows! : staffRows!;
      //If we are in error table mode, we have to consider the valid staffRows emails as well
      const previousEmails = fileDataCheckState.hasInitialErrors
        ? fileDataCheckState.staffRows.map((staff) => staff.email?.toLowerCase())
        : [];
      retestForEmailUniqueness(staffRowsOfInterest, formErrorsByStaffId, previousEmails);
    }

    if (formErrorsByStaffId?.[staff.id]?.email) {
      errorFoundInRow = true;
    }

    if (!errorFoundInRow) {
      delete formErrorsByStaffId[staff.id];
    }

    const values: any = { formErrorsByStaffId, isDirty: true };

    if (fileDataCheckState.hasInitialErrors) {
      values.staffErrorRows = staffErrorRows;
    } else {
      values.staffRows = staffRows;
    }

    setFileDataCheckState(values);
  };

  const adjustmentCardBody = (
    <div>
      <AddStaff
        showChecked={true}
        drawerTableComponent={DrawerTableComponent.fileUpload}
        deleteStaffHandler={deleteStaffHandler}
        editable={true}
        editStaffChangeHandler={editStaffChangeHandler}
        onBlur={onBlur}
        items={fileDataCheckState.hasInitialErrors ? fileDataCheckState.staffErrorRows : fileDataCheckState.staffRows}
        pageOpts={fileDataCheckState.startingPageOpts}
        formErrorsByStaffId={fileDataCheckState.formErrorsByStaffId}
      />
    </div>
  );

  const DetermineTitleContent = () => {
    if (fileDataCheckState.hasInitialErrors && Object.keys(fileDataCheckState.formErrorsByStaffId).length) {
      return <ErrorCardTitle />;
    } else if (fileDataCheckState.hasInitialErrors && !Object.keys(fileDataCheckState.formErrorsByStaffId).length) {
      return <ResolvedErrorCardTitle />;
    } else {
      return <AdjustmentCardTitle />;
    }
  };

  return loading ? (
    <div className='d-flex justify-content-center align-items-center w-100 my-5 py-5'>
      <Spinner />
    </div>
  ) : (
    <>
      {!fileDataCheckState.hasInitialErrors && <CardWithTitle titleContent={<SuccessCardTitle />} />}
      <CardWithTitle titleContent={<DetermineTitleContent />} bodyContent={adjustmentCardBody} />
    </>
  );
}
