/* eslint react/no-direct-mutation-state: 0 */

import React, { useEffect } from 'react';
import { Icon, YellowButton } from '@cb/apricot-react';
import { analyticsTracking } from '../../../utils/analytics';
import { fuzzySearch, localStateReducer, parseDateFormat } from '../../../utils/common';
import { isNoDisplayDateEvent, isInSchoolAPEvents } from '../../../utils/event';
import { useStateDispatch, useStateValue } from '../../../context/AppContext';
import { useLazyQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import orderBy from 'lodash/orderBy';
import ReactSelect, { components } from 'react-select';
import ReactSelectAsync from 'react-select/async';
import moment from 'moment';
import { GET_AVAILABLE_EVENTS_QUERY, GET_AVAILABLE_SITES_QUERY } from '../../../apollo/queries';
import iamRoles from '../../../constants/iamRoles';

const filterOptions = (candidate, input) => {
  const escapeRegExp = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  if (input) {
    const escapedString = escapeRegExp(input);
    return !!fuzzySearch(escapedString, candidate.label) || !!fuzzySearch(escapedString, candidate.value);
  }
  return true;
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon name='down' decorative style={{ fontSize: '.6875rem', fontWeight: 'bold', padding: '0 .3rem' }} />
    </components.DropdownIndicator>
  );
};

const customStyles = {
  control: (controlStyles) => {
    return {
      ...controlStyles,
      borderColor: '#d9d9d9',
      borderRadius: '8px',
      height: '40px',
    };
  },
  dropdownIndicator: (styles) => {
    return {
      ...styles,
      color: 'hsl(0, 0%, 0%)',
    };
  },
  menu: (styles) => {
    return {
      ...styles,
      zIndex: 20,
    };
  },
  indicatorSeparator: (indicatorStyles) => {
    return {
      ...indicatorStyles,
      backgroundColor: '#d9d9d9',
      margin: 0,
    };
  },
};

function getEvents(events = [], siteId = '', has803Role = false) {
  if (siteId === '' || events.length < 1) {
    return [];
  }

  // Events for a given test center, ordered by start date.
  const selectableEvents = orderBy(
    events.map((event) => {
      const setEventStartDt = moment(event.eventStartDt).format('MMM D, YYYY');
      const setDateRange = isNoDisplayDateEvent(event)
        ? ''
        : ` | ${isInSchoolAPEvents(event) ? setEventStartDt : parseDateFormat(event.eventStartDt, event.eventEndDt)}`;

      const adminEventIdText = ` [${event.asmtEventId}]`;
      const title = has803Role
        ? `${event.eventTitle}${adminEventIdText} ${setDateRange}`
        : `${event.eventTitle} ${setDateRange}`;
      return {
        order: Date.parse(event.eventStartDt),
        title: title,
        value: event.asmtEventId,
      };
    }),
    'order',
    'asc'
  );

  return selectableEvents;
}

function OrgEventQueryContainer() {
  const navigate = useNavigate();

  // Get session data if available.
  let orgEvent;

  try {
    orgEvent = JSON.parse(sessionStorage.getItem('orgEvent'));
  } catch (e) {
    orgEvent = {};
  }

  // Global App state.
  const { user } = useStateValue();
  const dispatch = useStateDispatch();

  const has803Role = user.token?.cb?.ro?.some?.((role) => role?.rid === '803');

  //role
  const userRole = user.token?.cb?.ro?.[0] || {};

  // Local state.
  const [localState, setLocalState] = React.useReducer(localStateReducer, {
    eventId: orgEvent ? orgEvent.asmtEventId : '',
    siteId: orgEvent ? orgEvent.siteId : '',
  });

  const [getSitesData, { data: sitesData }] = useLazyQuery(GET_AVAILABLE_SITES_QUERY, {
    variables: { input: { query: orgEvent?.siteId || '' } },
  });

  const [getEventsData, { data: eventsData }] = useLazyQuery(GET_AVAILABLE_EVENTS_QUERY);

  const selectableSitesKeyedByValue = keyBy(
    sitesData?.availableSitesQuery?.sites.map?.((site) => ({
      title: site?.title,
      type: site?.testCenterTypeCd === 'R' ? ' (Sunday)' : '',
      value: site?.siteId,
    })),
    'value'
  );

  function handleContinue(admins = [], eventId, siteId) {
    return function (e) {
      e && e.preventDefault && e.preventDefault();

      // Trigger selection.
      selectEvent(admins, eventId, siteId);
    };
  }

  useEffect(() => {
    if (orgEvent?.siteId) {
      getEventsData({ variables: { input: { siteId: orgEvent.siteId } } });
    }
  }, []);

  async function selectEvent(admins = [], eventId, siteId) {
    // Get all the event info for what they selected and store it.
    const site = sitesData?.availableSitesQuery?.sites?.find?.((site) => site?.siteId === siteId);
    const event = eventsData?.availableEventsQuery?.events?.find?.((event) => event?.asmtEventId === eventId);
    if (site && event) {
      sessionStorage.setItem(
        'orgEvent',
        JSON.stringify({
          asmtEventId: event?.asmtEventId || '',
          asmtEventTypeCd: event?.asmtEventTypeCd || '',
          asmtId: event?.asmtId || '',
          asmtSubtypeCd: event?.asmtSubtypeCd || '',
          dapInd: !!event?.dapInd,
          eventDisplayDescr: event?.eventDisplayDescr || '',
          eventTitle: event?.eventTitle || '',
          featureFlags: event?.featureFlags || [],
          isoCountryCode: 'US',
          orgId: site?.orgId || '',
          siteId: site?.siteId || '',
          testCenterTypeCd: site?.testCenterTypeCd || '',
          title: site?.title || '',
        })
      );

      dispatch({
        orgEvent: {
          asmtEventId: event?.asmtEventId || '',
          asmtEventTypeCd: event?.asmtEventTypeCd || '',
          asmtId: event?.asmtId || '',
          asmtSubtypeCd: event?.asmtSubtypeCd || '',
          dapInd: !!event?.dapInd,
          eventEndDt: event?.eventEndDt || '',
          eventStartDt: event?.eventStartDt || '',
          eventDisplayDescr: event?.eventDisplayDescr || '',
          eventTitle: event?.eventTitle || '',
          featureFlags: event?.featureFlags || [],
          isoCountryCode: 'US',
          orgId: site?.orgId || '',
          siteId: site?.siteId || '',
          testCenterTypeCd: site?.testCenterTypeCd || '',
          title: site?.title || '',
        },
        user: {
          rid: userRole?.rid,
          title: userRole?.rn,
        },
      });

      // Dispatch the update to the user state.
      if (window.location.pathname === '/event') {
        navigate('/');
      }
    }
  }

  async function handleOrgSelection(siteId) {
    // fire off site events query
    await getEventsData({ variables: { input: { siteId } } });

    const rid = userRole?.rid;
    const title = iamRoles[rid] || '';

    sessionStorage.setItem(
      'user',
      JSON.stringify({
        rid,
        title,
      })
    );
    setLocalState({
      eventId: '',
      siteId,
    });
  }

  function handleEventSelection(val) {
    setLocalState({
      eventId: val,
    });
  }

  React.useEffect(() => {
    // Invoke site analytics.
    analyticsTracking(user.userRole, '', 'select-admin');
  }, [user.userRole]);

  let eventId = localState.eventId;
  const siteId = localState.siteId;
  let selectableEvents;

  selectableEvents = getEvents(eventsData?.availableEventsQuery?.events, siteId, has803Role);
  selectableEvents = [{ order: 0, title: 'Choose a test administration', value: '', type: '' }, ...selectableEvents];
  if (selectableEvents.length === 1) {
    eventId = selectableEvents[0].value;
  }

  const selectableEventsKeyedByValue = keyBy(selectableEvents, 'value');

  // enhance options objects to have a label that we can use (based on their title)
  const transformSelectOptions = (a) => {
    return a.reduce((prev, cur) => {
      return [
        ...prev,
        {
          label: cur.title,
          ...cur,
        },
      ];
    }, []);
  };

  const loadSiteOptions = async (inputValue: string, cb) => {
    const response = await getSitesData({ variables: { input: { query: inputValue || localState.siteId } } });
    let formattedData = response?.data?.availableSitesQuery?.sites?.map?.((site) => ({
      title: site?.title,
      type: site?.testCenterTypeCd === 'R' ? ' (Sunday)' : '',
      value: site?.siteId,
    }));
    if (!has803Role && inputValue) {
      formattedData = formattedData.filter(
        (item) =>
          item.title?.toUpperCase().includes(inputValue.toUpperCase()) ||
          item.value?.toUpperCase().includes(inputValue.toUpperCase())
      );
    }

    cb(formattedData?.sort((a, b) => a.title.localeCompare(b.title)));
  };

  const debouncedLoadSiteOptions = debounce(loadSiteOptions, 500);
  const displayRole = JSON.parse(sessionStorage.getItem('user') || '{}').title;

  return (
    <React.Fragment>
      <form
        id='choose-test-admin'
        onSubmit={handleContinue(/*admins*/ [], eventId, siteId)}
        data-env={`${import.meta.env.REACT_APP_TDTK_ENV}`}
      >
        <div className='row'>
          <div className='mx-auto col-lg-offset-2 col-lg-8 box-card shadow border__rounded-full border__gray mt-4 px-4 pb-4 p-relative'>
            <h1 className='tdtk-h1 pt-4 mb-4' data-automation='heading-h1'>
              Choose a Test Administration
            </h1>

            <ul className='mb-4'>
              <li>You can access one test administration at one test site each time you sign in.</li>
              <li>We&rsquo;ll email you when each administration is available.</li>
            </ul>

            <div className='mb-4'>* = Required</div>

            <div className='tdtk-form-group' data-automation='select-test-center'>
              <label
                id='selectSite-label'
                className='control-label cb-required cb-roboto-bold'
                htmlFor='selectSite'
                style={{ fontWeight: 'bold' }}
              >
                Test Site
              </label>
              <ReactSelectAsync
                components={{ DropdownIndicator }}
                defaultOptions={true}
                defaultValue={selectableSitesKeyedByValue[siteId]}
                getOptionLabel={(option) => `${option.title} ${option.value}${option.type}`}
                id='site-select-menu'
                loadOptions={debouncedLoadSiteOptions}
                name='selectSite'
                onChange={(option) => handleOrgSelection(option.value)}
                styles={customStyles}
                value={selectableSitesKeyedByValue[siteId]}
              />
            </div>

            <div
              className={
                isEmpty(siteId) ? 'animation--slide-in-from-right__inactive' : 'animation--slide-in-from-right__active'
              }
            >
              {displayRole && (
                <div className='mb-4'>
                  <strong>Role</strong>
                  <br />
                  <p>{displayRole}</p>
                </div>
              )}

              <div className='mb-4'>
                <label
                  id='selectEvent-label'
                  className='control-label cb-required cb-roboto-bold'
                  htmlFor='selectEvent'
                  style={{ fontWeight: 'bold' }}
                >
                  Test Administration
                </label>
                <ReactSelect
                  components={{ DropdownIndicator }}
                  defaultValue={selectableEventsKeyedByValue[eventId]}
                  filterOption={filterOptions}
                  getOptionLabel={(option) => `${option.title}`}
                  id='event-select-menu'
                  name='selectEvent'
                  onChange={(option) => handleEventSelection(option.value)}
                  options={!isEmpty(siteId) ? transformSelectOptions(selectableEvents) : []}
                  styles={customStyles}
                  value={selectableEventsKeyedByValue[eventId]}
                />
              </div>
            </div>
            <div
              className={
                isEmpty(eventId) ? 'animation--slide-in-from-right__inactive' : 'animation--slide-in-from-right__active'
              }
            >
              <YellowButton key='org-event-continue' type='submit' disabled={isEmpty(siteId) || isEmpty(eventId)}>
                Continue
              </YellowButton>
            </div>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
}

export default OrgEventQueryContainer;
