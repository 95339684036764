import React, { useContext, useRef } from 'react';
import { Dropdown, DropdownItem } from '@cb/apricot-react';
import { trapKeyboardFocus, localStateReducer } from '../../../utils/common';
import TooltipWrapper from '../tooltip/TooltipWrapper';
import TwoButtonModal from '../modal/standard/TwoButtonModal';
import { ModalDispatchContext } from '../modal/ModalContext';
import './DropDownDrawerButton.scss';
import { Drawer } from '../drawer/Drawer';

type DropDownDrawerButtonProps = {
  items: { name: string; component: React.ReactElement; promptUnsavedDrawer?: boolean }[];
  name: string;
  title: string;
  label: string;
  tooltipMsg: { popoverTitle: string; popoverText: string };
};

export function DropDownDrawerButton(props: DropDownDrawerButtonProps) {
  const { tooltipMsg, label, items, name, title, ...restProps } = props;
  const [localState, setLocalState] = React.useReducer(localStateReducer, {
    drawerOpen: false,
    currentComponent: '',
  });
  const dispatchModal = useContext(ModalDispatchContext);
  const components: Record<
    string,
    { name: string; title?: string; component: React.ReactElement; promptUnsavedDrawer?: boolean }
  > = {};
  const childRef = useRef(false);
  const closeBtnRef = useRef(null);

  items.forEach((item) => {
    components[item.name] = item;
  });

  function handleEscPress(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      // below code determines the parent DOM of the event to avoid modal prompt in the loop when esc key is pressed continuously
      // function will be called when esc is being pressed from any parent modal other than cb-modal-overlay.
      const target = event.target as HTMLElement;
      if (!target.classList.contains('cb-modal-overlay') && !target.closest('.cb-modal-overlay')) {
        promptUnsavedDrawer();
      }
    }
  }

  function handleDrawerDirty(isDirty: boolean) {
    childRef.current = isDirty;
  }

  function focusCloseBtn() {
    if (closeBtnRef.current) {
      closeBtnRef.current.focus();
    }
  }

  function promptUnsavedDrawer() {
    const promptUnsavedDrawer = components[localState.currentComponent]?.promptUnsavedDrawer;
    const hasUnsavedData = childRef.current;
    if (promptUnsavedDrawer === true && hasUnsavedData === true) {
      dispatchModal(
        <TwoButtonModal
          body="You'll lose unsaved changes if you cancel now."
          title='Are you sure you want to cancel?'
          primaryButtonLabel='Yes'
          secondaryButtonLabel='No'
          primaryButtonHandler={() => closeDrawer()}
          modalId='changeStaffRoleConfirmation'
          secondaryButtonHandler={() => focusCloseBtn()}
          trigger={''}
          clickOverlayToClose={false}
          variant={'error'}
        />
      );
    } else {
      closeDrawer();
    }
  }

  function closeDrawer() {
    if (localState.drawerOpen) {
      document.getElementById(`${props.name}-dropdown`)?.focus();
      setLocalState({
        drawerOpen: false,
        currentComponent: '',
      });
      document.body.classList.remove('cb-modal-open');
      window.removeEventListener('keydown', handleEscPress);
    }
  }

  function openDrawer(item?: { name: string; component: React.ReactElement; promptUnsavedDrawer?: boolean }) {
    if (!localState.drawerOpen) {
      setLocalState({
        drawerOpen: true,
        currentComponent: item?.name,
      });
      document.body.classList.add('cb-modal-open');
      setTimeout(function () {
        trapKeyboardFocus(`${name}-side-drawer`);
      }, 100);
      window.addEventListener('keydown', handleEscPress);
      handleDrawerDirty(false);
    }
  }

  return (
    <>
      <Dropdown
        dropdownId={`${props.name}-dropdown`}
        dropdownMenuId={`${props.name}-dropdown-menu`}
        className='tdtk-no-box-shadow tdtk-auto-height tdtk-dropdown-decoration'
        classNameToggle='cb-btn cb-btn-sm cb-btn-yellow display-flex align-items-center tdtk-dropdown-toggle'
        classNameMenu='cb-margin-top-4 cb-no-border-radius'
        closeOnClick={true}
        title={props.label}
      >
        {items.map((item) => (
          <DropdownItem
            dropdownItemId={`${props.name}-dropdown--${item.name.split(' ').join('-').toLowerCase()}`}
            key={item.name}
            label={item.name}
            onClick={() => openDrawer(item)}
          />
        ))}
      </Dropdown>
      {tooltipMsg ? (
        <TooltipWrapper light={true} trigger={`${props.name}-dropdown`} tooltipId={`tooltip-${props.name}`}>
          <>
            <div>{tooltipMsg.popoverTitle}</div>
            <div>{tooltipMsg.popoverText}</div>
          </>
        </TooltipWrapper>
      ) : (
        <></>
      )}

      <div
        className={`drawer-overlay-bg ${localState.drawerOpen ? 'slide-open' : ''}`}
        onClick={() => promptUnsavedDrawer()}
        onKeyDown={() => promptUnsavedDrawer()}
        role='presentation'
      ></div>

      <Drawer
        open={localState.drawerOpen}
        title={components[localState.currentComponent]?.title}
        onClose={() => promptUnsavedDrawer()}
        drawerId={`${name}-side-drawer`}
        handleDrawerDirty={handleDrawerDirty}
        closeBtnRef={closeBtnRef}
      >
        {components[localState.currentComponent]?.component || <></>}
      </Drawer>
    </>
  );
}
