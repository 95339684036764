import React from 'react';
import './StaffRosterRow.scss';
import RosterV3 from '../../../common/roster/RosterV3';
import StaffRosterRow from './StaffRosterRow';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import uniqBy from 'lodash/uniqBy';
import { IRoom, IStaff } from './type';
import { InventoryContainer } from './InventoryContainer';
import { ResizeProvider, ResizeContext } from '../../../../context/ResizeContext';
import { isEqual } from 'apollo-utilities';
import { localStateReducer, sortItems } from '../../../../utils/common';
import { roles, ROLES_MAP } from '../../../../constants/roles';
import { StaffAddRoster } from './StaffAddRoster';
import { StaffUploadContainer } from './StaffUploadContainer';

type IStaffRosterProps = {
  checkboxAllRef: React.MutableRefObject<undefined>;
  checkedStaff: string[];
  handleCheckStaff: (staffIds: string[], checkAll?: boolean) => void;
  roomList: IRoom[];
  staffList: IStaff[];
};

function StaffRoster({
  checkboxAllRef = { current: undefined },
  checkedStaff = [],
  handleCheckStaff = () => {},
  roomList = [],
  staffList = [],
}: IStaffRosterProps) {
  const nonAdminStaff = staffList.filter((p) => p.role !== 'admin');
  const admins = staffList.filter((s) => s.role === ROLES_MAP.ADMIN);
  const isLastCoordinator = admins.length === 1;

  function applyFilters(filterObj = {}, searchValue = '', searchFields = ['lastName', 'firstName', 'username']) {
    // Only get active filters, make a special case for the false case
    const activeFilters = Object.keys(filterObj).filter((key) => filterObj[key] || filterObj[key] === false);
    const isFiltered = !isEmpty(activeFilters);
    let filteredStaff = staffList;
    let isVisible = true;

    if (!isEmpty(searchValue)) {
      searchValue = searchValue.toUpperCase();
    }

    if (isFiltered || searchValue) {
      filteredStaff = staffList.filter((item) => {
        isVisible = activeFilters.every((filter) => {
          // Check if they are unassigned.
          if ((filter === 'role' || filter === 'room.id') && filterObj[filter] === 'unassigned') {
            return !get(item, filter);
          } else if (filter === 'confirmed') {
            const valueMap: Record<string, boolean> = {
              all: true,
              unconfirmed: !get(item, filter),
              confirmed: get(item, filter),
            };

            return valueMap[filterObj[filter]];
          } else {
            return get(item, filter) === filterObj[filter];
          }
        });

        if (isVisible && searchValue) {
          const objFields = [];
          searchFields.forEach((searchField) => {
            if (item[searchField]) {
              objFields.push(item[searchField].toUpperCase());
            }
          });
          if (!isEmpty(objFields)) {
            isVisible = objFields.some((item) => item.indexOf(searchValue) > -1);
          }
        }
        return isVisible;
      });
    }

    const newChange = {
      isFiltered,
      filteredStaff,
    };

    newChange.search = searchValue;
    newChange.filterObj = filterObj;

    setLocalState({
      ...newChange,
    });
  }

  // The list of filters needed for staff
  function generateFilterList() {
    const { filterObj } = localState;

    // Get all the rooms that this staff has
    const roomSelections = sortItems(
      staffList.reduce((accum, item) => {
        if (get(item, 'room.title') && get(item, 'room.title')) {
          accum.push({
            title: item.room.title,
            value: item.room.id,
          });
        }
        return accum;
      }, []),
      [
        {
          name: 'title',
          order: 'asc',
        },
      ]
    );

    return [
      {
        defaultValue: [filterObj['role']],
        field: 'role',
        id: 'staff-filter-role',
        label: 'Filter Staff By Role',
        type: 'select',
        selectOptions: [
          { title: '-Select-', value: '' },
          { title: 'Unassigned', value: 'unassigned' },
          ...Object.keys(roles).map((key) => ({
            title: roles[key],
            value: key,
          })),
        ],
      },
      {
        defaultValue: [filterObj['room.id']],
        field: 'room.id',
        id: 'staff-filter-room',
        label: 'Filter Staff By Room',
        type: 'select',
        selectOptions: [
          { title: '-Select-', value: '' },
          { title: 'Unassigned', value: 'unassigned' },
          ...uniqBy(roomSelections, 'value'),
        ],
      },
      {
        defaultValue: [filterObj['confirmed']],
        field: 'confirmed',
        id: 'staff-filter-confirmation-status',
        label: 'Filter Staff by Sign-In Success Status',
        required: false,
        type: 'select',
        selectOptions: [
          { title: '-Select-', value: '' },
          { title: 'All', value: 'all' },
          { title: 'Confirmed', value: 'confirmed' },
          { title: 'Unconfirmed', value: 'unconfirmed' },
        ],
      },
    ];
  }

  function getNumFilters() {
    const numFilters = Object.keys(localState.filterObj).length;
    return numFilters ? ` (${numFilters})` : '';
  }

  function generateHeaders() {
    return [
      { title: 'checkAll', sortField: [] },
      { title: 'Staff Name', sortField: ['firstName', 'lastName'] },
      { title: 'Phone', sortField: ['phone'] },
      { title: 'Role', sortField: ['role'] },
      { title: 'Room', sortField: ['room.title'] },
      { title: 'Sign-In Success', sortField: ['confirmed'] },
      { title: 'Toolkit Access', sortField: ['active'] },
      { title: 'Actions', sortField: [] },
    ];
  }

  function updateSearch(e) {
    const search = e.target.value;

    applyFilters(localState.filterObj, search);
  }

  function updateFilter(e) {
    const name = get(e, 'target.name', 'confirmed');
    const value = get(e, 'target.value');

    let filterObj = {};

    if (name !== 'resetFilters') {
      filterObj = {
        ...localState.filterObj,
        [name]: value,
      };

      filterObj = omitBy(filterObj, (filterAttr) => {
        return filterAttr === '';
      });
    }

    applyFilters(filterObj, localState.search);
  }

  function buildTableControlElements() {
    return {
      search: [
        {
          defaultValue: localState.search,
          icon: 'search',
          label: 'Enter part of a staff member’s name here to find the staff member',
          name: 'staff-search',
          onChange: updateSearch,
          renderWhenRosterEmpty: true,
          placeholder: 'Search assigned staff',
          srOnlyLabel: true,
          suppressFormGroupClass: true,
          type: 'input',
        },
      ],
      buttons: [
        {
          label: `Filter${getNumFilters()}`,
          id: 'staffFilterButton',
          popoverTitle: 'Staff Filter',
          popoverText: 'Staff Filter Test',
          icon: 'filter',
          name: 'staffFilter',
          isExpanded: true,
          type: 'filterButton',
          filterList: generateFilterList(),
          updateFilter,
          classes: localState.isFiltered
            ? 'cb-btn-filter cb-filter-active full-width--small-screen'
            : 'full-width--small-screen',
        },
        {
          label: 'Add Staff',
          name: 'add-staff',
          renderWhenRosterEmpty: true,
          ...(!nonAdminStaff || nonAdminStaff.length === 0
            ? {
                tooltipMsg: {
                  popoverText: 'Search for staff you’ve already added or add new staff.',
                },
              }
            : {}),
          items: [
            {
              name: 'Upload file',
              title: 'Upload File',
              promptUnsavedDrawer: true,
              component: <StaffUploadContainer />,
            },
            {
              name: 'Select returning staff',
              title: 'Select Returning Staff From List',
              promptUnsavedDrawer: true,
              component: <InventoryContainer />,
            },
            {
              name: 'Add to table',
              title: 'Add New Staff',
              promptUnsavedDrawer: true,
              component: <StaffAddRoster />,
            },
          ],
          type: 'dropdown',
        },
      ],
    };
  }

  // Global App state.
  const windowSize = React.useContext(ResizeContext);

  // Local state.
  const [localState, setLocalState] = React.useReducer(localStateReducer, {
    filteredStaff: staffList,
    filterObj: {},
    initialStaffList: staffList,
    isFiltered: false,
  });

  // Update the filtered staff whenever we get new props.
  React.useEffect(() => {
    if (!isEqual(localState.initialStaffList, staffList)) {
      setLocalState({
        initialStaffList: staffList,
      });

      applyFilters(localState.filterObj, localState.search);
    }
  });

  // Apollo.
  const headers = generateHeaders();
  const tableControlElements = buildTableControlElements();
  const emptyMessage = isEmpty(staffList) ? 'You haven’t added any staff for this test date yet.' : '';

  return (
    <ResizeProvider>
      <div className='mobile-striping'>
        <div className='box-card py-4 px-0'>
          <RosterV3
            caption='Test Center Staff'
            checkboxAllRef={checkboxAllRef}
            checkedItems={checkedStaff}
            emptyMessage={emptyMessage}
            groupName='staff'
            handleCheckItems={handleCheckStaff}
            headers={headers}
            id='staff-table'
            items={[...localState.filteredStaff]}
            sortFields={[
              {
                name: 'lastName',
                order: 'asc',
              },
              {
                name: 'firstName',
                order: 'asc',
              },
            ]}
            renderItem={(staffUser, options) => (
              <StaffRosterRow
                handleCheckItems={handleCheckStaff}
                key={`staff_${staffUser.id}`}
                options={{
                  ...options,
                  checked: checkedStaff.indexOf(staffUser.id) > -1,
                }}
                staff={staffUser}
                rooms={roomList}
                isLastCoordinator={isLastCoordinator}
              />
            )}
            showSelected={!windowSize.mobile}
            stickyHeaders={true}
            tableControlsClassName='px-3 px-xl-4'
            tablePageOptsClassName='px-3 px-xl-4'
            tableWrapperClassName='px-3 px-xl-4 table-checkbox-override'
            tableControlElements={tableControlElements}
            totalCount={localState.filteredStaff.length}
          />
        </div>
      </div>
    </ResizeProvider>
  );
}

export default StaffRoster;
