export const EVENT_TYPES = Object.freeze({
  INSCHOOL_AP: 'inschool-ap',
  INSCHOOL_APHYBRID: 'inschool-aphybrid',
  INSCHOOL_PSAT10: 'inschool-psat10',
  INSCHOOL_PSATNM: 'inschool-psatnm',
  INSCHOOL_PSAT89: 'inschool-psat89',
  INSCHOOL_SAT: 'inschool-sat',
  WEEKEND_SAT: 'wknd-sat',
  WEEKENDATSCHOOL_SAT: 'weekend-at-school-sat',
});
