import React from 'react';
import QueryComponent from '../../../common/network/QueryComponent';
import { GET_STAFF_INVENTORY } from '../../../../apollo/queries';
import get from 'lodash/get';
import StaffInventoryRoster from './StaffInventoryRoster';

interface InventoryContainerProps {
  onClose: () => void;
  handleDrawerDirty: (isDirty: boolean) => void;
}
export function InventoryContainer({ onClose, handleDrawerDirty }: InventoryContainerProps) {
  return (
    <div>
      <QueryComponent query={{ kind: 'GetStaffInventory', specification: GET_STAFF_INVENTORY }}>
        {(data) => {
          const inventoryStaff = get(data, 'viewer.site.inventory') || [];

          return (
            <StaffInventoryRoster
              onClose={onClose}
              handleDrawerDirty={handleDrawerDirty}
              inventoryStaff={inventoryStaff}
            ></StaffInventoryRoster>
          );
        }}
      </QueryComponent>
    </div>
  );
}
