import { EVENT_TYPES } from '../constants/event';

export const IN_SCHOOL_EVENTS = [
  EVENT_TYPES.INSCHOOL_AP,
  EVENT_TYPES.INSCHOOL_APHYBRID,
  EVENT_TYPES.INSCHOOL_PSAT10,
  EVENT_TYPES.INSCHOOL_PSATNM,
  EVENT_TYPES.INSCHOOL_PSAT89,
  EVENT_TYPES.INSCHOOL_SAT,
];

export const IN_SCHOOL_PSAT_EVENTS = [
  EVENT_TYPES.INSCHOOL_PSAT10,
  EVENT_TYPES.INSCHOOL_PSATNM,
  EVENT_TYPES.INSCHOOL_PSAT89,
];

export const IN_SCHOOL_PSAT_SAT_EVENTS = [
  EVENT_TYPES.INSCHOOL_PSAT10,
  EVENT_TYPES.INSCHOOL_PSATNM,
  EVENT_TYPES.INSCHOOL_PSAT89,
  EVENT_TYPES.INSCHOOL_SAT,
];

export const IN_SCHOOL_AP_EVENTS = [EVENT_TYPES.INSCHOOL_AP, EVENT_TYPES.INSCHOOL_APHYBRID];

export const WEEKEND_EVENTS = [EVENT_TYPES.WEEKEND_SAT, EVENT_TYPES.WEEKENDATSCHOOL_SAT];

export const NO_DISPLAY_DATE_EVENTS = [
  EVENT_TYPES.INSCHOOL_PSAT10,
  EVENT_TYPES.INSCHOOL_PSAT89,
  EVENT_TYPES.INSCHOOL_SAT,
];

export function isInSchoolEvent(event) {
  if (!event?.tdtkAsmtType) {
    return false; // handle null or empty tdtkAsmtType: default to non-in-school event
  } else {
    return IN_SCHOOL_EVENTS.includes(event?.tdtkAsmtType);
  }
}

export function isInSchoolPSATEvent(event) {
  if (!event?.tdtkAsmtType) {
    return false; // handle null or empty tdtkAsmtType
  } else {
    return IN_SCHOOL_PSAT_EVENTS.includes(event?.tdtkAsmtType);
  }
}
export function isInSchoolPSATNMEvent(event) {
  if (!event?.tdtkAsmtType) {
    return false; // handle null or empty tdtkAsmtType
  } else {
    return event?.tdtkAsmtType === EVENT_TYPES.INSCHOOL_PSATNM;
  }
}

export function isInSchoolPSATSATEvent(event) {
  if (!event?.tdtkAsmtType) {
    return false; // handle null or empty tdtkAsmtType
  } else {
    return IN_SCHOOL_PSAT_SAT_EVENTS.includes(event?.tdtkAsmtType);
  }
}

export function isWeekendEvent(event) {
  if (!event?.tdtkAsmtType) {
    return false; // handle null or empty tdtkAsmtType: default to non-in-school event
  } else {
    return WEEKEND_EVENTS.includes(event?.tdtkAsmtType);
  }
}

export function isInSchoolSATEvent(event) {
  if (!event?.tdtkAsmtType) {
    return false;
  } else {
    return event?.tdtkAsmtType === EVENT_TYPES.INSCHOOL_SAT;
  }
}

export function isInSchoolAPEvent(event) {
  if (!event?.tdtkAsmtType) {
    return false;
  } else {
    return event?.tdtkAsmtType === EVENT_TYPES.INSCHOOL_AP;
  }
}

export function isInSchoolAPHybridEvent(event) {
  if (!event?.tdtkAsmtType) {
    return false;
  } else {
    return event?.tdtkAsmtType === EVENT_TYPES.INSCHOOL_APHYBRID;
  }
}

export function isInSchoolAPEvents(event) {
  if (!event?.tdtkAsmtType) {
    return false;
  } else {
    return IN_SCHOOL_AP_EVENTS.includes(event?.tdtkAsmtType);
  }
}

export function isWeekendAtSchoolSATEvent(event) {
  if (!event?.tdtkAsmtType) {
    return false;
  } else {
    return event?.tdtkAsmtType === EVENT_TYPES.WEEKENDATSCHOOL_SAT;
  }
}

export function isWeekendSATEvent(event) {
  if (!event?.tdtkAsmtType) {
    return false;
  } else {
    return event?.tdtkAsmtType === EVENT_TYPES.WEEKEND_SAT;
  }
}

export function isNoDisplayDateEvent(event) {
  if (!event?.tdtkAsmtType) {
    return false; // handle null or empty tdtkAsmtType: default to non-in-school event
  } else {
    return NO_DISPLAY_DATE_EVENTS.includes(event?.tdtkAsmtType);
  }
}
