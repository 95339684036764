import React, { useState } from 'react';
import { Room } from '../types';
import { Icon } from '@cb/apricot-react';
import ReloadButton from '../../../common/ReloadButton';
import BulkAddRooms from '../formV2/BulkAddRooms';

const GroupsInfo = React.lazy(() => import('../../../../common/groupsInfo/Container'));

import './CreateRoom.scss';

interface CreateRoomsProps {
  enableResetBtn?: boolean;
  footerButtonLabel: string;
  handleRoomChanges?: (currentRooms: Room[]) => void;
  handleUndoButton?: () => void;
  onClose?: () => void;
  handleDrawerDirty?: (isDirty: boolean) => void;
  rooms?: Room[];
  type?: 'import' | 'create';
}

function CreateRooms({
  enableResetBtn = false,
  footerButtonLabel = '',
  handleRoomChanges = () => {},
  handleUndoButton = () => {},
  onClose = () => {},
  handleDrawerDirty = (isDirty: boolean) => {},
  rooms = [],
  type = 'create',
}: CreateRoomsProps) {
  const [toggleSeeOn, setToggleSeeOn] = useState(false);

  function toggleShowTestingGroups() {
    setToggleSeeOn(!toggleSeeOn);
  }

  return (
    <React.Fragment>
      {!(type === 'import' && rooms.length === 0) && (
        <div className='d-flex justify-content-space-between px-4 pb-2 mt-3'>
          <div className='testingGroupsControl d-flex'>
            <div className='testingGroupsControl d-flex flex-row align-items-center'>
              <Icon name={toggleSeeOn ? 'see-off' : 'see-on'} decorative={true} size='24' />
              <a href='#0' className='px-2' onClick={toggleShowTestingGroups} id='rooms-testing-group-toggle'>
                {toggleSeeOn ? 'Hide testing groups' : 'Show testing groups'}
              </a>
            </div>
          </div>
          {type === 'import' && (
            <div className='d-flex cb-align-center cb-width-md-auto-up'>
              <ReloadButton
                buttonStyle='black'
                refetch={() => {
                  handleUndoButton();
                }}
                text='Reset'
                disabled={!enableResetBtn}
                reloadButtonID='import-undo-rel-btn'
                reloadTimeStampID='import-undo-rel-ts-btn'
              />
            </div>
          )}
        </div>
      )}
      {toggleSeeOn && (
        <div id='createRooms-testingGroups' className='testingGroupsContainer'>
          <div className='testingGroupsContent py-3'>
            <GroupsInfo
              description={[
                <p className='mb-4' key='addEnoughRooms' data-automation='testing-groups-paragraph'>
                  <strong>Add Rooms: </strong>
                  Add enough rooms to seat students in these testing groups.
                </p>,
              ]}
              showHeading1={false}
              showHeading2={false}
              usePopovers={true}
              stickyHeaders={false}
              tableStyleShadow={false}
              tableStyleLight={true}
              tableStyleReverseStripes={true}
              tableStyleBorderless={true}
              forceDesktopMode={true}
              showFilterLinks={false}
            />
          </div>
        </div>
      )}
      <BulkAddRooms
        onClose={onClose}
        handleDrawerDirty={handleDrawerDirty}
        initialRows={5}
        rooms={rooms}
        enableResetBtn={enableResetBtn}
        handleRoomChanges={handleRoomChanges}
        footerButtonLabel={footerButtonLabel}
        type={type}
      />
    </React.Fragment>
  );
}

export default CreateRooms;
