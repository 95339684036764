import React, { useContext, useRef, useState } from 'react';
import './StaffRosterRow.scss';
import IconStatus from '../../../ui/icons/IconStatus';
import OneButtonModal from '../../../ui/modal/standard/OneButtonModal';
import TwoButtonFormModal from '../../../ui/modal/standard/TwoButtonFormModal';
import TwoButtonModal from '../../../ui/modal/standard/TwoButtonModal';
import contentStrings from '../../../../constants/contentStrings';
import { GET_STAFF } from '../../../../apollo/queries';
import { IRoom, IStaff } from './type';
import { Link, useNavigate } from 'react-router-dom';
import { ModalDispatchContext } from '../../../ui/modal/ModalContext';
import { NakedButton, Popover, Select, SquareButton } from '@cb/apricot-react';
import { ResizeContext } from '../../../../context/ResizeContext';
import { UPDATE_USER, BULK_REMOVE_USERS, RESEND_EMAIL } from '../../../../apollo/mutations';
import { canCreateAdmin } from '../../../../utils/user';
import { customerSupportInfo } from '../../../../constants/customerSupportInfo';
import { getGroupTypes } from '../../../../utils/getGroupTypes';
import { parsePhoneNumbers, sortItems } from '../../../../utils/common';
import { roles, digitalrolesDisplay, rolesDisplay, ROLES_MAP } from '../../../../constants/roles';
import { staffPhoneLink } from './utils';
import { useMutation } from '@apollo/client';
import { useStateValue } from '../../../../context/AppContext';

interface StaffRosterRowProps {
  handleCheckItems: (staffId: string[], checkAll?: boolean) => void;
  forceDesktopViewForMobile?: boolean;
  inventoryRow?: boolean;
  options?: any;
  staff: IStaff;
  isLastCoordinator?: boolean;
  rooms: IRoom[];
}

function StaffRosterRow({
  forceDesktopViewForMobile = false,
  handleCheckItems = (staffId: string[], checkAll?: boolean) => {},
  isLastCoordinator = false,
  inventoryRow = false,
  options,
  staff,
  rooms,
}: StaffRosterRowProps) {
  // Global App state.
  const { orgEvent, user } = useStateValue();
  const roleRef = useRef(staff.role);
  const roomRef = useRef({ id: staff.room ? staff.room.id : '' });
  const rolesDisplayLocal = orgEvent?.dapInd ? digitalrolesDisplay : rolesDisplay;
  const [open, setOpen] = useState(false);

  function generateRoleOptions() {
    let displayItem;
    const displayRoles: { label: string; value: string; disabled: boolean }[] = [];

    rolesDisplayLocal.forEach((item) => {
      displayItem = {
        label: item.title,
        value: item.value,
        disabled: item.value === roleRef.current,
      };

      if (!item.requireAdmin) {
        displayRoles.push(displayItem);
      } else if (canCreateAdmin(orgEvent, user)) {
        displayRoles.push(displayItem);
      }
    });

    return [
      {
        label: 'Not assigned',
        value: '',
      },
      ...displayRoles,
    ];
  }

  function generateRoomOptions() {
    const groupTypes = getGroupTypes(orgEvent);
    let displayRooms = rooms.map((room: IRoom) => ({
      value: room.id,
      label: `${room.title}${
        room.groupTypes[0] ? ` - ${room.groupTypes[0]}: ${groupTypes[room.groupTypes[0]]?.label}` : ''
      }`,
    }));

    displayRooms = sortItems(
      displayRooms,
      [
        {
          name: 'label',
          order: 'asc',
        },
      ],
      'natural'
    );

    return [
      {
        label: 'Not assigned',
        value: '',
      },
      ...displayRooms,
    ];
  }

  const dispatchModal = useContext(ModalDispatchContext);
  const navigate = useNavigate();

  const [bulkRemoveUsers] = useMutation(BULK_REMOVE_USERS);
  const [resendEmail] = useMutation(RESEND_EMAIL);
  const [updateUser] = useMutation(UPDATE_USER);

  function grantStaffToolkitStatus(staff: IStaff) {
    return changeStaffStatus(staff, 'grant');
  }
  function revokeStaffToolkitStatus(staff: IStaff) {
    return changeStaffStatus(staff, 'revoke');
  }

  function changeStaffStatus(staff: IStaff, mode: 'grant' | 'revoke') {
    let body = (
      <p key={1}>
        If you grant this staff member access to the Test Day Toolkit, they will see sensitive student information.
        <br />
        <br />
        Tip: Wait until test day to grant access.
      </p>
    );

    function handleStaffStatusChange(): Promise<any> {
      const input = {
        active: mode === 'grant',
        id: staff.id,
      };

      return updateUser({
        variables: {
          input,
        },
        refetchQueries: ['inventory', 'getStaff', 'getSiteStats'],
      })
        .then(() => {
          return dispatchModal(
            <OneButtonModal
              modalId='staffChangeStatusCompleted'
              title={
                mode === 'grant'
                  ? 'You’ve granted this staff member access to the Toolkit.'
                  : 'You’ve revoked this staff member’s Toolkit access.'
              }
              variant='success'
            />
          );
        })
        .catch(() => {
          return dispatchModal(
            <OneButtonModal
              body='Please try again later.'
              modalId='staffStatusError'
              title={
                mode === 'grant'
                  ? 'We were unable to grant this staff member access to the Toolkit.'
                  : 'We were unable to revoke this staff member’s Toolkit access.'
              }
              variant='error'
            />
          );
        });
    }

    if (mode === 'revoke') {
      if (staff.id !== user.id) {
        body = (
          <>You&apos;re about to revoke this staff member&apos;s access—they won&apos;t be able use Test Day Toolkit.</>
        );
      } else {
        return dispatchModal(
          <OneButtonModal
            buttonLabel='OK'
            modalId='changeStaffStatusError'
            title='You can’t revoke your own Test Day Toolkit access.'
            variant='error'
          />
        );
      }
    }

    return dispatchModal(
      <TwoButtonModal
        body={body}
        primaryButtonHandler={handleStaffStatusChange}
        modalId='changeStaffStatusConfirmation'
        variant='error'
      />
    );
  }

  function resendEmailHandler(staff: IStaff) {
    const input = {
      ids: [staff.id],
    };

    if (staff.id !== user.id) {
      resendEmail({
        variables: { input },
      })
        .then(() => {
          return dispatchModal(
            <OneButtonModal
              modalId='staffChangeStatusCompleted'
              title='We’ve sent an access email to selected staff member.'
              variant='success'
            />
          );
        })
        .catch(() => {
          return dispatchModal(
            <OneButtonModal
              body='Please try again later.'
              modalId='changeStaffStatusError'
              title='We were unable to send this staff member an access email.'
              variant='error'
            />
          );
        });
    } else {
      return dispatchModal(
        <OneButtonModal
          modalId='changeStaffStatusError'
          title={'You can’t send yourself an access email. Email ' + customerSupportInfo.email}
          variant='error'
        />
      );
    }
  }

  function ChangeStaffRole(staff: IStaff) {
    const roleOptions = generateRoleOptions();
    if (staff.id === user.id) {
      return dispatchModal(
        <OneButtonModal
          buttonLabel='OK'
          modalId='changeStaffRoleError'
          title='You can’t update your role.'
          variant='error'
        />
      );
    }

    const body = (
      <div key={1} className='mb-4'>
        <Select
          floating={false}
          id={`role-${staff.id}`}
          label='Role'
          name={`role-${staff.id}`}
          onChange={(val) => {
            roleRef.current = val;
          }}
          value={staff.role}
          values={roleOptions}
        />
      </div>
    );

    function handleStaffUpdate(): Promise<any> {
      const input = {
        id: staff.id,
        role: roleRef.current,
      };

      return updateUser({
        variables: {
          input,
        },
        refetchQueries: ['inventory', 'getStaff', 'getSiteStats'],
      })
        .then(() => {
          return dispatchModal(
            <OneButtonModal
              modalId='staffChangeStatusCompleted'
              title={'You’ve changed a role for this staff member successfully.'}
              variant='success'
            />
          );
        })
        .catch(() => {
          return dispatchModal(
            <OneButtonModal
              body='Please try again later.'
              modalId='staffChangeRoleError'
              title='We were unable to change the role for this staff member.'
              variant='error'
            />
          );
        });
    }

    return dispatchModal(
      <TwoButtonFormModal
        body={body}
        title={`Assign ${staff.lastName + ', ' + staff.firstName} a Role`}
        primaryButtonLabel='Save'
        primaryButtonHandler={handleStaffUpdate}
        modalId='changeStaffRoleConfirmation'
      />
    );
  }

  function ChangeStaffRoom(staff: IStaff) {
    const roomOptions = generateRoomOptions();
    if (staff.id === user.id) {
      return dispatchModal(
        <OneButtonModal
          buttonLabel='OK'
          modalId='changeStaffRoomError'
          title='You can’t update your room.'
          variant='error'
        />
      );
    }

    const body = (
      <div key={1} className='mb-4'>
        <Select
          floating={false}
          id={`room-${staff.id}`}
          label='Room'
          name={`room-${staff.id}`}
          onChange={(val) => {
            roomRef.current.id = val;
          }}
          value={staff.room ? staff.room.id : ''}
          values={roomOptions}
        />
      </div>
    );

    function handleRoomUpdate(): Promise<any> {
      const input = {
        id: staff.id,
        room: roomRef.current.id,
      };
      return updateUser({
        variables: {
          input,
        },
        refetchQueries: ['inventory', 'getStaff', 'getSiteStats'],
      })
        .then(() => {
          return dispatchModal(
            <OneButtonModal
              modalId='staffChangeStatusCompleted'
              title={'You’ve changed a room for this staff member successfully.'}
              variant='success'
            />
          );
        })
        .catch(() => {
          return dispatchModal(
            <OneButtonModal
              body='Please try again later.'
              modalId='changeStaffRoomError'
              title='We were unable to change a room for this staff member.'
              variant='error'
            />
          );
        });
    }

    return dispatchModal(
      <TwoButtonFormModal
        body={body}
        title={`Assign ${staff.lastName + ', ' + staff.firstName} a Room`}
        primaryButtonLabel='Save'
        primaryButtonHandler={handleRoomUpdate}
        modalId='changeStaffRoomConfirmation'
      />
    );
  }

  function deleteStaff(staff: IStaff) {
    if (staff.id === user.id) {
      return dispatchModal(
        <OneButtonModal
          buttonLabel='OK'
          modalId='changeStaffStatusError'
          title='You can’t delete yourself.'
          variant='error'
        />
      );
    } else if (staff.role === 'admin' && user.role === 'admin') {
      return dispatchModal(
        <OneButtonModal
          body='Contact Customer Support for help.'
          buttonLabel='OK'
          modalId='changeStaffStatusError'
          title={contentStrings.staff.warn.coordinator}
          variant='error'
        />
      );
    }

    function handleStaffDelete() {
      const input = {
        ids: staff.id,
      };

      bulkRemoveUsers({
        variables: { input },
        refetchQueries: ['inventory', 'getStaff', 'getSiteStats'],
        update: (cache, { data: { bulkUsers } }) => {
          try {
            // Read the existing data from the local cache using the GET_STAFF query
            const { viewer } = cache.readQuery({ query: GET_STAFF }) || { viewer: { site: { staff: [] } } };
            const updatedStaff = Array.isArray(bulkUsers) ? bulkUsers : [];

            // Create a new object newData by spreading the existing viewer data and updating the staff array
            const newData = {
              viewer: {
                id: viewer.id,
                site: {
                  id: viewer.site.id,
                  staff: [...viewer.site.staff, ...updatedStaff],
                },
              },
            };

            // Write the updated data back to the local cache using the GET_STAFF query
            cache.writeQuery({
              query: GET_STAFF,
              data: newData,
            });
          } catch (error) {
            // Log an error message if there's an issue updating the cache
            console.error('Error updating cache:', error);
          }
        },
      })
        .then(() => {
          // Uncheck the staff member
          handleCheckItems([staff.id], false);

          return dispatchModal(
            <OneButtonModal
              modalId='staffChangeStatusCompleted'
              onClose={() => navigate('/staff')}
              title='You’ve removed selected staff from this test administration.'
              variant='success'
            />
          );
        })
        .catch(() => {
          return dispatchModal(
            <OneButtonModal
              body='Please try again later.'
              modalId='removeStaffError'
              title='We were unable to remove the selected staff from this test administration.'
              variant='error'
            />
          );
        });
    }

    return dispatchModal(
      <TwoButtonModal
        body='You’re about to remove the selected staff from this administration. They’ll still be on your test center staff list.'
        primaryButtonHandler={handleStaffDelete}
        modalId='changeStaffStatusConfirmation'
        variant='error'
      />
    );
  }

  function navigateEditStaff(staff: IStaff) {
    navigate(`/staff/edit/${staff.id}`);
  }

  function checkStaff(staffId: string) {
    return function () {
      handleCheckItems([staffId]);
    };
  }

  function mobileComponent() {
    return <li>{staffId ? <Link to={`/staff/get/${staffId}`}>{staffDisplayMobile}</Link> : staffDisplayMobile}</li>;
  }

  function getIconProps(active: boolean) {
    return active ? { color: 'green3', icon: 'check-fill' } : {};
  }
  const cbPopoverRef = useRef<any>(undefined);

  type Action = {
    value: 'activate' | 'deactivate' | 'resendEmail' | 'remove' | 'addRole' | 'addRoom' | 'editStaff';
    title: string;
    handler: (staff: IStaff) => void;
    disabled?: (staff: IStaff) => boolean;
  };

  function desktopComponent() {
    const actions: Action[] = [
      { value: 'editStaff', title: 'Edit Staff', handler: navigateEditStaff },
      {
        value: 'activate',
        title: 'Grant Toolkit access',
        handler: grantStaffToolkitStatus,
        disabled: (staff: IStaff) => staff.active,
      },
      {
        value: 'deactivate',
        title: 'Revoke Toolkit access',
        handler: revokeStaffToolkitStatus,
        disabled: (staff: IStaff) => !staff.active,
      },
      { value: 'resendEmail', title: 'Send Access Email', handler: resendEmailHandler },
      {
        value: 'remove',
        title: 'Remove from Administration',
        handler: deleteStaff,
        disabled: (staff: IStaff) => isLastCoordinator && staff.role === ROLES_MAP.ADMIN,
      },
      { value: 'addRole', title: 'Add Role', handler: ChangeStaffRole },
      { value: 'addRoom', title: 'Add Room', handler: ChangeStaffRoom },
    ];

    return (
      <tr>
        <td className='staff-checkbox'>
          <label htmlFor={checkId}>
            <input
              checked={options.checked ? true : false}
              id={checkId}
              name={checkName}
              onChange={checkStaff(staff.id)}
              type='checkbox'
              value={staff.id}
              aria-describedby='staff-actions-description'
            />
            <span className='cb-sr-only'>Select {staffTitle} for a bulk operation</span>
          </label>
        </td>
        <th scope='row'>
          {staffId ? <Link to={`/staff/get/${staffId}`}>{staffTitle}</Link> : staffTitle}
          <br />
          <span className='cb-sr-only'>Email: </span>
          {staff.email}
        </th>
        <td>
          <ul className='m-0 p-0' style={{ listStyle: 'none' }}>
            {parsePhoneNumbers(staff.phone).map((number) => {
              const type = number.type.charAt(0).toUpperCase() + number.type.slice(1);
              const value = number.phoneNumber;
              const ext = number.ext ? number.ext : '';
              return value ? (
                <li key={type + value}>
                  <strong>{type}</strong>: <br />
                  {value ? staffPhoneLink(ext, value) : null}
                </li>
              ) : (
                ''
              );
            })}
          </ul>
        </td>
        <td id={`role-col-${staff.id}`}>
          {inventoryRow ? (
            <>
              <Select
                floating={false}
                id={`role-${staff.id}`}
                name={`role-${staff.id}`}
                onChange={(val) => {
                  roleRef.current = val;
                }}
                value={roleTitle}
                values={Object.values(roles).map((role) => ({
                  label: role,
                  value: role,
                }))}
              />
            </>
          ) : (
            roleTitle
          )}
        </td>
        <td className='text-break' id={`room-col-${staff.id}`}>
          {roomTitle}
        </td>
        <td>
          <IconStatus
            id={`confirmed-${staff.id}`}
            {...getIconProps(staff.confirmed)}
            text={staff.confirmed ? 'Confirmed' : 'Unconfirmed for this administration'}
          />
        </td>
        <td>
          <IconStatus
            id={`granted-${staff.id}`}
            {...getIconProps(staff.active)}
            text={staff.active ? 'Granted' : 'Not granted'}
          />
        </td>
        {!inventoryRow && (
          <td>
            <SquareButton
              className={`staff-actions--btn ${
                open
                  ? 'cb-btn cb-btn-square cb-btn-filter cb-filter-active'
                  : 'cb-btn cb-btn-square cb-btn-greyscale cb-btn-close'
              }`}
              id={`action-${staff.id}`}
              name='resetFilters'
              data-automation='staff-actions'
              aria-label={`Perform Actions for staff ${staff.id}`}
              icon='more-alt'
            />
            <Popover
              closeButton={true}
              className='cb-popover'
              role='region'
              placement='bottom'
              trigger={`action-${staff.id}`}
              cbRef={cbPopoverRef}
              onShow={() => setOpen(true)}
              onHide={() => setOpen(false)}
            >
              <div className='staff-popover-content'>
                {actions.map((action: Action, i: number) => {
                  return (
                    <div className='action-btn-item w-100' key={`${action.value}-${i}`}>
                      <NakedButton
                        key={`${action.value}-${i}`}
                        id={`${action.value}-${staff.id}`}
                        className='w-100 btn-justify-content-left'
                        disabled={action.disabled && action.disabled(staff)}
                        onClick={() => {
                          action.handler(staff);
                          cbPopoverRef?.current?.hide();
                          setOpen(false);
                        }}
                      >
                        {action.title}
                      </NakedButton>
                    </div>
                  );
                })}
              </div>
            </Popover>
          </td>
        )}
      </tr>
    );
  }

  // Resize state.
  const windowSize = React.useContext(ResizeContext);

  const roleTitle = roles[staff.role] || 'Unassigned';
  const roomTitle = staff.room ? staff.room.title : 'Unassigned';
  const staffId = parseInt(staff.id, 10) < 0 ? undefined : staff.id;
  const staffTitle = `${staff.lastName ? staff.lastName + ', ' : ''}${staff.firstName}`;

  const checkId = `bulk-check-${staff.id}`;
  const checkName = `bulk-check[${staff.id}]`;

  const staffDisplayMobile = (
    <div className='tdtk-item-text'>
      <p className='tdtk-item-title cb-roboto-bold'>
        <span className='name'>{staffTitle}</span>
      </p>
    </div>
  );

  return windowSize.mobile && !forceDesktopViewForMobile ? mobileComponent() : desktopComponent();
}

export default StaffRosterRow;
