import { hasAdminAccess } from './user';
import { isInSchoolAPEvents, isInSchoolEvent, isWeekendEvent } from './event';
import { FEATURE_FLAGS } from '../constants/featureFlags';

export function getRoutes(features, orgEvent, user) {
  const useHonorariaVouchers = features?.includes(FEATURE_FLAGS.HONORARIA_VOUCHERS_TAB);
  let routes;

  if (orgEvent?.dapInd) {
    if (hasAdminAccess(user.rid, user.role)) {
      if (isInSchoolEvent(orgEvent)) {
        routes = [
          'digitalHelp',
          'digitalCommon',
          'digitalHome',
          'digitalElevated',
          'digitalInSchoolRoomDetails',
          'downloads',
        ];
        if (isIrregularitySupported(orgEvent)) {
          routes.push('digitalIrregularities');
        }
      } else {
        routes = [
          'digitalHelp',
          'digitalCommon',
          'digitalHome',
          'digitalElevated',
          'weekendDigitalRoomDetails',
          'digitalIrregularities',
          'downloads',
        ];

        if (isWeekendEvent(orgEvent) && useHonorariaVouchers) {
          routes.push('digitalVouchers');
        }
      }
    } else {
      if (user?.room?.id) {
        if (isInSchoolEvent(orgEvent)) {
          routes = ['digitalHelp', 'digitalInSchool', 'digitalInSchoolHomeWithRoom', 'digitalInSchoolRoomDetails'];
          if (isIrregularitySupported(orgEvent)) {
            routes.push('digitalIrregularities');
          }
        } else {
          routes = [
            'digitalHelp',
            'digitalCommon',
            'DigitalHomeWithRoom',
            'weekendDigitalRoomDetails',
            'digitalIrregularities',
          ];
        }
      } else {
        if (isInSchoolEvent(orgEvent)) {
          routes = ['digitalHelp', 'digitalHome', 'digitalInSchool', 'digitalInSchoolRoomDetails'];
          if (isIrregularitySupported(orgEvent)) {
            routes.push('digitalIrregularities');
          }
        } else {
          routes = [
            'digitalHelp',
            'digitalCommon',
            'digitalHome',
            'weekendDigitalRoomDetails',
            'digitalIrregularities',
          ];
        }
      }
    }
  } else {
    if (hasAdminAccess(user.rid, user.role)) {
      routes = ['common', 'elevated', 'irregularities'];
    } else {
      routes = ['common', 'irregularities'];
    }
  }

  return routes;
}

export function isIrregularitySupported(orgEvent) {
  return !isInSchoolAPEvents(orgEvent);
}
