import React, { useEffect, useState } from 'react';
import { CardWithTitle } from '../../../../ui/card/CardWithTitle';
import { Icon, BlackButton, Spinner } from '@cb/apricot-react';
import './FileFormatCheck.scss';
import { FileErrorType, FileStateType, IFileFormatOutput, validateFile } from '../../../../../utils/file';
import { formatBytes } from '../../../../../utils/common';

type FileFormatCheckProps = {
  handleReplace: () => void;
  handleDrawerDirty: (isDirty: boolean) => void;
  fileContainsAnError: boolean;
  setFileContainsAnError: (status: boolean) => void;
  file?: File;
  invalidCharacters?: boolean;
  loadingToS3: boolean;
};

export function FileFormatCheck({
  handleReplace,
  handleDrawerDirty,
  file,
  setFileContainsAnError,
  fileContainsAnError,
  invalidCharacters = false,
  loadingToS3 = false,
}: FileFormatCheckProps) {
  const [fileValidations, setFileValidations] = useState<IFileFormatOutput[]>([]);
  const [loading, setLoading] = useState(true);
  const fileSize = `(${formatBytes(file?.size ?? 0)})`;

  useEffect(() => {
    if (file) {
      validateFile(file).then((validations: IFileFormatOutput[]) => {
        const validationsFailed = validations.some((fV: IFileFormatOutput) => {
          return fV.status === FileStateType.fail;
        });
        handleDrawerDirty(true);
        setFileContainsAnError(validationsFailed);
        setFileValidations(validations);
        setLoading(false);
      });
    }
  }, [file]);

  useEffect(() => {
    if (invalidCharacters) {
      setFileValidations(
        fileValidations.map((validation: IFileFormatOutput) => {
          if (validation.type === FileErrorType.fileContent) {
            return { ...validation, status: FileStateType.fail };
          } else {
            return validation;
          }
        })
      );
    }
  }, [invalidCharacters]);

  const title = loading ? (
    <Spinner size='32' className='cb-margin-right-8' />
  ) : fileContainsAnError ? (
    <div>
      <Icon name='exclamation-fill' color='red1' className='p-2' />
      <b>The format check found errors. Correct them and replace your file.</b>
    </div>
  ) : (
    <div>
      <Icon name='check-fill' color='green3' className='p-2' />
      <b>Well done! Your file passed the format check.</b>
    </div>
  );

  const content = loading ? (
    <Spinner size='32' className='cb-margin-right-8' />
  ) : fileContainsAnError ? (
    <div className='row row-eq-height mx-0 justify-content'>
      {fileValidations.map((validations: any, index: number) => (
        <div className='d-flex w-100 p-2' key={`file-upload-validations-${index}`}>
          <div className='col-md-5 cb-align-right'>
            <Icon
              name={validations.status === FileStateType.fail ? 'exclamation-fill' : 'check-fill'}
              color={validations.status === FileStateType.fail ? 'red1' : 'green3'}
              size='32'
              className='p-2'
            />
          </div>
          <div className='col-md-5'>
            <b>{validations.message}</b>
            <div>{validations.detail}</div>
          </div>
        </div>
      ))}
    </div>
  ) : (
    <div>
      <h4 className='py-2'>Optional File Replacement</h4>
      <p className='py-2'>
        You uploaded a small enough CSV file with at least 1 real staff record, but you can replace it if you need to.{' '}
      </p>
      <div className='d-flex w-100 p-2 flex-direction-row align-items-center justify-content-space-between file-row'>
        <span>
          <Icon name='submit' className='p-2' />
          {`${file?.name} ${fileSize}`}
        </span>
        <BlackButton onClick={handleReplace}>Replace File</BlackButton>
      </div>
    </div>
  );

  return loadingToS3 ? (
    <div className='d-flex justify-content-center align-items-center w-100 my-5 py-5'>
      <Spinner />
    </div>
  ) : (
    <CardWithTitle titleContent={title} bodyContent={content} />
  );
}
