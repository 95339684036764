import React, { useContext } from 'react';
import { ModalDispatchContext } from '../ModalContext';
import ModalWrapper from '../ModalWrapper';
import ModalFooter from '../ModalFooter';

// this is very similar to the regular TwoButtonModal but is needed to work around a bug in Apricot 4's dialog modals
// once the bug is fixed, this component can be retired
// https://jira.collegeboard.org/browse/AOPS-36286

interface TwoButtonFormModalProps<T> {
  body: string;
  modalId: string;
  primaryButtonHandler: () => void;
  primaryButtonLabel: string;
  primaryButtonStyle: string;
  secondaryButtonHandler: () => void;
  secondaryButtonLabel: string;
  secondaryButtonStyle: string;
  title: string;
  trigger: string;
  clickOverlayToClose: boolean;
  disabled: boolean;
}

export default function TwoButtonFormModal<T>({
  body = '',
  modalId,
  primaryButtonHandler = () => {},
  primaryButtonLabel = 'Continue',
  primaryButtonStyle = 'yellow',
  secondaryButtonHandler = () => {},
  secondaryButtonLabel = 'Cancel',
  secondaryButtonStyle = 'black',
  title = 'Are you sure?',
  trigger = '',
  clickOverlayToClose = false,
  disabled = false,
}: TwoButtonFormModalProps<T>) {
  const dispatchModal = useContext(ModalDispatchContext);

  function closeModal() {
    dispatchModal(null);
    secondaryButtonHandler();
  }

  function handlePrimaryButton() {
    dispatchModal(null);
    primaryButtonHandler();
  }

  return (
    <ModalWrapper
      enableChildOverflow
      footer={
        <ModalFooter
          buttons={[
            {
              handler: closeModal,
              id: `btn-secondary-${modalId}`,
              style: secondaryButtonStyle,
              text: secondaryButtonLabel,
            },
            {
              handler: handlePrimaryButton,
              id: `btn-primary-${modalId}`,
              style: primaryButtonStyle,
              text: `${primaryButtonLabel}`,
              disabled,
            },
          ]}
        />
      }
      modalId={`modal-${modalId}`}
      onClose={closeModal}
      open
      title={title}
      trigger={trigger}
      clickOverlayToClose={clickOverlayToClose}
    >
      <>{body}</>
    </ModalWrapper>
  );
}
