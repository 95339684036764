import React, { ReactElement } from 'react';
import './CardWithTitle.scss';

export type CardWithTitleProps = {
  titleContent: ReactElement;
  bodyContent?: ReactElement;
};

export function CardWithTitle({ titleContent, bodyContent }: CardWithTitleProps) {
  return (
    <div className='cardContainer box-card  w-100 my-4 p-0'>
      <div className='d-flex flex-row align-items-center titleContent p-3 cb-box-shadow-bottom'>{titleContent}</div>
      {bodyContent && <div className='bodyContent w-100 p-4 pt-0'>{bodyContent}</div>}
    </div>
  );
}
