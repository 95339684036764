import React from 'react';
import { sat_national } from './sat_national';
import { createCleanMarkup } from '../utils/common';
import { useContentCMSData } from '../components/digital/common/cmsDataSets';
import ErrorPage from '../components/digital/error/ErrorPage';

interface MessagingProps {
  cmsContentLoaded?: (content: any) => void;
  dynamic?: boolean;
  section?: string;
}

function Messaging({ cmsContentLoaded, dynamic = false, section = '' }: MessagingProps) {
  // Put the content into state so it doens't keep re-rendering.

  const { cmsData, isLoading } = useContentCMSData(section, cmsContentLoaded);
  if (!dynamic) {
    return sat_national[section];
  }
  if (isLoading) {
    return null;
  }
  if (cmsData?.section_name) {
    return <div id='cms-content-wrapper' dangerouslySetInnerHTML={createCleanMarkup(cmsData?.content)} />;
  } else {
    return (
      <div className='container'>
        <ErrorPage errorCode='cms_missing' />
      </div>
    );
  }
}
export default Messaging;
