import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { localStateReducer } from '../../../utils/common';
import { ModalDispatchContext } from '../../ui/modal/ModalContext';
import { RESET_SITE } from '../../../apollo/mutations';
import { Stepper, NumericStep } from '@cb/apricot-react';
import { useMutation } from '@apollo/client';
import AutoAssignButton from './AutoAssignButton';
import Checkbox from '../../ui/form/Checkbox';
import CheckboxGroup from '../../ui/form/CheckboxGroup';
import OneButtonModal from '../../ui/modal/standard/OneButtonModal';
import some from 'lodash/some';
import Spinner from '../../ui/loading/SpinnerWrapper';
import TwoButtonModal from '../../ui/modal/standard/TwoButtonModal';

function Notification({ cta = null }) {
  function handleResetClick(e) {
    e && e.preventDefault && e.preventDefault();

    setLocalState({
      disabled: true,
      showModal: true,
    });
  }

  // Global App state.
  const dispatchModal = useContext(ModalDispatchContext);

  // Local state.
  const [localState, setLocalState] = React.useReducer(localStateReducer, {
    showModal: false,
    assignedStaff: false,
    assignedStudents: false,
    disabled: false,
    error: null,
    roomGroupTypes: false,
  });

  // Apollo.
  const [resetSite] = useMutation(RESET_SITE);

  React.useEffect(() => {
    function handleClick() {
      const input = {
        assignedStudents: localState.assignedStudents,
        roomGroupTypes: localState.roomGroupTypes,
        assignedStaff: localState.assignedStaff,
      };

      if (!some(input, (prop) => prop === true)) {
        setLocalState({
          error: 'Select at least one option.',
        });
        return;
      }

      function handlerFunction() {
        resetSite({
          variables: {
            input,
          },
        })
          .then(() => {
            dispatchModal(
              <OneButtonModal
                title={'Your test center was reset.'}
                modalId='testCenterResetSuccess'
                variant='success'
                body={
                  <span key='auto_reset_reminder_message' data-automation='auto-reset-reminder-message'>
                    Remember to discard any room rosters you’ve downloaded or printed.
                  </span>
                }
                buttonLabel='OK'
                onClose={() => {
                  setLocalState({
                    disabled: false,
                    showModal: false,
                  });
                  window.location.reload();
                }}
              />
            );
          })
          .catch((e) => {
            const hasStudentsCheckedInError = e?.graphQLErrors?.some(
              (err) => err?.payload?.code === 'site:reset_failure:student_checked_into_room'
            );

            const errorMsg =
              e?.graphQLErrors?.length && hasStudentsCheckedInError
                ? 'We can’t perform a reset. There is at least 1 student checked into a room.'
                : 'We can’t perform a reset right now. Please try again later.';

            dispatchModal(
              <OneButtonModal
                title={'We’re sorry'}
                modalId='testCenterResetFailed'
                variant='error'
                body={errorMsg}
                buttonLabel='Close'
                onClose={() => {
                  setLocalState({
                    disabled: false,
                    showModal: false,
                  });
                }}
              />
            );
          });
      }

      dispatchModal(
        <TwoButtonModal
          title='Test Center Reset'
          modalId='testCenterResetForm'
          variant='error'
          body={
            <span key='auto_reset_confirm_body' data-automation='auto-reset-confirm-body'>
              You’re about to reset your test center.
            </span>
          }
          primaryButtonLabel='Reset'
          primaryButtonHandler={handlerFunction}
          secondaryButtonHandler={() => {
            setLocalState({
              disabled: false,
              showModal: false,
            });
          }}
        />
      );
    }

    function handleChange(name, val) {
      const newState = {
        [name]: val,
      };

      if (name === 'roomGroupTypes' && !localState.roomGroupTypes) {
        newState['assignedStudents'] = true;
      } else if (name === 'assignedStudents' && localState.assignedStudents) {
        newState['roomGroupTypes'] = false;
      }

      if (localState.error && some(newState, (prop) => prop === true)) {
        newState.error = null;
      }

      // Set the state, then update the modal with new checkboxes.
      setLocalState(newState);
    }

    function formComponentResetTestCenter() {
      const { error } = localState;

      const values = [];

      if (localState.assignedStaff) {
        values.push('assignedStaff');
      }
      if (localState.assignedStudents) {
        values.push('assignedStudents');
      }
      if (localState.roomGroupTypes) {
        values.push('roomGroupTypes');
      }

      const checkboxOptions = [
        {
          name: 'assignedStudents',
          label: 'Remove all students from their assigned rooms.',
        },
        {
          name: 'roomGroupTypes',
          label: 'Remove all testing group assignments from rooms.',
        },
        {
          name: 'assignedStaff',
          label: 'Remove all staff from their assigned rooms.',
        },
      ];

      return (
        <React.Fragment key='resetModal'>
          <p className='mb-4' data-automation='reset-test-center-body' key='resetModal:p'>
            If you&rsquo;re not happy with the way your test center is set up, start over the easy way.
          </p>
          <form key='z'>
            <CheckboxGroup
              additionalInstructions='What do you want to do?'
              id='resetField'
              key='resetModal:radio'
              required={true}
              errorMessage={error}
              vertical
            >
              {checkboxOptions.map(({ name, label }) => {
                return (
                  <Checkbox
                    id={`checkbox-${name}`}
                    key={`checkbox-${name}`}
                    label={label}
                    onChange={(val) => {
                      handleChange(name, val);
                    }}
                    value={localState[name] || '-'}
                  />
                );
              })}
            </CheckboxGroup>
          </form>
        </React.Fragment>
      );
    }

    function updateResetModal() {
      dispatchModal(
        <TwoButtonModal
          title='Test Center Reset'
          modalId='testCenterResetForm'
          variant='error'
          body={formComponentResetTestCenter()}
          primaryButtonLabel='Reset'
          primaryButtonHandler={handleClick}
          secondaryButtonHandler={() => {
            setLocalState({
              disabled: false,
              showModal: false,
            });
          }}
        />
      );
    }

    // Update the modal whenever the state changes.
    if (localState.showModal) {
      updateResetModal();
    }
  }, [dispatchModal, localState, localState.showModal, resetSite]);

  const { disabled } = localState;
  const ctaLink = cta && cta.ctaLink ? cta.ctaLink : '';
  const ctaText = cta && cta.ctaText ? cta.ctaText : '';
  const ctaComponent = cta && cta.ctaComponent ? cta.ctaComponent : '';
  const description = cta && cta.description ? cta.description : '';
  const currentStep = cta && cta.stepper ? cta.stepper : '';
  const isMagicButton = ctaText === 'Auto-Setup' ? true : false;
  const stepperLabels = ['Add Rooms', 'Auto-Setup', 'Add Staff', 'Assign Staff', 'All Set!'];

  // Find out the class for the description in case they're all set.
  const descriptionClasses = 'col-md-4 py-3 py-m-0 display--flex_desktop_align-items--center';

  return cta ? (
    <div className='container'>
      <div className='row px-0 mb-4 mb-md-0'>
        <div className='col-md-6'>
          <Stepper ariaLabel='test event checklist' stepperId='tdtk-event-checklist-stepper' role='region'>
            {stepperLabels.map((label, index) => (
              <NumericStep
                key={label}
                label={label}
                state={index + 1 > currentStep ? '' : index + 1 === currentStep ? 'active' : 'complete'}
              />
            ))}
          </Stepper>
        </div>
        <div className={descriptionClasses}>
          <strong>{description}</strong>
        </div>

        {
          /**
           * If they are getting the auto-assign, put that here.
           * If not, check if they have a CTA Link, otherwise, don't show a link.
           */
          isMagicButton ? (
            <div className='col-md-2 px-0 display-flex-col-content-center'>
              <div className='py-3'>
                <AutoAssignButton />
              </div>
            </div>
          ) : ctaLink !== '' ? (
            <div className='col-md-2 px-0 display-flex-col-content-center'>
              <div
                className='py-3 w-100 justify-content-center  display--flex_desktop_align-items--center'
                data-automation='cta-button'
              >
                {ctaComponent ? (
                  ctaComponent
                ) : (
                  <a href={ctaLink} className='cb-btn cb-btn-sm cb-btn-yellow w-100'>
                    {ctaText}
                  </a>
                )}
              </div>
              <div data-automation='reset-test-center-link-1'>
                {disabled ? (
                  <Spinner />
                ) : (
                  <Link className='cb-palette-blue2 cb-roboto-medium' to='/' onClick={handleResetClick}>
                    Reset Test Center
                  </Link>
                )}
              </div>
            </div>
          ) : (
            <div className='col-md-2 display-flex-col-content-center'>
              <div data-automation='reset-test-center-link-2'>
                <Link className='cb-palette-blue2 cb-roboto-medium' to='/' onClick={handleResetClick}>
                  Reset Test Center
                </Link>
              </div>
            </div>
          )
        }
      </div>
    </div>
  ) : null;
}

Notification.propTypes = {
  cta: PropTypes.object,
};

export default Notification;
