import React from 'react';
import Input from '../ui/form/Input';
import RadioButton from '../ui/form/RadioButton';
import RadioButtonGroup from '../ui/form/RadioButtonGroup';
import Select from '../ui/form/Select';
import { NakedButton, PrimaryButton } from '@cb/apricot-react';

interface RosterFilter {
  id: string;
  field: string;
  label: string;
  type: string;
  defaultValue?: any;
  required?: boolean;
  description?: string;
  selectOptions?: any[];
}
interface RosterFiltersProps<T> {
  filters: RosterFilter[];
  updateFilter: () => void;
  shadow: boolean;
}

function RosterFilters<T>({ filters = [], updateFilter = () => {}, shadow = true }: RosterFiltersProps<T>) {
  function renderFilter(filter: RosterFilter) {
    switch (filter.type) {
      case 'textbox':
        return renderTextBox(filter);

      case 'checkbox':
      case 'radio':
        return renderRadio(filter);

      case 'select':
        return renderSelect(filter);

      default:
        console.error('Unknown filter field type');
    }
  }

  function renderTextBox(filter: RosterFilter) {
    return (
      <Input
        additionalInstructions={filter.description}
        floating={false}
        id={filter.field}
        key={filter.id}
        label={filter.label}
        name={filter.field}
        onChange={(val) => {
          val = {
            target: {
              name: filter.field,
              type: 'input',
              value: val,
            },
          };
          updateFilter(val);
        }}
        value={filter.defaultValue || ''}
      />
    );
  }

  function renderRadio(filter: RosterFilter) {
    return (
      <RadioButtonGroup
        fieldsetId={`radio-${filter.id}-label`}
        id={filter.field}
        legend={filter.label}
        name={filter.field}
        onChange={(val) => {
          val = {
            target: {
              name: filter.field,
              type: 'select',
              value: val,
            },
          };
          updateFilter(val);
        }}
        required={false}
        value={filter.defaultValue !== undefined ? filter.defaultValue.toString() : 'none'}
        vertical
      >
        {Object.keys(filter?.selectOptions || []).map((key) => {
          return <RadioButton id={`${filter.id}-${key}`} key={key} label={filter.selectOptions[key]} value={key} />;
        })}
      </RadioButtonGroup>
    );
  }

  function renderSelect(filter: RosterFilter) {
    // enchance options objects to have a label that we can use (based on their title)
    const transformSelectOptions = (a) => {
      return a.reduce((prev, cur) => {
        return [
          ...prev,
          {
            label: cur.title,
            ...cur,
          },
        ];
      }, []);
    };

    return (
      <Select
        additionalInstructions={filter.description}
        id={filter.id}
        key={filter.id}
        label={filter.label}
        name={filter.field}
        onChange={(val) => {
          val = {
            target: {
              name: filter.field,
              type: 'select',
              value: val,
            },
          };
          updateFilter(val);
        }}
        required={filter.required}
        value={filter?.defaultValue[0] || '-'}
        values={transformSelectOptions(filter.selectOptions)}
      />
    );
  }

  const renderedFields = filters.map((filter) => {
    return (
      <div key={filter.id} className='tdtk-form-group'>
        {renderFilter(filter)}
      </div>
    );
  });

  return (
    <form className={`${shadow ? 'shadow' : ''} hide-from-print`}>
      <fieldset>
        {renderedFields}
        <div className='cb-popover-footer'>
          <NakedButton
            small
            noPadding
            data-cb-popover-close
            className='cb-filter-reset'
            disabled={!filters.length}
            id='resetFilters'
            name='resetFilters'
            onClick={updateFilter}
          >
            Clear
          </NakedButton>
          <PrimaryButton data-cb-popover-close small id='applyFilters'>
            Apply Filters
            <span className='cb-sr-only'> and close filter form </span>
          </PrimaryButton>
        </div>
      </fieldset>
    </form>
  );
}

export default RosterFilters;
