import React from 'react';
import { Link } from 'react-router-dom';
import TooltipWrapper from '../../ui/tooltip/TooltipWrapper';
import { ResizeContext } from '../../../context/ResizeContext';
import { useStateValue } from '../../../context/AppContext';
import { generateGroupTypeLabel } from '../../../utils/groupTypeLabels';

interface GroupInfoRowProps {
  groupInfo: {
    groupType: string;
    title: string;
    description: string;
    regCount: number;
    waitCount: number;
    standard?: boolean;
  };
  usePopovers: boolean;
  forceDesktopMode: boolean;
  showFilterLinks: boolean;
}

function GroupInfoRow({
  groupInfo = { groupType: '', title: '', description: '', regCount: 0, waitCount: 0 },
  usePopovers = false,
  forceDesktopMode = false,
  showFilterLinks = true,
}: GroupInfoRowProps) {
  const { orgEvent } = useStateValue();

  function clickHandler(e) {
    e && e.preventDefault && e.preventDefault();
  }

  function handleClick(data) {
    return function () {
      sessionStorage.setItem('studentFilters', JSON.stringify(data));
    };
  }

  function mobileComponent() {
    return (
      <li>
        <Link onClick={handleClick({ groupType: `${groupInfo.groupType}` })} to='/students'>
          <div className='tdtk-item-text'>
            <div className='tdtk-item-title cb-roboto-bold'>
              <div className='row'>
                {groupInfo?.title
                  ? `${groupInfo.groupType}: ${groupInfo.title} (${generateGroupTypeLabel(groupInfo, orgEvent)})`
                  : `${groupInfo.groupType}: ${generateGroupTypeLabel(groupInfo, orgEvent)}`}
              </div>
            </div>
          </div>
        </Link>
      </li>
    );
  }

  function desktopComponent() {
    return (
      <tr>
        <td className='col-xs-6'>
          {usePopovers ? (
            <>
              <a
                href='#0'
                id={`popover-trigger-${groupInfo.groupType}`}
                style={{ textDecoration: 'none', cursor: 'default' }}
                ref={popoverRef}
                onClick={clickHandler}
              >
                {groupInfo.groupType}
              </a>
              <TooltipWrapper light={true} placement='right' trigger={`popover-trigger-${groupInfo.groupType}`}>
                <>
                  =
                  {groupInfo?.title
                    ? `${groupInfo.title} (${generateGroupTypeLabel(groupInfo, orgEvent)})${
                        groupInfo.description ? ` ${groupInfo.description}` : ''
                      }`
                    : `${generateGroupTypeLabel(groupInfo, orgEvent)} ${
                        groupInfo.description ? ` ${groupInfo.description}` : ''
                      }`}
                </>
              </TooltipWrapper>
            </>
          ) : (
            <>
              {showFilterLinks ? (
                <Link
                  id={`group-type-${groupInfo.groupType}`}
                  onClick={handleClick({ groupType: `${groupInfo.groupType}` })}
                  to='/students'
                >
                  {groupInfo?.title
                    ? `${groupInfo.groupType}: ${groupInfo.title} (${generateGroupTypeLabel(groupInfo, orgEvent)})`
                    : `${groupInfo.groupType}: ${generateGroupTypeLabel(groupInfo, orgEvent)}`}
                </Link>
              ) : (
                <div id={`group-type-${groupInfo.groupType}`}>
                  {groupInfo?.title
                    ? `${groupInfo.groupType}: ${groupInfo.title} (${generateGroupTypeLabel(groupInfo, orgEvent)})`
                    : `${groupInfo.groupType}: ${generateGroupTypeLabel(groupInfo, orgEvent)}`}
                </div>
              )}
            </>
          )}

          {groupInfo.description !== '' && !usePopovers ? <p>{`${groupInfo.description}`}</p> : ''}
        </td>
        <td className='col-xs-6'>
          {groupInfo?.regCount > 0 ? (
            <>
              {showFilterLinks ? (
                <Link
                  aria-label={`Go to filtered student roster showing ${groupInfo?.regCount} registered student${
                    groupInfo?.regCount === 1 ? '' : 's'
                  } in the ${groupInfo.groupType} ${groupInfo?.title ? `${groupInfo.title} ` : ''}${
                    groupInfo?.standard ? 'standard' : 'accommodated'
                  } testing group`}
                  id={`group-type-${groupInfo.groupType}-count`}
                  onClick={handleClick({ groupType: `${groupInfo.groupType}` })}
                  to='/students'
                >
                  {groupInfo.regCount}
                </Link>
              ) : (
                <div
                  aria-label={`Go to filtered student roster showing ${groupInfo?.regCount} registered student${
                    groupInfo?.regCount === 1 ? '' : 's'
                  } in the ${groupInfo.groupType} ${groupInfo?.title ? `${groupInfo.title} ` : ''}${
                    groupInfo?.standard ? 'standard' : 'accommodated'
                  } testing group`}
                  id={`group-type-${groupInfo.groupType}-count`}
                >
                  {groupInfo.regCount}
                </div>
              )}
            </>
          ) : (
            '0'
          )}
        </td>
      </tr>
    );
  }

  const popoverRef = React.useRef();

  // Global App state.
  const windowSize = React.useContext(ResizeContext);

  if (forceDesktopMode) {
    return desktopComponent();
  } else {
    return windowSize.mobile ? mobileComponent() : desktopComponent();
  }
}

export default GroupInfoRow;
