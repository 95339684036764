import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown, DropdownItem, YellowButton } from '@cb/apricot-react';
import './common.scss';

type ToolItem = {
  'path': string;
  'data-automation': string;
  'label': string;
};

function ToggleCoordinatorTools({ options }: { options: ToolItem[] }) {
  const navigate = useNavigate();

  return (
    <div className='display-flex justify-content-end cb-margin-bottom-32'>
      <Dropdown
        className='display-inline-block cb-margin-bottom-8'
        caret={false}
        dropdownMenuId='coord-tools-nav-menu'
        dropdownId='coord-tools-toggle-wrapper'
        title={
          <YellowButton
            iconDecorative
            iconLeft
            icon='tool'
            small
            className='cb-gray5-bg'
            aria-controls='coord-tools-nav-menu'
            id='coord-tools-toggle'
          >
            Coordinator Tools
          </YellowButton>
        }
        classNameToggle='cb-no-padding cb-no-border cb-no-box-shadow display-inline align-items-center'
        classNameMenu='cb-margin-top-16 cb-box-shadow-light cb-no-border-radius'
      >
        {options.map(({ path, ...opts }: { path: string }) => (
          <DropdownItem key={path} {...opts} className='cb-font-size-regular' onClick={() => navigate(path)} />
        ))}
      </Dropdown>
    </div>
  );
}

export default ToggleCoordinatorTools;
