import React, { useContext, useEffect, useReducer } from 'react';
import { PrimaryButton } from '@cb/apricot-react';
import './FloatingBar.scss';
import { localStateReducer } from '../../../utils/common';
import { ResizeContext } from '../../../context/ResizeContext';
import { BulkActionType } from '../../nonDigital/staff/roster/type';
import TooltipWrapper from '../tooltip/TooltipWrapper';

export interface FloatingActionBtn {
  actionVal: BulkActionType;
  icon: string;
  label: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, actionVal: BulkActionType) => void;
  name: string;
}

interface FloatingBarProps {
  actionBtns: FloatingActionBtn[];
  show: boolean;
  id: string;
  /**
   * Key - actionVal in FloatingActionBtn
   */
  errorMessage: { [key: string]: string };
}

export function FloatingBar({ actionBtns = [], show = false, errorMessage = {}, id = '' }: FloatingBarProps) {
  const windowSize = useContext(ResizeContext);

  const [localState, setLocalState] = useReducer(localStateReducer, {
    isMobile: windowSize.mobile,
  });

  useEffect(() => {
    setLocalState({
      isMobile: windowSize.mobile,
    });
  }, [windowSize.mobile]);

  // To Do: Use to add mobile version
  if (localState.isMobile) {
    return null;
  }

  const FloatingButton = (btn: FloatingActionBtn): React.ReactElement => {
    return (
      <div id={`tooltip-trigger-${id}-${btn.actionVal.split(' ').join('-').toLowerCase()}`}>
        {errorMessage && errorMessage[btn.actionVal] && (
          <span className='cb-sr-only' aria-live='assertive' aria-atomic='true'>
            {' '}
            {errorMessage[btn.actionVal]}
          </span>
        )}
        <PrimaryButton
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            btn.onClick(e, btn.actionVal);
          }}
          id={btn.name}
          icon={btn.icon as any}
          disabled={Boolean(errorMessage[btn.actionVal])}
          iconLeft={true}
          className='mx-1'
        >
          {btn.label}
        </PrimaryButton>
      </div>
    );
  };

  return (
    <div
      className={`floating-container floating-btns d-inline-flex justify-content-center align-items-center p-2 ${
        show ? '' : 'hide'
      }`}
      id={id}
      aria-hidden={!show}
    >
      <div id={`${id}-description`} className='cb-sr-only'>
        {show ? 'Bulk actions container opened. Use the buttons to perform actions.' : 'Bulk actions container closed.'}
      </div>
      {actionBtns.map((btn) => (
        <div key={btn.name.split(' ').join('-').toLowerCase()}>
          {errorMessage && errorMessage[btn.actionVal] ? (
            <>
              {FloatingButton(btn)}
              <TooltipWrapper
                light={true}
                trigger={`tooltip-trigger-${id}-${btn.actionVal.split(' ').join('-').toLowerCase()}`}
                tooltipId={`tooltip-${id}-${btn.actionVal.split(' ').join('-').toLowerCase()}`}
              >
                {errorMessage[btn.actionVal]}
              </TooltipWrapper>
            </>
          ) : (
            FloatingButton(btn)
          )}
        </div>
      ))}
    </div>
  );
}
