import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { getRoomStaffByRole, roomStaffRequirements } from '../../../../utils/room';
import { UPDATE_USERS } from '../../../../apollo/mutations';
import AddStaffSelection from './AddStaffSelection';
import get from 'lodash/get';
import { localStateReducer } from '../../../../utils/common';
import { useStateValue } from '../../../../context/AppContext';
import { useMutation } from '@apollo/client';
import { checkRoleChange, generateStaffOptions, mapUserToEditLink } from '../../../../utils/staff';
import { getTitle } from '../../../../constants/roles';
import { ModalDispatchContext } from '../../../ui/modal/ModalContext';
import TwoButtonFormModal from '../../../ui/modal/standard/TwoButtonFormModal';
import IconStatus from '../../../ui/icons/IconStatus';

function StaffList({ eventStaff = [], room = {} }) {
  function updateStaffRoles() {
    const roomId = get(room, 'id');
    const usersWithOtherRolesChanged = [
      ...checkRoleChange('proctor', roomId, proctorSelected),
      ...checkRoleChange('roomMonitor', roomId, roomMonitorsSelected),
    ];

    // Some selected users are already in another rooms or halls.
    const body = [];
    let evUser;
    let name = '';

    if (usersWithOtherRolesChanged.length) {
      // Update the messaging based on the users who are being affected.
      if (usersWithOtherRolesChanged.length === 1) {
        const oneUser = usersWithOtherRolesChanged[0];
        // Get the first/last name.
        evUser = eventStaff.find((es) => es.id === oneUser.value);
        name = `${evUser.firstName} ${evUser.lastName ? evUser.lastName : ''}`;

        body.push(
          `${name} is already assigned to the room or role listed below. If you continue, they'll be reassigned.`
        );

        if (oneUser.role !== 'hallMonitor' || oneUser.role !== 'technologyCoordinator') {
          body.push(`\n\nRoom: ${get(oneUser, 'room.title') || 'Unassigned'}`);
          body.push(`\nRole: ${getTitle(oneUser.role) || 'Unassigned'}`);
        } else {
          body.push(`\n\nRole: ${getTitle(oneUser.role)}`);
        }
      } else if (usersWithOtherRolesChanged.length > 1) {
        body.push(
          'These staff members are already assigned to the rooms or roles listed below. If you continue, they’ll be reassigned.'
        );

        usersWithOtherRolesChanged.forEach((user) => {
          // Get the first/last name.
          evUser = eventStaff.find((es) => es.id === user.value);
          name = `${evUser.firstName} ${evUser.lastName ? evUser.lastName : ''}`;

          body.push(`\n\n${name}`);

          if (user.role !== 'hallMonitor' || user.role !== 'technologyCoordinator') {
            body.push(`\nRoom: ${get(user, 'room.title') || 'Unassigned'}`);
            body.push(`\nRole: ${getTitle(user.role) || 'Unassigned'}`);
          } else {
            body.push(`\nRole: ${getTitle(user.role)}`);
          }
        });
      }

      // Dispatch a modal that will prompt them to confirm.
      dispatchModal(
        <TwoButtonFormModal
          body={body.join('')}
          modalId='updateStaffRoles'
          primaryButtonHandler={updateSelectedUsers}
          primaryButtonLabel='OK'
          secondaryButtonHandler={() => {
            setLocalState({
              ...initialState,
            });
          }}
        />
      );
    } else {
      // Should be good to update. Go nuts.
      updateSelectedUsers();
    }
  }

  function generateStaffClasses(proctorTotal = 0, monitorTotal = 0, roomMonitorsNeeded = 0, amountStudents = 0) {
    return amountStudents > 0 && (proctorTotal < 1 || monitorTotal < roomMonitorsNeeded)
      ? orgEvent?.dapInd
        ? 'red1'
        : 'orange1'
      : '';
  }

  function updateStaffToRoomChangeStatus(staffFormState) {
    proctorSelected = staffFormState['proctorSelected'] ? staffFormState['proctorSelected'] : proctorSelected;

    roomMonitorsSelected = staffFormState['roomMonitorsSelected']
      ? staffFormState['roomMonitorsSelected']
      : roomMonitorsSelected;

    setLocalState({
      ...staffFormState,
    });
  }

  function handleAddStaff(e) {
    if (e) {
      e.preventDefault();
    }

    dispatchModal(
      <TwoButtonFormModal
        body={
          <AddStaffSelection
            callback={updateStaffToRoomChangeStatus}
            key='addStaffToRoom'
            proctorSelected={localState.proctorSelected}
            roomMonitorsNeeded={roomMonitorRequirement}
            roomMonitorsSelected={localState.roomMonitorsSelected}
            staff={eventStaff.filter((s) => s.role !== 'technologyCoordinator')}
          />
        }
        modalId='addStaffConfirm'
        primaryButtonHandler={updateStaffRoles}
        primaryButtonLabel='Save'
        secondaryButtonHandler={() => {
          setLocalState({
            ...initialState,
          });
        }}
        title={`Add Staff to ${room.title}`}
      />
    );
  }

  function generateStaffList(room) {
    const roomStaffList = [];

    const { admin = [], proctor = [], roomMonitors = [] } = getRoomStaffByRole([...room.staff]);

    if (admin.length > 0) {
      // Output all admin if assigned to this room.
      admin.forEach((c) => {
        roomStaffList.push(mapUserToEditLink('Edit Coordinator', handleAddStaff, c));
      });
    }

    if (proctor.length > 0) {
      // Output all admin if assigned to this room.
      proctor.forEach((p) => {
        roomStaffList.push(mapUserToEditLink('Edit Proctor', handleAddStaff, p));
      });
    }

    if (roomMonitors.length > 0) {
      // Output all admin if assigned to this room.
      roomMonitors.forEach((m) => {
        roomStaffList.push(mapUserToEditLink('Edit Room Monitor', handleAddStaff, m));
      });
    }

    if (studentTotal > 0 && admin.length < 1 && proctor.length < 1) {
      // We don't have a proctor or coordinator but we have students in the room, recommend proctor.
      roomStaffList.unshift(
        <li key='proctorsNeeded'>
          Proctor:{' '}
          <a
            aria-label={`Add room proctor to ${room.title}`}
            href='#0'
            id={`add-proctor-${room.id}`}
            onClick={handleAddStaff}
            style={{ fontWeight: 'bold' }}
          >
            Click to add
          </a>
        </li>
      );
    }

    const { proctorTotal, roomMonitorRequirement } = roomStaffRequirements(room, orgEvent);

    const addMoreRoomMonitors = roomMonitorRequirement - roomMonitors.length;

    // Room monitor needed.
    for (let i = 0; i < addMoreRoomMonitors; i++) {
      roomStaffList.push(
        <li key={`room_monitor_recommended_${i}`}>
          Room Monitor:{' '}
          <a
            aria-label={`Add room monitor to ${room.title}`}
            href='#0'
            id={`add-monitor-${room.id}-${i}`}
            onClick={handleAddStaff}
            style={{ fontWeight: 'bold' }}
          >
            Click to add
          </a>
        </li>
      );
    }

    return {
      proctorTotal,
      roomMonitorRequirement,
      roomStaffList,
    };
  }

  function updateSelectedUsers() {
    const mergedSelectedUsers = [
      // Assign appropriate roles so our function knows where they belong.
      ...proctorSelected.map((s) => ({
        ...s,
        role: s.role !== 'admin' && s.role !== 'CBAdmin' ? 'proctor' : s.role,
      })),
      ...roomMonitorsSelected.map((s) => ({
        ...s,
        role: s.role !== 'admin' && s.role !== 'CBAdmin' ? 'roomMonitor' : s.role,
      })),
    ];

    let input = {};

    // Remove existing staff if they were not included in the changes.
    room.staff.forEach((a) => {
      findStaff = mergedSelectedUsers.find((s) => a.id === s.value);

      if (findStaff === undefined) {
        // If a previous staff was in the room, and now is not, remove their room.
        changingUsers.push({
          id: a.id,
          role: a.role,
          room: '',
        });
      } else if (findStaff.role !== a.role) {
        // Check if their role has changed.
        changingUsers.push({
          id: a.id,
          role: findStaff.role,
          room: room.id,
        });
      }
    });

    // Loop through selections to see if we need to mark any as changed.
    mergedSelectedUsers.forEach((a) => {
      findStaff = room.staff.find((s) => s.id === a.value);

      if (findStaff === undefined) {
        // This is a new user for this room.
        changingUsers.push({
          id: a.value,
          role: a.role,
          room: room.id,
        });
      }
    });

    input = {
      users: [...changingUsers],
    };

    if (changingUsers.length > 0) {
      // Mutation.
      updateUsers({
        variables: {
          input,
        },
        refetchQueries: ['getRooms', 'getStaff', 'getSiteStats'],
      }).catch((e) => {
        console.error('updateUsers failed', e);
      });
    }
  }

  const initialState = {
    proctorSelected: generateStaffOptions(room.staff.filter((s) => s.role === 'admin' || s.role === 'proctor')),
    roomMonitorsSelected: generateStaffOptions(room.staff.filter((s) => s.role === 'roomMonitor')),
    staffToRoomStatusChanged: false,
  };

  // Global App state.
  const dispatchModal = useContext(ModalDispatchContext);
  const { orgEvent } = useStateValue();

  // Local state.
  const [localState, setLocalState] = React.useReducer(localStateReducer, { ...initialState });

  // Apollo.
  const [updateUsers] = useMutation(UPDATE_USERS);

  // If there is any update to our staff, re-render the localState.
  React.useEffect(() => {
    setLocalState({
      proctorSelected: generateStaffOptions(room.staff.filter((s) => s.role === 'admin' || s.role === 'proctor')),
      roomMonitorsSelected: generateStaffOptions(room.staff.filter((s) => s.role === 'roomMonitor')),
    });
  }, [room.staff]);

  const studentTotal = get(room, 'students', []).length;

  const { proctorTotal, roomMonitorRequirement, roomStaffList } = generateStaffList(room);

  const proctorIconColor = generateStaffClasses(
    proctorTotal,
    room.staff.filter((s) => s.role === 'roomMonitor').length,
    roomMonitorRequirement,
    studentTotal
  );

  const changingUsers = [];
  let findStaff;

  /**
   * For some reason, we can't use localState inside of the actual submit call, because it's scope is older.
   * This was the only workaround I could get working in updateStaffRoles().
   */
  let proctorSelected = localState.proctorSelected;
  let roomMonitorsSelected = localState.roomMonitorsSelected;

  return (
    <IconStatus
      color={proctorIconColor}
      showIcon={Boolean(proctorIconColor)}
      text={roomStaffList.length > 0 ? <ul className='list-unstyled'>{roomStaffList}</ul> : <div>-</div>}
    />
  );
}

StaffList.propTypes = {
  eventStaff: PropTypes.array,
  room: PropTypes.object,
  rooms: PropTypes.array,
};

export default StaffList;
