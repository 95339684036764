import React from 'react';
import { hasAdminAccess } from '../../../utils/user';
import { OPEN_DRAWER_DELAY_MS } from '../../../constants/delay';
import { isEqual } from 'apollo-utilities';
import { Icon } from '@cb/apricot-react-icon';
import { localStateReducer, scrollTo, setCheckedProp, sortItems } from '../../../utils/common';
import { ResizeContext } from '../../../context/ResizeContext';
import { useStateValue } from '../../../context/AppContext';

import AmountCheckedDisplay from '../../ui/AmountCheckedDisplay';
import Pager from '../../ui/pagination/PagerWrapper';
import PageSize from '../../ui/pagination/PageSizeWrapper';
import SimpleList from '../../ui/list/SimpleList';
import Table from '../../ui/table/Table';

import '../Roster.scss';
import TableControlsV2 from '../../ui/table/TableControlsV2';

import { debounce } from 'lodash';

/**
 * Returns the appropriate page number if the number is out of bounds. otherwise return page number.
 */
const pageNumCleanUp = (pageNum: number, maxPages: number): number => {
  if (typeof pageNum !== 'number') {
    return 0;
  } else if (pageNum < 0) {
    return 0;
  } else if (pageNum >= maxPages) {
    //page number indexing starts from 0
    return maxPages - 1;
  }
  return pageNum;
};

interface RosterV3Props<T> {
  bulkAction: any;
  caption: string;
  checkboxAllRef: any;
  checkedItems: T[];
  columnSelector: () => JSX.Element;
  emptyMessage: string;
  forceDesktopViewForMobile: boolean;
  goToPage: any;
  groupName: string;
  handleCheckItems: (items: any[], checked: boolean) => void;
  headers: { title: string; sortField: string[] }[];
  id: string;
  items: T[];
  loading: boolean;
  onPageSizeChange: (num: number) => void;
  pageSize: number;
  /**
   * pageNum is applied in beginning and whenever there is an update to the items list. i.e. filtering
   */
  pageNum: number;
  renderItem: (item: T, options: { mobile: boolean }) => React.ReactElement;
  showChecked: boolean;
  showSelected: boolean;
  sortFields: { name: string; order: string }[];
  sortStrategy: 'natural' | 'numeric' | 'alphabetic';
  stickyHeaders: boolean;
  stickyTopBar?: string;
  striped?: boolean;
  tableClassName?: string;
  tableWrapperClassName?: string;
  tableControlsClassName?: string;
  tablePageOptsClassName?: string;
  tableControlElements: { search: any[]; buttons: any[] };
  tableControlForm: any;
  onPageChange?: (num: number) => void;
}

function RosterV3<T>({
  bulkAction = null,
  caption = '',
  checkboxAllRef = {},
  checkedItems = [],
  columnSelector,
  emptyMessage = '',
  forceDesktopViewForMobile = false,
  goToPage = undefined,
  groupName = '',
  handleCheckItems = () => {},
  headers = [],
  id = '',
  items = [],
  loading = false,
  onPageSizeChange,
  pageSize = 30,
  pageNum = 0,
  renderItem = (item: T) => <></>,
  showChecked = true,
  showSelected = false,
  sortFields = [],
  sortStrategy = 'natural',
  stickyHeaders = false,
  striped = true,
  stickyTopBar = 'nav',
  tableClassName = '',
  tableWrapperClassName = 'p-0 mb-4',
  tableControlsClassName = '',
  tablePageOptsClassName = '',
  tableControlElements = {},
  tableControlForm = null,
  onPageChange,
}: RosterV3Props<T>) {
  function renderHeader(header: { title: string; sortField: any[] }, index: number) {
    const key = `header_${header.title}_${index}`;
    const headerSortField =
      header.sortField && header.sortField.length > 0 ? header.sortField[header.sortField.length - 1] : '';
    return (
      <th scope='col' aria-sort={headerSortOrder(headerSortField)} className={stickyHeaders ? 'sticky' : ''} key={key}>
        {header.sortField ? (
          <a
            aria-roledescription='sort header'
            className='text--color-white'
            data-automation={`roster-${header.title.split(' ').join('-').toLowerCase()}`}
            href='#0'
            onClick={handleHeaderOnClick(header.sortField)}
          >
            {header.title}
            {generateIcon(header.sortField)}
          </a>
        ) : (
          <span data-automation={`roster-${header.title.split(' ').join('-').toLowerCase()}`}>{header.title}</span>
        )}
      </th>
    );
  }

  function headerSortOrder(field?: string) {
    // See if this field is already being sorted.
    const matchingField = localState.sortFields.find((item: { name: string }) => item.name === field);

    // Invert the sort criteria if necessary.
    if (!matchingField || !matchingField.order) {
      return 'none';
    } else {
      return matchingField.order === 'asc' ? 'ascending' : 'descending';
    }
  }

  function generateIcon(field: string[]) {
    // We're using arrays now, so just use the last one in the list.
    const sortOrder = headerSortOrder(field[field.length - 1]);

    if (sortOrder === 'none') {
      return null;
    } else {
      return <Icon name={sortOrder === 'ascending' ? 'sort-asc' : 'sort-desc'} decorative />;
    }
  }

  function handleHeaderOnClick(fields: string[]) {
    return function (e: Event) {
      e && e.preventDefault && e.preventDefault();

      let matchingIndex = -1;
      const newSortFields = JSON.parse(JSON.stringify(localState.sortFields));
      let oldSortObj = {};
      let order = 'asc';

      // We might be passing an array of sortable fields per column header, so go through them.
      fields.forEach((field: string) => {
        // Search the current list of sorted fields, and adjust accordingly.
        matchingIndex = newSortFields.findIndex((sortField: { name: string }) => sortField.name === field);

        if (matchingIndex > -1) {
          // Remove the old obj, while returning the object that was removed.
          oldSortObj = newSortFields.splice(matchingIndex, 1)[0];

          // Change the order as per rotation -> asc -> desc -> default.
          if (oldSortObj.order === 'asc') {
            order = 'desc';
          } else if (oldSortObj.order === 'desc') {
            order = 'default';
          }
        }

        // Add it back in to the beginning of the order array with the new order.
        if (order !== 'default') {
          newSortFields.unshift({
            name: field,
            order,
          });
        }
      });

      setLocalState({
        paginationPage: 0,
        sortFields: newSortFields,
      });
    };
  }

  function mobileComponent(items: any) {
    return <SimpleList id={id} items={items} renderItem={(item) => renderItem(item, { mobile: true })} />;
  }

  function emptyTableComponent() {
    return (
      <>
        <React.Fragment>
          <a href='#0' id='top-of-table' tabIndex={-1} className='cb-sr-only'>
            Top of main table
          </a>
          <Table
            checkboxAllRef={checkboxAllRef}
            handleCheckAll={() => {}}
            headers={headers}
            id={id}
            items={emptyMessage ? [] : items}
            options={{ caption }}
            renderHeader={renderHeader}
            renderItem={renderItem}
            tableWrapperClassName={tableWrapperClassName}
            tableClassName={tableClassName}
            stickyHeaders={stickyHeaders}
            striped={striped}
            shadow={false}
          />
        </React.Fragment>
        <div
          className='w-100 d-flex align-items-center justify-content-center'
          style={{ marginTop: '4rem', fontWeight: '700' }}
          aria-live='polite'
        >
          <div className='' data-automation='no-rooms-message'>
            {emptyMessage}
          </div>
        </div>
      </>
    );
  }

  function desktopComponent() {
    function handleCheckAll(toCheck: { id: string }[]) {
      return function (e) {
        const checked = e.target.checked;
        const checkItems: string[] = [];

        // Loop through the checked items and add as needed.
        toCheck.forEach((item) => {
          checkItems.push(item.id);
        });

        handleCheckItems(checkItems, checked);
      };
    }

    return (
      <React.Fragment>
        <a href='#0' id='top-of-table' tabIndex={-1} className='cb-sr-only'>
          Top of main table
        </a>
        <Table
          checkboxAllRef={checkboxAllRef}
          handleCheckAll={handleCheckAll}
          headers={headers}
          id={id}
          items={displayItems}
          options={{ caption }}
          renderHeader={renderHeader}
          renderItem={renderItem}
          tableWrapperClassName={tableWrapperClassName}
          tableClassName={tableClassName}
          stickyHeaders={stickyHeaders}
          striped={striped}
          shadow={false}
        />
      </React.Fragment>
    );
  }

  // Global App state.
  const { user } = useStateValue();

  // Global App state.
  const windowSize = React.useContext(ResizeContext);

  //if pageNum is specified as -1, proceed to the last page
  const startingPageSize = pageSize || 30;
  const maxPages = Math.ceil(items.length / startingPageSize) || 1;
  const startingPageNum = pageNum === -1 ? maxPages - 1 : pageNum;
  // Local state.
  const [localState, setLocalState] = React.useReducer(localStateReducer, {
    initialItems: items,
    paginationPage: pageNumCleanUp(startingPageNum, maxPages),
    sortFields,
    sortStrategy,
    currentPageSize: startingPageSize,
  });

  const handleChangePageSize = React.useCallback(
    (num: number) => {
      return function () {
        const currentPageSize = num;

        // Un-check the "check all" checkbox if they change pages.
        setCheckedProp(checkboxAllRef, false);

        const maxPages = Math.ceil(items.length / currentPageSize);
        let paginationPage = localState.paginationPage;

        if (paginationPage < 0) {
          paginationPage = 0;
        } else if (paginationPage >= maxPages) {
          paginationPage = maxPages - 1;
        }

        //updating local state for all students Page Size preference
        onPageSizeChange && onPageSizeChange(currentPageSize);
        setLocalState({ currentPageSize, paginationPage });
      };
    },
    [items.length, localState.currentPageSize, localState.paginationPage]
  );

  const handleChangePage = React.useCallback(
    (num: number) => {
      return function () {
        // Un-check the "check all" checkbox if they change pages.
        setCheckedProp(checkboxAllRef, false);

        const maxPages = Math.ceil(items.length / localState.currentPageSize);

        if (typeof num !== 'number') {
          num = 0;
        } else if (num < 0) {
          num = 0;
        } else if (num >= maxPages) {
          num = maxPages - 1;
        }

        if (localState.paginationPage !== num) {
          setLocalState({
            paginationPage: num,
          });
        }

        onPageChange?.(num);
        // Scroll to the top of the Roster.
        scrollTo('top-of-table');
      };
    },
    [checkboxAllRef, items.length, localState.paginationPage, localState.currentPageSize]
  );

  const sortedItems = localState.sortFields ? sortItems(items, localState.sortFields, localState.sortStrategy) : items;

  const startIndex = localState.paginationPage * localState.currentPageSize;
  const endIndex = localState.paginationPage * localState.currentPageSize + localState.currentPageSize;
  const displayItems = sortedItems.slice(startIndex, endIndex);

  const showPageOpts: boolean = items.length > 30;
  //determine if the function has been passed to props and exists
  const showDynamicColumnsButton: boolean = typeof columnSelector === 'function';

  const updateStickies = () => {
    //dynamically set the top sticky for the topTableDynamicComponents and desktopComponentTable
    const navBar = stickyTopBar === 'nav' ? document.querySelector('nav') : document.getElementById(stickyTopBar);
    const topTableDynamicComponents = document.getElementById(`top-roster-page-opts-${id}`);

    const desktopTableContainer = document.getElementById(id);
    const desktopComponentTableHeaders = desktopTableContainer?.querySelectorAll<HTMLElement>('th.sticky');
    const navHeight = navBar?.offsetHeight;
    const topTableDynCompHeight = topTableDynamicComponents?.offsetHeight;
    //consider showPageOpts as the calculation, since that determines whether or not
    //the Dynamic Components on the top turn sticky or not
    if (navHeight && topTableDynamicComponents) {
      topTableDynamicComponents.style.top = `${navHeight}px`;
    }

    if (desktopComponentTableHeaders) {
      for (const header of desktopComponentTableHeaders) {
        let top = 0;
        if (navHeight) {
          top += navHeight;
        }
        if (topTableDynCompHeight) {
          top += topTableDynCompHeight;
        }
        if (top > 0) {
          top -= 1;
        }

        header.style.top = `${top}px`;
      }
    }
  };
  //if screensize changes, go ahead and update top for sticky
  //Set timeout needed, whenever Rosterv3 is used in a slide out drawer
  //otherwise sticky headers are off
  const debouncedUpdateStickies = debounce(updateStickies, OPEN_DRAWER_DELAY_MS);

  React.useEffect(() => {
    setTimeout(() => {
      debouncedUpdateStickies();
    }, OPEN_DRAWER_DELAY_MS);
  }, [windowSize.mobile, windowSize.prefix, headers]);

  React.useEffect(() => {
    // Update the page to 1 if we get an updated or filtered list.

    if (!isEqual(localState.initialItems, items)) {
      updateStickies();

      const maxPages = Math.ceil(items.length / localState.currentPageSize) || 1;
      //proceed to go to the last page, if pageNum is -1
      const page = pageNum === -1 ? maxPages - 1 : pageNum;
      const paginationPage = pageNumCleanUp(page, maxPages);

      setLocalState({
        initialItems: items,
        paginationPage: paginationPage,
      });
    }
  }, [localState.initialItems, items, localState.paginationPage]);

  // Set the "Check all" box to checked if they manually checked all of their display items.
  const displayedRegNos = displayItems.map((m) => m.candRegNo);
  const allDisplayedItemsChecked = displayedRegNos.every((v) => checkedItems.includes(v));

  if (allDisplayedItemsChecked && displayedRegNos.length > 0) {
    setCheckedProp(checkboxAllRef, true);
  }

  React.useEffect(() => {
    if (typeof goToPage === 'function' && sortedItems.length > localState.currentPageSize) {
      const gotoPage = goToPage(sortedItems, localState.currentPageSize);
      if (gotoPage) {
        handleChangePage(gotoPage)();
      }
    }
  }, [goToPage, sortedItems, handleChangePage, localState.currentPageSize]);

  const pageSizeOptions = (itemsLength: number) => {
    if (itemsLength <= 30) {
      return [];
    } else if (itemsLength > 30 && itemsLength <= 60) {
      return [30, 60];
    } else if (itemsLength > 60 && itemsLength <= 90) {
      return [30, 60, 90];
    } else {
      return [30, 60, 90, 120];
    }
  };

  const controlElementsCount = tableControlElements.search.length + tableControlElements.buttons.length;

  return (
    <>
      {controlElementsCount > 0 && (
        <div className={`pt-4 top-roster--controls ${tableControlsClassName}`}>
          <TableControlsV2
            tableControlElements={tableControlElements}
            tableControlForm={tableControlForm}
            isRosterEmpty={!!emptyMessage}
          />
        </div>
      )}
      {
        <div
          className={`top-roster-sticky top-roster-sticky--white-bkg ${tablePageOptsClassName}`}
          id={`top-roster-page-opts-${id}`}
        >
          {!!groupName && showChecked && (
            <div
              className={
                showPageOpts ? 'row' : windowSize.mobile || !showDynamicColumnsButton ? 'row py-4' : 'row mt-4'
              }
            >
              {!!items.length && (
                <div className='col-sm-6 pt-4'>
                  <AmountCheckedDisplay
                    amountSelected={checkedItems.length}
                    endDisplayIndex={startIndex + displayItems.length}
                    itemsPerPage={localState.currentPageSize}
                    showSelected={hasAdminAccess(user.rid, user.role) && showSelected}
                    startDisplayIndex={startIndex + 1}
                    tableName={caption}
                    totalCount={items.length}
                    loading={loading}
                  />
                </div>
              )}
              {bulkAction && <div className='col-sm-6'>{bulkAction}</div>}
            </div>
          )}
          {showPageOpts ? (
            <div className='cb-pagination-size row mt-0 py-2'>
              <div className='col-12 col-sm-6'>
                <div className='page-size-align display--flex_desktop_align-items--center'>
                  {!windowSize.mobile && showDynamicColumnsButton && columnSelector()}
                  <PageSize
                    maxRecords={items.length}
                    pagesizeId='pageSizeOption'
                    buttonId='pagerBtnOption'
                    current={localState.currentPageSize}
                    ariaLabel='Page Size Component'
                    pageSizeOptions={pageSizeOptions(items.length)}
                    onSizeChange={(num: number) => handleChangePageSize(num)()}
                    recordCountLabel={''}
                  />
                </div>
              </div>

              <div className='col-12 col-sm-6 mt-4 mt-sm-0'>
                <Pager
                  ariaLabel='pagination'
                  buttonId={`studentRoster-pagination-page-${id}`}
                  className='d-flex w-100 pager-align'
                  current={localState.paginationPage + 1}
                  delta={4}
                  max={Math.ceil(items.length / localState.currentPageSize)}
                  onPageChange={(num: number) => handleChangePage(num - 1)()}
                  pagerId={`studentRoster-Pagination-${id}`}
                />
              </div>
            </div>
          ) : (
            !windowSize.mobile &&
            showDynamicColumnsButton && (
              <div className='cb-pagination-size row mt-0 py-2'>
                <div className='col-12 col-sm-6'>
                  <div className='page-size-align'>{columnSelector()}</div>
                </div>
              </div>
            )
          )}
        </div>
      }
      {emptyMessage && !items.length ? (
        emptyTableComponent()
      ) : !displayItems.length ? (
        <div className='row' style={{ marginTop: '1rem' }} aria-live='polite'>
          <span style={{ marginLeft: '1rem' }}>0 results ({checkedItems.length} selected)</span>
        </div>
      ) : (
        <React.Fragment>
          {windowSize.mobile && !forceDesktopViewForMobile ? mobileComponent(displayItems) : desktopComponent()}
        </React.Fragment>
      )}
    </>
  );
}

export default RosterV3;
