import React from 'react';
import { Button, Popover } from '@cb/apricot-react';
import Input from '../form/Input';
import TooltipWrapper from '../tooltip/TooltipWrapper';
import './TableControls.scss';
import { DropDownDrawerButton } from '../button/DropDownDrawerButton';
import RosterFilters from '../../common/RosterFilters';

export type TableControlV2Props = {
  tableControlElements: {
    search: TableControlItemTypes[];
    buttons: TableControlItemTypes[];
  };
  tableControlForm: React.ReactElement | null;
  isRosterEmpty: boolean;
};

export type TableControlOpt = {
  /**
   * By default it is false
   */
  renderWhenRosterEmpty?: boolean;
};

// In TypeButton and TypeInput, we're using the 'string' type for icon because of an error with Vite-eslint plugin.
// It is actually an Icon type

export type TypeButton = {
  isExpanded: boolean;
  classes: string;
  parentClassName: string;
  name: string;
  onClick: () => void;
  icon: string;
  label: string;
  type: string;
} & TableControlOpt;

export type TypeButtonPopover = TypeButton & {
  popoverTitle: string;
  popoverText: string;
} & TableControlOpt;

export type TypeDropDown = {
  items: { name: string; component: React.ReactElement }[];
  label: string;
  parentClassName: string;
  name: string;
  type: string;
  tooltipMsg: string;
} & TableControlOpt;

export type TypeInput = {
  defaultValue: string;
  icon: string;
  name: string;
  label: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  parentClassName: string;
  placeholder: string;
  type: string;
  className: string;
} & TableControlOpt;

export type TypeFilterButton = TypeButtonPopover & {
  filterList: any;
  updateFilter: () => void;
} & TableControlOpt;

export type TableControlItemTypes = TypeButton | TypeButtonPopover | TypeDropDown | TypeInput;

function TableControlsV2({
  tableControlElements = { search: [], buttons: [] },
  tableControlForm = null,
  isRosterEmpty = false,
}: TableControlV2Props) {
  function buildControlElement(element: TableControlItemTypes) {
    const controlElementTemplate: Record<string, React.ReactElement> = {
      // Render a Button.
      button: (
        <Button
          aria-controls='tableControlForm'
          aria-expanded={(element as TypeButton).isExpanded}
          className={(element as TypeButton).classes}
          data-automation={`button-${element.name}`}
          id={(element as TypeButton).name}
          key={(element as TypeButton).name}
          onClick={(element as TypeButton).onClick}
          icon={(element as TypeButton).icon as any}
          iconDecorative
          small
        >
          {element.label}
        </Button>
      ),
      buttonWithPopover: (
        <>
          <Button
            aria-controls='tableControlForm'
            aria-expanded={(element as TypeButtonPopover).isExpanded}
            className={(element as TypeButtonPopover).classes}
            data-automation={`button-${(element as TypeButtonPopover).name}`}
            id={(element as TypeButtonPopover).name}
            icon={(element as TypeButtonPopover).icon as any}
            key={(element as TypeButtonPopover).name}
            onClick={(element as TypeButtonPopover).onClick}
            type='button'
            small
          >
            {(element as TypeButtonPopover).label}
          </Button>
          <TooltipWrapper light={true} trigger={element.name}>
            <>
              <div>{(element as TypeButtonPopover).popoverTitle}</div>
              <div>{(element as TypeButtonPopover).popoverText}</div>
            </>
          </TooltipWrapper>
        </>
      ),
      dropdown: (
        <DropDownDrawerButton
          items={(element as TypeDropDown).items}
          label={(element as TypeDropDown).label}
          name={(element as TypeDropDown).name}
          tooltipMsg={(element as TypeDropDown).tooltipMsg}
        />
      ),
      input: (
        <Input
          data-automation='input-roster-search'
          defaultValue={(element as TypeInput).defaultValue}
          icon={(element as TypeInput).icon}
          id={(element as TypeInput).name}
          key={(element as TypeInput).name}
          label={(element as TypeInput).label}
          labelClassName='cb-sr-only'
          name={(element as TypeInput).name}
          onChange={(element as TypeInput).onChange}
          placeholder={(element as TypeInput).placeholder}
          mainClassName={(element as TypeInput).className}
        />
      ),
      filterButton: (
        <>
          <Button
            aria-controls='tableControlForm'
            aria-expanded={(element as TypeFilterButton).isExpanded}
            className={(element as TypeFilterButton).classes}
            data-automation={`button-${(element as TypeFilterButton).name}`}
            id={(element as TypeFilterButton).name}
            icon={(element as TypeFilterButton).icon as any}
            key={(element as TypeFilterButton).name}
            onClick={(element as TypeFilterButton).onClick}
            type='button'
            small
            iconLeft
          >
            {(element as TypeFilterButton).label}
          </Button>
          <Popover
            popoverId='filter-popover'
            headerId='filter-popover-header'
            closeButton={true}
            className='cb-popover cb-filter'
            role='region'
            placement='bottom'
            popoverTitle={'Filter'}
            trigger={(element as TypeFilterButton).name}
          >
            <>
              <RosterFilters
                shadow={false}
                filters={(element as TypeFilterButton).filterList}
                updateFilter={(element as TypeFilterButton).updateFilter}
              />
            </>
          </Popover>
        </>
      ),
    };

    return controlElementTemplate[element.type];
  }

  const renderTableControlElements = (elements: TableControlItemTypes[], rightControls?: boolean) => {
    return elements
      .filter((tableControl) => {
        if (!tableControl.renderWhenRosterEmpty) {
          return !isRosterEmpty;
        } else {
          return true;
        }
      })
      .map((tableControl, index) => (
        <div
          id={`${tableControl.name}-button-wrapper`}
          key={tableControl.name}
          className={`${
            (tableControl.parentClassName ? tableControl.parentClassName : '') +
            (elements.length - 1 === index ? '' : ' mr-sm-3')
          } ${rightControls ? ' right-control-element' : ''}`}
        >
          {buildControlElement(tableControl)}
        </div>
      ));
  };

  const tableControlLeftElementsRendered = renderTableControlElements(tableControlElements?.search);
  const tableControlRightElementsRendered = renderTableControlElements(tableControlElements?.buttons, true);

  if (tableControlElements?.search?.length > 0 || tableControlElements?.buttons?.length > 0) {
    return (
      <React.Fragment>
        <div className='row'>
          <div className='col-sm-6'>{tableControlLeftElementsRendered}</div>
          <div className='right-control-elements hide-from-print col-sm-6 py-3 py-sm-0'>
            {tableControlRightElementsRendered}
          </div>
        </div>

        <div className='hide-from-print' id='tableControlForm' role='region' tabIndex={-1}>
          {tableControlForm}
        </div>
      </React.Fragment>
    );
  } else {
    return null;
  }
}

export default TableControlsV2;
