import { sortItems } from '../../../utils/common';

interface Student {
  joinCode?: string;
  startPin?: string;
  checkedInRoom?: boolean;
  dapTestStatus?: { name: string };
  candLastName?: string;
  candFirstName?: string;
  candMidInit?: string;
}

export const getStudentStatuses = (studentsArr: Student[] = []) => {
  const preRosteredStudents: Student[] = [];
  const joinedStudents: (Student & { joinedAndPresent: boolean })[] = [];
  const readyStudents: Student[] = [];

  // TODO: Some of these are not dapTestStatuses, but dapExamStatuses. We should probably only use dapExamStatuses
  // to check if they are joinedStudents vs. readyStudents
  const dapTestStatusesToConsider = ['none', 'exam-checkin-started', 'exam-setup-started', 'exam-setup-completed'];

  // sort students
  studentsArr = sortItems(
    studentsArr,
    [
      {
        name: 'candLastName',
        order: 'asc',
      },
      {
        name: 'candFirstName',
        order: 'asc',
      },
      {
        name: 'candMidInit',
        order: 'asc',
      },
    ],
    'natural'
  );

  // split students into roster group swimlanes
  studentsArr.map((student) => {
    if (!student.joinCode?.length) {
      preRosteredStudents.push(student);
    }

    if (student.joinCode?.length) {
      if (student.startPin && student.checkedInRoom) {
        if (student.dapTestStatus?.name && dapTestStatusesToConsider.includes(student.dapTestStatus?.name)) {
          joinedStudents.push({ ...student, joinedAndPresent: true });
        } else {
          // missing dapTestStatus or not in the list to consider
          readyStudents.push(student);
        }
      } else {
        // no startPin and/or not checked in
        joinedStudents.push({ ...student, joinedAndPresent: false });
      }
    }

    return student;
  });

  return { preRosteredStudents, joinedStudents, readyStudents };
};
