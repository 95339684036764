import React, { useReducer, useContext } from 'react';
import { Icon, PrimaryButton, YellowButton } from '@cb/apricot-react';
import { AddStaff, DrawerTableComponent } from './inventory-staff/AddStaff';
import { localStateReducer, scrollTo } from '../../../../utils/common';
import { useMutation } from '@apollo/client';
import { BULK_CREATE_USER } from '../../../../apollo/mutations';
import { uniqueId, isEqual, get, keyBy } from 'lodash';
import { IStaffEditable } from './type';
import {
  staffValidationRules,
  userValidationMessages,
  userBackendValidationMessages,
} from '../../../../validations/user/validator';
import { buildNetworkErrorMessages } from '../../../../validations/common';
import { ResizeContext } from '../../../../context/ResizeContext';
import ErrorMessages from '../../../ui/message/ErrorMessage';
import { validateAll } from 'indicative';
import { ModalDispatchContext } from '../../../ui/modal/ModalContext';
import { isValidPhoneNumber } from 'react-phone-number-input';
import OneButtonModal from '../../../ui/modal/standard/OneButtonModal';

type StaffAddRosterProps = {
  handleDrawerDirty: (isDirty: boolean) => void;
  onClose: () => void;
};

const UI_PREFIX = 'bulk-add-staff-form';

const DEFAULT_ADD_STAFF_ROWS: IStaffEditable[] = [];
const emptyStaff: IStaffEditable = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  role: 'proctor',
  id: '',
};

for (let i = 0; i < 5; i++) {
  DEFAULT_ADD_STAFF_ROWS.push({ ...emptyStaff, id: uniqueId() });
}

/**
 * Check if some of the rows are filled
 */
function areSomeRowsFilled(rows: IStaffEditable[]) {
  const filledRows = rows.filter((row) => {
    const rowDup = { ...row };
    rowDup.id = '';
    return !isEqual(rowDup, emptyStaff);
  });

  return filledRows.length > 0;
}

export function StaffAddRoster({ onClose, handleDrawerDirty }: StaffAddRosterProps) {
  const dispatchModal = useContext(ModalDispatchContext);
  const windowSize = useContext(ResizeContext);
  const [bulkCreateUsers] = useMutation(BULK_CREATE_USER);

  const [localState, setLocalState] = useReducer(localStateReducer, {
    staffRows: DEFAULT_ADD_STAFF_ROWS,
    dirtyForm: false,
    isProcessing: false,
    //using an object, since a new object triggers a re-render for useEffect
    //since a new object is consider different with same key/value pairs
    //this helps enforce the add row logic, in navigating to the last page.
    startingPageOpts: { pageNum: 0 },
    formErrorsByStaffId: {},
    submitErrors: [],
    submitEnabled: false,
  });

  const emailToStaff = keyBy(localState.staffRows, 'email');

  const editStaffChangeHandler = (staff: IStaffEditable) => {
    const copyStaffRows = [...localState.staffRows];
    const updatedStaffRows = copyStaffRows.map((row) => {
      if (row.id === staff.id) {
        return staff;
      }
      return row;
    });

    const dirtyForm = areSomeRowsFilled(updatedStaffRows);
    setLocalState({ staffRows: updatedStaffRows, dirtyForm, submitEnabled: dirtyForm });
    handleDrawerDirty(dirtyForm);
  };

  const handleAddRow = () => {
    setLocalState({
      //navigate to last page
      startingPageOpts: { pageNum: -1 },
      staffRows: [...localState.staffRows, { ...emptyStaff, id: uniqueId() }],
    });
  };

  const deleteStaffHandler = (staff: any) => {
    const dupStaffRows = [...localState.staffRows];
    const index = dupStaffRows.findIndex((s) => s.id === staff.id);
    if (index === -1) {
      console.error('Unable to delete row.');
      return;
    }
    dupStaffRows.splice(index, 1);
    const dirtyForm = areSomeRowsFilled(dupStaffRows);
    setLocalState({ staffRows: dupStaffRows, dirtyForm, submitEnabled: dirtyForm });
  };

  const validateTheForm = async (rows: any[]) => {
    const formErrorsByStaffId: { [key: string]: IStaffEditable } = { ...localState.formErrorsByStaffId };
    let submitEnabled = true;

    const catchCallback = (staffId: number) => (errors: any[]) => {
      submitEnabled = false;
      errors.forEach((err: any) => {
        if (formErrorsByStaffId[staffId]) {
          formErrorsByStaffId[staffId][err.field as keyof IStaffEditable] = err.message;
        }
      });
    };

    //validate each staff row, and check for email uniqueness
    const emails: string[] = [];
    for (let index = 0; index < rows.length; index++) {
      formErrorsByStaffId[rows[index].id] = { ...emptyStaff, role: '' };

      await validateAll(rows[index], staffValidationRules, userValidationMessages).catch(catchCallback(rows[index].id));

      // validate phone number again with E164 check
      if (rows[index].phone && !isValidPhoneNumber(rows[index].phone)) {
        submitEnabled = false;
        formErrorsByStaffId[rows[index].id].phone = 'Please enter a valid phone number.';
      }

      // validate if the email is unique
      if (emails.includes(rows[index].email)) {
        submitEnabled = false;
        formErrorsByStaffId[rows[index].id].email = 'Email has already been used.';
      } else {
        emails.push(rows[index].email);
      }
    }

    //if there is validation errors, make drawer dirty again
    if (!submitEnabled) {
      handleDrawerDirty(true);
    }

    return {
      rows,
      formErrorsByStaffId: submitEnabled ? {} : formErrorsByStaffId,
      submitEnabled: !localState.isProcessing && submitEnabled,
    };
  };

  const processForm = async () => {
    const finalForm: IStaffEditable[] = [];

    localState.staffRows.forEach((row: IStaffEditable) => {
      const { firstName, lastName, email, phone, role, id } = row;

      //if role differs from default, we need to push to final form
      if (firstName || lastName || email || phone || role !== 'proctor') {
        // Push for final validation
        finalForm.push({
          firstName: firstName?.trim(),
          lastName: lastName?.trim(),
          email: email?.trim(),
          phone: phone?.trim(),
          role,
          id,
        });
      }
    });

    // Ensure we have at least 1 row.
    if (finalForm.length > 0) {
      // Validate the form one last time.
      const { formErrorsByStaffId, submitEnabled } = await validateTheForm(finalForm);

      //no validation errors
      if (Object.keys(formErrorsByStaffId).length === 0) {
        // Submit the form to GraphQL!

        //json stringify the phone number
        //make sure not to include generated id to the BE
        const copyFinalForm: { firstName: string; lastName: string; email: string; phone: string; role: string }[] = [
          ...finalForm,
        ].map((row) => {
          const copy_row = {
            firstName: row.firstName,
            lastName: row.lastName,
            phone: row.phone,
            role: row.role,
            email: row.email,
          };
          if (copy_row.phone) {
            const phoneStringified = JSON.stringify([
              {
                type: 'work',
                phoneNumber: copy_row.phone,
              },
            ]);

            copy_row.phone = phoneStringified;
          }
          return copy_row;
        });

        bulkCreateUsers({
          variables: { input: { source: 'BULK_ADD_STAFF_TABLE', users: copyFinalForm } },
          refetchQueries: ['getStaffAndRooms'],
        })
          .then(async (response) => {
            onClose();
            const afterAction = () => {
              setLocalState({
                staffRows: DEFAULT_ADD_STAFF_ROWS,
              });
            };

            dispatchModal(
              <OneButtonModal
                modalId='staffAddRosterConfirm'
                buttonLabel='Close'
                onClose={afterAction}
                title={'Your staff have been successfully added'}
                variant='success'
              />
            );
          })
          .catch((errors) => {
            scrollTo(UI_PREFIX, 'add-staff-side-drawer');

            let submitErrors;
            /**
             * @returns {string} - the input id of the field that the error message is for
             */
            const fieldMessageToInputId = (
              customMessage: string,
              email: string,
              formErrorsByStaffId: { [key: string]: IStaffEditable }
            ): string => {
              const fields = ['firstName', 'lastName', 'email', 'phone'];
              const field = fields.find((field) => customMessage.includes(field));

              const fieldToIdFieldName: { [key: string]: string } = {
                firstName: 'first-name',
                lastName: 'last-name',
                email: 'email',
                phone: 'phone',
                role: 'role',
              };

              if (!formErrorsByStaffId[emailToStaff[email].id] && field) {
                formErrorsByStaffId[emailToStaff[email].id] = { ...emptyStaff, role: '' };
              }
              if (field) {
                formErrorsByStaffId[emailToStaff[email].id][field] = customMessage;
              }

              return field ? `staff-${fieldToIdFieldName[field]}-${emailToStaff[email]?.id}` : UI_PREFIX;
            };

            const buildErrorMessages = (errors, buildMessages) => {
              const errorMessages: any[] = [];
              errors.map((errorObject) => {
                if (errorObject.payload.length) {
                  errorObject.payload.map((p: { email: string; errorDetails: [] }) => {
                    const email = p.email;
                    p.errorDetails.map((errorDetailsObject: { message: string; path: string }, index: number) => {
                      let customMessage =
                        get(buildMessages, errorDetailsObject?.message) || errorDetailsObject?.message;
                      let uiName = `${UI_PREFIX}-${errorDetailsObject?.path}${index}`;
                      if (customMessage.includes('emailDomain')) {
                        customMessage = customMessage.replace('%%emailDomain%%', email);
                        uiName = `staff-email-${emailToStaff[email]?.id}`;
                        if (!formErrorsByStaffId[emailToStaff[email].id]) {
                          formErrorsByStaffId[emailToStaff[email].id] = { ...emptyStaff, role: '' };
                        }
                        formErrorsByStaffId[emailToStaff[email].id].email = customMessage;
                      } else {
                        uiName = fieldMessageToInputId(customMessage, email, formErrorsByStaffId);
                      }

                      errorMessages.push({
                        errorMessage: customMessage,
                        key: `error-${UI_PREFIX}-${errorDetailsObject?.path}${index}`,
                        uiName,
                      });
                    });
                  });
                }
              });
              return errorMessages;
            };
            if (Array.isArray(errors.graphQLErrors) && errors.graphQLErrors.length > 0) {
              submitErrors = buildErrorMessages(errors.graphQLErrors, userBackendValidationMessages);
              setLocalState({ submitErrors });
            } else if (
              Array.isArray(errors?.networkError?.result?.errors) &&
              errors.networkError.result.errors.length > 0
            ) {
              submitErrors = buildNetworkErrorMessages(
                errors.networkError.result.errors,
                UI_PREFIX,
                userValidationMessages
              );
            }

            setLocalState({
              isProcessing: false,
              submitErrors,
              dirtyForm: false,
              staffRows: finalForm, //will remove empty fields
              formErrorsByStaffId,
              submitEnabled: false,
            });
          });
      } else {
        setLocalState({
          isProcessing: false,
          dirtyForm: false,
          staffRows: finalForm, //will remove empty fields
          formErrorsByStaffId,
          submitEnabled: false,
        });
      }
    }
  };

  const handleSubmit = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e && e.preventDefault && e.preventDefault();

    if (localState.dirtyForm && !localState.isProcessing) {
      setLocalState({
        dirtyForm: false,
        submitEnabled: false,
      });
      handleDrawerDirty(false);
      processForm();
    }
  };

  return (
    <form className='cb-form w-100' id={UI_PREFIX}>
      {localState.submitErrors?.length > 0 && (
        <ErrorMessages errorMessages={localState.submitErrors} scrollableWindowId='add-staff-side-drawer' />
      )}
      <div className='sticky-wrapper p-4 form-content form-content-staff-inventory w-100'>
        <AddStaff
          drawerTableComponent={DrawerTableComponent.addStaff}
          deleteStaffHandler={deleteStaffHandler}
          editable={true}
          editStaffChangeHandler={editStaffChangeHandler}
          items={localState.staffRows}
          pageOpts={localState.startingPageOpts}
          formErrorsByStaffId={localState.formErrorsByStaffId}
        />
        <div className='display-flex mb-4 form-add-row pl-0'>
          {windowSize.mobile ? (
            <></>
          ) : (
            <PrimaryButton data-automation='button-add-staff-row' small onClick={handleAddRow}>
              <Icon name='plus' size='12' style={{ paddingRight: '5px' }} />
              Add Row
            </PrimaryButton>
          )}
        </div>
      </div>

      <div className='display-flex form-footer'>
        <YellowButton
          small
          disabled={!localState.submitEnabled || windowSize.mobile}
          data-automation={'button-add-table-staff-form-confirm'}
          onClick={handleSubmit}
          className='flex--justify-right ml-auto mr-4'
        >
          Add Staff
        </YellowButton>
      </div>
    </form>
  );
}
