import { React, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@cb/apricot-react';

interface TooltipWrapperProps {
  children: React.ReactNode;
  className?: string;
  placement?: string;
  show?: boolean;
  light?: boolean;
  tooltipId?: string;
  trigger: string;
}

/*
  wrapper for Apricot 4 tooltip that adds an option to show the tooltip upon creation
  (when show = true)
 */
export default function TooltipWrapper({
  children = {},
  className = '',
  placement = 'top',
  show = false,
  light = false,
  tooltipId = '',
  trigger = '',
}: TooltipWrapperProps) {
  const cbRef = useRef();

  useEffect(() => {
    if (show) {
      cbRef.current.show();
    }
  }, [show]);

  return (
    <div style={{ position: 'absolute', left: '-9999px' }}>
      <Tooltip
        cbRef={cbRef}
        light={light}
        placement={placement}
        trigger={trigger}
        tooltipId={tooltipId}
        className={className}
      >
        {children}
      </Tooltip>
    </div>
  );
}

TooltipWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  light: PropTypes.bool,
  placement: PropTypes.string,
  show: PropTypes.bool,
  tooltipId: PropTypes.string,
  trigger: PropTypes.string,
};
