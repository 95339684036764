const { REACT_APP_TDTK_ENV } = import.meta.env;

export const cbEnv =
  REACT_APP_TDTK_ENV === 'prod' || REACT_APP_TDTK_ENV === 'preprod'
    ? 'pine'
    : REACT_APP_TDTK_ENV === 'perf' || REACT_APP_TDTK_ENV === 'int'
    ? 'spruce'
    : REACT_APP_TDTK_ENV === 'uat'
    ? 'maple'
    : 'palm';

export const getCurrentDomain = () => {
  if (REACT_APP_TDTK_ENV === 'prod') {
    return 'https://testday.collegeboard.org';
  } else if (REACT_APP_TDTK_ENV === 'preprod') {
    return 'https://preprod.tdtk-prod.collegeboard.org';
  }
  return `https://${REACT_APP_TDTK_ENV}.tdtk-nonprod.collegeboard.org`;
};
