import React from 'react';
import PropTypes from 'prop-types';
import { formatStudentName } from '../../../../../utils/common';
import { generateStatus } from '../../../../common/students/utility';
import { isInSchoolEvent } from '../../../../../utils/event';
import { Link, useLocation } from 'react-router-dom';
import { useStateValue } from '../../../../../context/AppContext';
import moment from 'moment';
import { AccommodationLookup } from '../../../../common/accommodations/lookup';
import Accommodations from '../../../students/roster/Accommodations';
import IconStatus from '../../../../ui/icons/IconStatus';
import { cbAdminRoleProps } from '../../../../../constants/roles';

function StudentRosterRow({ student = {} }) {
  function generateStatusColor(student) {
    // Determine which class to use for Student Status.
    if (student.checkedInRoom || student.checkedInCenter) {
      return 'green3';
    } else if (student.absent || student.deniedEntry) {
      return 'red1';
    }
  }

  function generateAccommodationList(accommodations = []) {
    return AccommodationLookup(accommodations, 'digital_cb').map((item, index) => {
      return (
        <li key={`accommodations_${index}`}>
          <Accommodations item={item} studentId={student?.id} studentName={student?.candFirstName} />
        </li>
      );
    });
  }

  function getColumnClass(columnName) {
    function classes() {
      if (user?.rid === cbAdminRoleProps.CB_ADMIN_USER_RID) {
        return {
          studentName: 'col-xs-12 col-sm-6 col-md-3',
          accommodations: 'hidden-xs hidden-sm col-md-3 student-table-row-info--text',
          attendance: 'hidden-xs hidden-sm col-xs-12 col-md-2',
          dapTestStatus: 'col-xs-12 col-sm-3 col-md-2 student-table-row-info--text',
          lastUpdated: 'hidden-xs col-xs-12 col-sm-2 col-md-2 px-lg-1 student-table-row-info--last-updated',
        };
      } else {
        return {
          studentName: 'col-xs-12 col-sm-6 col-md-3',
          accommodations: 'hidden-xs hidden-sm col-md-3 student-table-row-info--text',
          attendance: 'hidden-xs col-sm-3 col-xs-12 col-md-3',
          dapTestStatus: 'col-xs-12 col-sm-3 col-md-3 student-table-row-info--text',
          lastUpdated: 'hidden',
        };
      }
    }

    return classes()[columnName];
  }

  // Global App state.
  const { orgEvent, user } = useStateValue();

  // History for current page so we can return to it later.
  const { pathname } = useLocation();

  const accommodations = generateAccommodationList(student.accommodationsArray);

  return (
    <div className={'student-table-row row mx-0'}>
      <div className={getColumnClass('studentName')} role='rowheader'>
        {/* student name, regNo, and waitlist */}
        {
          <Link
            className='student-table-row-info--name cb-blue5-color'
            to={'/students/get/' + student?.id}
            state={{
              from: pathname,
            }}
          >
            {formatStudentName(student)}
          </Link>
        }
        {!isInSchoolEvent(orgEvent) ? (
          <div className='student-table-row-info--reg'>
            {student?.displayedRegNo && <span> Reg. no.: {student?.displayedRegNo}</span>}
          </div>
        ) : (
          ''
        )}
        {student?.waitListFlag === 'Y' ? <div className='student-table-row-info--reg'>Waitlisted</div> : ''}
      </div>
      <div className={getColumnClass('accommodations')} role='cell'>
        {/* accommodations */}
        {accommodations.length > 0 ? (
          <ul className='student-table-li-info--accommodations'>{accommodations}</ul>
        ) : (
          'None'
        )}
      </div>
      <div className={getColumnClass('attendance')} role='cell'>
        {/* attendance -- present/absent/denied/etc */}
        <IconStatus color={generateStatusColor(student)} text={generateStatus(student).statusText} />
      </div>
      <div className={getColumnClass('dapTestStatus')} role='cell'>
        {/* dapTestStatus */}
        {student?.dapTestStatus?.displayName}
      </div>
      <div className={getColumnClass('lastUpdated')} role='cell'>
        {' '}
        {/* last updated */}
        {moment(student?.updated || undefined)
          .local()
          .format('lll')}
      </div>
    </div>
  );
}

StudentRosterRow.propTypes = {
  student: PropTypes.object,
};

export default StudentRosterRow;
