export interface IDigitalGroupTypes {
  [key: string]: {
    canCombineWith: string[];
    description: string;
    essay?: boolean;
    standard: boolean;
    label?: string;
    voucherReportOrder?: number;
    voucherReportRoomTitle?: string;
    voucherReportStaffTitle?: string;
  };
}

export const digitalWeekendGroupTypes: IDigitalGroupTypes = {
  C1: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'Center (Standard Time)',
    standard: true,
    voucherReportOrder: 0,
    voucherReportRoomTitle: '',
    voucherReportStaffTitle: '',
  },
  C2: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'Center (Time and One-half)',
    standard: false,
    voucherReportOrder: 0,
    voucherReportRoomTitle: '',
    voucherReportStaffTitle: '',
  },
  C3: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'Center (Double Time)',
    standard: false,
    voucherReportOrder: 0,
    voucherReportRoomTitle: '',
    voucherReportStaffTitle: '',
  },
  H1: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'School (Home Hospital)',
    standard: false,
    voucherReportOrder: 0,
    voucherReportRoomTitle: '',
    voucherReportStaffTitle: '',
  },
  P1: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'School (Paper - Standard Time)',
    standard: false,
    voucherReportOrder: 0,
    voucherReportRoomTitle: '',
    voucherReportStaffTitle: '',
  },
  P3: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'School (Paper - Time and One-half)',
    standard: false,
    voucherReportOrder: 0,
    voucherReportRoomTitle: '',
    voucherReportStaffTitle: '',
  },
  P5: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'School (Paper - Double Time)',
    standard: false,
    voucherReportOrder: 0,
    voucherReportRoomTitle: '',
    voucherReportStaffTitle: '',
  },
  P6: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'School (Paper - More than Double Time)',
    standard: false,
    voucherReportOrder: 0,
    voucherReportRoomTitle: '',
    voucherReportStaffTitle: '',
  },
  S1: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'School (Standard Time)',
    standard: true,
    voucherReportOrder: 0,
    voucherReportRoomTitle: '',
    voucherReportStaffTitle: '',
  },
  S2: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'School (Time and One-half)',
    standard: false,
    voucherReportOrder: 0,
    voucherReportRoomTitle: '',
    voucherReportStaffTitle: '',
  },
  S3: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'School (Double Time)',
    standard: false,
    voucherReportOrder: 0,
    voucherReportRoomTitle: '',
    voucherReportStaffTitle: '',
  },
  S4: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'School (More than Double Time)',
    standard: false,
    voucherReportOrder: 0,
    voucherReportRoomTitle: '',
    voucherReportStaffTitle: '',
  },
  S5: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'School (One-to-one)',
    standard: false,
    voucherReportOrder: 0,
    voucherReportRoomTitle: '',
    voucherReportStaffTitle: '',
  },
  S7: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'School (Other)',
    standard: false,
    voucherReportOrder: 0,
    voucherReportRoomTitle: '',
    voucherReportStaffTitle: '',
  },
};

export const digitalInSchoolSatGroupTypes: IDigitalGroupTypes = {
  H1: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'Home Hospital',
    standard: false,
  },
  P1: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'Paper - Standard Time',
    standard: false,
  },
  P3: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'Paper - Time and One-half',
    standard: false,
  },
  P5: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'Paper - Double Time',
    standard: false,
  },
  P6: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'Paper - More than Double Time',
    standard: false,
  },
  S1: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'Standard Time',
    standard: true,
  },
  S2: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'Time and One-half - Math only',
    standard: false,
  },
  S3: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'Time and One-half',
    standard: false,
  },
  S4: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'Double Time - Math only',
    standard: false,
  },
  S5: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'Double Time',
    standard: false,
  },
  S6: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'More than Double Time',
    standard: false,
  },
  S7: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'Other',
    standard: false,
  },
  S8: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'One-to-one',
    standard: false,
  },
  S9: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'Double Time - Essay only',
    standard: false,
  },
  S10: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'Time and One-half - Essay only',
    standard: false,
  },
  S11: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'Double Time - Math and Essay',
    standard: false,
  },
  S12: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'Time and One-Half - Math and Essay',
    standard: false,
  },
  S13: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'State-Allowed: EL Math Only',
    standard: false,
  },
};
export const digitalInSchoolPsatGroupTypes: IDigitalGroupTypes = {
  H1: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'Home Hospital',
    standard: false,
  },
  P1: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'Paper - Standard Time',
    standard: false,
  },
  P3: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'Paper - Time and One-half',
    standard: false,
  },
  P5: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'Paper - Double Time',
    standard: false,
  },
  P6: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'Paper - More than Double Time',
    standard: false,
  },
  S1: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'Standard Time',
    standard: true,
  },
  S2: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'Time and One-half - Math only',
    standard: false,
  },
  S3: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'Time and One-half',
    standard: false,
  },
  S4: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'Double Time - Math only',
    standard: false,
  },
  S5: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'Double Time',
    standard: false,
  },
  S6: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'More than Double Time',
    standard: false,
  },
  S7: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'Other',
    standard: false,
  },
  S8: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'One-to-one',
    standard: false,
  },
  S9: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'Double Time - Essay only',
    standard: false,
  },
  S10: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'Time and One-half - Essay only',
    standard: false,
  },
  S11: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'Double Time - Math and Essay',
    standard: false,
  },
  S12: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'Time and One-Half - Math and Essay',
    standard: false,
  },
  S13: {
    canCombineWith: [],
    description: '',
    essay: false,
    label: 'State-Allowed: EL Math Only',
    standard: false,
  },
};
