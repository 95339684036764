import Accordion from '../../ui/accordion/AccordionWrapper';
import AccordionItem from '../../ui/accordion/AccordionItemWrapper';
import React from 'react';
import capitalize from 'lodash/capitalize';
import { Icon } from '@cb/apricot-react';
import { createCleanMarkup } from '../../../utils/common';
import './Notification.scss';
import { useNotificationsCMSData } from '../../digital/common/cmsDataSets';

function EmergencyNotification() {
  const cmsData = useNotificationsCMSData();

  function generateMessage(priority: string, header: string, content: string, showExpanded: boolean) {
    return {
      title: (
        <React.Fragment>
          <Icon name='exclamation-circle' size='24' decorative />
          <span className='tdtk-notification-header'>
            {header ? header : `${capitalize(priority)} Priority Notification`}
          </span>
        </React.Fragment>
      ),
      body: [
        <div key='emergency-notification-body' className='tdtk-notification-body' id='emergency-notification-body'>
          <div className='container'>
            <div className='pl-2'>{content ? <div dangerouslySetInnerHTML={createCleanMarkup(content)} /> : null}</div>
          </div>
        </div>,
      ],
      showExpanded,
    };
  }

  function getContainerColorCode(priority: string) {
    switch (priority) {
      case 'Low':
        return 'tdtk-notification-low';
      case 'Medium':
        return 'tdtk-notification-med';
      case 'Urgent':
        return 'tdtk-notification-urgent';
      default:
        return 'tdtk-notification-low';
    }
  }
  if (cmsData?.body) {
    const emergencyAccordionItems = [
      generateMessage(cmsData.alert_level, cmsData.header, cmsData.body, cmsData.show_accordion),
    ];
    return (
      <div
        className={getContainerColorCode(capitalize(cmsData.alert_level))}
        style={{ backgroundColor: getContainerColorCode(capitalize(cmsData.alert_level)) }}
      >
        <div className='container tdtk-notification' id='tdtk-emergency-notification'>
          <Accordion>
            {emergencyAccordionItems.map((panel) => (
              <AccordionItem
                key={panel.key || panel.title}
                title={panel.title}
                glyphIcon={panel.glyphIcon}
                open={panel.showExpanded}
              >
                {panel.body}
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </div>
    );
  }
  return null;
}
export default EmergencyNotification;
