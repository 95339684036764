import { DAP_STUDENT_TEST_STATUS } from './bluebox';
import { EVENT_TYPES } from './event';

const FIRST_SECTION_SORT_ORDER = 400;
const SECOND_SECTION_SORT_ORDER_INCREASE = 200;

// Enums with sortOrders representing the order they should appear in the student filters.
// Note: Add {alert: true} if you want to add extra styling for something like "Needs Attention".
const BLUEBOX_TEST_STATUSES = [
  {
    name: DAP_STUDENT_TEST_STATUS.NONE,
    sortOrder: 100,
    displayName: 'Not Started',
    description: '',
    descriptionContent: {
      [EVENT_TYPES.INSCHOOL_AP]:
        'Students need to click the first button and sign in to Bluebook™ with the credentials on their ticket, enter the room code, and complete check-in.',
      [EVENT_TYPES.INSCHOOL_APHYBRID]:
        'Students need to click the first button and sign in to Bluebook™ with the credentials on their ticket, enter the room code, and complete check-in.',
      default:
        'Students need to click the first button and sign in to Bluebook™ with the credentials on their ticket, enter the room code, and complete check-in.',
    },
  },
  {
    name: DAP_STUDENT_TEST_STATUS.EXAM_CHECKIN_STARTED,
    sortOrder: 200,
    displayName: 'App Check-In',
    description: '',
    descriptionContent: {
      [EVENT_TYPES.INSCHOOL_AP]: 'If students are slow to start the test, find out if they need help.',
      [EVENT_TYPES.INSCHOOL_APHYBRID]: 'If students are slow to start the test, find out if they need help.',
      [EVENT_TYPES.WEEKEND_SAT]: 'If students are slow to start the test, find out if they need help.',
      [EVENT_TYPES.WEEKENDATSCHOOL_SAT]: 'If students are slow to start the test, find out if they need help.',
      default: 'If students are slow to start the test, find out if they need help.',
    },
  },
  {
    name: DAP_STUDENT_TEST_STATUS.WAITING_ROOM_ARRIVAL,
    sortOrder: 300,
    displayName: 'Ready to Test',
    description: '',
    descriptionContent: {
      default: '',
    },
  },
  {
    name: DAP_STUDENT_TEST_STATUS.SECTION_START_TIME,
    sortOrder: FIRST_SECTION_SORT_ORDER,
    displayName: '',
    description: '',
    descriptionContent: {
      [EVENT_TYPES.INSCHOOL_AP]:
        'Watch for raised hands, closed laptops, covered tablets, and signs of security violations such as inactive exam takers and devices displaying nontest content.',
      [EVENT_TYPES.INSCHOOL_APHYBRID]:
        'Watch for raised hands, closed laptops, covered tablets, and signs of security violations such as inactive exam takers and devices displaying nontest content.',
      [EVENT_TYPES.WEEKEND_SAT]:
        'Walk around the room every 10–15 minutes. Watch for raised hands, closed or covered devices, and signs of security violations such as devices displaying nontest content. ',
      [EVENT_TYPES.WEEKENDATSCHOOL_SAT]:
        'Walk around the room every 10–15 minutes. Watch for raised hands, closed or covered devices, and signs of security violations such as devices displaying nontest content.',
      default:
        'Watch for raised hands, closed laptops, covered tablets, and signs of security violations such as devices displaying nontest content.',
    },
  },
  {
    name: 'break', // this is an artificial break status which is used for a combination of several DAP_STUDENT_TEST_STATUSes
    sortOrder: 500,
    displayName: 'Break',
    description: '',
    descriptionContent: {
      [EVENT_TYPES.INSCHOOL_AP]:
        'Students should resume testing after their break by clicking the button to start Section II.',
      [EVENT_TYPES.INSCHOOL_APHYBRID]:
        'Students must keep their calculators and booklets on their desks during break. After their break, they should click the button to start Section II.',
      [EVENT_TYPES.WEEKEND_SAT]:
        'Students should take their ID with them if they leave the room. After they return, make sure they’re testing on their own device.',
      [EVENT_TYPES.WEEKENDATSCHOOL_SAT]:
        'Students should take their ID with them if they leave the room. After they return, make sure they’re testing on their own device.',
      default:
        'Students have a break between test sections. If they leave the room, they shouldn’t take anything except a snack (and an ID if they don’t attend your school).',
    },
  },
  {
    name: DAP_STUDENT_TEST_STATUS.OFFLINE,
    sortOrder: 700,
    displayName: 'Offline',
    description: '',
    descriptionContent: {
      [EVENT_TYPES.INSCHOOL_AP]:
        'Students can test offline so there might not be a problem. Without interrupting, check the student’s screen to see if they’re still testing.',
      [EVENT_TYPES.INSCHOOL_APHYBRID]:
        'Students can test offline so there might not be a problem. Without interrupting, check the student’s screen to see if they’re still testing.',
      [EVENT_TYPES.WEEKEND_SAT]:
        'Students can test offline so there might not be a problem. Without interrupting, check the student’s screen to see if they’re still testing.',
      [EVENT_TYPES.WEEKENDATSCHOOL_SAT]:
        'Students can test offline so there might not be a problem. Without interrupting, check the student’s screen to see if they’re still testing.',
      default:
        'Students can test offline so there might not be a problem. Without interrupting, check the student’s screen to see if they’re still testing.',
    },
  },
  {
    name: DAP_STUDENT_TEST_STATUS.EXIT_CONFIRMED,
    sortOrder: 800,
    displayName: 'Exited',
    description: '',
    descriptionContent: {
      [EVENT_TYPES.INSCHOOL_AP]:
        'Student needs attention! Check their device immediately to make sure it’s open and not asleep or being used improperly.',
      [EVENT_TYPES.INSCHOOL_APHYBRID]:
        'Student needs attention! Check their device immediately to make sure it’s open and not asleep or being used improperly.',
      [EVENT_TYPES.WEEKEND_SAT]:
        'Student needs attention! Check their device immediately to make sure it’s open and not asleep or being used improperly.',
      [EVENT_TYPES.WEEKENDATSCHOOL_SAT]:
        'Student needs attention! Check their device immediately to make sure it’s open and not asleep or being used improperly.',
      default:
        'Student needs attention! Check their device immediately to make sure it’s open and not asleep or being used improperly.',
    },
  },
  {
    name: DAP_STUDENT_TEST_STATUS.EXAM_SUBMISSION_PENDING,
    sortOrder: 900,
    displayName: 'Submission Pending',
    description: '',
    descriptionContent: {
      [EVENT_TYPES.INSCHOOL_AP]:
        'Answers are saved to the device but haven’t been submitted. Follow the instructions on the "Help" page to assist the student.',
      [EVENT_TYPES.INSCHOOL_APHYBRID]:
        'Answers are saved to the device but haven’t been submitted. Follow the instructions on the "Help" page to assist the student.',
      [EVENT_TYPES.WEEKEND_SAT]:
        'Answers are saved to the device but haven’t been submitted. Follow the instructions on the "Help" page to assist the student.',
      [EVENT_TYPES.WEEKENDATSCHOOL_SAT]:
        'Answers are saved to the device but haven’t been submitted. Follow the instructions on the "Help" page to assist the student.',
      default:
        'Answers are saved to the device but haven’t been submitted. Follow the instructions on the "Help" page to assist the student.',
    },
  },
  {
    name: 'not-arrived', // this is an artificial status that is used by UI filters
    sortOrder: 1000,
    displayName: 'Not arrived',
    description: '',
    descriptionContent: {
      default: '',
    },
  },
  {
    name: DAP_STUDENT_TEST_STATUS.EXAM_SUBMISSION_COMPLETE,
    sortOrder: 1100,
    displayName: 'Submitted',
    description: '',
    descriptionContent: {
      [EVENT_TYPES.INSCHOOL_AP]:
        'After testing ends, dismiss students with a "Submitted" status before helping students with other statuses.',
      [EVENT_TYPES.INSCHOOL_APHYBRID]:
        'Students with a “Submitted” status see a dark blue “Congratulations” screen and should no longer be writing. Dismiss them before helping students with other statuses.',
      [EVENT_TYPES.WEEKEND_SAT]:
        'Students will raise their hand when they finish testing. Wave them over silently so you can collect their scratch paper and return their device.',
      [EVENT_TYPES.WEEKENDATSCHOOL_SAT]:
        'Students will raise their hand when they finish testing. Wave them over silently so you can collect their scratch paper and return their device.',
      default:
        'After testing ends, dismiss students with a "Submitted" status before helping students with other statuses.',
    },
  },
];

/**
 * Look up and return the descriptionContent string for a given bluebox status name and tdtkAsmtType.
 *   if the bluebox status exists but there's no matching tdtkAsmtType in descriptionContent,
 *   return the default string from descriptionContent
 * Return an empty string for any other case or error
 * @param  {string} statusName       bluebox status name
 * @param  {string} tdtkAsmtType     tdtkAsmtType
 * @return {string}                  matching description string from BLUEBOX_TEST_STATUSES
 */
const getDescription = (statusName = '', tdtkAsmtType = EVENT_TYPES.WEEKEND_SAT) => {
  const matchingEnum = BLUEBOX_TEST_STATUSES.find((statusObj) => statusObj?.name === statusName);

  try {
    if (matchingEnum?.descriptionContent[tdtkAsmtType]) {
      return matchingEnum?.descriptionContent[tdtkAsmtType];
    } else if (matchingEnum?.descriptionContent?.default) {
      return matchingEnum?.descriptionContent.default;
    } else {
      return '';
    }
  } catch (e) {
    return '';
  }
};

// comparison fn for sorting by the sortOrder property of the BLUEBOX_TEST_STATUSES objects
export const compareSortOrder = (a = {}, b = {}) => {
  if (a?.sortOrder < b?.sortOrder) {
    return -1;
  }
  if (a?.sortOrder > b?.sortOrder) {
    return 1;
  }
  return 0;
};

// regex to grab e.g. "Section 1" or "Section II" from the sectionNames
export const sectionRegex = /^section \w+/i;

/**
 * get list of unique section names from students for each student in 'section-start-time'
 * @param  {student[]} students      Array of students
 * @return {string[]}                Array of the section-part of the student's dapTestStatus sectionName
 */
export const getUniqueSectionNames = (students) => {
  return students
    .reduce((acc, student) => {
      const { name, payload } = student?.dapTestStatus || {};
      const [matchedSectionName] = payload?.sectionName?.match(sectionRegex) || [];
      if (
        name === DAP_STUDENT_TEST_STATUS.SECTION_START_TIME &&
        matchedSectionName &&
        !acc?.includes(matchedSectionName)
      )
        return [...acc, matchedSectionName];
      return acc;
    }, [])
    .sort();
};

/**
 * Block of cases to derive student's actual testing status.
 */
export const blueboxSectionNameFormatter = (student) => {
  if (
    student?.dapTestStatus?.name === DAP_STUDENT_TEST_STATUS.SECTION_START_TIME &&
    student?.dapTestStatus?.payload?.sectionName?.match(sectionRegex)
  ) {
    return `${DAP_STUDENT_TEST_STATUS.SECTION_START_TIME}-${
      student?.dapTestStatus?.payload?.sectionName?.match(sectionRegex)[0]
    }`;
  } else if (
    // List of all possible breaks go into a single "break" status.
    [
      DAP_STUDENT_TEST_STATUS.SECTION_BREAK,
      DAP_STUDENT_TEST_STATUS.STUDENT_SELF_BREAK,
      DAP_STUDENT_TEST_STATUS.EMERGENCY_BREAK,
    ].includes(student?.dapTestStatus?.name)
  ) {
    return 'break';
  } else {
    return student?.dapTestStatus?.name;
  }
};

/**
 * get the BLUEBOX_TEST_STATUSES array with additional sections added to support variable section names
 * at a minimum, this function creates two section statuses:
 *   for AP assessments: 'Section I' and 'Section II'
 *   for SAT assessments: 'Section 1' and 'Section 2'
 * This happens regardless of which additionalSectionNames are supplied, so that the filter list can show those section filters at all times
 * @param  {string[]} additionalSectionNames      Array of section name strings from the bluebox payload, e.g. ['Section I, Part A']
 * @param  {string}   tdtkAsmtType                the tdtkAsmtType that will be used to get the correct description strings and to build the default sections
 * @return {object[]}                             Array of objects in the shape of the BLUEBOX_TEST_STATUSES objects
 */
export const getDynamicBlueboxTestStatuses = (
  additionalSectionNames = [],
  tdtkAsmtType: string = EVENT_TYPES.WEEKEND_SAT
) => {
  let ret = [...BLUEBOX_TEST_STATUSES].map((section) => {
    return {
      ...section,
      description: getDescription(section.name, tdtkAsmtType),
    };
  });

  // depending on assessment type, inject section 1/I and 2/II into additionalSectionNames
  switch (tdtkAsmtType) {
    case EVENT_TYPES.INSCHOOL_AP:
    case EVENT_TYPES.INSCHOOL_APHYBRID:
      additionalSectionNames = [...additionalSectionNames, 'Section I', 'Section II'];
      break;
    case EVENT_TYPES.INSCHOOL_SAT:
    case EVENT_TYPES.INSCHOOL_PSAT10:
    case EVENT_TYPES.INSCHOOL_PSAT89:
    case EVENT_TYPES.INSCHOOL_PSATNM:
    case EVENT_TYPES.WEEKEND_SAT:
    case EVENT_TYPES.WEEKENDATSCHOOL_SAT:
      additionalSectionNames = [...additionalSectionNames, 'Section 1', 'Section 2'];
      break;
    default:
      console.error('unhandled tdtkAsmtType', tdtkAsmtType);
      break;
  }

  if (tdtkAsmtType === EVENT_TYPES.INSCHOOL_SAT) {
    additionalSectionNames.push('Section 3');
  }

  // add additional entries if they don't already exist
  let sortOrder = FIRST_SECTION_SORT_ORDER; // starting sortOrder: sections are sorted by name and then given incrementing sort orders (with a numeric jump after the Break status)
  additionalSectionNames.sort().map((sectionName) => {
    const match = sectionName?.match(sectionRegex);

    if (match && !ret.find((item) => item?.name === `${DAP_STUDENT_TEST_STATUS.SECTION_START_TIME}-${match[0]}`)) {
      ret = [
        ...ret,
        {
          description: getDescription(DAP_STUDENT_TEST_STATUS.SECTION_START_TIME, tdtkAsmtType),
          displayName: sectionName,
          name: `${DAP_STUDENT_TEST_STATUS.SECTION_START_TIME}-${match[0]}`,
          // after the first section, increase the sort order once and then increment by 1 thereafter
          sortOrder:
            sortOrder === FIRST_SECTION_SORT_ORDER + 1
              ? (sortOrder += SECOND_SECTION_SORT_ORDER_INCREASE)
              : sortOrder++,
        },
      ];
    }
    return sectionName;
  });

  return ret;
};
