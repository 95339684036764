import { isEqual } from 'lodash';
/*
  Check if there is a UTF-8 Byte Order Mark (BOM) at the beginning of the file
  If it exists, remove and return the rest of the file's data
*/
export const removeBomFromArrayBuffer = (content: Uint8Array): Uint8Array => {
  const firstThreeBytes = content.slice(0, 3);
  const utf8Bom = new Uint8Array([239, 187, 191]);
  if (isEqual(firstThreeBytes, utf8Bom)) {
    return content.slice(3);
  }
  return content;
};

/*
  converts a File object to a Data URL including a base64 encoded string
  resulting string will be in this format:
  data:text/csv;base64,Q29sMSxjb2wyCmRhdGExLGRhdGEy
*/
export const convertFileToDataURL = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      let binary = '';
      const processedFile: Uint8Array = removeBomFromArrayBuffer(new Uint8Array(reader.result as ArrayBuffer));
      const len = processedFile.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(processedFile[i]);
      }
      const dataURL = `data:${file.type};base64,${btoa(binary)}`;
      resolve(dataURL);
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
};
