import React from 'react';
import ToggleDirections from '../../../../../common/ToggleDirections';
import { isInSchoolAPHybridEvent } from '../../../../../../../utils/event';
import { useStateValue } from '../../../../../../../context/AppContext';

function MonitorStudentDirectionsAP() {
  const { orgEvent } = useStateValue();
  return (
    <ToggleDirections>
      <div className='cb-white-bg'>
        <h3 className='tdtk-h3 how-to-Monitor-Testing'>How to Monitor Testing</h3>
        <div className='row px-0 mx-0 py-3 cb-border-top' role='region' aria-label='directions'>
          <div className='col-md-4 cb-margin-sm-down-bottom-16'>
            <h4 className='cb-paragraph6-st'>Reminders</h4>
            <ul className='pl-4 ml-2'>
              <li>Walk around every 15 minutes.</li>
              <li>Make sure laptops are open and tablets are uncovered.</li>
              <li>Make sure students resume testing after breaks.</li>
              <li>Watch for raised hands.</li>
              <li>Minimize distractions.</li>
            </ul>
          </div>
          <div className='col-md-4 cb-margin-sm-down-bottom-16'>
            <h4 className='cb-paragraph6-st'>Tips</h4>
            <ul className='pl-4 ml-2'>
              {isInSchoolAPHybridEvent(orgEvent) ? (
                <>
                  <li>
                    A student&rsquo;s status could be inaccurate if they&rsquo;re testing offline and can&rsquo;t send
                    updates.
                  </li>
                  <li>No action is required if students are testing smoothly.</li>
                  <li>
                    If you see a dark blue <strong>Congratulations</strong> screen on a student&rsquo;s device,
                    they&rsquo;ve run out of time for Section II, and their booklet should be closed.
                  </li>
                </>
              ) : (
                <>
                  <li>
                    A student&rsquo;s status could be inaccurate if they&rsquo;re testing offline and can&rsquo;t send
                    updates.
                  </li>
                  <li>No action is required if students are testing smoothly.</li>
                  <li>
                    When students complete Section II, their answers should be submitted automatically, and you&rsquo;ll
                    see a dark blue <strong>Congratulations</strong> screen on their device.
                  </li>
                </>
              )}
            </ul>
          </div>
          <div className='col-md-4'>
            <h4 className='cb-paragraph6-st'>Digital Security Violations</h4>
            <ul className='pl-4 ml-2'>
              <li>Viewing nontest content or using other applications.</li>
              {isInSchoolAPHybridEvent(orgEvent) ? (
                <li>Taking screenshots or removing test content another way.</li>
              ) : (
                <li>Taking screenshots.</li>
              )}
              <li>Working on another student&rsquo;s device.</li>
              <li>Angling a device so another student can see it.</li>
              {isInSchoolAPHybridEvent(orgEvent) && <li>Writing in their booklet after their time is up.</li>}
            </ul>
          </div>
        </div>
      </div>
    </ToggleDirections>
  );
}

export default MonitorStudentDirectionsAP;
