import React from 'react';
import { Link } from 'react-router-dom';
import WizardNav from '../../../common/WizardNav';
import StepContentPanel from '../../../common/StepContentPanel';
import HelpNav from '../../../common/HelpNav';
import '../../../common/common.scss';
import { createCleanMarkup } from '../../../../../utils/common';
import { CmsContent, FlowData, Room } from '../../../common/proctorStepsTypes';
import { isInSchoolAPHybridEvent } from '../../../../../utils/event';
import { useStateValue } from '../../../../../context/AppContext';

interface GetStepDefinitionsProps {
  cmsContent: CmsContent;
  stepNum: number;
  isInSchoolAPHybridEvent: boolean;
}

function GetStepDefinitions({ cmsContent, stepNum, isInSchoolAPHybridEvent }: GetStepDefinitionsProps) {
  const progressStepperMax = isInSchoolAPHybridEvent ? 3 : 2;
  const stepContent = cmsContent?.steps?.find((n) => n.step_number === stepNum);
  const mainPanelData = <div dangerouslySetInnerHTML={createCleanMarkup(stepContent?.main_panel_content)} />;
  const detailPanelData = <div dangerouslySetInnerHTML={createCleanMarkup(stepContent?.detail_panel_content)} />;

  const content = {
    main_panel_content: mainPanelData,
    main_panel_header: stepContent?.main_panel_header,
    detail_panel_content: detailPanelData,
    detail_panel_header: stepContent?.detail_panel_header,
  };

  const stepComponents = {
    verifyAndDismiss: (
      <StepContentPanel
        showProgressBar={true}
        stepNum={stepNum}
        progressStepperMax={progressStepperMax}
        heading='Verify Answer Submission'
        content={content}
        desc={mainPanelData}
        img={<div className='test-complete-step-1-svg step-illustration-img mt-4' />}
        imgDesc='Complete steps 1-3 before you dismiss students.'
      ></StepContentPanel>
    ),
    collectMaterials: (
      <StepContentPanel
        showProgressBar={true}
        stepNum={stepNum}
        progressStepperMax={progressStepperMax}
        heading='Collect Materials and Dismiss'
        content={content}
        desc={mainPanelData}
        img={<div className='setup-step-4-svg step-illustration-img mt-4' />}
        imgDesc='Report problems to your coordinator'
      ></StepContentPanel>
    ),
    reportProblems: (
      <StepContentPanel
        showProgressBar={true}
        stepNum={stepNum}
        progressStepperMax={progressStepperMax}
        heading='Report Problems'
        content={content}
        desc={mainPanelData}
        img={<div className='test-complete-step-2-svg step-illustration-img mt-4' />}
        imgDesc='Report problems to your coordinator'
      ></StepContentPanel>
    ),
  };

  //flow does this
  if (isInSchoolAPHybridEvent) {
    return {
      introStep: 1,
      outerStep: 4,
      steps: () => {
        switch (stepNum) {
          case 1:
            return stepComponents.verifyAndDismiss;
          case 2:
            return stepComponents.collectMaterials;
          case 3:
            return stepComponents.reportProblems;
        }
      },
    };
  } else {
    return {
      introStep: 1,
      outerStep: 3,
      steps: () => {
        switch (stepNum) {
          case 1:
            return stepComponents.verifyAndDismiss;
          case 2:
            return stepComponents.reportProblems;
        }
      },
    };
  }
}
interface TestCompletionProps {
  flowData: FlowData;
  room: Room;
  cmsContent: CmsContent;
}

function TestCompletion({ flowData, room, cmsContent }: TestCompletionProps) {
  const { stepNum } = flowData;
  const { orgEvent } = useStateValue();

  const { steps, introStep, outerStep } = GetStepDefinitions({
    cmsContent,
    stepNum,
    isInSchoolAPHybridEvent: isInSchoolAPHybridEvent(orgEvent),
  });

  function EndTest() {
    const endTestContent = cmsContent?.steps?.find((n) => n.step_number === outerStep);
    const endTestText = <div dangerouslySetInnerHTML={createCleanMarkup(endTestContent?.main_panel_content)} />;
    const detailPanelData = <div dangerouslySetInnerHTML={createCleanMarkup(endTestContent?.detail_panel_content)} />;
    const content = { main_panel_content: endTestText, detail_panel_content: detailPanelData };
    return (
      <div>
        <StepContentPanel
          room={room}
          invertStyle={true}
          heading='Thank You!'
          showStepCompletePanel={true}
          content={content}
          desc={endTestText}
          img={<div className='thankYou-svg step-illustration-img mt-4' />}
          imgDesc='Let your coordinator know your room finished testing.'
        ></StepContentPanel>
      </div>
    );
  }

  function navigation() {
    const prevButton =
      stepNum === introStep ? (
        <Link className='cb-btn cb-btn-sm cb-btn-light' to={`/rooms/get/${room.id}/flow/monitor/step/1`}>
          Back
        </Link>
      ) : stepNum > introStep ? (
        <Link
          className='cb-btn cb-btn-sm cb-btn-light'
          to={`/rooms/get/${room.id}/flow/completion/step/${stepNum - 1}`}
        >
          Back
        </Link>
      ) : (
        <></>
      );

    const nextButton =
      stepNum !== outerStep ? (
        <Link
          className='cb-btn cb-btn-sm cb-btn-yellow ml-auto'
          data-automation='nav-nextStep-Link'
          to={`/rooms/get/${room.id}/flow/completion/step/${stepNum + 1}`}
        >
          {stepNum >= introStep && stepNum < outerStep && 'Next Step'}
        </Link>
      ) : (
        <></>
      );

    return <WizardNav prevButton={prevButton} nextButton={nextButton}></WizardNav>;
  }

  return (
    <>
      {stepNum === outerStep && EndTest()}
      {stepNum >= introStep && stepNum < outerStep && steps()}
      <HelpNav />
      {navigation()}
    </>
  );
}

export default TestCompletion;
