import React, { useEffect } from 'react';
import { createCleanMarkup } from '../../../utils/common';
import { AnchorMenu } from '@cb/apricot-react';

interface DynamicAnchorMenuProps {
  cmsContent?: string | null;
  label?: string;
  id?: string;
}

export default function DynamicAnchorMenu({
  cmsContent = '',
  label = 'On This Page',
  id = 'dynamic-anchor',
}: DynamicAnchorMenuProps) {
  const [anchorMenuItems, setAnchorMenuItems] = React.useState('');

  useEffect(() => {
    if (cmsContent) {
      setAnchorMenuItems(
        Array.from(createCleanMarkup(cmsContent).__html.matchAll(/<h2 id=[^<]*/g), (m) => m[0]).map((h) => {
          return {
            label: h.split('>')[1],
            bookmark: h.match(/(id=")([^"]*)/)[2],
          };
        })
      );
    }
  }, [cmsContent]);

  // Reactively return a menu if and only if we have something to show,
  // otherwise render null.

  if (anchorMenuItems?.length) {
    return (
      <AnchorMenu
        anchorId={id}
        anchorMenuId={`menu-${id}`}
        anchorLabel={label}
        offsetTop={120}
        items={anchorMenuItems}
        close={true}
      />
    );
  }

  return null;
}
