import React from 'react';
import PropTypes from 'prop-types';
import Accordion from '../../../ui/accordion/AccordionWrapper';
import AccordionItem from '../../../ui/accordion/AccordionItemWrapper';
import IrregularityForm from './IrregularityForm';
import QueryComponent from '../../network/QueryComponent';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import { GET_ROOMS_AND_STUDENTS, GET_SIR } from '../../../../apollo/queries';
import { YellowButton } from '@cb/apricot-react';
import { createCleanMarkup, scrollTo } from '../../../../utils/common';
import { generateStatus } from './utility';
import { useParams } from 'react-router-dom';
import { useStateValue } from '../../../../context/AppContext';
import { useTitle } from '../../../../constants/useTitle';

function Container({ irregularity = {}, irregularityCategory = {} }) {
  function enableForm() {
    setFormEnabled(true);

    // Scroll to the form.
    scrollTo('top-of-ir-form');
  }

  function generateAccordions(instructions = []) {
    // We should be getting an array of instructions.
    const accordionItems = [];
    let i = 0;

    // Sort the IR Instructions by weight ascending.
    instructions = sortBy(instructions, 'weight', 'asc');

    instructions.forEach((instruction) => {
      const body = instruction.body
        ? [<div key={instruction.id + '_body'} dangerouslySetInnerHTML={createCleanMarkup(instruction.body)} />]
        : ['-'];
      const glyphIcon = instruction.glyphicon ? instruction.glyphicon : null;
      const key = instruction.id ? instruction.id : `ir_instructions_${++i}`;
      const showExpanded = instruction.showExpanded ? instruction.showExpanded : false;
      const title = instruction.title ? (
        <span key={instruction.id + '_title'} dangerouslySetInnerHTML={createCleanMarkup(instruction.title)} />
      ) : (
        <span>-</span>
      );

      accordionItems.push({
        body,
        glyphIcon,
        key,
        showExpanded,
        title,
      });
    });

    return (
      <div className='shadow cb-white-bg pt-3 mb-4'>
        <Accordion controls={true} className='accord-add-ir-form'>
          {accordionItems.map((panel) => (
            <AccordionItem key={panel.key || panel.title} title={panel.title} glyphIcon={panel.glyphIcon}>
              {panel.body}
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    );
  }

  const { id } = useParams();

  const input = { id };

  // Global App state.
  const { orgEvent } = useStateValue();

  // Local state.
  const [formEnabled, setFormEnabled] = React.useState(
    !(irregularity.instructions && irregularity.instructions.length)
  );
  useTitle('Edit Irregularity');

  return (
    <>
      <QueryComponent query={{ kind: 'GetRoomsAndStudents', specification: GET_ROOMS_AND_STUDENTS }}>
        {(data) => {
          const eventRooms = get(data, 'viewer.site.rooms');
          const eventStudents = get(data, 'viewer.site.students');

          if (eventRooms && eventStudents) {
            if (id) {
              // They are trying to edit an existing form, so query that form.
              return (
                <div className={orgEvent?.dapInd ? 'container' : ''}>
                  <QueryComponent query={{ kind: 'GetSir', specification: GET_SIR, variables: { input } }}>
                    {(data) => {
                      const irData = get(data, 'getSir');

                      if (irData) {
                        const {
                          id = -1,
                          irregCreatedBy = {},
                          entireTestCenter = '',
                          irregularityCategory = {},
                          reportId = '',
                          rooms = [],
                          statusText = 'drafted',
                          students = [],
                          userResponse = {},
                        } = irData;

                        const categoryIrregularity = get(irregularityCategory, 'irregularity', null);
                        const irregularityInstructions = get(categoryIrregularity, 'instructions', null);

                        // Return the form they can edit.
                        return categoryIrregularity ? (
                          <React.Fragment>
                            <h1 className='tdtk-h1 py-4' data-automation='heading-h1'>
                              Edit Irregularity
                            </h1>
                            <h2 className='tdtk-h2' data-automation='heading-h2'>
                              {categoryIrregularity.label}
                            </h2>

                            <ul style={{ listStyleType: 'none', padding: 0 }}>
                              <li>
                                Reported by:{' '}
                                <b>{`${irregCreatedBy.lastName ? irregCreatedBy.lastName + ', ' : ''}${
                                  irregCreatedBy.firstName
                                }`}</b>
                              </li>
                              <li>
                                Report ID: <b className='b__code'>{reportId}</b>
                              </li>
                              <li>
                                Status: <b>{generateStatus(statusText)}</b>
                              </li>
                            </ul>

                            {irregularityInstructions && irregularityInstructions.length ? (
                              <div className='row'>
                                <div className='col-xs-12'>{generateAccordions(irregularityInstructions)}</div>
                              </div>
                            ) : null}

                            <IrregularityForm
                              entireTestCenter={entireTestCenter}
                              eventRooms={eventRooms}
                              eventStudents={eventStudents?.filter((student) => !!student)}
                              id={id}
                              irregularity={categoryIrregularity}
                              irregularityCategory={irregularityCategory}
                              readOnly={statusText === 'cb_submit'}
                              rooms={rooms}
                              students={students?.filter((student) => !!student)}
                              updating={true}
                              userResponse={userResponse}
                            />
                          </React.Fragment>
                        ) : (
                          <p>Category not found.</p>
                        );
                      } else {
                        return <p>Irregularity not found.</p>;
                      }
                    }}
                  </QueryComponent>
                </div>
              );
            } else if (irregularity && irregularityCategory) {
              // They are adding a new, blank form.
              return (
                <React.Fragment>
                  <h3 className='tdtk-h3 mb-4' data-automation='heading-h3'>
                    {irregularity.label}
                  </h3>
                  {irregularity.instructions && irregularity.instructions.length ? (
                    <div className='row'>
                      <div className='col-xs-12'>
                        {generateAccordions(irregularity.instructions)}
                        {
                          // Add a "footer" section that allows the user to use the form.
                          <div
                            className='tdtk-form-group tdtk-primary-secondary display-flex--column-mobile'
                            key='accordion-ir-footer'
                          >
                            <a className='cb-btn cb-btn-sm cb-btn-black' href='/irregularities/add'>
                              Change Irregularity
                            </a>

                            <YellowButton
                              small
                              data-automation='button-accordion-ir-primary'
                              onClick={enableForm}
                              disabled={formEnabled}
                            >
                              Complete the Form
                            </YellowButton>
                          </div>
                        }
                      </div>
                    </div>
                  ) : (
                    <div className='cb-float-right'>
                      <p className='text-right mb-4'>
                        <a href='/irregularities/add'>Choose a different irregularity</a>
                      </p>
                    </div>
                  )}
                  <div
                    style={
                      !formEnabled
                        ? {
                            opacity: '0.5',
                          }
                        : {}
                    }
                  >
                    <IrregularityForm
                      eventRooms={eventRooms}
                      eventStudents={eventStudents?.filter((student) => !!student)}
                      irregularity={irregularity}
                      irregularityCategory={irregularityCategory}
                      readOnly={!formEnabled}
                    />
                  </div>
                </React.Fragment>
              );
            } else {
              return (
                <p>
                  No valid irregularity found. Please <a href='/irregularities/add'>go back and try again</a>.
                </p>
              );
            }
          } else {
            return <p>No rooms or students found for this event.</p>;
          }
        }}
      </QueryComponent>
    </>
  );
}

Container.propTypes = {
  irregularity: PropTypes.object,
  irregularityCategory: PropTypes.object,
};

export default Container;
