import { EVENT_TYPES } from '../constants/event';
import {
  digitalWeekendGroupTypes,
  digitalInSchoolSatGroupTypes,
  digitalInSchoolPsatGroupTypes,
  IDigitalGroupTypes,
} from '../constants/digitalGroupTypes';
import { IGroupTypes, groupTypes } from '../constants/groupTypes';

export function getGroupTypes(orgEvent: { dapInd: boolean; tdtkAsmtType: string }): IDigitalGroupTypes | IGroupTypes {
  if (!orgEvent?.dapInd) {
    return groupTypes;
  }

  switch (orgEvent?.tdtkAsmtType) {
    case EVENT_TYPES.WEEKEND_SAT:
    case EVENT_TYPES.WEEKENDATSCHOOL_SAT:
      return digitalWeekendGroupTypes;
    case EVENT_TYPES.INSCHOOL_SAT:
      return digitalInSchoolSatGroupTypes;
    case EVENT_TYPES.INSCHOOL_PSAT89:
    case EVENT_TYPES.INSCHOOL_PSAT10:
    case EVENT_TYPES.INSCHOOL_PSATNM:
      return digitalInSchoolPsatGroupTypes;
    case EVENT_TYPES.INSCHOOL_AP:
    case EVENT_TYPES.INSCHOOL_APHYBRID:
      return groupTypes;
    default:
      return groupTypes;
  }
}

export function groupTypesCheck(groupTypes, standardCheck = { check: false, room: {} }) {
  if (standardCheck?.check === true) {
    if (groupTypes[standardCheck?.room?.groupTypes]) {
      return groupTypes[standardCheck?.room?.groupTypes[0]]?.standard ? 'standard' : 'accommodated';
    } else {
      return '';
    }
  }
  const groupTypesCheck = Object.keys(groupTypes).map((key) => {
    if (groupTypes[key]) {
      return {
        label: `${key}: ${groupTypes[key]?.title || ''}${groupTypes[key]?.label || ''}`,
        value: key,
      };
    } else {
      return {
        label: `${key}`,
        value: key,
      };
    }
  });
  return groupTypesCheck;
}
