import React from 'react';
import { DropDownDrawerButton } from '../components/ui/button/DropDownDrawerButton';
import { CreateRoomRoster } from '../components/digital/weekend/rooms/roster/CreateRoomRoster';
import { ImportRoomRoster } from '../components/digital/weekend/rooms/roster/ImportRoomRoster';

export default {
  need_rooms: {
    ctaLink: '/rooms#add',
    ctaText: 'Add Rooms',
    ctaComponent: (
      <DropDownDrawerButton
        items={[
          {
            name: 'Create Room',
            component: <CreateRoomRoster footerButtonLabel='Create Rooms' />,
            promptUnsavedDrawer: true,
          },
          { name: 'Import Rooms', component: <ImportRoomRoster></ImportRoomRoster>, promptUnsavedDrawer: true },
        ]}
        label={'Add Rooms'}
        name={'cta-add-rooms'}
      />
    ),
    description: {
      initial: <p className='mb-0'>You need more rooms to seat all registered students on your roster.</p>,
      updated: (
        <p className='mb-0'>
          <b>Update needed:</b> You no longer have enough rooms to seat registered students.
        </p>
      ),
    },
    stepper: 1,
  },
  auto_assign: {
    ctaLink: '',
    ctaText: 'Auto-Setup',
    description: {
      initial: (
        <p className='mb-0'>
          Registered students on your roster need seats. Click <b>Auto-Setup</b> and we&rsquo;ll move them into rooms.
        </p>
      ),
      updated: (
        <p className='mb-0'>
          <b>Update needed:</b> Registered students need to be seated or moved. Click <b>Auto-Setup</b> and we&rsquo;ll
          get the job done.
        </p>
      ),
    },
    stepper: 2,
  },
  need_proctors: {
    ctaLink: '/staff#add',
    ctaText: 'Add Staff',
    description: {
      initial: <p className='mb-0'>You don&rsquo;t have enough proctors in this administration.</p>,
      updated: <p className='mb-0'>You don&rsquo;t have enough proctors in this administration.</p>,
    },
    stepper: 3,
  },
  assign_proctors: {
    ctaLink: '/rooms',
    ctaText: 'Assign Staff',
    description: {
      initial: <p className='mb-0'>You have one or more rooms that need a proctor.</p>,
      updated: <p className='mb-0'>You have one or more rooms that need a proctor.</p>,
    },
    stepper: 4,
  },
  none: {
    ctaLink: '',
    ctaText: '',
    description: {
      initial: (
        <p className='mb-0'>
          Check back later. You&rsquo;re all set for now, but your roster may change and updates might be needed.
        </p>
      ),
      updated: (
        <p className='mb-0'>
          Check back later. You&rsquo;re all set for now, but your roster may change and updates might be needed.
        </p>
      ),
    },
    stepper: 5,
  },
  calculation_failure: {
    ctaLink: '',
    ctaText: '',
    description: {
      initial: <p className='mb-0'>You&rsquo;re all set for now.</p>,
      updated: <p className='mb-0'>You&rsquo;re all set for now.</p>,
    },
    stepper: 5,
  },
};
