import React from 'react';
import PropTypes from 'prop-types';
import CenterCheckinButton from './buttons/CenterCheckinButton';
import ChangeRoomButton from './buttons/ChangeRoomButton';
import DenyEntryButton from './buttons/DenyEntryButton';
import MarkAbsentButton from './buttons/MarkAbsentButton';
import RemoveFromRoomButton from './buttons/RemoveFromRoomButton';
import RoomCheckinButton from './buttons/RoomCheckinButton';

function StudentDetailsButtons({ student = {} }) {
  return (
    <>
      {/* these two buttons appear side by side in the mobile view */}
      <div className='row' style={{ alignItems: 'baseline' }}>
        <div className='col-xs-6 col-sm-6 col-md-12'>
          <CenterCheckinButton student={student} />
        </div>
        <div className='col-xs-6 col-sm-6 col-md-12'>
          <span data-tour='check-in'>
            <RoomCheckinButton student={student} />
          </span>
        </div>
      </div>

      <ChangeRoomButton student={student} />

      <RemoveFromRoomButton student={student} />

      <DenyEntryButton student={student} />

      <MarkAbsentButton student={student} />
    </>
  );
}

StudentDetailsButtons.propTypes = {
  detail: PropTypes.object,
  student: PropTypes.shape({
    absent: PropTypes.bool.isRequired,
    candFirstName: PropTypes.string,
    candLastName: PropTypes.string,
    candMidInit: PropTypes.string,
    checkedInCenter: PropTypes.bool.isRequired,
    checkedInRoom: PropTypes.bool.isRequired,
    deniedEntry: PropTypes.bool.isRequired,
    groupType: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    room: PropTypes.object,
  }),
  updateStudent: PropTypes.func,
};

export default StudentDetailsButtons;
