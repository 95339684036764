import React, { useEffect } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useStateValue } from '../../../../../../context/AppContext';
import { createCleanMarkup } from '../../../../../../utils/common';
import {
  isInSchoolAPEvent,
  isInSchoolAPEvents,
  isInSchoolAPHybridEvent,
  isInSchoolPSATEvent,
  isInSchoolSATEvent,
} from '../../../../../../utils/event';
import { hasAdminAccess } from '../../../../../../utils/user';
import ErrorPage from '../../../../../nonDigital/error/ErrorPage';
import '../../../../common/common.scss';
import HelpNav from '../../../../common/HelpNav';
import { CmsContent, FlowData, Room } from '../../../../common/proctorStepsTypes';
import RoomCode from '../../../../common/RoomCode';
import Staff from '../../../../common/rooms/details/Setup/Staff';
import StepContentPanel from '../../../../common/StepContentPanel';
import StepContentTab from '../../../../common/StepContentTab';
import ToggleCoordinatorTools from '../../../../common/ToggleCoordinatorTools';
import WizardNav from '../../../../common/WizardNav';
import { getStudentStatuses } from '../../../../common/utility';
import './Setup.scss';

interface GetStepDefinitionsProps {
  cmsContent: CmsContent;
  stepNum: number;
  isInSchoolAPEvents: boolean;
  isInSchoolPSATEvent: boolean;
  isInSchoolSATEvent: boolean;
  isInSchoolAPEvent: boolean;
  isInSchoolAPHybridEvent: boolean;
  room: Room;
}

function GetStepDefinitions({
  cmsContent,
  stepNum,
  isInSchoolAPEvents,
  isInSchoolPSATEvent,
  isInSchoolAPEvent,
  isInSchoolAPHybridEvent,
  isInSchoolSATEvent,
  room,
}: GetStepDefinitionsProps) {
  const progressStepperMax = 6;

  const stepContent = cmsContent?.steps?.find((n) => n.step_number === stepNum);
  const mainPanelData = <div dangerouslySetInnerHTML={createCleanMarkup(stepContent?.main_panel_content)} />;
  const detailPanelData = <div dangerouslySetInnerHTML={createCleanMarkup(stepContent?.detail_panel_content)} />;

  const content = {
    main_panel_content: mainPanelData,
    main_panel_header: stepContent?.main_panel_header,
    detail_panel_content: detailPanelData,
    detail_panel_header: stepContent?.detail_panel_header,
  };

  const stepComponents = {
    aboutAPExam: (
      <StepContentPanel
        room={room}
        showProgressBar={true}
        stepNum={stepNum}
        progressStepperMax={progressStepperMax}
        heading='About Digital AP Exams'
        content={content}
        desc={<div>{mainPanelData}</div>}
        //TODO: AOPS-57732: check image and description for step #1
        img={
          <div
            className={`${
              isInSchoolAPHybridEvent
                ? 'hybrid-digital-svg'
                : isInSchoolAPEvent
                ? 'fully-digital-svg'
                : 'setup-step-1-svg'
            } step-illustration-img mt-4`}
          />
        }
        imgDesc={`Your room should have ${room?.capacity} seats.`}
      ></StepContentPanel>
    ),
    countSeats: (
      <StepContentPanel
        room={room}
        showProgressBar={true}
        stepNum={stepNum}
        progressStepperMax={progressStepperMax}
        heading='Count Seats'
        content={content}
        desc={<div>{mainPanelData}</div>}
        //TODO: AOPS-57732: check image and description for step #2
        img={<div className='wknd-sat-setup-step-2-svg step-illustration-img mt-4' />}
        imgDesc={`Your room should have ${room?.capacity} seats.`}
      ></StepContentPanel>
    ),
    checkSpacing: (
      <StepContentPanel
        room={room}
        showProgressBar={true}
        stepNum={stepNum}
        progressStepperMax={progressStepperMax}
        heading='Check Spacing'
        content={content}
        desc={<div>{mainPanelData}</div>}
        img={
          <div
            className={`${
              isInSchoolPSATEvent ? 'psat-setup-step-2-svg' : 'setup-step-2-svg'
            } step-illustration-img mt-4`}
          />
        }
        imgDesc='Students must be separated by at least 3 feet on the right and left.'
      ></StepContentPanel>
    ),
    checkSupplies: (
      <StepContentPanel
        room={room}
        showProgressBar={true}
        stepNum={stepNum}
        progressStepperMax={progressStepperMax}
        heading='Check Supplies'
        content={content}
        desc={<div>{mainPanelData}</div>}
        img={
          <div
            className={`${
              isInSchoolPSATEvent
                ? 'psat-setup-step-3-svg'
                : isInSchoolAPEvents
                ? 'ap-setup-step-3-svg'
                : 'setup-step-3-svg'
            } step-illustration-img mt-4`}
          />
        }
        imgDesc='No maps, charts or other teaching materials should be visible.'
      ></StepContentPanel>
    ),
    checkMaterials: (
      <StepContentPanel
        room={room}
        showProgressBar={true}
        stepNum={stepNum}
        progressStepperMax={progressStepperMax}
        heading='Check Test Materials and Support Staff'
        content={content}
        desc={<div>{mainPanelData}</div>}
        img={<div className='setup-step-4-svg step-illustration-img mt-4' />}
        imgDesc='Place 3 sheets of scratch paper on each desk.'
      ></StepContentPanel>
    ),
    checkWifi: (
      <StepContentPanel
        room={room}
        showProgressBar={true}
        stepNum={stepNum}
        progressStepperMax={progressStepperMax}
        heading='Test the Wi-Fi'
        content={content}
        desc={<div>{mainPanelData}</div>}
        img={
          <div
            className={`${
              isInSchoolAPHybridEvent ? 'ap-setup-step-3-svg' : isInSchoolAPEvent ? 'post-it-svg' : 'setup-step-4-svg'
            } step-illustration-img mt-4`}
          />
        }
        imgDesc='Place 3 sheets of scratch paper on each desk.'
      ></StepContentPanel>
    ),
    distributeSignInTickets: (
      <StepContentPanel
        room={room}
        showProgressBar={true}
        stepNum={stepNum}
        progressStepperMax={progressStepperMax}
        heading='Distribute Sign-In Tickets'
        content={content}
        desc={<div>{mainPanelData}</div>}
        img={
          isInSchoolSATEvent ? (
            <div className='setup-step-5-inschool-sat-svg step-illustration-img mt-4' />
          ) : (
            <div
              className={`${
                isInSchoolPSATEvent ? 'psat-setup-step-5-svg' : 'setup-step-5-svg'
              } step-illustration-img mt-4`}
            />
          )
        }
        imgDesc='You should have a bin, labels, and bags or envelopes for prohibited devices.'
      ></StepContentPanel>
    ),
    writeInstructions: (
      <StepContentTab
        showProgressBar={true}
        stepNum={stepNum}
        progressStepperMax={progressStepperMax}
        heading='Write Instructions on the Board'
        invertStyle={true}
        subHeading={<div className='wizard-subHeading'>{content.detail_panel_content}</div>}
        panelHeading='Write these instructions on the board (add the Wi-Fi password if you need to):'
        content={content}
        desc={
          <div className='row row-eq-height'>
            <div className='col-xs-12 col-md-6 py-3'>
              <div>{mainPanelData}</div>
            </div>
            <div className='col-xs-12 col-md-6 wizard-room-code-wrapper py-3'>
              <div style={{ fontSize: '1.5rem', lineHeight: '1.75rem' }}>Room Code:</div>
              <div style={{ fontSize: '3.75rem', lineHeight: '4.375rem' }} className='mt-3'>
                <RoomCode code={room?.joinCode} />
              </div>
            </div>
          </div>
        }
      ></StepContentTab>
    ),
    collectProhibitedItems: (
      <StepContentPanel
        showProgressBar={true}
        stepNum={stepNum}
        progressStepperMax={progressStepperMax}
        heading='Prepare to Collect Prohibited Items'
        panelHeading='Write these instructions on the board (add the Wi-Fi password if you need to):'
        content={content}
        desc={<div>{mainPanelData}</div>}
        //TODO: AOPS-57732: check image and description for step #6
        img={<div className='setup-step-5-svg step-illustration-img mt-4' />}
        imgDesc={`Your room should have ${room?.capacity} seats.`}
      ></StepContentPanel>
    ),
  };

  //flow does this
  if (isInSchoolAPEvents) {
    return {
      introStep: 0,
      outerStep: 7,
      steps: () => {
        switch (stepNum) {
          case 1:
            return stepComponents.aboutAPExam;
          case 2:
            return stepComponents.countSeats;
          case 3:
            return stepComponents.checkSupplies;
          case 4:
            //TODO:: AOPS-57732: need split for regAP (checkWifi) and APHybrid(materials) ?
            return stepComponents.checkWifi;
          case 5:
            return stepComponents.writeInstructions;
          case 6:
            return stepComponents.collectProhibitedItems;
        }
      },
    };
  } else {
    return {
      introStep: 0,
      outerStep: 7,
      steps: () => {
        switch (stepNum) {
          case 1:
            return stepComponents.countSeats;
          case 2:
            return stepComponents.checkSpacing;
          case 3:
            return stepComponents.checkSupplies;
          case 4:
            return stepComponents.checkMaterials;
          case 5:
            return stepComponents.distributeSignInTickets;
          case 6:
            return stepComponents.writeInstructions;
        }
      },
    };
  }
}

interface SetupProps {
  flowData: FlowData;
  room: Room;
  cmsContent: CmsContent;
}

function Setup({ flowData, room, cmsContent }: SetupProps) {
  // Global App state
  const { orgEvent, user } = useStateValue();

  // Local vars
  const { stepNum } = flowData;
  const studentStatuses = getStudentStatuses(room?.students);

  const { steps, introStep, outerStep } = GetStepDefinitions({
    cmsContent,
    stepNum,
    room,
    isInSchoolAPEvents: isInSchoolAPEvents(orgEvent),
    isInSchoolPSATEvent: isInSchoolPSATEvent(orgEvent),
    isInSchoolSATEvent: isInSchoolSATEvent(orgEvent),
    isInSchoolAPEvent: isInSchoolAPEvent(orgEvent),
    isInSchoolAPHybridEvent: isInSchoolAPHybridEvent(orgEvent),
  });

  useEffect(() => {
    // Load room capacity dynamically
    const roomCapacity = document.querySelector('.setup-step-1-seatCounts');

    if (roomCapacity) {
      (roomCapacity as HTMLElement).innerText = room?.capacity.toString();
    }
  });

  // intro/welcome step
  function Welcome() {
    const { orgEvent } = useStateValue();

    function checkEventStartEndDate() {
      if (orgEvent.eventStartDt === orgEvent.eventEndDt) {
        return (
          <div className='mt-4'>
            <strong>
              {moment(orgEvent.eventStartDt || undefined)
                .local()
                .format('LL')}
            </strong>
          </div>
        );
      }
    }

    if (!cmsContent) {
      return (
        <div className='container'>
          <ErrorPage errorCode='cms_missing' />
        </div>
      );
    }
    const welcomeContent = cmsContent?.steps?.find((n) => n.step_number === 0);
    const welcomeText = <div dangerouslySetInnerHTML={createCleanMarkup(welcomeContent?.main_panel_content)} />;
    const content = { main_panel_content: welcomeText, main_panel_header: welcomeContent?.main_panel_header };

    let lastAttendanceStepNum = 9;
    if (isInSchoolAPEvent(orgEvent)) {
      lastAttendanceStepNum = 8;
    } else if (isInSchoolAPHybridEvent(orgEvent)) {
      lastAttendanceStepNum = 11;
    }
    return (
      <div>
        {hasAdminAccess(user.rid, user.role) ? (
          <ToggleCoordinatorTools
            options={[
              { 'path': `/rooms/edit/${room?.id}`, 'data-automation': 'coord-link-edit-room', 'label': 'Edit Room' },
              {
                'path': `/rooms/get/${room?.id}/flow/attendance/step/1`,
                'data-automation': 'coord-link-take-attend',
                'label': 'Take Attendance',
              },
              {
                'path': `/rooms/get/${room?.id}/flow/attendance/step/${lastAttendanceStepNum}`,
                'data-automation': 'coord-link-start-test',
                'label': 'Start Test',
              },
              {
                'path': `/rooms/get/${room?.id}/flow/monitor/step/1`,
                'data-automation': 'coord-link-monitor-test',
                'label': 'Monitor Testing',
              },
            ]}
          />
        ) : null}
        <StepContentPanel
          heading='Welcome'
          content={content}
          desc={
            <>
              <div>{welcomeText}</div>
              <div className='wizard-panel-getStarted-Link mt-4'>
                <Link
                  data-automation='coord-getStarted-Link'
                  className='cb-palette-blue5'
                  to={`/rooms/get/${room?.id}/flow/setup/step/1`}
                >
                  Get Started
                </Link>
              </div>
            </>
          }
          room={room}
        >
          <div className='text-center'>
            <div style={{ fontSize: '32px', lineHeight: '40px' }} role='heading' aria-level={2}>
              {room?.title}
            </div>
            <div style={{ fontSize: '26px', lineHeight: '32px' }} className='mt-2'>
              {orgEvent.eventTitle}
            </div>
            {checkEventStartEndDate()}
          </div>
          <hr />
          <div style={{ lineHeight: '35px' }}>
            <div>
              {' '}
              <span>
                <strong>Capacity:</strong>
              </span>{' '}
              <span>
                {room?.capacity} ({studentStatuses.preRosteredStudents.length} students assigned)
              </span>
            </div>
            <div>
              {' '}
              <span>
                <strong>Staff:</strong>
              </span>{' '}
              <Staff room={room} />
            </div>
          </div>
        </StepContentPanel>
      </div>
    );
  }

  function SetupComplete() {
    const setupCompleteContent = cmsContent?.steps?.find((n) => n.step_number === 7);
    const setupCompleteText = (
      <div dangerouslySetInnerHTML={createCleanMarkup(setupCompleteContent?.main_panel_content)} />
    );
    const detailPanelData = (
      <div dangerouslySetInnerHTML={createCleanMarkup(setupCompleteContent?.detail_panel_content)} />
    );
    const content = { main_panel_content: setupCompleteText, detail_panel_content: detailPanelData };
    return (
      <StepContentPanel
        heading='Setup Complete'
        room={room}
        showStepCompletePanel={true}
        showProgressBar={false}
        content={content}
        invertStyle={true}
        desc={setupCompleteText}
        img={<div className='setupComplete-svg step-illustration-img mt-4' />}
        imgDesc='You&rsquo;re ready to admit students'
      ></StepContentPanel>
    );
  }

  function navigation() {
    const prevButton =
      stepNum > introStep ? (
        <Link className='cb-btn cb-btn-sm cb-btn-light' to={`/rooms/get/${room?.id}/flow/setup/step/${stepNum - 1}`}>
          Back
        </Link>
      ) : (
        <></>
      );

    const initialAttendanceStep = isInSchoolAPEvents(orgEvent) ? 0 : 1;
    const nextButton =
      stepNum === outerStep ? (
        <Link
          className='cb-btn cb-btn-sm cb-btn-yellow ml-auto'
          data-automation='nav-nextStep-Link'
          to={`/rooms/get/${room?.id}/flow/attendance/step/${initialAttendanceStep}`}
        >
          Next Step
        </Link>
      ) : (
        <Link
          className='cb-btn cb-btn-sm cb-btn-yellow ml-auto'
          data-automation='nav-nextStep-Link'
          to={`/rooms/get/${room?.id}/flow/setup/step/${stepNum + 1}`}
        >
          {stepNum === introStep && 'Get Started'}
          {stepNum > introStep && stepNum < outerStep && 'Next Step'}
        </Link>
      );

    return <WizardNav prevButton={prevButton} nextButton={nextButton}></WizardNav>;
  }

  return (
    <>
      {stepNum === introStep && Welcome()}
      {stepNum === outerStep && SetupComplete()}
      {stepNum > introStep && stepNum < outerStep && steps()}
      <HelpNav />
      {navigation()}
    </>
  );
}

export default Setup;
