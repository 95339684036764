import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { ModalDispatchContext } from '../../../../../ui/modal/ModalContext';
import { RoomContext } from '../../../../common/context/RoomContext';
import { useStateValue } from '../../../../../../context/AppContext';
import HelpNav from '../../../../common/HelpNav';
import MonitorStudentDirectionsAP from './MonitorStudentDirections/MonitorStudentDirectionsAP';
import MonitorStudentDirectionsSAT from './MonitorStudentDirections/MonitorStudentDirectionsSAT';
import MonitorStudents from './MonitorStudents';
import SubmissionPendingModal from '../../../../common/modals/SubmissionPendingModal';
import WizardNav from '../../../../common/WizardNav';
import { isInSchoolAPEvents, isInSchoolAPEvent, isInSchoolAPHybridEvent } from '../../../../../../utils/event';

function MonitorStudentsContainer({ room, refetch = () => {} }) {
  const { orgEvent } = useStateValue();
  const { startedButNotSubmitted } = useContext(RoomContext);
  const dispatchModal = useContext(ModalDispatchContext);
  const navigate = useNavigate();
  const proceedToNextStep = () => {
    navigate(`/rooms/get/${room.id}/flow/completion/step/1`);
  };

  function navigation() {
    let lastAttendanceStepNum = 10;
    if (isInSchoolAPEvent(orgEvent)) {
      lastAttendanceStepNum = 9;
    } else if (isInSchoolAPHybridEvent(orgEvent)) {
      lastAttendanceStepNum = 12;
    }
    const prevButton = (
      <Link
        className='cb-btn cb-btn-sm cb-btn-light'
        to={`/rooms/get/${room.id}/flow/attendance/step/${lastAttendanceStepNum}`}
      >
        Back
      </Link>
    );

    const nextButton =
      startedButNotSubmitted > 0 ? (
        <button
          className='cb-btn cb-btn-sm cb-btn-yellow ml-auto'
          data-automation='nav-nextStep-Button'
          onClick={() => {
            dispatchModal(
              <SubmissionPendingModal
                proceedToNextStep={proceedToNextStep}
                startedButNotSubmitted={startedButNotSubmitted}
                refetch={refetch}
              />
            );
          }}
        >
          Next Step
        </button>
      ) : (
        <Link
          className='cb-btn cb-btn-sm cb-btn-yellow ml-auto'
          data-automation='nav-nextStep-Link'
          to={`/rooms/get/${room.id}/flow/completion/step/1`}
        >
          Next Step
        </Link>
      );

    return <WizardNav prevButton={prevButton} nextButton={nextButton}></WizardNav>;
  }

  return (
    <>
      <MonitorStudents
        refetch={refetch}
        room={room}
        toggleDirections={
          isInSchoolAPEvents(orgEvent) ? <MonitorStudentDirectionsAP /> : <MonitorStudentDirectionsSAT />
        }
      />
      <HelpNav />
      {navigation()}
    </>
  );
}

MonitorStudentsContainer.propTypes = {
  flowData: PropTypes.object,
  room: PropTypes.object,
  refetch: PropTypes.func,
  cmsContent: PropTypes.object,
};

export default MonitorStudentsContainer;
