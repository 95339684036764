import React, { useReducer, useEffect, useRef } from 'react';
import { AddStaffRow } from './AddStaffRow';
import { localStateReducer, setCheckedProp } from '../../../../../utils/common';
import RosterV3 from '../../../../common/roster/RosterV3';
import { ResizeContext } from '../../../../../context/ResizeContext';
import { AddStaffRowEditable } from './AddStaffRowEditable';
import { IStaffEditable } from '../type';

export enum DrawerTableComponent {
  addStaff = 'addStaff',
  bulkAddStaff = 'bulkAddStaff',
  inventoryStaff = 'inventoryStaff',
  fileUpload = 'fileUpload',
}

const searchDisabledComponent = ['addStaff', 'fileUpload'];

interface AddStaffProps {
  addUserHandler?: (ids: string[]) => void;
  deleteStaffHandler?: (staff: any) => void;
  drawerTableComponent: DrawerTableComponent;
  editable: boolean;
  showChecked?: boolean;
  editStaffChangeHandler?: (staff: any) => void;
  onBlur?: (staff: IStaffEditable, field: keyof IStaffEditable) => void;
  items: any[];
  pageOpts?: { pageNum: number };
  staffChangeHandler?: (staff: any) => void;
  formErrorsByStaffId?: { [key: string]: { firstName: string; lastName: string; email: string; phone: string } };
}

export const AddStaff = ({
  addUserHandler = () => {},
  deleteStaffHandler = () => {},
  drawerTableComponent,
  editable = false,
  showChecked,
  editStaffChangeHandler = () => {},
  items = [],
  onBlur = () => {},
  staffChangeHandler = () => {},
  pageOpts = { pageNum: 0 },
  formErrorsByStaffId = {},
}: AddStaffProps) => {
  const checkboxAllRef = React.useRef();
  const [checkedStaff, setCheckedStaff] = React.useState([]);

  const pageNumRef = useRef(pageOpts.pageNum);

  const [localState, setLocalState] = useReducer(localStateReducer, {
    filteredItems: items,
    initialItems: items,
    search: '',
  });

  const windowSize = React.useContext(ResizeContext);

  function updateSearch(e) {
    const search = e.target.value;
    const filteredItems = items.filter((item) =>
      Object.values(item).join('').toLowerCase().includes(search.toLowerCase())
    );
    setLocalState({ filteredItems, search });
    pageNumRef.current = 0;
  }

  useEffect(() => {
    if (checkedStaff.length === items.length && !(items.length === 0)) {
      setCheckedProp(checkboxAllRef, true);
    }
  }, [checkedStaff, items]);

  useEffect(() => {
    //this is triggered whenever addRow happens from the parent component.
    pageNumRef.current = pageOpts.pageNum;
  }, [pageOpts]);

  useEffect(() => {
    //if filter is currently applied, reapply to updated items
    const search = localState.search;
    let filteredItems = items;
    if (search) {
      filteredItems = items.filter((item) => Object.values(item).join('').toLowerCase().includes(search.toLowerCase()));
    }
    setLocalState({ filteredItems });
  }, [items]);

  const handleCheckStaff = React.useCallback(
    (staffIds = [], checkAll: boolean) => {
      let index;
      const updatedCheckedStaff = [...checkedStaff];

      staffIds.forEach((id) => {
        index = updatedCheckedStaff.indexOf(id);

        // Determine if we should un-check them based on a "check all" command.
        if (checkAll !== undefined) {
          // The ID exists, and they want to "un-check all".
          if (index > -1 && !checkAll) {
            updatedCheckedStaff.splice(index, 1);
          } else if (index === -1 && checkAll) {
            // They want to check-all and this ID is not in our array.
            updatedCheckedStaff.push(id);
          }
        } else {
          // They are probably just checking an individual item.
          if (index > -1) {
            // Un-check them since it's already in the array, and un-check the "check all" button.
            updatedCheckedStaff.splice(index, 1);
            setCheckedProp(checkboxAllRef, false);
          } else {
            // They are not checked, so check them.
            updatedCheckedStaff.push(id);
          }
        }
      });

      // Update our state to have all checked values accordingly.
      setCheckedStaff(updatedCheckedStaff);
      addUserHandler(updatedCheckedStaff);
    },
    [checkedStaff, addUserHandler]
  );

  const handleCheckAll = React.useCallback(
    (toCheck) => {
      return function (e) {
        const checked = e.target.checked;
        const checkItems = [];
        toCheck = items;
        // Loop through the checked items and add as needed.
        toCheck.forEach((item) => {
          checkItems.push(item.id);
        });

        handleCheckStaff(checkItems, checked);
      };
    },
    [handleCheckStaff, items]
  );

  const editableHeaders = [
    { title: '' },
    { title: 'First Name*' },
    { title: 'Last Name*' },
    { title: 'Email*' },
    { title: 'Phone*' },
    { title: 'Role*' },
  ];

  const sortableHeaders = [
    { title: 'checkAll', sortField: [] },
    { title: 'First Name', sortField: ['firstName'] },
    { title: 'Last Name', sortField: ['lastName'] },
    { title: 'Email', sortField: ['email'] },
    { title: 'Phone', sortField: ['phone'] },
    { title: 'Role', sortField: ['role'] },
  ];
  const headers = windowSize.mobile
    ? [
        { title: 'checkAll', sortField: [] },
        { title: 'Staff Name', sortField: ['lastName', 'firstName'] },
      ]
    : editable
    ? editableHeaders
    : sortableHeaders;

  const emptyMessage = !items || items.length === 0 ? 'You haven’t added any staff for this test date yet.' : '';

  const isEditableAndMobile = editable && windowSize.mobile;

  const showCheckedOverride = showChecked !== undefined ? showChecked : !editable;

  return isEditableAndMobile ? (
    <div id='add-staff-editable-no-mobile' className='d-flex justify-content-center align-items-center w-100 my-5 py-5'>
      <strong>This feature is not available in mobile view.</strong>
    </div>
  ) : (
    <>
      {editable && <p className='mb-4'>* = Required</p>}

      <RosterV3
        checkedItems={checkedStaff}
        showChecked={showCheckedOverride}
        forceDesktopViewForMobile={true}
        groupName={'addStaffInventoryRoster'}
        handleCheckItems={handleCheckStaff}
        headers={headers}
        id={'addStaffInventoryRoster'}
        emptyMessage={editable ? '' : emptyMessage}
        items={localState.filteredItems}
        renderItem={(staffUser, options) => {
          return editable ? (
            <AddStaffRowEditable
              key={`staff_${staffUser.id}`}
              editStaffChangeHandler={editStaffChangeHandler}
              deleteEnabled={localState.filteredItems.length > 1}
              deleteStaffHandler={deleteStaffHandler}
              staff={staffUser}
              onBlur={onBlur}
              formError={formErrorsByStaffId[staffUser.id]}
            />
          ) : (
            <AddStaffRow
              handleCheckItems={handleCheckStaff}
              key={`staff_${staffUser.id}`}
              options={{
                ...options,
                checked: checkedStaff.indexOf(staffUser.id) > -1,
              }}
              staff={staffUser}
              staffChangeHandler={staffChangeHandler}
            />
          );
        }}
        shadow={false}
        pageNum={pageNumRef.current}
        showSelected={!editable}
        stickyHeaders={true}
        stickyTopBar={'drawer-header-add-staff-side-drawer'}
        sortFields={
          editable
            ? []
            : [
                {
                  name: 'lastName',
                  order: 'asc',
                },
                {
                  name: 'firstName',
                  order: 'asc',
                },
              ]
        }
        tableControlElements={{
          search: searchDisabledComponent.includes(drawerTableComponent)
            ? []
            : [
                {
                  defaultValue: localState.search,
                  icon: 'search',
                  label: 'Enter part of a staff member’s name here to find the staff member',
                  name: 'add-staff-search',
                  onChange: updateSearch,
                  placeholder: 'Search assigned staff',
                  srOnlyLabel: true,
                  suppressFormGroupClass: true,
                  type: 'input',
                },
              ],
          buttons: [],
        }}
        tableClassName='cb-input-default'
        tableWrapperClassName={'w-100 p-0 mb-4 table-checkbox-override'}
        tableControlsClassName='w-100'
        tablePageOptsClassName='w-100'
        striped={false}
        addStaff
        {...{ checkboxAllRef, handleCheckAll }}
        onPageChange={(num: number) => {
          pageNumRef.current = num;
        }}
      />
    </>
  );
};
