import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import isFunction from 'lodash/isFunction';
import get from 'lodash/get';
import { validate, validateAll } from 'indicative';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CREATE_ROOMS } from '../../../../apollo/mutations';
import { GET_PAST_ACTIVE_EVENTS } from '../../../../apollo/queries';
import QueryComponent from '../../../common/network/QueryComponent';
import GroupsInfo from '../../../common/groupsInfo/Container';
import Spinner from '../../../ui/loading/SpinnerWrapper';
import ErrorMessages from '../../../ui/message/ErrorMessage';
import { messages as updateMessages, rules as updateRules } from '../../../../validations/room/add';
import { buildNetworkErrorMessages, buildValidationErrorMessages } from '../../../../validations/common';
import { localStateReducer, scrollTo, sortItems } from '../../../../utils/common';
import { ModalDispatchContext } from '../../../ui/modal/ModalContext';
import Input from '../../../ui/form/Input';
import OneButtonModal from '../../../ui/modal/standard/OneButtonModal';
import Select from '../../../ui/form/Select';
import TwoButtonModal from '../../../ui/modal/standard/TwoButtonModal';
import { BlackButton, NakedButton, YellowButton } from '@cb/apricot-react';

function BulkAddRooms({ initialRows = 1, toggleForm = () => {} }) {
  function deepCopy(obj = {}) {
    return JSON.parse(JSON.stringify(obj));
  }

  function handleAddRow(e) {
    e && e.preventDefault && e.preventDefault();

    // Deep copy form objects.
    const form = deepCopy(localState.form);
    const formErrors = deepCopy(localState.formErrors);

    form.push({ ...formFields });

    formErrors.push({ ...formFields });

    setLocalState({
      form,
      formErrors,
    });
  }

  function handleDelete(index = 0) {
    return function (e) {
      e && e.preventDefault && e.preventDefault();

      let capacityTotal = 0;
      let roomTotal = 0;

      // Deep copy form objects.
      const form = deepCopy(localState.form);
      const formErrors = deepCopy(localState.formErrors);

      if (form.length > 1) {
        form.splice(index, 1);
        formErrors.splice(index, 1);
      }

      // Get the capacity total.
      form.forEach((row) => {
        if (row.title.trim() !== '' && !isNaN(parseInt(row.capacity, 10))) {
          capacityTotal += parseInt(row.capacity, 10);
          roomTotal++;
        }
      });

      setLocalState({
        capacityTotal,
        form,
        formErrors,
        isDirty: roomTotal > 0 && capacityTotal > 0,
        roomTotal,
        submitEnabled: roomTotal > 0 && capacityTotal > 0,
      });
    };
  }

  function handleCancel(e) {
    e && e.preventDefault && e.preventDefault();

    if (localState.isDirty) {
      // Ensure they're sure they want to cancel if they've made changes.
      dispatchModal(
        <TwoButtonModal
          body='You’ll lose unsaved changes if you cancel now.'
          modalId='bulk-add-rooms-cancel-confirmation'
          primaryButtonHandler={() => {
            if (isFunction(toggleForm)) {
              toggleForm(false);
            }
          }}
          primaryButtonLabel='Yes'
          secondaryButtonLabel='No'
          title='Are you sure you want to cancel?'
          variant='error'
        />
      );
    } else {
      // Just close the form.
      toggleForm(false);
    }
  }

  function generateForm() {
    // Check out the imported data
    return localState.form.map((formRow, index) => (
      <tr key={index}>
        <td
          style={{
            width: '70%',
          }}
        >
          <Input
            errorMessage={localState.formErrors[index] ? localState.formErrors[index].title : ''}
            id={`${UI_PREFIX}-title-${index}`}
            label='Room Name'
            labelClassName='cb-sr-only'
            maxLength={254}
            name={`${UI_PREFIX}-title-${index}`}
            onChange={changeFormState(index)}
            required={true}
            value={formRow.title ? formRow.title : ''}
          />
        </td>
        <td
          style={{
            width: '20%',
          }}
        >
          <Input
            errorMessage={localState.formErrors[index] ? localState.formErrors[index].capacity : ''}
            id={`${UI_PREFIX}-capacity-${index}`}
            label='Capacity'
            labelClassName='cb-sr-only'
            maxLength={4}
            name={`${UI_PREFIX}-capacity-${index}`}
            onChange={changeFormState(index)}
            required={true}
            value={formRow.capacity ? formRow.capacity.toString() : ''}
          />
        </td>
        <td
          style={{
            verticalAlign: 'middle',
            textAlign: 'center',
          }}
        >
          <button
            className='cb-btn cb-btn-sm cb-btn-naked'
            data-automation='button-delete-row'
            disabled={localState.form.length > 1 ? '' : 'disabled'}
            onClick={handleDelete(index)}
            style={{
              margin: 0,
              padding: '0',
              width: 'auto',
            }}
            type='button'
          >
            Delete <span className='cb-sr-only'>{formRow.title ? ` room ${formRow.title}` : ' blank row'}</span>
          </button>
        </td>
      </tr>
    ));
  }

  function changeFormState(index = 0) {
    return async function (e) {
      const name = e.target.name;
      const value = e.target.value;
      let capacityTotal = 0;
      let roomTotal = 0;

      // Deep copy objects in array.
      const form = deepCopy(localState.form);
      const formErrors = deepCopy(localState.formErrors);
      let submitEnabled = true;

      // Strip out form prefix.
      const simpleFieldName = name.replace(`${UI_PREFIX}-`, '').replace(`-${index}`, '');
      const newObj = {
        [simpleFieldName]: value,
      };

      // Reset error validation till we get a new error.
      formErrors[index][simpleFieldName] = '';

      form[index][simpleFieldName] = value;

      // Validate this field if it has any rules.
      if (updateRules[simpleFieldName] !== undefined) {
        await validate(newObj, { [simpleFieldName]: updateRules[simpleFieldName] }, updateMessages).catch((errors) => {
          errors.forEach((err) => {
            formErrors[index][simpleFieldName] = err.message;
            submitEnabled = false;
          });
        });
      }

      // Get the capacity total.
      form.forEach((row) => {
        if (row.title.trim() !== '' && !isNaN(parseInt(row.capacity, 10))) {
          capacityTotal += parseInt(row.capacity, 10);
          roomTotal++;
        }
      });

      setLocalState({
        capacityTotal,
        form,
        isDirty: capacityTotal > 0,
        formErrors,
        roomTotal,
        submitEnabled: submitEnabled && roomTotal > 0 && capacityTotal > 0,
      });
    };
  }

  function fetchPreviousEvent(value) {
    if (value) {
      // Fetch our old event rooms.
      fetchPastEvent();
    }
    document.getElementById('import-rooms-selection').focus();
  }

  function handleImportSubmit(selectedPastEvent) {
    return function (e) {
      e && e.preventDefault && e.preventDefault();

      // If they already have data in the form, warn them that it'll get overwritten.
      const isDirtyForm = localState.form.filter((row) => row.title || row.capacity);

      if (isDirtyForm.length > 0) {
        // They have at least some data in the form currently, so warn them.
        dispatchModal(
          <TwoButtonModal
            body='You’ll lose unsaved changes if you proceed.'
            primaryButtonHandler={() => {
              fetchPreviousEvent(selectedPastEvent);
            }}
            primaryButtonLabel='Yes'
            title='Are you sure you want to import rooms?'
            variant='error'
            modalId='dirty-form-warning-modal'
          />
        );
      } else {
        fetchPreviousEvent(selectedPastEvent);
      }
    };
  }

  function handleSelectedPastEventChange(val) {
    // If they already have data in the form, warn them that it'll get overwritten.
    const isDirtyForm = localState.form.filter((row) => row.title || row.capacity);
    const changeValue = val;

    if (isDirtyForm.length > 0 && changeValue !== localState.selectedPastEvent && changeValue !== '') {
      dispatchModal(
        <TwoButtonModal
          body='You’ll lose unsaved changes if you proceed.'
          primaryButtonHandler={() => {
            fetchPreviousEvent(localState.selectedPastEvent);
          }}
          secondaryButtonHandler={() => {
            setLocalState({
              selectedPastEvent: localState.selectedPastEvent,
            });
          }}
          primaryButtonLabel='Yes'
          title='Are you sure you want to import rooms?'
          variant='error'
          modalId='dirty-form-warning-modal'
        />
      );
    } else {
      setLocalState({
        selectedPastEvent: changeValue,
      });
    }
  }

  function addNewRooms(finalForm = []) {
    createRooms({
      variables: { input: finalForm },
      refetchQueries: ['getSiteStats', 'getRooms'],
    })
      .then(() => {
        toggleForm(false);

        // Confirm rooms added successfully.
        dispatchModal(
          <OneButtonModal
            onClose={() => {
              scrollTo('add-room');
            }}
            buttonLabel='Close'
            title={finalForm.length > 1 ? 'You’ve added rooms successfully.' : 'You’ve added a room successfully.'}
            modalId='confirm-room-add-successfully'
            variant='success'
          />
        );
      })
      .catch((errors) => {
        scrollTo(UI_PREFIX);

        let submitErrors;

        if (Array.isArray(errors.graphQLErrors) && errors.graphQLErrors.length > 0) {
          submitErrors = buildValidationErrorMessages(errors.graphQLErrors, UI_PREFIX, updateMessages);
          setLocalState({ submitErrors });
        } else if (Array.isArray(errors.networkError.result.errors) && errors.networkError.result.errors.length > 0) {
          submitErrors = buildNetworkErrorMessages(errors.networkError.result.errors, UI_PREFIX, updateMessages);
          setLocalState({ submitErrors });
        }

        setLocalState({
          isProcessing: false,
          submitEnabled: false,
          submitErrors,
        });
      });
  }

  async function validateTheForm(form) {
    const { capacityTotal } = localState;
    const formErrors = [];
    let submitEnabled = true;
    const namesArr = [];

    const catchCallback = (index) => (errors) => {
      submitEnabled = false;
      errors.forEach((err) => {
        formErrors[index][err.field] = err.message;
      });
    };

    for (let index = 0, len = form.length; index < len; index++) {
      // Reset this row's validation first and only set if there's an issue.
      formErrors.push({ ...formFields });

      // If they filled out one or the other field, validate both.
      if (form[index] && (form[index].title || form[index].capacity)) {
        // Push the name for this room to a temporary array to detect duplicates.
        if (namesArr.indexOf(form[index].title.toLowerCase()) === -1) {
          // We don't have this, add it to the array.
          namesArr.push(form[index].title.toLowerCase());
        } else {
          submitEnabled = false;
          formErrors[index]['title'] = 'This room name is already in use.';
        }

        // Validate this row.
        await validateAll(form[index], updateRules, updateMessages).catch(catchCallback(index));
      }
    }

    setLocalState({
      form,
      formErrors,
      isDirty: capacityTotal > 0,
      submitEnabled: !localState.isProcessing && submitEnabled,
    });
  }

  async function processForm() {
    let capacity = '';
    const finalForm = [];
    let hasErrors = false;
    let title = '';

    // Clear any empty rows where they didn't fill anything out.
    localState.form.forEach((formRow) => {
      capacity = !isNaN(parseInt(formRow.capacity, 10)) ? parseInt(formRow.capacity, 10) : 0;
      title = formRow.title ? formRow.title.trim() : '';

      if (capacity || title) {
        // Convert Capacity to int.
        finalForm.push({
          capacity,
          title,
        });
      }
    });

    // Ensure we have at least 1 row.
    if (finalForm.length > 0) {
      // Validate the form one last time.
      await validateTheForm(finalForm);

      // If we have any client errors, find the first one and scroll to it.
      for (let formErrIndex = 0, formErrLen = localState.formErrors.length; formErrIndex < formErrLen; formErrIndex++) {
        if (localState.formErrors[formErrIndex].title) {
          hasErrors = true;
          scrollTo(`${UI_PREFIX}-title-${formErrIndex}`);
          break;
        } else if (localState.formErrors[formErrIndex].capacity) {
          hasErrors = true;
          scrollTo(`${UI_PREFIX}-capacity-${formErrIndex}`);
          break;
        }
      }

      // Check if we're good to go.
      if (!hasErrors) {
        // Submit the form to GraphQL!
        addNewRooms(finalForm);
      } else {
        setLocalState({
          isProcessing: false,
        });
      }
    }
  }

  function handleSubmit(e) {
    e && e.preventDefault && e.preventDefault();

    if (localState.submitEnabled && !localState.isProcessing) {
      setLocalState({
        isProcessing: true,
        submitEnabled: false,
      });

      processForm();
    }
  }

  const transformSelectOptions = (a) => {
    return a.reduce((prev, cur) => {
      return [
        ...prev,
        {
          label: cur.title,
          ...cur,
        },
      ];
    }, []);
  };

  const UI_PREFIX = 'bulk-add-room-form';

  // Global App state.
  const dispatchModal = useContext(ModalDispatchContext);

  const formFields = {
    capacity: '',
    title: '',
  };

  // Prepare the initial form, using the number of initial rows to populate it.
  const initialForm = [];
  const initialFormErrors = [];

  // Populate the initial form with fields that can be filled later.
  for (let i = 0; i < initialRows; i++) {
    initialForm.push({ ...formFields });
    initialFormErrors.push({ ...formFields });
  }

  // Local state.
  const [localState, setLocalState] = React.useReducer(localStateReducer, {
    accessibleFormUpdateMsg: '',
    capacityTotal: 0,
    form: initialForm,
    formErrors: initialFormErrors,
    importError: '',
    isDirty: false,
    isProcessing: false,
    roomTotal: 0,
    selectedPastEvent: '',
    submitEnabled: false,
    submitErrors: [],
  });

  // Apollo.
  const [createRooms] = useMutation(CREATE_ROOMS);
  const [fetchPastEvent, { data, error, loading }] = useLazyQuery(GET_PAST_ACTIVE_EVENTS, {
    variables: { input: { asmtEventId: localState.selectedPastEvent } },
  });

  let importButtonDisabled = true;
  let pastEventRooms = [];

  if (
    data &&
    data.pastEvents &&
    data.pastEvents[0] &&
    data.pastEvents[0].rooms &&
    data.pastEvents[0].rooms.length > 0
  ) {
    pastEventRooms = sortItems(
      data.pastEvents[0].rooms,
      [
        {
          name: 'title',
          order: 'asc',
        },
      ],
      'natural'
    );
  }

  // React useEffect freaks out because every array is "new" so we'll stringify it into a primitive for comparison.
  const memoRooms = JSON.stringify(pastEventRooms);

  React.useEffect(() => {
    function organizedFetchedRooms(rooms) {
      const formFields = {
        capacity: '',
        title: '',
      };
      let capacityTotal = 0;
      const formRows = [];
      const formRowsErrors = [];
      let roomTotal = 0;

      for (let i = 0, len = rooms.length; i < len; i++) {
        if (rooms[i].capacity && rooms[i].title) {
          formRows.push({
            capacity: rooms[i].capacity,
            title: rooms[i].title,
          });
          // Add a spot for errors.
          formRowsErrors.push({ ...formFields });
        }
      }

      if (formRows.length > 0) {
        // Get the capacity total.
        formRows.forEach((row) => {
          if (row.title.trim() !== '' && !isNaN(parseInt(row.capacity, 10))) {
            capacityTotal += parseInt(row.capacity, 10);
            roomTotal++;
          }
        });

        return {
          accessibleFormUpdateMsg: 'Imported rooms are listed in the table below. Remember to click Save.',
          capacityTotal,
          form: formRows,
          formErrors: formRowsErrors,
          importError: '',
          isDirty: true,
          roomTotal,
          submitEnabled: true,
        };
      } else {
        return {
          accessibleFormUpdateMsg: '',
          capacityTotal,
          form: formRows,
          formErrors: formRowsErrors,
          importError: 'No rooms were found for that test administration.',
          roomTotal,
          submitEnabled: false,
        };
      }
    }

    // Undo the fact that it was a string.
    const importedRooms = JSON.parse(memoRooms);

    if (importedRooms.length) {
      setLocalState({
        ...organizedFetchedRooms(importedRooms),
      });
    }
  }, [memoRooms]);

  return (
    <div className='box-card shadow'>
      <div className='row sticky-wrapper mt-0'>
        <div className='col-md-7'>
          <form className='cb-form' id={UI_PREFIX}>
            <ErrorMessages errorMessages={error || localState.submitErrors} />

            <QueryComponent
              query={{
                kind: 'GetPastAndActiveEvents',
                specification: GET_PAST_ACTIVE_EVENTS,
                variables: { input: { since: 13 } },
              }}
            >
              {(pastEventsData) => {
                // Sort by eventStartDt.
                const events = get(pastEventsData, 'pastEvents', []).sort(
                  (a, b) => moment(b.eventStartDt).unix() - moment(a.eventStartDt).unix()
                );

                if (events.length > 0 && localState.selectedPastEvent) {
                  importButtonDisabled = false;
                  // initialEvent = !isNaN(parseInt(events[0].asmtEventId, 10)) ? events[0].asmtEventId : '';
                }

                const addRoomTitle = events.length ? 'Import Rooms or Add New Ones' : 'Add Rooms';
                const addRoomDescription = events.length
                  ? 'You can import rooms from a past test administration or add rooms manually by completing the table below. You can auto-assign students to rooms when your total capacity is high enough.'
                  : 'Complete the table below to add rooms. You can auto-assign students to rooms when your total capacity is high enough.';

                const selectBoxEvents = events.map((event) => {
                  return {
                    title: event.eventTitle,
                    value: event.asmtEventId,
                  };
                });

                selectBoxEvents.unshift({
                  title: 'Select from the list',
                  value: '',
                });

                return (
                  <React.Fragment>
                    <h2 className='tdtk-h2 mb-4'>{addRoomTitle}</h2>
                    <p className='mb-4'>{addRoomDescription}</p>
                    {selectBoxEvents.length ? (
                      <fieldset className='mb-4'>
                        <legend className='cb-sr-only'>Import rooms from a previous test administration</legend>
                        <div className='row align-items-center'>
                          <div className='col-sm-7 cb-margin-xs-bottom-16'>
                            <Select
                              defaultValue={localState.selectedPastEvent}
                              errorMessage={localState.importError}
                              onChange={handleSelectedPastEventChange}
                              id='import-rooms-selection'
                              label='Choose a test administration'
                              name='import-rooms-selection'
                              labelClassName='cb-sr-only'
                              values={transformSelectOptions(selectBoxEvents)}
                            />
                          </div>

                          <div className='col-sm-5'>
                            <YellowButton
                              className='shadow w-100'
                              data-automation='button-import-rooms'
                              disabled={importButtonDisabled || localState.accessibleFormUpdateMsg}
                              onClick={handleImportSubmit(localState.selectedPastEvent)}
                              style={{ marginTop: 0 }}
                              small
                            >
                              Import Rooms
                            </YellowButton>
                          </div>
                        </div>
                      </fieldset>
                    ) : null}
                  </React.Fragment>
                );
              }}
            </QueryComponent>
            {localState.accessibleFormUpdateMsg && (
              <p
                id='import-success-msg'
                tabIndex='-1'
                aria-atomic='true'
                aria-live='polite'
                className='text-success mb-4'
              >
                <strong>{localState.accessibleFormUpdateMsg}</strong>
              </p>
            )}
            <p className='mb-4'>* = Required</p>

            {loading ? (
              <Spinner />
            ) : (
              <div className='box-card border__rounded-full p-0 shadow'>
                <table className='cb-table cb-input-default' id='room-form-table'>
                  <thead>
                    <tr>
                      <th id='roomName' className='sticky'>
                        Room Name<sup>*</sup>
                      </th>
                      <th id='roomCapacity' className='sticky'>
                        Capacity<sup>*</sup>
                      </th>
                      <th id='roomAction' className='sticky'>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody key={`bulk-add-rooms-form-${localState.form.length}`}>
                    {generateForm()}
                    <tr
                      style={{
                        verticalAlign: 'middle',
                      }}
                    >
                      <td colSpan='3'>
                        <div className='display-flex justify-content-between'>
                          <p
                            data-automation='import-rooms-total'
                            aria-atomic='true'
                            aria-live='polite'
                            className='cb-roboto-bold py-2'
                          >
                            Total: {localState.capacityTotal} seat{`${localState.capacityTotal === 1 ? '' : 's'}`} in{' '}
                            {localState.roomTotal} room{`${localState.roomTotal === 1 ? '' : 's'}`}
                          </p>
                          <BlackButton data-automation='button-add-row' onClick={handleAddRow} icon='plus' small>
                            Add Row
                          </BlackButton>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
            <div className='display-flex flex-xs-column-only my-4'>
              <YellowButton
                small
                disabled={!localState.submitEnabled}
                data-automation={'button-add-room-form-confirm'}
                onClick={handleSubmit}
              >
                Save
              </YellowButton>
              <NakedButton small data-automation='button-cancel-room' onClick={handleCancel}>
                Cancel
              </NakedButton>
            </div>
          </form>
        </div>
        <div className='hidden-sm hidden-xs col-md-5'>
          <GroupsInfo
            description={[
              <p className='mb-4' key='addEnoughRooms' data-automation='testing-groups-paragraph'>
                <strong>Add Rooms: </strong>
                Add enough rooms to seat students in these testing groups.
              </p>,
              /*, <p key="combinedRooms"><strong>Combined Rooms: </strong>You can now use Test Day Toolkit to create rooms that seat both SAT and SAT with Essay students. <a href="/help#combine-rooms">Find out how<span className="cb-sr-only"> to combine rooms on our help page</span>.</a></p>*/
            ]}
            showHeading1={false}
            showHeading2={true}
            usePopovers={true}
          />
        </div>
      </div>
    </div>
  );
}

BulkAddRooms.propTypes = {
  initialRows: PropTypes.number,
  toggleForm: PropTypes.func,
};

export default BulkAddRooms;
