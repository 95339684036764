import React, { useEffect, useState, useReducer, useContext } from 'react';
import { localStateReducer } from '../../../../utils/common';
import { YellowButton, NumericStep, Stepper } from '@cb/apricot-react';
import { ChooseFile } from './uploadSteps/ChooseFile';
import ErrorMessages from '../../../ui/message/ErrorMessage';
import { FileDataCheck, FileDataCheckStateType } from './uploadSteps/FileDataCheck';
import { FileFormatCheck } from './uploadSteps/FileFormatCheck';
import { ResizeContext } from '../../../../context/ResizeContext';
import TwoButtonModal from '../../../ui/modal/standard/TwoButtonModal';
import './StaffUploadContainer.scss';
import { get, keyBy } from 'lodash';
import { ModalDispatchContext } from '../../../ui/modal/ModalContext';
import OneButtonModal from '../../../ui/modal/standard/OneButtonModal';
import { BULK_CREATE_USER, STAFF_FILE_UPLOAD_MUTATION } from '../../../../apollo/mutations';
import { IStaffEditable } from './type';
import { ROLES_MAP } from '../../../../constants/roles';
import { userBackendValidationMessages } from '../../../../validations/user/validator';
import { useMutation } from '@apollo/client';
import { convertFileToDataURL } from '../../../../utils/base64';

type StaffUploadContainerProps = {
  handleDrawerDirty: (isDirty: boolean) => void;
  onClose: () => void;
};

export enum UploadSteps {
  chooseFile,
  fileFormatCheck,
  fileDataCheck,
}

export function StaffUploadContainer({ onClose, handleDrawerDirty }: StaffUploadContainerProps) {
  const [uploadStaffFile, { loading: loadingToS3 }] = useMutation(STAFF_FILE_UPLOAD_MUTATION);
  const [activeStep, setActiveStep] = useState<UploadSteps>(UploadSteps.chooseFile);
  const [fileId, setFileId] = useState('');
  const [file, setFile] = useState<File>();
  const [fileContainsAnError, setFileContainsAnError] = useState(true);
  const dispatchModal = useContext(ModalDispatchContext);
  const [bulkCreateUsers, { loading }] = useMutation(BULK_CREATE_USER);
  const [invalidCharacters, setInvalidCharacters] = useState(false);

  //FileDataCheck state
  const [fileDataCheckState, setFileDataCheckState]: [
    FileDataCheckStateType,
    React.Dispatch<Partial<FileDataCheckStateType>>
  ] = useReducer(localStateReducer, {
    staffRows: [],
    staffErrorRows: [],
    dirtyForm: false,
    isProcessing: false,
    //using an object, since a new object triggers a re-render for useEffect
    //since a new object is consider different with same key/value pairs
    //this helps enforce the add row logic, in navigating to the last page.
    startingPageOpts: { pageNum: 0 },
    formErrorsByStaffId: {},
    submitErrors: [],
    submitEnabled: false,
    hasInitialErrors: false,
  });

  const windowSize = React.useContext(ResizeContext);
  const isMobile = windowSize.mobile;
  function handleChangeActiveStep(step: UploadSteps) {
    setActiveStep(step);
  }

  useEffect(() => {
    if (file) {
      setActiveStep(UploadSteps.fileFormatCheck);
    }
  }, [file]);

  const activeComponentMap: { [key in UploadSteps]: React.ReactElement } = {
    [UploadSteps.chooseFile]: <ChooseFile setFile={setFile} />,
    [UploadSteps.fileFormatCheck]: (
      <FileFormatCheck
        handleReplace={() => handleChangeActiveStep(UploadSteps.chooseFile)}
        handleDrawerDirty={handleDrawerDirty}
        file={file}
        loadingToS3={loadingToS3}
        fileContainsAnError={fileContainsAnError}
        setFileContainsAnError={setFileContainsAnError}
        invalidCharacters={invalidCharacters}
      />
    ),
    [UploadSteps.fileDataCheck]: (
      <FileDataCheck
        file={file}
        loading={loading}
        fileDataCheckState={fileDataCheckState}
        setFileDataCheckState={setFileDataCheckState}
      />
    ),
  };

  const handleDataCheck = async () => {
    if (file) {
      const b64Contents = await convertFileToDataURL(file);
      const input = { fileName: file?.name, file: b64Contents };
      try {
        const response = await uploadStaffFile({ variables: { input } });

        setFileId(response.data?.staffFileUpload?.fileId ?? 'ERROR_UPLOADING_STAFF_FILE');
        handleChangeActiveStep(UploadSteps.fileDataCheck);
      } catch (e) {
        setInvalidCharacters(true);
        setFileContainsAnError(true);
      }
    }
  };

  const approveCorrections = () => {
    // add error rows back to staff rows
    const combinedStaffRows = fileDataCheckState.staffRows.concat(fileDataCheckState.staffErrorRows);
    // set state to display whole staff roster
    setFileDataCheckState({
      hasInitialErrors: false,
      staffRows: combinedStaffRows,
      staffErrorRows: [],
    });
  };

  const FileDataCheckButton = () => {
    return (
      <YellowButton
        small
        data-automation={'button-add-table-staff-form-confirm'}
        onClick={() => {
          fileDataCheckState.hasInitialErrors && fileDataCheckState.staffErrorRows.length
            ? approveCorrections()
            : handleSubmit();
        }}
        disabled={Object.keys(fileDataCheckState.formErrorsByStaffId).length > 0 || loading}
        className='flex--justify-right ml-auto mr-4'
      >
        {fileDataCheckState.hasInitialErrors && fileDataCheckState.staffErrorRows.length
          ? 'Approve Corrections'
          : 'Submit File'}
      </YellowButton>
    );
  };

  const handleSubmit = () => {
    dispatchModal(
      <TwoButtonModal
        title={'Are You Sure?'}
        modalId='autoAssign'
        variant='error'
        body={
          <div>
            <p className='mb-4' key='auto_assign:body' data-automation='auto-assign-body'>
              By submitting this file, you confirm you have permission from everyone listed to share their personal
              information with College Board and for it to be used to administer tests.
            </p>
          </div>
        }
        primaryButtonLabel='Submit File'
        primaryButtonHandler={() => {
          const formData = fileDataCheckState.staffRows.map((staff: IStaffEditable) => {
            return {
              firstName: staff.firstName,
              lastName: staff.lastName,
              email: staff.email,
              phone: JSON.stringify([
                {
                  type: 'work',
                  phoneNumber: staff.phone,
                },
              ]),
              role: staff.role,
            };
          });

          handleDrawerDirty(false);

          bulkCreateUsers({
            variables: {
              input: {
                source: fileId,
                users: formData,
              },
            },
            refetchQueries: ['getStaffAndRooms'],
          })
            .then(async (response) => {
              onClose();

              dispatchModal(
                <OneButtonModal
                  modalId='staffAddRosterConfirm'
                  buttonLabel='Close'
                  title={'Your staff have been successfully added'}
                  variant='success'
                />
              );
            })
            .catch((e: any) => {
              handleDrawerDirty(true);
              const formErrorsByStaffId = { ...fileDataCheckState.formErrorsByStaffId };
              const UI_PREFIX = 'staff-upload-form';
              const emailToStaff = keyBy(fileDataCheckState.staffRows, 'email');

              const emptyStaff: IStaffEditable = {
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                role: ROLES_MAP.PROCTOR,
                id: '',
              };
              /**
               * @returns {string} - the input id of the field that the error message is for
               */
              const fieldMessageToInputId = (
                customMessage: string,
                email: string,
                formErrorsByStaffId: { [key: string]: IStaffEditable }
              ): string => {
                const fields = ['firstName', 'lastName', 'email', 'phone'];
                const field = fields.find((field) => customMessage.includes(field));

                const fieldToIdFieldName: { [key: string]: string } = {
                  firstName: 'first-name',
                  lastName: 'last-name',
                  email: 'email',
                  phone: 'phone',
                  role: 'role',
                };

                if (!formErrorsByStaffId[emailToStaff[email].id] && field) {
                  formErrorsByStaffId[emailToStaff[email].id] = { ...emptyStaff, role: '' };
                }
                if (field) {
                  formErrorsByStaffId[emailToStaff[email].id][field as keyof IStaffEditable] = customMessage;
                }

                return field ? `staff-${fieldToIdFieldName[field]}-${emailToStaff[email]?.id}` : UI_PREFIX;
              };

              // TODO: make it a utility function
              const buildErrorMessages = (errors: { payload: any }[], buildMessages: any) => {
                const errorMessages: any[] = [];
                errors.map((errorObject) => {
                  if (errorObject.payload.length) {
                    errorObject.payload.map((p: { email: string; errorDetails: [] }) => {
                      const email = p.email;
                      p.errorDetails.map((errorDetailsObject: { message: string; path: string }, index: number) => {
                        let customMessage =
                          get(buildMessages, errorDetailsObject?.message) || errorDetailsObject?.message;
                        let uiName = `${UI_PREFIX}-${errorDetailsObject?.path}${index}`;

                        if (customMessage.includes('emailDomain')) {
                          customMessage = customMessage.replace('%%emailDomain%%', email);
                          uiName = `staff-email-${emailToStaff[email]?.id}`;
                          if (!formErrorsByStaffId[emailToStaff[email].id]) {
                            formErrorsByStaffId[emailToStaff[email].id] = { ...emptyStaff, role: '' };
                          }
                          formErrorsByStaffId[emailToStaff[email].id].email = customMessage;
                        } else {
                          uiName = fieldMessageToInputId(customMessage, email, formErrorsByStaffId);
                        }

                        errorMessages.push({
                          errorMessage: customMessage,
                          key: `error-${UI_PREFIX}-${errorDetailsObject?.path}${index}`,
                          uiName,
                        });
                      });
                    });
                  }
                });
                return errorMessages;
              };
              if (Array.isArray(e.graphQLErrors) && e.graphQLErrors.length > 0) {
                buildErrorMessages(e.graphQLErrors, userBackendValidationMessages);
                const filteredErrorRows: any = [],
                  filteredSuccessRows: any = [];
                fileDataCheckState.staffRows.map((row: IStaffEditable) => {
                  row.id in formErrorsByStaffId ? filteredErrorRows.push(row) : filteredSuccessRows.push(row);
                });

                setFileDataCheckState({
                  staffErrorRows: filteredErrorRows,
                  staffRows: filteredSuccessRows,
                  hasInitialErrors: true,
                  formErrorsByStaffId,
                  submitErrors: [],
                });
              } else if (Array.isArray(e?.networkError?.result?.errors) && e.networkError.result.errors.length > 0) {
                const networkError = [
                  {
                    errorMessage: 'A network error occurred. Please try again later.',
                    key: 'error-file-upload-container-network-1',
                    uiName: 'file-upload-container',
                  },
                ];

                setFileDataCheckState({
                  submitErrors: networkError,
                });
              } else {
                //unknown error
                const defaultErr = [
                  {
                    errorMessage: 'An error occurred. Please try again later.',
                    key: 'error-file-upload-container-1',
                    uiName: 'file-upload-container',
                  },
                ];

                setFileDataCheckState({
                  submitErrors: defaultErr,
                });
              }
            });
        }}
        secondaryButtonLabel='Cancel'
        secondaryButtonStyle='naked'
      />
    );
  };

  return isMobile ? (
    <div id='add-staff-editable-no-mobile' className='d-flex justify-content-center align-items-center w-100 my-5 py-5'>
      <strong>This feature is not available in mobile view.</strong>
    </div>
  ) : (
    <form id='file-upload-container' className='cb-form w-100'>
      {fileDataCheckState?.submitErrors && (
        <ErrorMessages errorMessages={fileDataCheckState.submitErrors} scrollableWindowId='add-staff-side-drawer' />
      )}
      <div className='stepper-wrapper'>
        <Stepper vertical={false} ariaLabel='Stepper' horizontalFix={false} role='region'>
          <NumericStep label='Prepare File' state={activeStep === UploadSteps.chooseFile ? 'active' : 'complete'} />
          <NumericStep
            label='Check File Format'
            state={
              activeStep === UploadSteps.fileFormatCheck
                ? 'active'
                : activeStep === UploadSteps.fileDataCheck
                ? 'complete'
                : undefined
            }
          />
          <NumericStep
            label='Check Data Format'
            state={activeStep === UploadSteps.fileDataCheck ? 'active' : undefined}
          />
        </Stepper>
      </div>
      <div className='form-content px-0'>
        <div className='cb-padding-32 w-100'>
          <div>{activeComponentMap[activeStep]}</div>
        </div>
      </div>

      {activeStep !== UploadSteps.chooseFile ? (
        <div className='display-flex form-footer py-2'>
          {activeStep === UploadSteps.fileFormatCheck ? (
            <YellowButton
              small
              data-automation={'button-add-table-staff-form-confirm'}
              onClick={() => {
                fileContainsAnError ? handleChangeActiveStep(UploadSteps.chooseFile) : handleDataCheck();
              }}
              className='flex--justify-right ml-auto mr-4'
            >
              {fileContainsAnError ? 'Replace File' : 'Continue'}
            </YellowButton>
          ) : (
            <FileDataCheckButton />
          )}
        </div>
      ) : null}
    </form>
  );
}
