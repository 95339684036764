import { CloseButton } from '@cb/apricot-react';
import React, { useRef } from 'react';

import './Drawer.scss';

export type DrawerProps = {
  open?: boolean;
  title?: string;
  onClose?: any;
  children: React.ReactElement;
  drawerId: string;
  handleDrawerDirty?: any;
  closeBtnRef: any;
};

export function Drawer(props: DrawerProps) {
  const { open = false, title = '', onClose, children, drawerId, handleDrawerDirty, closeBtnRef } = props;

  return (
    <div aria-labelledby='drawer' className={`side-drawer ${open ? 'slide-open' : ''}`} id={drawerId}>
      <div className='drawer-header cb-box-shadow-bottom' id={`drawer-header-${drawerId || ''}`}>
        <CloseButton
          id={`${drawerId}-drawer-close-btn`}
          onClick={() => onClose()}
          aria-controls={drawerId}
          aria-expanded={open}
          aria-label={'Close Drawer'}
          ref={closeBtnRef}
        />
        <div className='drawer-title'>{title}</div>
      </div>

      <div className='drawer-content'>
        {React.Children.map(children, (child) => {
          return React.cloneElement(child, { onClose: onClose, handleDrawerDirty, closeBtnRef });
        })}
      </div>
    </div>
  );
}
