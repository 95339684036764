export function fetchMoreStudents(data) {
  const { lastKey } = data?.paginatedStudents || {};

  return lastKey
    ? {
        variables: {
          input: { startKey: lastKey },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult || !fetchMoreResult.paginatedStudents || !fetchMoreResult.paginatedStudents.students) {
            return {
              paginatedStudents: {
                students: [...prev.paginatedStudents.students],
                lastKey: null,
              },
            };
          }
          return Object.assign({}, prev, {
            paginatedStudents: {
              students: [...prev.paginatedStudents.students, ...fetchMoreResult.paginatedStudents.students],
              lastKey: fetchMoreResult.paginatedStudents.lastKey,
            },
          });
        },
      }
    : undefined;
}
