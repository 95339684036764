import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import GroupsInfo from '../../groupsInfo/Container';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import some from 'lodash/some';
import isFunction from 'lodash/isFunction';
import { validate, validateAll } from 'indicative';
import { getGroupTypes, groupTypesCheck } from '../../../../utils/getGroupTypes';
import { roomStatus } from '../../../../constants/roomConstants';
import { roomStaffRequirements } from '../../../../utils/room';
import { checkRoleChange, generateStaffOptions } from '../../../../utils/staff';
import { DELETE_ROOM, CREATE_ROOM, UPDATE_ROOM, UPDATE_USERS } from '../../../../apollo/mutations';
import { GET_ROOMS } from '../../../../apollo/queries';
import ErrorMessages from '../../../ui/message/ErrorMessage';
import SuccessMessage from '../../../ui/message/SuccessMessage';
import { messages as updateMessages, rules as updateRules } from '../../../../validations/room/add';
import { buildNetworkErrorMessages, buildValidationErrorMessages } from '../../../../validations/common';
import { localStateReducer, scrollTo } from '../../../../utils/common';
import { hasAdminAccess } from '../../../../utils/user';
import AddStaffSelection from '../../../nonDigital/rooms/staff/AddStaffSelection';
import { useStateValue } from '../../../../context/AppContext';
import { ModalDispatchContext } from '../../../ui/modal/ModalContext';
import { getTitle } from '../../../../constants/roles';
import { useTitle } from '../../../../constants/useTitle';
import Input from '../../../ui/form/Input';
import OneButtonModal from '../../../ui/modal/standard/OneButtonModal';
import Select from '../../../ui/form/Select';
import TwoButtonModal from '../../../ui/modal/standard/TwoButtonModal';
import { BlackButton, NakedButton, YellowButton } from '@cb/apricot-react';

function RoomForm({ room = {}, staff = [], students = [], toggleForm = () => {} }) {
  const navigate = useNavigate();

  useTitle(`Edit Room Details - ${room.title}`);

  // Global App state.
  const { orgEvent, user } = useStateValue();
  const dispatchModal = useContext(ModalDispatchContext);

  function generateGroupTypeOptions() {
    let displayGroupTypes = getGroupTypes(orgEvent);
    const shortGroupTypes = {};
    let uniqueGroups = [];

    // Filter out any group types that are not being used by students in this roster.
    if (students.length) {
      uniqueGroups = [...new Set(students.map((item) => item.groupType))];

      // Sort alphabetically.
      uniqueGroups.sort();

      // Get the objects from our groupTypes.
      for (let i = 0, len = uniqueGroups.length; i < len; i++) {
        shortGroupTypes[uniqueGroups[i]] = displayGroupTypes[uniqueGroups[i]];
      }

      displayGroupTypes = shortGroupTypes;
    }
    return groupTypesCheck(displayGroupTypes);
  }

  function generateStatusOptions() {
    return Object.keys(roomStatus).map((key) => ({
      value: key,
      label: roomStatus[key].statusText,
    }));
  }

  function handleDelete() {
    const input = {
      id: room.id,
    };

    if (some(room.students, { checkedInRoom: true })) {
      dispatchModal(
        <OneButtonModal
          body='You can’t delete a room with assigned students. Remove the students from the room and try again.'
          modalId='student-assigned-to-digital-room-warning'
          variant='error'
          buttonLabel='OK'
          title='Students are assigned to this room.'
        />
      );

      // Kick the user out of this function before continuing.
      return;
    }

    // Render a modal indicating their intention to delete this room.
    dispatchModal(
      <TwoButtonModal
        body={
          isEmpty(room.students) && isEmpty(room.staff)
            ? 'You’re about to delete this room.'
            : 'If you delete this room, students and staff will need to be reassigned.'
        }
        primaryButtonHandler={() => {
          deleteRoom({
            variables: { input },
            optimisticResponse: {
              deleteRoom: {
                id: input.id,
                __typename: 'RoomType',
              },
            },
          })
            .then(() => {
              navigate('/rooms');
            })
            .catch((e) => {
              if (e && Array.isArray(e.graphQLErrors)) {
                console.error('error', e);
              }
            });
        }}
        variant='error'
        modalId='digital-room-delete-form'
      />
    );
  }

  function handleCancel() {
    const { form, isDirty } = localState;

    if (isDirty) {
      // Confirm cancellation.
      dispatchModal(
        <TwoButtonModal
          body='You’ll lose unsaved changes if you cancel now.'
          modalId='room-form-cancellation-confirm'
          primaryButtonLabel='Yes'
          primaryButtonHandler={() => {
            if (isFunction(toggleForm)) {
              toggleForm(false);
            }
            navigate(!form.id || form.id === '' ? '' : `/rooms/get/${form.id}`);
          }}
          secondaryButtonLabel='No'
          title='Are you sure you want to cancel?'
          variant='error'
        />
      );
    } else {
      navigate(!form.id || form.id === '' ? '' : `/rooms/get/${form.id}`);
    }
  }

  function addNewRoom(defaultFields, newRoom) {
    createRoom({
      variables: { input: newRoom },
      refetchQueries: ['getSiteStats'],
      optimisticResponse: {
        createRoom: {
          ...defaultFields,
          ...newRoom,
        },
      },
      update: (store, { data: { createRoom } }) => {
        try {
          // Read the data from our cache for this query.
          const data = store.readQuery({ query: GET_ROOMS });
          // Add that data from the cache.
          data.viewer.site.rooms.unshift(createRoom);
          // Write our data back to the cache.
          store.writeQuery({
            query: GET_ROOMS,
            data,
          });
        } catch (e) {
          console.error('Failed to create room.', e);
        }
      },
    })
      .then(() => {
        // Scroll up to the top of the form after they finish.
        scrollTo(UI_PREFIX);

        // Reset the form.
        document.getElementById(UI_PREFIX) && document.getElementById(UI_PREFIX).reset();

        setLocalState({
          form: {
            capacity: '',
            title: '',
          },
          formSuccess: 'You’ve successfully added a room. Add more if you need to.',
          submitDisabled: false,
          submitErrors: [],
        });
      })
      .catch((errors) => {
        scrollTo(UI_PREFIX);
        let submitErrors;

        if (Array.isArray(errors.graphQLErrors) && errors.graphQLErrors.length > 0) {
          submitErrors = buildValidationErrorMessages(errors.graphQLErrors, UI_PREFIX, updateMessages);
          setLocalState({ submitErrors });
        } else if (Array.isArray(errors.networkError.result.errors) && errors.networkError.result.errors.length > 0) {
          submitErrors = buildNetworkErrorMessages(errors.networkError.result.errors, UI_PREFIX, updateMessages);
          setLocalState({ submitErrors });
        }

        setLocalState({
          submitDisabled: false,
          submitErrors,
        });
      });
  }

  function compatibleGroupTypes(selectedGroupType) {
    // Stores all the group types in this particular room.
    let filteredGroupTypeOptions = generateGroupTypeOptions();
    const groupTypes = getGroupTypes(orgEvent);

    filteredGroupTypeOptions = filteredGroupTypeOptions.filter((groupType) => {
      // Only allow this option if the selected group types allow it.
      return groupTypes[groupType.value]
        ? groupTypes[groupType.value].canCombineWith.includes(selectedGroupType)
        : true;
    });

    return filteredGroupTypeOptions;
  }

  function updateStaffToRoomChangeStatus(staffToRoomStatusChanged) {
    setLocalState({
      ...staffToRoomStatusChanged,
      isDirty: true,
      submitDisabled: false,
    });
  }

  function checkRoleOrRoomChange() {
    return [
      ...checkRoleChange('proctor', localState.id, localState.proctorSelected),
      ...checkRoleChange('roomMonitor', localState.id, localState.roomMonitorsSelected),
    ];
  }

  async function changeFormState(val, e) {
    // handle variable inputs to this function for the Apricot 4 transition
    if (!e) {
      e = val;
    }

    const name = e.target.name;
    const value = e.target.value;

    // Strip out form prefix.
    const newField = name.replace(`${UI_PREFIX}-`, '');

    let groupTypeIndex = -1;
    let groupTypeMessage = localState.groupTypeMessage;
    let groupTypes = [];
    let newObj = {};

    // Check if this is a group type field.
    if (newField.indexOf('groupTypes-') > -1) {
      // We have a groupType field. Strip out the index.
      groupTypeIndex = parseInt(newField.replace('groupTypes-', ''), 10);
    }

    if (!isNaN(groupTypeIndex) && groupTypeIndex !== -1) {
      // We are editing a group type.
      groupTypes = localState.form.groupTypes;
      groupTypes[groupTypeIndex] = value;

      // Determine if this can be combined with any other group type.
      if (groupTypeIndex === 0) {
        if (!value) {
          groupTypeMessage = 'Select a testing group first.';
        } else if (compatibleGroupTypes(value).length === 0) {
          groupTypeMessage = 'This testing group can’t be combined with any other group at your test center.';
        } else {
          groupTypeMessage = '';
        }
        // If they are updating the first Group Type, reset it so we don't have stale data.
        // If the value is set to an empty string, pass an empty array, not [''] or GraphQL complains.
        groupTypes = value !== '' ? [groupTypes[0]] : [];
      }

      // Remove any empty strings.
      groupTypes = groupTypes.filter((groupType) => groupType !== '');

      // Set the new object with the updated value.
      newObj = {
        groupTypes,
      };
    } else {
      // We're just editing a normal field.
      newObj = {
        [newField]: value,
      };
    }

    // Merge in current state.
    const updatedForm = {
      ...localState.form,
      ...newObj,
    };

    // Let's reset the form error for this field until it gets re-validated.
    const formErrors = {
      ...localState.formErrors,
      [newField]: '',
    };

    // Validate this field if it has any rules.
    if (updateRules[newField] !== undefined) {
      await validate(newObj, { [newField]: updateRules[newField] }, updateMessages).catch((errors) => {
        errors.forEach((err) => {
          formErrors[err.field] = err.message;
        });
      });
    }

    setLocalState({
      form: updatedForm,
      formErrors,
      formSuccess: '',
      groupTypeMessage,
      isDirty: true,
      submitDisabled: false,
    });
  }

  async function roomUpdate(updatedRoom) {
    const mergedSelectedUsers = [
      // Assign appropriate roles so our function knows where they belong.
      ...localState.proctorSelected.map((s) => ({
        ...s,
        role: s.role !== 'admin' && s.role !== 'CBAdmin' ? 'proctor' : s.role,
      })),
      ...localState.roomMonitorsSelected.map((s) => ({
        ...s,
        role: s.role !== 'admin' && s.role !== 'CBAdmin' ? 'roomMonitor' : s.role,
      })),
    ];

    const changingUsers = [];
    let findStaff;

    // Remove existing staff if they were not included in the changes.
    get(room, 'staff', []).forEach((a) => {
      findStaff = mergedSelectedUsers.find((s) => a.id === s.value);

      if (findStaff === undefined) {
        // If a previous staff was in the room, and now is not, remove their room.
        changingUsers.push({
          id: a.id,
          role: a.role,
          room: '',
        });
      } else if (findStaff.role !== a.role) {
        // Check if their role has changed.
        changingUsers.push({
          id: a.id,
          role: findStaff.role,
          room: room.id,
        });
      }
    });

    // Loop through selections to see if we need to mark any as changed.
    mergedSelectedUsers.forEach((a) => {
      findStaff = room.staff.find((s) => s.id === a.value);

      if (findStaff === undefined) {
        // This is a new user for this room.
        changingUsers.push({
          id: a.value,
          role: a.role,
          room: room.id,
        });
      }
    });

    // Update the room itself, and refresh the stats.
    updateRoom({
      variables: {
        input: updatedRoom,
      },
    })
      .then(() => {
        // Only run an update if the users changed.
        if (changingUsers.length > 0) {
          // Send a call to update the appropriate staff members.
          updateUsers({
            variables: {
              input: {
                users: [...changingUsers],
              },
            },
            refetchQueries: ['inventory', 'getStaff', 'getSiteStats'],
          })
            .then(() => {
              navigate(`/rooms/get/${room.id}`);
            })
            .catch((e) => {
              console.error('Failed to update user records.', e);
            });
        } else {
          navigate(`/rooms/get/${room.id}`);
        }
      })
      .catch((error) => {
        if (error && Array.isArray(error.graphQLErrors)) {
          scrollTo(UI_PREFIX);
          const submitErrors = buildValidationErrorMessages(error.graphQLErrors, UI_PREFIX, updateMessages);
          setLocalState({
            submitErrors,
          });
        }
      });
  }

  async function handleSubmit(e) {
    e && e.preventDefault && e.preventDefault();

    function callback() {
      // Disable submit until we're done.
      setLocalState({
        submitDisabled: true,
      });
      processForm();
    }

    const usersWithOtherRolesChanged = checkRoleOrRoomChange();

    // Some selected users are already in another rooms or halls.
    const body = [];
    let evUser;
    let name = '';

    if (usersWithOtherRolesChanged.length) {
      // Update the messaging based on the users who are being affected.
      if (usersWithOtherRolesChanged.length === 1) {
        const oneUser = usersWithOtherRolesChanged[0];
        // Get the first/last name.
        evUser = staff.find((es) => es.id === oneUser.value);
        name = `${evUser.firstName} ${evUser.lastName ? evUser.lastName : ''}`;

        body.push(
          `${name} is already assigned to the room or role listed below. If you continue, they'll be reassigned.`
        );

        if (oneUser.role !== 'hallMonitor' || oneUser.role !== 'technologyCoordinator') {
          body.push(`\n\nRoom: ${get(oneUser, 'room.title') || 'Unassigned'}`);
          body.push(`\nRole: ${getTitle(oneUser.role) || 'Unassigned'}`);
        } else {
          body.push(`\n\nRole: ${getTitle(oneUser.role)}`);
        }
      } else if (usersWithOtherRolesChanged.length > 1) {
        body.push(
          'These staff members are already assigned to the rooms or roles listed below. If you continue, they’ll be reassigned.'
        );

        usersWithOtherRolesChanged.forEach((user) => {
          // Get the first/last name.
          evUser = staff.find((es) => es.id === user.value);
          name = `${evUser.firstName} ${evUser.lastName ? evUser.lastName : ''}`;

          body.push(`\n\n${name}`);

          if (user.role !== 'hallMonitor' || user.role !== 'technologyCoordinator') {
            body.push(`\nRoom: ${get(user, 'room.title') || 'Unassigned'}`);
            body.push(`\nRole: ${getTitle(user.role) || 'Unassigned'}`);
          } else {
            body.push(`\nRole: ${getTitle(user.role)}`);
          }
        });
      }

      // Dispatch a modal that will prompt them to confirm.
      dispatchModal(
        <OneButtonModal
          body={body}
          buttonLabel='OK'
          variant='error'
          onClose={() => {
            callback();
          }}
          modalId='room-proctor-reassign'
        />
      );
    } else {
      // Should be good to update. Go nuts.
      callback();
    }
  }

  async function processForm() {
    // Function that does stuff depending on what action they're doing.
    async function applyChanges() {
      // Check if there any students in the room.
      let roomChangeMsg = 'You’re about to change this room’s testing group.';
      // Holder for clientside errors.
      let firstError;
      const formErrors = {};
      let hasErrors = false;
      let removedGroupType = false;

      // Determine if they removed a group type.
      if (room.groupTypes && room.groupTypes.length > 0) {
        room.groupTypes.forEach((roomGroupType) => {
          if (!form.groupTypes.includes(roomGroupType)) {
            // They removed a group type, so note that.
            removedGroupType = true;

            // Now, ensure we don't have any students with that group type, change messaging accordingly.
            if (room.students && room.students.length > 0) {
              room.students.forEach((roomStudent) => {
                if (roomStudent.groupType === roomGroupType) {
                  roomChangeMsg =
                    'If you change this room’s testing group, you’ll need to find new rooms for the assigned students.';
                }
              });
            }
          }
        });
      }

      // Get the total room occupation by subtracting absent students.
      const roomOccupancy = room.students.filter((student) => !student.absent && !student.deniedEntry).length;

      // If this is a new form, only validate and save "title" and "capacity".
      if (!form.id || form.id === '') {
        await validateAll(newRoom, updateRules, updateMessages).catch((errors) => {
          hasErrors = true;
          errors.forEach((err) => {
            formErrors[err.field] = err.message;
          });
        });
        // Add the room if there are no errors.
        if (!hasErrors) {
          addNewRoom(defaultFields, newRoom);
        }
      } else if (removedGroupType) {
        await validateAll(form, updateRules, updateMessages).catch((errors) => {
          hasErrors = true;
          errors.forEach((err) => {
            formErrors[err.field] = err.message;
          });
        });

        // Add the room if there are no errors.
        if (!hasErrors) {
          // They are editing a Room but changed the group type.
          dispatchModal(
            <TwoButtonModal
              body={roomChangeMsg}
              secondaryButtonHandler={() => {
                setLocalState({
                  submitDisabled: false,
                });
              }}
              primaryButtonHandler={() => {
                roomUpdate(form);
              }}
              variant='error'
              modalId='room-add-form'
            />
          );
        }
      } else if (
        !isNaN(parseInt(form.capacity, 10)) &&
        roomOccupancy &&
        roomOccupancy > form.capacity &&
        roomOccupancy > localState.form.capacity
      ) {
        // They changed the room capacity below the number of students in the room.
        dispatchModal(
          <TwoButtonModal
            body={[
              `If you lower this room's capacity to ${form.capacity}, there won't be enough seats for the ${roomOccupancy} student(s) assigned to it.`,
              <br key='br1' />,
              <br key='br2' />,
              'Click ',
              <strong key='remove-students'>Remove Students</strong>,
              ' to go back to the Room Details page where you can move students out and, if you want, assign them to a new room.',
            ]}
            secondaryButtonHandler={() => {
              navigate(-1);
            }}
            primaryButtonHandler={() => {
              navigate(-1);
            }}
            primaryButtonLabel='Remove Students'
            variant='error'
            title={`You need to remove ${roomOccupancy - form.capacity} student(s) first.`}
            modalId='room-form-capacity-error'
          />
        );
      } else {
        // Just validate the form and only stop them if they have errors.
        await validateAll(form, updateRules, updateMessages).catch((errors) => {
          hasErrors = true;
          errors.forEach((err) => {
            formErrors[err.field] = err.message;
          });
        });
        // Add the room if there are no errors.
        if (!hasErrors) {
          roomUpdate(form);
        }
      }

      // If we have any client errors, find the first one and scroll to it.
      if (!isEmpty(formErrors)) {
        firstError = `${UI_PREFIX}-${Object.keys(formErrors)[0]}`;

        if (firstError) {
          // We've failed! Scroll to top so they can see the new errors on the form.
          scrollTo(firstError);
        }
      }

      // We had errors, set the state.
      setLocalState({
        formErrors,
      });
    }

    const now = new Date().getTime();

    const defaultFields = {
      __typename: 'RoomType',
      created: now,
      groupTypes: null,
      id: `${Math.round(Math.random() * -1000000)}`,
      staff: [],
      status: 'none',
      students: [],
      updated: now,
    };

    const newRoom = {
      capacity: localState.form.capacity,
      title: localState.form.title,
    };

    if (newRoom.capacity && typeof newRoom.capacity === 'string') {
      newRoom.capacity = parseInt(newRoom.capacity);
    }

    const form = {
      ...localState.form,
      capacity: newRoom.capacity,
    };

    applyChanges();
  }

  const { roomMonitorRequirement } = roomStaffRequirements(room, orgEvent);

  const initialState = {
    elementInvalid: {},
    form: {
      capacity: !isEmpty(room) ? room.capacity : '',
      groupTypes: !isEmpty(room) ? [...room.groupTypes] : [],
      id: !isEmpty(room) ? room.id : '',
      status: !isEmpty(room) ? room.status : '',
      title: !isEmpty(room) ? room.title : '',
    },
    formErrors: {
      id: '',
      title: '',
      capacity: '',
      groupTypes: [],
      status: '',
    },
    formSuccess: '',
    groupTypeMessage:
      !isEmpty(room) && room.groupTypes.length > 0
        ? compatibleGroupTypes(room.groupTypes[0]).length === 0
          ? 'This testing group can’t be combined with any other group at your test center.'
          : ''
        : 'Select a testing group first.',
    isDirty: false,
    makeCombined: !isEmpty(room) && room.groupTypes.length > 1,
    proctorSelected: generateStaffOptions(
      room.staff
        .filter((s) => s.role === 'admin' || s.role === 'proctor')
        .map((s) => ({
          ...s,
          room: s.room,
        }))
    ),
    roomMonitorsSelected: generateStaffOptions(
      room.staff
        .filter((s) => s.role === 'roomMonitor')
        .map((s) => ({
          ...s,
          room: s.room,
        }))
    ),
    showModal: false,
    staffToRoomStatusChanged: false,
    submitDisabled: true,
    submitErrors: [],
  };

  // Local state.
  const [localState, setLocalState] = React.useReducer(localStateReducer, {
    ...initialState,
  });

  // Apollo.
  const [deleteRoom] = useMutation(DELETE_ROOM);
  const [createRoom] = useMutation(CREATE_ROOM);
  const [updateRoom] = useMutation(UPDATE_ROOM);
  const [updateUsers] = useMutation(UPDATE_USERS);

  // If there is any update to our staff, re-render the localState.
  React.useEffect(() => {
    setLocalState({
      proctorSelected: generateStaffOptions(
        room.staff
          .filter((s) => s.role === 'admin' || s.role === 'proctor')
          .map((s) => ({
            ...s,
            room: s.room,
          }))
      ),
      roomMonitorsSelected: generateStaffOptions(
        room.staff
          .filter((s) => s.role === 'roomMonitor')
          .map((s) => ({
            ...s,
            room: s.room,
          }))
      ),
    });
  }, [room.staff]);

  const UI_PREFIX = 'room-form';

  const {
    //groupTypeMessage,
    form,
    formErrors,
    formSuccess,
    submitErrors,
  } = localState;

  //const goButtonRef = React.useRef();
  const isAdmin = hasAdminAccess(user.rid, user.role);
  const statusOptions = generateStatusOptions();

  const openedStyle = {
    opacity: 1,
    transition: 'opacity 1.8s',
  };

  /* This CSS style is applied when the drawer is closed */
  const closedStyle = {
    opacity: 0,
    position: 'absolute',
    left: '-9999999rem',
    transition: 'opacity 1.5s',
  };

  const showGroupType2 =
    localState.form.groupTypes[0] &&
    compatibleGroupTypes(localState.form.groupTypes[0]).length > 0 &&
    localState.makeCombined;

  let secondGroupTypeValues = [];

  if (localState.makeCombined) {
    // Check the group type values for compatible group types.
    secondGroupTypeValues = compatibleGroupTypes(localState.form.groupTypes[0]);

    if (secondGroupTypeValues.length > 1) {
      // Add a "select one" default option.
      secondGroupTypeValues.unshift({ value: '', label: 'Select a second testing group' });
    }
  }

  return (
    <div className='row'>
      <form className='cb-form col-md-8 col-lg-6' id={UI_PREFIX} tabIndex='-1' onSubmit={handleSubmit}>
        <ErrorMessages errorMessages={submitErrors} />
        <SuccessMessage successMessage={formSuccess} />
        <p className='mb-4'>* = Required</p>
        <div className='mb-4'>
          <Input
            disabled={!isAdmin}
            errorMessage={formErrors.title}
            floating={false}
            id={`${UI_PREFIX}-title`}
            label='Room Name'
            maxLength={100}
            name={`${UI_PREFIX}-title`}
            onChange={changeFormState}
            required={true}
            value={form.title ? form.title : ''}
          />
        </div>
        <div className='mb-4'>
          <Input
            disabled={!isAdmin}
            errorMessage={formErrors.capacity}
            floating={false}
            id={`${UI_PREFIX}-capacity`}
            label='Capacity'
            maxLength={4}
            name={`${UI_PREFIX}-capacity`}
            onChange={changeFormState}
            required={true}
            value={form.capacity ? form.capacity.toString() : ''}
          />
        </div>
        {form.id && (
          <fieldset className='mb-4'>
            <legend
              style={{
                borderBottom: 'none',
                fontFamily: 'inherit',
                fontSize: 'inherit',
                margin: 0,
                padding: 0,
              }}
            >
              {`Testing Group${showGroupType2 ? 's' : ''}`}
            </legend>
            <Select
              errorMessage={formErrors.groupTypes ? formErrors.groupTypes[0] : ''}
              id={`${UI_PREFIX}-groupTypes-0`}
              label='Testing Group 1'
              labelClassName='cb-sr-only'
              labelNoValue='Select'
              name={`${UI_PREFIX}-groupTypes-0`}
              onChange={(val) => {
                val = {
                  target: {
                    name: `${UI_PREFIX}-groupTypes-0`,
                    type: 'select',
                    value: val,
                  },
                };
                changeFormState(val);
              }}
              readOnly={!hasAdminAccess}
              value={localState.form.groupTypes.length ? localState.form.groupTypes[0] : '-'}
              values={generateGroupTypeOptions()}
            />
          </fieldset>
        )}

        {
          <div
            className='mb-4'
            id='group-type-2-wrapper'
            style={showGroupType2 ? { ...openedStyle } : { ...closedStyle }}
          >
            <Select
              disabled={showGroupType2}
              errorMessage={formErrors.groupTypes ? formErrors.groupTypes[1] : ''}
              id={`${UI_PREFIX}-groupTypes-1`}
              label='Testing Group 2'
              // labelClassName="cb-sr-only"
              name={`${UI_PREFIX}-groupTypes-1`}
              onChange={(val) => {
                val = {
                  target: {
                    name: `${UI_PREFIX}-groupTypes-1`,
                    type: 'select',
                    value: val,
                  },
                };
                changeFormState(val);
              }}
              readOnly={!hasAdminAccess}
              value={localState.form.groupTypes.length > 1 ? localState.form.groupTypes[1] : '-'}
              values={secondGroupTypeValues}
            />
          </div>
        }
        {form.id && (
          <div className='mb-4'>
            <Select
              errorMessage={formErrors.status}
              id={`${UI_PREFIX}-status`}
              label='Status'
              name={`${UI_PREFIX}-status`}
              onChange={(val) => {
                val = {
                  target: {
                    name: `${UI_PREFIX}-status`,
                    type: 'select',
                    value: val,
                  },
                };
                changeFormState(val);
              }}
              value={localState.form.status ? localState.form.status : '-'}
              values={statusOptions}
            />
          </div>
        )}

        {isAdmin && form.id && (
          <AddStaffSelection
            callback={updateStaffToRoomChangeStatus}
            key='addStaffToRoom'
            proctorSelected={localState.proctorSelected}
            roomMonitorsNeeded={roomMonitorRequirement}
            roomMonitorsSelected={localState.roomMonitorsSelected}
            staff={staff.filter((s) => s.role !== 'technologyCoordinator')}
          />
        )}

        {!localState.form.id && (
          <p className='mb-4'>You can auto-assign students to rooms when your total capacity is high enough.</p>
        )}

        <div className='display-flex justify-content-between flex-xs-column-only my-5'>
          {
            // delete button for digital tests
            orgEvent?.dapInd ? (
              <BlackButton
                small
                onClick={handleDelete}
                className='cb-margin-xs-top-16 order-xs-last-only'
                style={{
                  backgroundColor: '#F1C6D5',
                  border: '#F1C6D5',
                  color: '#AB2334',
                }}
              >
                Delete Room
              </BlackButton>
            ) : null
          }
          <div className='display-flex flex-xs-column-only'>
            <YellowButton
              disabled={localState.submitDisabled}
              small
              data-automation={`button-${localState.form.id ? 'update-room-form-confirm' : 'add-room-form-confirm'}`}
              type='submit'
            >
              {localState.form.id ? 'Update Room' : 'Add Room'}
            </YellowButton>
            <NakedButton
              data-automation='button-cancel-room'
              small
              onClick={handleCancel}
              className='cb-margin-xs-top-16 cb-margin-sm-up-left-16'
            >
              Cancel
            </NakedButton>
          </div>
        </div>
      </form>
      {!localState.form.id && (
        <div className='hidden-sm hidden-xs col-md-5'>
          <GroupsInfo
            description={[
              <p className='mb-4' key='addEnoughRooms'>
                Add enough rooms to seat students in these testing groups.
              </p>,
            ]}
            showHeading1={false}
            showHeading2={true}
            usePopovers={true}
          />
        </div>
      )}
    </div>
  );
}

RoomForm.propTypes = {
  room: PropTypes.object,
  staff: PropTypes.array,
  students: PropTypes.array,
  toggleForm: PropTypes.func,
};

export default RoomForm;
