import React from 'react';
import orderBy from 'lodash/orderBy';
import GroupInfoRow from './GroupInfoRow';
import Roster from '../Roster';
import { ResizeProvider } from '../../../context/ResizeContext';
import { getGroupTypes } from '../../../utils/getGroupTypes';
import { useStateValue } from '../../../context/AppContext';

function setGroupsOptions(students = [], groupTypes = {}) {
  // Get group types if needed.
  const groupsOptions = {};
  let regCount = 0;
  let waitCount = 0;

  students.forEach((student) => {
    if (groupTypes[student?.groupType]) {
      // Get the current count for this object if it exists.
      if (groupsOptions[student.groupType] !== undefined) {
        regCount = groupsOptions[student.groupType].regCount;
        waitCount = groupsOptions[student.groupType].waitCount;
      } else {
        regCount = 0;
        waitCount = 0;
      }
      groupsOptions[student.groupType] = {
        description: `${groupTypes[student.groupType]['description']}`,
        groupType: student.groupType,
        regCount: student.waitListFlag === 'N' ? ++regCount : regCount,
        standard: groupTypes[student.groupType]['standard'],
        title: groupTypes[student.groupType]['title'],
        waitCount: student.waitListFlag !== 'N' ? ++waitCount : waitCount,
        label: groupTypes[student.groupType]['label'] ? groupTypes[student.groupType]['label'] : '',
      };
    } else {
      console.error('Student group type not valid: ', JSON.stringify(student));
    }
  });

  const groupsInfo = orderBy(
    Object.keys(groupsOptions).map((key) => {
      const value = groupsOptions[key];
      return {
        groupType: key,
        ...value,
      };
    }),
    'groupType',
    'asc'
  );

  return groupsInfo;
}

interface GroupsInfoProps {
  description?: React.ReactElement[];
  showHeading1?: boolean;
  showHeading2?: boolean;
  showFilterLinks?: boolean;
  students?: any[];
  usePopovers?: boolean;
  stickyHeaders?: boolean;
  tableStyleReverseStripes?: boolean;
  tableStyleShadow?: boolean;
  tableStyleLight?: boolean;
  tableStyleBorderless?: boolean;
  forceDesktopMode?: boolean;
}

function GroupsInfo({
  description = [
    <div key='groupInfo-default'>
      Each group listed below includes students who can test in the same room. Registered students include only those
      students with a confirmed seat.
    </div>,
  ],
  showHeading1 = true,
  showHeading2 = false,
  showFilterLinks = true,
  students = [],
  usePopovers = false,
  stickyHeaders = false,
  tableStyleReverseStripes = false,
  tableStyleShadow = false,
  tableStyleLight = false,
  tableStyleBorderless = false,
  forceDesktopMode = false,
}: GroupsInfoProps) {
  // Global App state.
  const { orgEvent } = useStateValue();
  const groupTypes = getGroupTypes(orgEvent);

  const headers = [{ title: 'Testing Group:' }, { title: 'Registered Students:' }];

  const groupsInfo = React.useMemo(() => {
    return setGroupsOptions(students, groupTypes);
  }, [students, groupTypes]);

  // Pass a message if they don't have any group types.
  const emptyMessage = groupsInfo.length ? '' : 'Student roster information is unavailable.';

  return (
    <React.Fragment>
      {showHeading1 ? (
        <h1 className='tdtk-h1 py-4' data-automation='heading-h1'>
          Testing Groups ({`${groupsInfo.length}`})
        </h1>
      ) : null}
      {
        // Show an H2 if asked, but also make sure we don't accidentally have an H1.
        !showHeading1 && showHeading2 ? (
          <h2 className='tdtk-h2 my-4' data-automation='heading-h2'>
            Testing Groups ({`${groupsInfo.length}`})
          </h2>
        ) : null
      }

      {description}

      <React.Fragment>
        <ResizeProvider>
          <Roster
            emptyMessage={emptyMessage}
            headers={headers}
            items={groupsInfo}
            renderItem={(groupInfo, options) => (
              <GroupInfoRow
                key={`group_type_${groupInfo.groupType}`}
                groupInfo={groupInfo}
                usePopovers={usePopovers}
                forceDesktopMode={forceDesktopMode}
                showFilterLinks={showFilterLinks}
              />
            )}
            sortFields={[
              {
                name: 'groupType',
                order: 'asc',
              },
            ]}
            stickyHeaders={stickyHeaders}
            tableClassName='p-0 mb-0'
            tableStyleShadow={tableStyleShadow}
            tableStyleLight={tableStyleLight}
            tableStyleReverseStripes={tableStyleReverseStripes}
            tableStyleBorderless={tableStyleBorderless}
            forceDesktopMode={forceDesktopMode}
          />
        </ResizeProvider>
      </React.Fragment>
    </React.Fragment>
  );
}

export default GroupsInfo;
